import { useQuery } from '@tanstack/react-query';

import { getKpi } from '../../api/data';

export const useGetKpi = () => {
  return useQuery({
    queryKey: ['kpi'],
    queryFn: getKpi,
  });
};
