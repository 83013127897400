import blackrock from 'assets/imgs/new-partners/blackrock.svg';
import chainlink from 'assets/imgs/new-partners/chainlink.svg';
import circle from 'assets/imgs/new-partners/circle.svg';
import coinfirm from 'assets/imgs/new-partners/coinfirm.svg';
import fireblocks from 'assets/imgs/new-partners/fireblocks.svg';
import goldman from 'assets/imgs/new-partners/goldman.svg';
import hacken from 'assets/imgs/new-partners/hacken.svg';
import maker from 'assets/imgs/new-partners/maker.svg';
import mckinsey from 'assets/imgs/new-partners/mckinsey.svg';
import pwc from 'assets/imgs/new-partners/pwc.svg';
import atg from 'assets/imgs/partners/atg.svg';
import olx from 'assets/imgs/partners/olx.svg';

const partners = {
  bankingAndFinancial: {
    title: 'High-Quality Banking and Financial Partners',
    companies: [blackrock, circle],
  },
  security: {
    title: 'Institutional Grade Security',
    companies: [atg, hacken, fireblocks],
  },
  bestInClass: {
    title: 'Best-In-Class Partners',
    companies: [chainlink, coinfirm],
  },
  experiencedLeadership: {
    title: 'Experienced Leadership',
    companies: [maker, goldman, mckinsey, olx, pwc],
  },
};

const PartnerSection = ({ title, companies }: { title: string; companies: string[] }) => {
  return (
    <div className="flex flex-col gap-[40px] border-0 border-solid border-[#d6d6d6] pt-[40px] text-center first:!pl-0 last:border-0 last:!pr-0 max-xl:border-b xl:px-12">
      <h6 className="mx-auto my-0 h-auto w-full text-lg font-medium text-gray lg:h-[53px]">
        {title}
      </h6>
      <div className="flex flex-col items-center justify-between gap-[40px] px-[60px] max-xl:pb-[40px] lg:flex-row lg:flex-wrap lg:justify-center lg:px-0 xl:flex-col">
        {companies.map((company, index) => (
          <img
            className="max-w-full"
            key={`company-${index}`}
            src={company}
            alt={`partner-${index}`}
          />
        ))}
      </div>
    </div>
  );
};

export const Partners = () => {
  return (
    <section className="mt-[64px] flex flex-col bg-transparent lg:mt-[120px]">
      <h1 className="m-0 text-center text-[32px] font-semibold leading-[40px] text-stone-blue lg:text-[40px] lg:leading-[56px]">
        World Class Partners
      </h1>
      <div className="flex w-full flex-col justify-between lg:px-0 xl:grid xl:grid-cols-4 xl:divide-x xl:py-[40px]">
        {Object.entries(partners).map(([key, value], index) => (
          <PartnerSection key={key + index} title={value.title} companies={value.companies} />
        ))}
      </div>
    </section>
  );
};
