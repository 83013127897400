export const dateToFetchFrom = new Date(1672531200000);

export const getEndOfDayTimestamp = (date = new Date()) => {
  const endOfDay = new Date(date);
  endOfDay.setHours(23, 59, 59, 999);
  return Math.floor(endOfDay.getTime() / 1000);
};

export const getEndOfDayTimestampDaysAgo = (daysAgo: number) => {
  const now = new Date();
  now.setDate(now.getDate() - daysAgo);
  return getEndOfDayTimestamp(now);
};

export const ONE_DAY_IN_SECONDS = 24 * 60 * 60;
export const now = getEndOfDayTimestamp();
export const yearAgo = getEndOfDayTimestampDaysAgo(365);
