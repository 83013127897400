import React from 'react';
import { Link } from 'react-router-dom';

import { ConnectWalletButton } from '../components/ConnectWalletButton';

export function HeaderCabinet() {
  return (
    <div className="header-block">
      <Link to={'/mTBILL'} className="invest-btn !border-black !bg-white !text-[#14223D] transition">
        Invest
      </Link>
      <ConnectWalletButton style={{ marginLeft: '10px' }} />
    </div>
  );
}

export default HeaderCabinet;
