import { PlatformToken } from 'constants/tokens';

type PlatformTokenPageConfig = {
  name: string;
  description: string;
};

export const platformTokenPageConfig: Record<PlatformToken, PlatformTokenPageConfig> = {
  [PlatformToken.MTBILL]: {
    name: 'mTBILL',
    description: 'Permissionless yield-bearing token tracking short-term US Treasuries',
  },
  [PlatformToken.MBASIS]: {
    name: 'mBASIS',
    description:
      'Permissionless yield-bearing token tracking a crypto delta-neutral basis trading strategy',
  },
};
