export const Imprint = () => {
  return (
    <main className="works mb-[200px] !bg-transparent !text-stone-blue">
      <div className="block !bg-transparent">
        <div className="info !top-0 !rounded-b-[0] !bg-transparent">
          <h3 className="!pb-[16px]">Imprint</h3>
          <div className="w-full text-center text-[18px] text-stone-blue">
            Effective Date: 07 June 2023
          </div>
          <div className="mt-[64px] text-[20px] font-semibold">Processing of Personal Data</div>
          <div className="mt-[24px] text-justify text-[18px]">
            As a service provider we are responsible according to Section 7(1) German Telemedia Act
            for our own contents on these pages according to the general laws. Despite careful
            content control, we assume no liability for the content of external links. The operators
            of the linked pages are solely responsible for their content.
          </div>
          <div className="mt-[24px] text-justify text-[18px]">
            Our web pages and their contents are subject to German copyright law. Unless expressly
            permitted by law (Sections 44a et seq. of the copyright law), every form of utilizing,
            reproducing or processing works subject to copyright protection on our web pages requires
            the prior consent of the respective owner of the rights. Individual reproductions of a
            work are allowed only for private use, so must not serve either directly or indirectly
            for earnings. Unauthorized utilization of copyrighted works is punishable (Section 106 of
            the copyright law).
          </div>
          <div className="mt-[40px] text-[18px] font-semibold">Midas Software GmbH</div>
          {/* <div className="mt-[12px] text-justify text-[18px]">
            c/o gunnercooke Rechtsanwaltsgesellschaft mbH
          </div> */}
          <div className="mt-[6px] text-justify text-[18px]">Kurfürstendamm 15</div>
          <div className="mt-[6px] text-justify text-[18px]">10719 Berlin</div>
          {/*<div className="mt-[12px] text-justify text-[18px] text-[#0C1C46]">Phone: NUMBER</div>*/}
          <div className="mt-[6px] text-justify text-[18px]">
            Email:{' '}
            <a href="mailto:team@midas.app" className="text-stone-blue">
              team@midas.app
            </a>
          </div>
          <div className="mt-[40px] text-[18px] font-semibold">Represented by</div>
          <div className="mt-[12px] text-justify text-[18px]">Dennis Klaus Dinkelmeyer</div>
          <div className="mt-[40px] text-[18px] font-semibold">Register Entry</div>
          <div className="mt-[12px] text-justify text-[18px]">Register number: HRB 254645 B</div>
          <div className="mt-[12px] text-justify text-[18px]">
            Register court: Berlin-Charlottenburg
          </div>
        </div>
      </div>
    </main>
  );
};
