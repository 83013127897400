import { GET_USERS_WITH_ROLE } from 'graphql/queries.js/roles';
import { useDefaultSubgraphQuery } from 'hooks/useDefaultSubgraphQuery';
import { useGreenListUser } from 'hooks/useGreenlistUser';
import Aside from 'layouts/adminTab';
import Header from 'layouts/headerAdminTab';
import { Fragment, useMemo, useState } from 'react';
import { successToast, txErrorToast } from 'toasts';
import { isAddress, getAddress } from 'viem';
import { useConfig } from 'wagmi';

import { acRoles } from 'constants/roles';

import { NotificationModal } from '../../components/NotificationModal';
import { useKyc } from '../../hooks/useKyc';
import { getHumanError, isCustomError } from '../../utils/errors';

export function GreenList() {
  const { publicClient } = useConfig();
  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [isModalOpened, setIsModalOpened] = useState(false);
  const closeModal = () => setIsModalOpened(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { data: greenListedUsers, refetch } = useDefaultSubgraphQuery<any>(GET_USERS_WITH_ROLE, {
    variables: {
      role: acRoles.greenListed,
    },
  });

  const { greenListAsync, revokeGreenListAsync, role } = useGreenListUser();
  const { allKycData } = useKyc();

  const kycDatas = useMemo(() => {
    const addresses: string[] =
      greenListedUsers?.userRoles?.map(({ user }: { user: string }) => user) ?? [];

    return addresses.map(address => {
      const kycData = allKycData?.data?.find(
        kyc =>
          kyc.ethAddress?.toLowerCase() === address.toLowerCase() ||
          kyc?.user?.account?.address?.toLowerCase() === address.toLowerCase(),
      );

      return {
        address,
        email: kycData?.user?.email || '-',
        name: kycData?.individualKycData
          ? `${kycData.individualKycData.firstName} ${kycData.individualKycData.lastName}`
          : kycData?.entityKycData
          ? kycData.entityKycData.legalCompanyName
          : '-',
      };
    });
  }, [allKycData, greenListedUsers]);

  const onAddToGreenList = async () => {
    setIsLoading(true);
    const address = prompt('Enter a valid user address');

    if (!address || !isAddress(address)) {
      txErrorToast('Invalid address provided');
      setIsLoading(false);
      return;
    }

    try {
      await publicClient.waitForTransactionReceipt(await greenListAsync({ args: [role, address] }));
      successToast('User added to green list');
      refetch();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      const error = getHumanError(e.shortMessage);
      setErrorText(
        `Error while sending transaction: ${isCustomError(error) ? e.shortMessage : error}`,
      );
      setIsModalOpened(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onRemoveFromGreenList = async (address: string) => {
    setIsLoading(true);
    if (!isAddress(address)) {
      txErrorToast('Invalid address provided');
      setIsLoading(false);
      return;
    }

    try {
      await publicClient.waitForTransactionReceipt(
        await revokeGreenListAsync({ args: [role as `0x${string}`, address as `0x${string}`] }),
      );
      successToast('User removed from green list');
      refetch();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      const error = getHumanError(e.shortMessage);
      setErrorText(
        `Error while sending transaction: ${isCustomError(error) ? e.shortMessage : error}`,
      );
      setIsModalOpened(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="admin">
      <NotificationModal
        isModalOpened={isModalOpened}
        closeModal={closeModal}
        onBtnClick={closeModal}
        content={{
          bodyContent: errorText,
          headerContent: <>Sorry.</>,
          btnContent: 'Close',
        }}
      />
      <Aside></Aside>
      <section>
        <Header></Header>
        <div className="lists">
          <h5 className="!text-black">Green-List.</h5>
          <button
            className="add-wallet-to-list add-btn"
            onClick={onAddToGreenList}
            disabled={isLoading}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M14.5938 6.59375H9.40625V1.40625C9.40625 0.629594 8.77666 0 8 0C7.22334 0 6.59375 0.629594 6.59375 1.40625V6.59375H1.40625C0.629594 6.59375 0 7.22334 0 8C0 8.77666 0.629594 9.40625 1.40625 9.40625H6.59375V14.5938C6.59375 15.3704 7.22334 16 8 16C8.77666 16 9.40625 15.3704 9.40625 14.5938V9.40625H14.5938C15.3704 9.40625 16 8.77666 16 8C16 7.22334 15.3704 6.59375 14.5938 6.59375Z"
                fill="black"
              />
            </svg>
            {isLoading ? 'Processing...' : 'Add to green-list'}
          </button>
          <div className="table-list">
            <div className="table-info">
              <div className="head-table">
                <div className="name-cell">
                  <p>Wallet ID</p>
                </div>
                <div className="name-cell">
                  <p>Name</p>
                </div>
                <div className="name-cell">
                  <p>Email</p>
                </div>
                <div className="name-cell">
                  <p></p>
                </div>
              </div>
              {kycDatas.map(({ address, email, name }) => (
                <Fragment key={address}>
                  <div className="table-row">
                    <div className="table-cell">
                      <p>{getAddress(address)}</p>
                    </div>
                    <div className="table-cell">
                      <p>{name}</p>
                    </div>
                    <div className="table-cell">
                      <p>{email}</p>
                    </div>
                    <div
                      className="table-cell"
                      onClick={() => {
                        onRemoveFromGreenList(getAddress(address));
                      }}
                    >
                      <p className="link">Remove</p>
                    </div>
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default GreenList;
