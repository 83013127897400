export const MidasMLogo = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="85.23 76.94 589.3 140.5"
      width="157"
      height="37"
    >
      <g>
        <path
          fill="#000"
          d="m410.12,110.11l-20.69,51.56-20.9-51.56h-21.43v74.47h18.14v-44.77l16.76,44.77h14.64l16.66-44.66v44.66h18.14v-74.47h-21.32Z"
        />
        <path fill="#000" d="m444.43,110.11v74.47h18.14v-74.47h-18.14Z" />
        <path
          fill="#000"
          d="m503.22,110.11h-27.9v74.47h27.9c23.77,0,39.04-15.17,39.04-37.24s-15.27-37.23-39.04-37.23Zm-1.17,58.77h-8.59v-43.28h8.59c13.69,0,21.75,8.06,21.75,21.74s-8.06,21.54-21.75,21.54Z"
        />
        <path
          fill="#000"
          d="m589.79,110.11h-21l-26.95,74.47h18.99l4.46-13.15h27.79l4.46,13.15h19.2l-26.95-74.47Zm-19.73,47.31l9.12-27.26,9.24,27.26h-18.36Z"
        />
        <path
          fill="#000"
          d="m637.72,130.37c0-4.67,3.29-6.9,7.64-6.79,4.88.11,8.27,2.97,8.59,7.74h19.63c-.74-14.21-11.67-22.28-27.9-22.28s-27.16,7.86-27.16,21.97c-.22,26.41,37.02,18.99,37.02,32.78,0,4.35-3.39,7-8.7,7s-8.7-2.76-9.23-8.49h-19.31c.53,15.07,13.05,23.03,29.07,23.03,17.4,0,27.16-10.4,27.16-22.92,0-24.93-36.81-19.1-36.81-32.04Z"
        />
      </g>
      <g>
        <path
          fill="#000"
          d="m114.37,155.43v-41.12c0-1.52-.81-2.93-2.13-3.69l-21.32-12.31c-2.53-1.46-5.69.37-5.69,3.29v74.69c0,1.35.72,2.61,1.9,3.28l64.68,37.35c2.53,1.46,5.69-.37,5.69-3.28v-24.62c0-1.52-.81-2.93-2.13-3.69l-35.61-20.56c-3.32-1.91-5.39-5.5-5.39-9.34Z"
        />
        <path
          fill="#000"
          d="m264.01,158.71c1.17-.68,1.89-1.93,1.89-3.28v-74.7c0-2.92-3.16-4.74-5.68-3.28l-64.69,37.35c-1.17.67-2.62.67-3.79,0l-64.69-37.35c-2.52-1.46-5.68.36-5.68,3.28v74.7c0,1.35.72,2.6,1.89,3.28l34.24,19.77,7,4.04,27.24,15.73c1.17.67,2.62.67,3.79,0l27.24-15.73,7-4.04,34.24-19.77Z"
        />
        <path
          fill="#000"
          d="m296.35,98.31l-21.32,12.31c-1.32.76-2.13,2.17-2.13,3.69v41.12c0,3.84-2.07,7.42-5.4,9.34l-35.6,20.56c-1.32.76-2.13,2.17-2.13,3.69v24.62c0,2.91,3.16,4.74,5.68,3.28l64.69-37.35c1.17-.67,1.9-1.93,1.9-3.28v-74.69c0-2.92-3.16-4.75-5.69-3.29Z"
        />
      </g>
    </svg>
  );
};
