import { PlatformToken } from 'constants/tokens';

import { useMbasisTokenData } from './useMbasisTokenData';
import { useMtbillTokenData } from './useMtbillTokenData';

export const useTokensData = (platformToken: PlatformToken) => {
  const mtbillData = useMtbillTokenData();
  const mbasisData = useMbasisTokenData();

  if (platformToken === PlatformToken.MTBILL) {
    return mtbillData;
  } else if (platformToken === PlatformToken.MBASIS) {
    return mbasisData;
  } else {
    throw new Error('Invalid platformToken value');
  }
};
