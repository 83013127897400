import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useAuth } from './useAuth';

import {
  getKycAdminAddress,
  patchAcceptKyc,
  patchRejectKyc,
  patchRequestChangesKyc,
} from '../api/kyc';

export const useKycByAddress = (address?: string) => {
  const { jwt, isAdmin } = useAuth();

  const queryClient = useQueryClient();

  const { data: kycData, refetch: refetchKycData } = useQuery({
    queryKey: ['adminKycByAddress', jwt, address],
    queryFn: () => getKycAdminAddress(jwt, address),
    enabled: isAdmin && !!address,
  });

  const { mutateAsync: acceptKyc, isLoading: isAcceptingKyc } = useMutation({
    mutationFn: () => patchAcceptKyc(jwt, address),
    onSuccess: () => {
      return queryClient.invalidateQueries(['adminKycByAddress', 'adminKycAll']);
    },
  });

  const { mutateAsync: rejectKyc, isLoading: isRejectingKyc } = useMutation({
    mutationFn: (revisionComment: string) => patchRejectKyc(jwt, address, revisionComment),
    onSuccess: () => {
      return queryClient.invalidateQueries(['adminKycByAddress', 'adminKycAll']);
    },
  });

  const { mutateAsync: requestChangesKyc, isLoading: isRequestingChangesKyc } = useMutation({
    mutationFn: (revisionComment: string) => patchRequestChangesKyc(jwt, address, revisionComment),
    onSuccess: () => {
      return queryClient.invalidateQueries(['adminKycByAddress', 'adminKycAll']);
    },
  });

  return {
    kycData,
    acceptKyc,
    isAcceptingKyc,
    rejectKyc,
    isRejectingKyc,
    requestChangesKyc,
    isRequestingChangesKyc,
    refetchKycData,
  };
};
