import { mtbillCardsData } from 'constants/mtbill/featureCards';

import FeatureCard from './FeatureCard';

export const MtbillFeatures = () => {
  return (
    <div className="mt-[56px] flex flex-col gap-6 md:mt-[80px] md:gap-10">
      <h3 className="text-2xl font-bold text-[#0C1C46] md:text-[40px] md:leading-[48px]">
        Features
      </h3>
      <div className="grid grid-cols-1 gap-4 xl:grid-cols-3">
        {mtbillCardsData.map(cardData => (
          <FeatureCard {...cardData} />
        ))}
      </div>
    </div>
  );
};
