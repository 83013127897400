import type { ToastOptions } from 'react-hot-toast';
import { toast } from 'react-hot-toast';
import { matchErrorMessage } from 'utils/regex/txErroMessage';

export const txErrorToast = (message: string, params?: ToastOptions) => {
  const matchedError = matchErrorMessage(message);

  return toast.error(
    matchedError ? `Error while sending the transaction: ${matchedError}` : message,
    params,
  );
};

export const successToast = (message: string, params?: ToastOptions) =>
  toast.success(message, params);
