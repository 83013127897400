import defiSvg from '../../assets/imgs/feature-highlights/defi.svg';
import gradeSvg from '../../assets/imgs/feature-highlights/grade.svg';
import regulatorySvg from '../../assets/imgs/feature-highlights/regulatory.svg';

type FeatureHighlightsConfig = {
  id: string;
  name: string;
  icon: string;
  color: string;
  features: string[];
};

export const featureHighlightsConfig: FeatureHighlightsConfig[] = [
  {
    id: '1-institutional-grade',
    name: 'Institutional Grade',
    icon: gradeSvg,
    color: '#fdf7fe',
    features: [
      'Assets Protected from Managerial Risks',
      'Transparent and Independent Portfolio Verification',
    ],
  },
  {
    id: '2-deFi-composability',
    name: 'DeFi Composability',
    icon: defiSvg,
    color: '#eefbf9',
    features: ['Freely Transferable and Permissionless', 'Yield-Bearing Collateral'],
  },
  {
    id: '3-regulatory-compliant',
    name: 'Regulatory Compliant',
    icon: regulatorySvg,
    color: '#def3ff',
    features: [
      'Fully Compliant with European Securities Regulations',
      // 'Holders possess Full Legal Rights to the Assets',
    ],
  },
];
