import { useMutation } from '@tanstack/react-query';

import { postContactUs, postSubscribeOnUpdates, postWaitlist } from '../api/email';

export const useEmail = () => {
  const { mutateAsync: joinWaitlist, isLoading: isJoiningWaitingList } = useMutation({
    mutationFn: postWaitlist,
  });

  const { mutateAsync: subscribeOnUpdates, isLoading: isSubscribingOnUpdates } = useMutation({
    mutationFn: postSubscribeOnUpdates,
  });

  const { mutateAsync: contactUs, isLoading: isContactingUs } = useMutation({
    mutationFn: postContactUs,
  });

  return {
    joinWaitlist,
    isJoiningWaitingList,
    subscribeOnUpdates,
    isSubscribingOnUpdates,
    contactUs,
    isContactingUs,
  };
};
