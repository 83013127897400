import { useQuery } from '@tanstack/react-query';

import { useAuth } from './useAuth';

import { getContactUsAdmin, getSubscribeOnUpdatesAdmin, getWaitlistAdmin } from '../api/admin';

export const useAdminInfo = () => {
  const { jwt } = useAuth();

  const { data: waitlistedUsers } = useQuery({
    queryKey: ['waitlist-admin', jwt],
    queryFn: () => getWaitlistAdmin(jwt),
    refetchInterval: 60000,
    refetchIntervalInBackground: true,
  });

  const { data: subscribedOnUpdatesUsers } = useQuery({
    queryKey: ['subscribe-on-updates-admin', jwt],
    queryFn: () => getSubscribeOnUpdatesAdmin(jwt),
    refetchInterval: 60000,
    refetchIntervalInBackground: true,
  });

  const { data: contactRequests } = useQuery({
    queryKey: ['contact-us-admin', jwt],
    queryFn: () => getContactUsAdmin(jwt),
    refetchInterval: 60000,
    refetchIntervalInBackground: true,
  });

  return {
    waitlistedUsers,
    subscribedOnUpdatesUsers,
    contactRequests,
  };
};
