import { BigNumber, constants } from 'ethers';
import { parseUnits } from 'ethers/lib/utils';

import { getExplorerByNetwork } from 'constants/explorers';

import { comaFormat } from './comaFormat';
import { txToExplorerUri } from './explorer';
import { formatBN } from './formatBN';

export const transactionsToComponentData = (network, data) => {
  if (!data) {
    return {
      investments: [],
      redemptions: [],
      mints: [],
      all: [],
    };
  }

  const parsedData = {
    investments:
      data.deposits
        ?.map(val => {
          const fee = BigNumber.from(val.fee ?? constants.Zero);
          const investment = BigNumber.from(val.amountUsdIn);
          const feePercent = fee
            .mul(10000)
            .div(fee.add(investment).isZero() ? constants.One : fee.add(investment));

          return {
            id: val.id,
            mToken: val.mToken,
            wallet: val.user,
            transactionType: 'Invest',
            asset: `${comaFormat(formatBN(val.amountUsdIn, 0))}`,
            assetName: `USD`,
            investment: `${comaFormat(formatBN(investment, 0))}`,
            explorerLink: txToExplorerUri(
              getExplorerByNetwork(network),
              val.creationTransactionHash,
            ),
            exchangeRatio:
              !val.isFulfilled || val.isCancelled
                ? '-'
                : formatBN(
                    BigNumber.from(val.amountUsdIn).mul(parseUnits('1')).div(val.amountStUsdOut),
                    2,
                  ),
            fee: fee.isZero()
              ? '-'
              : `${comaFormat(formatBN(fee, 2))} (${comaFormat(formatBN(feePercent, 2, 2))}%)`,
            date: new Date(+val.createdAt * 1000),
            rawValues: val,
          };
        })
        ?.sort((a, b) => b.date.getTime() - a.date.getTime()) ?? [],
    mints:
      data.mints
        ?.map(val => {
          const investment = BigNumber.from(val.amount);

          return {
            id: val.id,
            wallet: val.to,
            transactionType: 'Issuance',
            asset: `${comaFormat(formatBN(val.amount, 0))}`,
            assetName: `mTBILL`,
            investment: `${comaFormat(formatBN(investment, 0))}`,
            explorerLink: txToExplorerUri(getExplorerByNetwork(network), val.txHash),
            exchangeRatio: 0,
            fee: 0,
            date: new Date(+val.timestamp * 1000),
            rawValues: val,
          };
        })
        ?.sort((a, b) => b.date.getTime() - a.date.getTime()) ?? [],
    redemptions:
      data.redemptionVaultRedeemRequests
        ?.map(val => {
          const fee = BigNumber.from(val.fee ?? constants.Zero);
          const asset = BigNumber.from(val.amountStUsdIn);
          const feePercent = fee
            .mul(10000)
            .div(fee.add(asset).isZero() ? constants.One : fee.add(asset));

          return {
            id: val.id,
            wallet: val.user,
            transactionType: 'Redeem',
            asset: `${comaFormat(formatBN(val.amountStUsdIn, 0))}`,
            assetName: `mTBILL`,
            investment: val.isCancelled
              ? 'Cancelled'
              : val.isFulfilled
              ? `${comaFormat(formatBN(val.amountUsdOut, 0))}`
              : 'Pending',
            explorerLink: txToExplorerUri(
              getExplorerByNetwork(network),
              val.creationTransactionHash,
            ),
            exchangeRatio:
              !val.isFulfilled || val.isCancelled
                ? '-'
                : formatBN(
                    BigNumber.from(val.amountUsdOut).mul(parseUnits('1')).div(val.amountStUsdIn),
                    2,
                  ),
            fee: fee.isZero()
              ? '-'
              : `${comaFormat(formatBN(fee, 2))} (${comaFormat(formatBN(feePercent, 2, 2))}%)`,
            date: new Date(+val.createdAt * 1000),
            rawValues: val,
          };
        })
        ?.sort((a, b) => b.date.getTime() - a.date.getTime()) ?? [],
  };

  return {
    ...parsedData,
    all: [...parsedData.investments, ...parsedData.mints, ...parsedData.redemptions].sort(
      (a, b) => b.date.getTime() - a.date.getTime(),
    ),
  };
};
