import gear from 'assets/imgs/admin/gear 1.svg';
import { ConnectWalletButton } from 'components/ConnectWalletButton';
import { Link } from 'react-router-dom';

export function HeaderAdminTab() {
  return (
    <>
      <header>
        <ConnectWalletButton />
        <div>
          <Link to={'/'}>
            <img src={gear} alt="gear" />
          </Link>
        </div>
      </header>
    </>
  );
}

export default HeaderAdminTab;
