import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import jwt_decode from 'jwt-decode';
import { useCallback, useMemo } from 'react';
import { useAccount, useSignMessage } from 'wagmi';

import { getUser, postUser, putUserConfirmation } from '../api/auth';
import { sign } from '../utils/web3-token/sign';
import { verify } from '../utils/web3-token/verify';

const domain = process.env.REACT_APP_FRONTEND_URL as string;

export const useAuth = (email?: string) => {
  const queryClient = useQueryClient();

  const { address } = useAccount();

  const { data: jwt } = useQuery({
    queryKey: ['jwt', address],
    queryFn: () => localStorage.getItem('accessToken') ?? null,
  });

  const { data: jwtEmail } = useQuery({
    queryKey: ['jwtEmail', email],
    queryFn: () => localStorage.getItem('emailAccessToken') ?? null,
    refetchInterval: 10000,
    refetchIntervalInBackground: true,
  });

  const { data: isJwtValid } = useQuery({
    queryKey: ['validateAccessToken', jwt, address],
    queryFn: () => {
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        try {
          const { address: jwtAddress } = verify(accessToken, {
            domain,
          });
          return jwtAddress.toLowerCase() === address?.toLowerCase();
        } catch (e) {
          return false;
        }
      }
      return false;
    },
    refetchInterval: 60000,
    refetchIntervalInBackground: true,
  });

  const { data: isEmailJwtValid } = useQuery({
    queryKey: ['validateEmailAccessToken', jwtEmail, email],
    queryFn: () => {
      const accessToken = localStorage.getItem('emailAccessToken');
      if (accessToken) {
        try {
          const { exp, email: emailJwt } = jwt_decode(accessToken) as {
            email: string;
            exp: number;
          };

          if (emailJwt != email) {
            console.log('emails are different', { email, emailJwt });
            return false;
          } else {
            console.log('emails are equal');
          }

          const currentDate = new Date();

          const isValid = exp * 1000 >= currentDate.getTime();
          console.log({ isValid, exp, emailJwt });
          return isValid;
        } catch (e) {
          return false;
        }
      }
      return false;
    },
    refetchInterval: 10000,
    refetchIntervalInBackground: true,
  });

  const { data: user } = useQuery({
    queryKey: ['user', jwt],
    queryFn: () => getUser(jwt),
  });

  const { signMessageAsync } = useSignMessage();

  const { mutate: signMessage, isLoading: isSigningMessage } = useMutation({
    mutationFn: () => {
      return sign(
        async message => {
          return await signMessageAsync({ message });
        },
        {
          domain,
          nonce: Math.round(Math.random() * 1_000_000 + 1),
          expires_in: '1d',
        },
      );
    },
    onSuccess: accessToken => {
      localStorage.setItem('accessToken', accessToken);
      return queryClient.invalidateQueries(['jwt']);
    },
  });

  const signIn = useCallback(() => {
    if (!isJwtValid) {
      signMessage();
    }
  }, [isJwtValid, signMessage]);

  const { mutateAsync: createUser } = useMutation({
    mutationFn: (email: string) => postUser(jwt, email),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ['user'] });
    },
  });

  const { mutateAsync: resendConfirmationLink, isLoading: isResendingLink } = useMutation({
    mutationFn: () => putUserConfirmation(email),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ['user'] });
    },
  });

  const isAdmin = useMemo(() => {
    if (!user) {
      return undefined;
    }
    return !!user.adminUser && isJwtValid;
  }, [user, isJwtValid]);

  return {
    user,
    isAdmin,
    createUser,
    signIn,

    isJwtValid,
    isEmailJwtValid,
    jwt,
    jwtEmail,
    isLoading: isSigningMessage,
    resendConfirmationLink,
    isResendingLink,
  };
};
