import Aside from 'layouts/adminTab';
import Header from 'layouts/headerAdminTab';
import React from 'react';

import { useApiWallet } from '../../hooks/useApiWallet';
import { dateToShorten } from '../../utils/date';

export const Wallets = () => {
  const { connectedWallets } = useApiWallet();

  return (
    <div className="admin">
      <Aside />
      <section>
        <Header />
        <div className="lists">
          <h5 className="!text-black">Connected Wallets.</h5>
        </div>
        <div className="relative">
          <table className="w-full table-fixed border-collapse">
            <thead
              style={{
                borderTop: '1px solid #A5A5A5',
                borderBottom: '1px solid #A5A5A5',
              }}
            >
              <tr className="!text-[14px] text-black">
                <td className="p-[16px] font-semibold">First connected, UTC</td>
                <td className="p-[16px] font-semibold">Wallet</td>
                <td className="p-[16px] font-semibold">Last connected, UTC</td>
              </tr>
            </thead>
            <tbody>
              {connectedWallets?.wallets.map(wallet => (
                <tr key={wallet.address} className="odd:bg-[#F0F0F0]">
                  <td className="p-[16px]">{dateToShorten(new Date(wallet.createdAt), true)}</td>
                  <td className="p-[16px]">{wallet.address}</td>
                  <td className="p-[16px]">{dateToShorten(new Date(wallet.updatedAt), true)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};
