import { getAddress } from '@ethersproject/address';
import { ethers } from 'ethers';
import { mainnet, sepolia } from 'wagmi';

import mBASISIcon from '../../assets/imgs/new/mbasis.svg';
import mTBILLIcon from '../../assets/imgs/new/mtbill.svg';
import daiIcon from '../../assets/tokens/dai.svg';
import usdcIcon from '../../assets/tokens/usdc.svg';
import usdtIcon from '../../assets/tokens/usdt.svg';

export enum PlatformToken {
  MTBILL = 'mTBILL',
  MBASIS = 'mBASIS',
}

export type TokenName = 'DAI' | 'USDC' | 'USDT' | 'mTBILL' | 'USD' | 'mBASIS';

export type Token = {
  name: TokenName;
  address: `0x${string}`;
  decimals: number;
  icon?: typeof daiIcon | null;
};

export type Tokens = {
  stableCoins: Token[];
  mTBILL: Token;
  mBASIS: Token;
  usdCoin: Token;
};

export type TokensByNetwork = Record<string, Tokens>;

export const tokens: TokensByNetwork = {
  [mainnet.network]: {
    stableCoins: [
      {
        name: 'USDC',
        address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
        decimals: 6,
        icon: usdcIcon,
      },
    ],
    mTBILL: {
      name: 'mTBILL',
      address: '0xDD629E5241CbC5919847783e6C96B2De4754e438',
      decimals: 18,
      icon: mTBILLIcon,
    },
    mBASIS: {
      name: 'mBASIS',
      address: '0x2a8c22E3b10036f3AEF5875d04f8441d4188b656',
      decimals: 18,
      icon: mBASISIcon,
    },
    usdCoin: {
      name: 'USD',
      address: ethers.constants.AddressZero,
      decimals: 18,
      icon: null,
    },
  },
  [sepolia.network]: {
    stableCoins: [
      {
        name: 'USDC',
        address: '0xF55588f2f8CF8E1D9C702D169AF43c15f5c85f12',
        decimals: 6,
        icon: usdcIcon,
      },
      {
        name: 'DAI',
        address: '0x98c9b17BC83904Dd5987488152AceAe08b7668B5',
        decimals: 18,
        icon: daiIcon,
      },
      {
        name: 'USDT',
        address: '0x7D2feA7f7a2688E43B4393f8272EACE11e9877b0',
        decimals: 18,
        icon: usdtIcon,
      },
    ],
    mTBILL: {
      name: 'mTBILL',
      address: '0xefED40D1eb1577d1073e9C4F277463486D39b084',
      decimals: 18,
      icon: mTBILLIcon,
    },
    mBASIS: {
      name: 'mBASIS',
      address: '0x4089dC8b6637218f13465d28950A82a7E90cBE27',
      decimals: 18,
      icon: mBASISIcon,
    },
    usdCoin: {
      name: 'USD',
      address: ethers.constants.AddressZero,
      decimals: 18,
      icon: null,
    },
  },
};

export const getTokenByAddress = (network: string | undefined, address: string) => {
  network = network ?? mainnet.network;
  if (!tokens[network]) network = mainnet.network;
  if (tokens[network].usdCoin.address === address) return tokens[network].usdCoin;
  const foundToken = tokens[network].stableCoins.find(
    v => getAddress(v.address) === getAddress(address),
  );

  if (foundToken) return foundToken;

  return (Object.values(tokens[network]).filter(s => !Array.isArray(s)) as Token[]).find(
    v => getAddress(v.address) === getAddress(address),
  );
};

export const getTokenByName = (network?: string, name?: TokenName) => {
  if (!network || !tokens[network]) network = mainnet.network;
  return (
    tokens[network ?? mainnet.network].stableCoins.find(v => v.name === name) ??
    tokens[mainnet.network].stableCoins[0]
  );
};

export const getStableCoins = (network: string) => {
  if (!tokens[network]) network = mainnet.network;
  return tokens[network ?? mainnet.network].stableCoins;
};

export const getMtbillToken = (network?: string) => {
  if (!network || !tokens[network]) network = mainnet.network;
  return tokens[network ?? mainnet.network].mTBILL;
};

export const getMbasisToken = (network?: string) => {
  if (!network || !tokens[network]) network = mainnet.network;
  return tokens[network ?? mainnet.network].mBASIS;
};
