import React from 'react';
import { Outlet } from 'react-router-dom';

import { Auth } from '../../components/Auth';
import { useAuth } from '../../hooks/useAuth';

export const AdminLayout = () => {
  const { isAdmin } = useAuth();

  return isAdmin ? <Outlet /> : <Auth />;
};
