import Aside from 'layouts/adminTab';
import Header from 'layouts/headerAdminTab';
import React, { useMemo, useState } from 'react';

import { useAdminInfo } from '../../hooks/useAdminInfo';
import { downloadCsvFromObj } from '../../utils/csv';
import { dateToShorten } from '../../utils/date';

export const Waitlist = () => {
  const { waitlistedUsers } = useAdminInfo();
  const [isDownloading, setIsDownloading] = useState(false);

  const dataToDownload = useMemo(
    () =>
      waitlistedUsers?.joined.map(user => ({
        Date: dateToShorten(new Date(user.createdAt), true),
        Email: user.email.email,
      })) ?? [],
    [waitlistedUsers],
  );

  return (
    <div className="admin">
      <Aside />
      <section>
        <Header />
        <div className="lists">
          <h5 className="!text-black">Waitlisters.</h5>
        </div>
        <div className="relative">
          <table className="w-full table-auto border-collapse">
            <thead
              style={{
                borderTop: '1px solid #A5A5A5',
                borderBottom: '1px solid #A5A5A5',
              }}
            >
              <tr className="!text-[14px] text-black">
                <td className="p-[16px] font-semibold">Date, UTC</td>
                <td className="p-[16px] font-semibold">Email</td>
              </tr>
            </thead>
            <tbody>
              {dataToDownload.map(({ Email: email, Date: date }) => (
                <tr key={email} className="odd:bg-[#F0F0F0]">
                  <td className="p-[16px]">{date}</td>
                  <td className="p-[16px]">{email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex items-center gap-2 !py-0">
          <button
            onClick={async () => {
              if (!isDownloading) {
                setIsDownloading(true);
                try {
                  await downloadCsvFromObj('waitlister.csv', dataToDownload);
                } catch (error) {
                  console.error("Couldn't download CSV", error);
                } finally {
                  setIsDownloading(false);
                }
              }
            }}
            className="cursor-pointer rounded-[40px] border-[1px] border-black bg-black px-[16px] py-[8px] text-[16px] font-medium text-white transition-all hover:bg-white hover:text-black"
          >
            {isDownloading ? 'Processing...' : 'Export to CSV'}
          </button>
        </div>
      </section>
    </div>
  );
};
