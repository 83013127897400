import { TransactionsTable } from 'components/TransactionsTable';
import { GET_USER_TRANSACTIONS_HISTORY } from 'graphql/queries.js/transactions';
import { useDefaultSubgraphQuery } from 'hooks/useDefaultSubgraphQuery';
import { useMemo } from 'react';
import { transactionsToComponentData } from 'utils/parsers';
import { useAccount, useNetwork } from 'wagmi';

import { CabinetMenu } from '../components/CabinetMenu';
import HeaderCabinet from '../layouts/headerCabinet';

export function Cabinet() {
  const { address } = useAccount();

  const { chain } = useNetwork();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { data: oldData } = useDefaultSubgraphQuery<any>(GET_USER_TRANSACTIONS_HISTORY, {
    variables: {
      userAddress: address,
    },
  });
  console.log({ oldData });

  const data = useMemo(() => {
    return {
      deposits: (oldData?.depositVaultDepositRequests ?? []).concat(
        oldData?.manualDepositEntities ?? [],
      ),
      mints: oldData?.mints ?? [],
      redemptionVaultRedeemRequests: (oldData?.redemptionVaultRedeemRequests ?? []).concat(
        oldData?.manualRedeemEntities ?? [],
      ),
    };
  }, [oldData]);

  console.log({ data111: data });
  const parsedTxData = transactionsToComponentData(chain?.name, data);

  return (
    <div className="main-cabinet">
      <CabinetMenu />
      <div className="content-block">
        <HeaderCabinet />
        <TransactionsTable parsedTxData={parsedTxData.all} title="Transaction History" />
      </div>
    </div>
  );
}

export default Cabinet;
