export const EmailIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0664 0.265625H1.93359C0.865262 0.265625 0 1.13626 0 2.19922V13.8008C0 14.8701 0.871621 15.7344 1.93359 15.7344H20.0664C21.1258 15.7344 22 14.8737 22 13.8008V2.19922C22 1.13815 21.1382 0.265625 20.0664 0.265625ZM19.7956 1.55469C19.4006 1.94764 12.602 8.7104 12.3673 8.9439C12.002 9.30913 11.5165 9.51022 11 9.51022C10.4835 9.51022 9.99797 9.30909 9.63153 8.94269C9.47366 8.78564 2.75013 2.09755 2.20438 1.55469H19.7956ZM1.28906 13.5384V2.4624L6.85945 8.00344L1.28906 13.5384ZM2.2052 14.4453L7.77339 8.91253L8.72124 9.85539C9.32993 10.4641 10.1392 10.7993 11 10.7993C11.8608 10.7993 12.6701 10.4641 13.2776 9.85659L14.2266 8.91253L19.7948 14.4453H2.2052ZM20.7109 13.5384L15.1406 8.00344L20.7109 2.4624V13.5384Z"
        fill="#7E8BA4"
      />
    </svg>
  );
};
