import type { ApolloClientOptions, NormalizedCacheObject } from '@apollo/client';
import { ApolloClient, InMemoryCache } from '@apollo/client';

export const getClient = (
  uri: string,
  params?: Omit<ApolloClientOptions<NormalizedCacheObject>, 'uri' | 'cache'>,
) =>
  new ApolloClient({
    uri: uri,
    cache: new InMemoryCache(),
    ...params,
  });
