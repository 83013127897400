import React from 'react';

export const TermsAndConditions = () => {
  return (
    <main className="works mb-[200px] !bg-transparent !text-stone-blue">
      <div className="block !bg-transparent">
        <div className="info !top-0 !rounded-b-[0] !bg-transparent">
          <h3 className="!pb-[24px] !text-stone-blue">General Terms and Conditions</h3>
          <div className="w-full text-justify text-[18px] text-stone-blue">
            <b>
              PLEASE READ THESE GENERAL TERMS AND CONDITIONS CAREFULLY AS THEY AFFECT YOUR LEGAL
              RIGHTS AND OBLIGATIONS. THE INFORMATION CONTAINED ON THE WEBSITE IS DESCRIPTIVE ONLY,
              IS NOT BINDING AND DOES NOT FORM PART OF THESE GENERAL TERMS AND CONDITIONS.
            </b>
          </div>
          <div className="mt-[12px] w-full text-justify text-[18px] text-stone-blue">
            <b>
              NO REGULATOR HAS AUTHORISED OR APPROVED THESE GENERAL TERMS AND CONDITIONS. YOU SHOULD
              BE AWARE OF THE RISKS ASSOCIATED WITH PARTICIPATING IN TOKENS, INCLUDING AS REGARDS (I)
              THE POTENTIAL FOR (TOTAL) CAPITAL LOSS; (II) THE FACT THAT TOKENS MAY NOT ALWAYS BE
              TRANSFERRABLE; AND (III) THE FACT THAT TOKENS MAY NOT BE LIQUID.
            </b>
          </div>
          <div className="mt-[12px] w-full text-justify text-[18px] text-stone-blue">
            <b>
              DEALING IN TOKENS WILL EXPOSE YOU TO RISK, INCLUDING THE RISK OF LOSING ALL OF THE
              VALUE OF YOUR INVESTMENT. YOU DO NOT BENEFIT FROM ANY STATUTORY COMPENSATION SCHEME IN
              RELATION TO PARTICIPATING IN THE TOKENS. IF YOU HAVE NOT PARTICIPATED IN CRYPTOASSETS
              BEFORE, WE SUGGEST THAT YOU SEEK APPROPRIATE ADVICE BEFORE PARTICIPATING IN THESE
              GENERAL TERMS AND CONDITIONS.
            </b>
          </div>
          <div className="mt-[12px] w-full text-justify text-[18px] text-stone-blue">
            <b>
              IF YOU DO NOT AGREE WITH THESE GENERAL TERMS AND CONDITIONS, YOU MUST NOT DEAL IN THE
              TOKENS. BY DEALING IN THE TOKENS, YOU ACKNOWLEDGE THAT YOU HAVE READ CAREFULLY AND
              ACCEPT THESE GENERAL TERMS AND CONDITIONS AND THE FINAL TERMS, INCLUDING ANY SCHEDULES.
            </b>
          </div>
          <div className="mt-[64px] text-[20px] font-semibold">INTRODUCTION</div>
          <div className="mt-[24px] text-justify text-[18px]">
            These general terms and conditions, together with any schedules and policies referred to
            in them (together, the "<b>General Terms and Conditions</b>" and each, a "
            <b>Condition</b>") are applicable to all Tokens issued by Midas Software GmbH (the "
            <b>Issuer</b>", "<b>we</b>", "<b>us</b>", "<b>our</b>") and shall be completed by, and
            read in conjunction with, the terms related to the relevant Token (the "
            <b>Final Terms</b>"). In case of inconsistencies between the General Terms and Conditions
            and the Final Terms, the Final Terms shall prevail.
          </div>
          <div className="mt-[24px] text-justify text-[18px]">
            All subsequent references in these General Terms and Conditions to Tokens are to the
            Tokens which are the subject of relevant Final Terms. All capitalised terms that are not
            defined in these General Terms and Conditions will have the meanings given to them in the
            relevant Final Terms.
          </div>
          <div className="mt-[24px] text-justify text-[18px]">
            You represent that you are at least the age of majority in your jurisdiction and have the
            full right, power, and authority to enter into and comply with the terms and conditions
            of these General Terms and Conditions on behalf of yourself and any company or legal
            entity for which you may act. If you are entering into these General Terms and Conditions
            on behalf of an entity, you represent to us that you have the legal authority to bind
            such entity.
          </div>
          <div className="mt-[24px] text-justify text-[18px]">
            You further represent that you are not (a) the subject of economic or trade sanctions
            administered or enforced by any governmental authority or otherwise designated on any
            list of prohibited or restricted parties or (b) a citizen, resident, or organized in a
            jurisdiction or territory that is the subject of comprehensive country-wide,
            territory-wide, or regional economic sanctions. Finally, you represent that you will
            fully comply with all applicable laws and regulations, and that you will not conduct,
            promote, or otherwise facilitate any illegal activity.
          </div>
          <div className="mt-[64px] text-[20px] font-semibold">YOU AND WE AGREE AS FOLLOWS:</div>
          <div className="mt-[24px] text-justify text-[18px]">
            <ol className="nested">
              <li>
                <b>Interpretation</b>
                <ol className="nested">
                  <li>
                    In these General Terms and Conditions, the following words and expressions have
                    the following meanings unless inconsistent with the context:
                    <table className="terms border-collapse">
                      <tbody>
                        <tr>
                          <td>"Adverse Regulatory Event"</td>
                          <td>
                            an Adverse Regulatory Event shall be deemed to occur if there is a
                            material change in the regulatory environment that significantly impacts
                            the Issuer’s ability to comply with relevant regulations in the operation
                            or issuance of the Tokens.
                          </td>
                        </tr>
                        <tr>
                          <td>"Adverse Tax Event"</td>
                          <td>
                            an Adverse Tax Event shall be deemed to occur if there is a material
                            change in tax law or interpretation of tax law that results in a
                            substantial adverse tax consequence to the Issuer related to the
                            issuance, operation, or holding of the Tokens.
                          </td>
                        </tr>
                        <tr>
                          <td>"Applicable Law(s)"</td>
                          <td>
                            means all laws, statutes, regulatory rules, and regulations that apply to
                            the Parties in connection with these General Terms and Conditions from
                            time to time.
                          </td>
                        </tr>
                        <tr>
                          <td>"Business Day"</td>
                          <td>
                            means a day on which (i) relevant Clearing Systems are open, (ii)
                            relevant commercial banks are open, and/or (iii) banks in Frankfurt are
                            open.
                          </td>
                        </tr>
                        <tr>
                          <td>"Event of Default"</td>
                          <td>has the meaning given in Condition 14.</td>
                        </tr>
                        <tr>
                          <td>"Greenlisted", "Greenlisting"</td>
                          <td>
                            means the completion of both the onboarding as described in Condition 2
                            and the KYC/AML Requirements.
                          </td>
                        </tr>
                        <tr>
                          <td>"Insolvency Event"</td>
                          <td>has the meaning given in Condition 14.</td>
                        </tr>
                        <tr>
                          <td>"Issuer"</td>
                          <td>
                            means the Midas Software GmbH, Kurfürstendamm 15, 10719 Berlin, Federal
                            Republic of Germany, registered in the commercial register
                            Berlin-Charlottenburg under the registration number HRB 254645.
                          </td>
                        </tr>
                        <tr>
                          <td>"Issuer Call Option"</td>
                          <td>
                            is the right of the Issuer to initiate the redemption process in the
                            event of a force majeure or other unforeseen circumstances beyond the
                            reasonable control of the Issuer, including but not limited to Adverse
                            Regulatory Events, Adverse Tax Events, natural disasters, acts of war or
                            terrorism, or other events or circumstances not contemplated at the time
                            of issuance of the Tokens. The Issuer may also execute the Call Option in
                            the event that it voluntarily or involuntarily dissolves, liquidates, or
                            otherwise ceases to exist. Upon determination by the Issuer to execute
                            the Issuer Call Option, the Issuer will notify the Tokenholders in
                            accordance with Condition 12 (Notice).
                          </td>
                        </tr>
                        <tr>
                          <td>"KYC/AML Requirements"</td>
                          <td>
                            mean the know your client and anti-money laundering processes established
                            by the Issuer to ensure compliance with Applicable Law.
                          </td>
                        </tr>
                        <tr>
                          <td>"Market Disruption Event"</td>
                          <td>has the meaning specified in Condition 5.</td>
                        </tr>
                        <tr>
                          <td>"Qualified Investor(s)"</td>
                          <td>
                            <div>
                              According to Article 2 lit. e) Prospectus Regulation, Points (1) to (4)
                              of Section I of Annex II to MiFID II the following shall all be
                              regarded as qualified investors:
                            </div>
                            <div className="mt-[12px]">
                              Entities which are required to be authorised or regulated to operate in
                              the financial markets. The list below shall be understood as including
                              all authorised entities carrying out the characteristic activities of
                              the entities mentioned: entities authorised by a Member State under a
                              Directive, entities authorised or regulated by a Member State without
                              reference to a Directive, and entities authorised or regulated by a
                              third country:
                            </div>
                            <ol className="mt-[12px]">
                              <li>Credit institutions;</li>
                              <li>Investment firms;</li>
                              <li>Other authorised or regulated financial institutions;</li>
                              <li>Insurance companies;</li>
                              <li>
                                Collective investment schemes and management companies of such
                                schemes;
                              </li>
                              <li>Pension funds and management companies of such funds;</li>
                              <li>Commodity and commodity derivatives dealers;</li>
                              <li>Locals;</li>
                              <li>Other institutional investors;</li>
                            </ol>
                            <div className="mt-[12px]">
                              Large undertakings meeting two of the following size requirements on a
                              company basis:
                            </div>
                            <ul className="mt-[12px]">
                              <li>balance sheet total: EUR 20,000,000</li>
                              <li>net turnover: EUR 40,000,000</li>
                              <li>own funds: EUR 2,000,000</li>
                            </ul>
                            <div className="mt-[12px]">
                              National and regional governments, including public bodies that manage
                              public debt at national or regional level, Central Banks, international
                              and supranational institutions such as the World Bank, the IMF, the
                              ECB, the EIB and other similar international organisations.
                            </div>
                            <div className="mt-[12px]">
                              Other institutional investors whose main activity is to invest in
                              financial instruments, including entities dedicated to the
                              securitisation of assets or other financing transactions.
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>"Party", "Parties"</td>
                          <td>you and us as the parties to these General Terms and Conditions.</td>
                        </tr>
                        <tr>
                          <td>"Product Documentation"</td>
                          <td>
                            means these General Terms and Conditions and the relevant Final Terms,
                            each as may be amended and/or supplemented and/or restated from
                            time-to-time.
                          </td>
                        </tr>
                        <tr>
                          <td>"Redemption Amount"</td>
                          <td>
                            means an amount in the Settlement Currency payable by the Issuer to the
                            Tokenholder. It is calculated on the basis of a hypothetical best-efforts
                            liquidation of assets reflecting the number of shares of the Underlying
                            that corresponds to the number of Tokens redeemed by the Tokenholder. The
                            Redemption Fees are subtracted from the redeemable amount.
                          </td>
                        </tr>
                        <tr>
                          <td>"Redemption Fees"</td>
                          <td>has the meaning as specified in the Final Terms.</td>
                        </tr>
                        <tr>
                          <td>"Settlement Currency"</td>
                          <td>
                            means the currency in which the Redemption Amount is settled; the
                            Settlement Currency is specified by a Tokenholder in the Tokenholder
                            Order Request Form.
                          </td>
                        </tr>
                        <tr>
                          <td>"Stablecoins"</td>
                          <td>means the stablecoins as specified in the Final Terms.</td>
                        </tr>
                        <tr>
                          <td>"Token(s)"</td>
                          <td>
                            are the digital tokens generated by the Issuer which have a smart
                            contract that is implemented on the Ethereum Blockchain. The Tokens track
                            the performance of the Underlying.
                          </td>
                        </tr>
                        <tr>
                          <td>"Tokenholder(s)"</td>
                          <td>
                            shall mean any person holding the private key in relation to a specific
                            Token.
                          </td>
                        </tr>
                        <tr>
                          <td>"Underlying"</td>
                          <td>has the meaning as specified in the Final Terms.</td>
                        </tr>
                        <tr>
                          <td>"Website"</td>
                          <td>
                            means <a href="https://www.midas.app">www.midas.app</a>.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </li>
                  <li>
                    In these General Terms and Conditions, unless the context otherwise requires:
                    references to these General Terms and Conditions shall include any Schedules to
                    it and references to Clauses, Sub-clauses and any Schedules are to Clauses of,
                    Sub-clauses of, and any Schedules to these General Terms and Conditions; the
                    singular includes the plural and vice versa; “person” denotes any person,
                    partnership, corporation or other association of whatever nature; and any
                    references to any directive, statute, statutory instrument, laws or regulations
                    shall be references to such directive, statute, statutory instrument, laws or
                    regulations as from time to time amended, re-enacted or replaced and to any
                    codification, consolidation, re-enactment or substitution thereof as from time to
                    time in force and any reference to a regulator or public authority and rules made
                    by it shall include its successor and rules made by the successor which replace
                    those rules.
                  </li>
                  <li>
                    Headings are for convenience only and have no bearing on the interpretation of
                    these General Terms and Conditions.
                  </li>
                  <li>
                    Any phrase introduced by the term "<b>include</b>", "<b>includes</b>", "
                    <b>including</b>", "<b>for example</b>", "<b>in particular</b>" or any similar
                    expression will be construed as illustrative and will not limit the sense of the
                    words preceding that term.
                  </li>
                  <li>
                    References to "<b>dealing in</b>" or "<b>deal in</b>" are references to any
                    participation in crypto assets including buying, acquiring, accepting, holding,
                    selling, staking, disposing of or otherwise making use of crypto assets.
                  </li>
                </ol>
              </li>
              <li>
                <b>KYC/AML Requirements and Onboarding (Greenlisting)</b>
                <ol className="nested">
                  <li>
                    In order to purchase and/or redeem Tokens, a successful onboarding of the
                    investor and completion of the KYC/AML Requirements by the investor providing all
                    required data and including confirmation that the investor is eligible is
                    required.
                  </li>
                  <li>
                    Eligible to purchase and/or redeem Tokens are all natural and legal persons as
                    well as partnerships with a legal personality if they fulfil the following
                    requirements:
                    <ol type="a">
                      <li>
                        the investor is neither a citizen of nor does possess a permanent residence
                        or working permit for the United States of America (USA), Canada, China,
                        Australia or Iran and has no residence or registered office within the
                        territory of one of these states;
                      </li>
                      <li>
                        the investor is not a corporation or other entity organized under federal or
                        any state law of the USA or under the comparable law of Canada, China,
                        Australia, or Iran of which the income is subject to the law of one of the
                        aforementioned jurisdictions;
                      </li>
                      <li>
                        neither the investor nor the investor’s wallet is listed on neither one of
                        the European Union’s nor on one of the United States of America’s respective
                        sanction lists
                      </li>
                    </ol>
                  </li>
                  <li>
                    The following information is required for onboarding if the investor is a natural
                    person:
                    <ol type="a">
                      <li>All names and surnames of the investor;</li>
                      <li>the declared place of residence including the complete address;</li>
                      <li>the investor’s date of birth;</li>
                      <li>the investor’s place of birth;</li>
                      <li>the nationality of the investor;</li>
                      <li>
                        the number of an identity card or passport which has been issued by the
                        competent; authority to the investor;
                      </li>
                      <li>an e-mail address of the investor.</li>
                    </ol>
                    Additional information may be required in enhanced due diligence processes.
                  </li>
                  <li>
                    The following information is required for onboarding if the investor is an entity
                    or a partnership with a legal personality:
                    <ol type="a">
                      <li>complete legal form of the investor;</li>
                      <li>
                        statuary respectively the in a public registry registered place of business
                        of the investor including the complete address;
                      </li>
                      <li>
                        (if existing) the investors registry number from the commercial registry or a
                        comparable public registry;
                      </li>
                      <li>
                        name of the statutory authorized representative or representatives of the
                        investor;
                      </li>
                      <li>an e-mail address of the investor.</li>
                    </ol>
                    Additional information may be required in enhanced due diligence processes.
                  </li>
                  <li>
                    As part of the onboarding process, the investor is asked to name a blockchain
                    address on the Ethereum blockchain to which the Tokens can be transferred after
                    successful completion of the onboarding process. Investors may be asked to
                    provide confirmation of ownership or control of the wallet. A wallet screen may
                    be conducted as part of the onboarding process.
                  </li>
                  <li>
                    In order for the onboarding to be successful it is required that the information
                    provided by the investor is
                    <ol type="a">
                      <li>complete; and</li>
                      <li>that there is no indication that the investor provided incorrect data.</li>
                    </ol>
                  </li>
                  <li>
                    The Issuer is entitled but not obligated to audit the data provided during the
                    onboarding process by a qualified third party.
                  </li>
                  <li>
                    The Issuer notifies the investor if the onboarding and the completion of the
                    KYC/AML Requirements was successful. The respective investor is then considered
                    as Greenlisted.
                  </li>
                  <li>
                    The investor is obligated to notify the Issuer immediately if any of the
                    information provided has changed.
                  </li>
                </ol>
              </li>
              <li>
                <b>Exercise of Rights</b>
                <ol className="nested">
                  <li>
                    The Issuer will recognize and acknowledge as Tokenholders only those persons who
                    both hold Tokens, and who have successfully completed the KYC/AML Requirements.
                  </li>
                  <li>
                    Various functionalities of the Tokens, including, but not limited to, the
                    issuance and the transfer of Tokens and the redemption of the Tokens, are
                    available only with the private key associated with the Tokens. Each Tokenholder
                    agrees that the Issuer shall not be held liable and waive any claim against the
                    Issuer to the fullest extent permitted by Applicable Law, for any loss or damages
                    resulting from the loss or theft of its private key, including, but not limited
                    to, any claims for indirect or consequential damages, loss of profit or earnings,
                    unrealised savings and additional expenses.
                  </li>

                  <li>
                    In order to comply with legal obligations and official or court orders, Tokens
                    may also be transferred or deleted without or against the will of the
                    Tokenholders.
                  </li>
                </ol>
              </li>
              <li>
                <b>Redemption</b>
                <ol className="nested">
                  <li>
                    The redemption process is initiated if:
                    <ol type="a">
                      <li>
                        a Tokenholder submits the necessary details for a redemption by completing a
                        Tokenholder order request form that can be obtained from the Issuer (the "
                        <b>Tokenholder Order Request Form</b>"); or
                      </li>
                      <li>the Issuer exercises the Issuer Call Option.</li>
                    </ol>
                  </li>
                  <li>
                    If the Redemption Amount is to be paid in Stablecoins, the Tokenholder must
                    notify the Issuer of a wallet address to which the Stablecoins are to be
                    transferred. The Tokenholder bears sole responsibility for ensuring that he has
                    access to the wallet, that the wallet is compatible with the Stablecoins and that
                    no third party has access to the wallet. The Issuer will not check this and
                    assumes no liability for this. If the Redemption Amount is to be paid in USD, the
                    Tokenholder must provide the Issuer with bank details before the payout can be
                    made. Any bank transfer costs shall be borne by the Tokenholder. If the Issuer
                    incurs costs, these will be offset against the Redemption Amount.
                  </li>
                  <li>
                    If the Tokenholder is Greenlisted, the Issuer shall transfer the relevant
                    Redemption Amount to the Tokenholder’s wallet or account within ten Business Days
                    after notice of the exercise of the Issuer Call Option by the Issuer or notice of
                    receipt of the Tokenholder Order Request Form by the Issuer. Beforehand, the
                    Tokens are transferred to the Issuer.
                  </li>
                </ol>
              </li>
              <li>
                <b>Market Disruption Event</b>
                <ol className="nested">
                  <li>
                    Market Disruption Event means, in respect of the Underlying, that the price or
                    value relevant for the Token cannot be determined or announced or published or
                    otherwise is not being made available on a day relevant for the fixing,
                    observation or valuation of the Underlying.
                  </li>
                  <li>
                    If a Market Disruption Event has occurred and is continuing on a day relevant for
                    the fixing, observation or valuation of the Underlying, then the respective day
                    relevant for the fixing, observation or valuation of the Underlying shall be
                    postponed until the next following Business Day where there is no such Market
                    Disruption Event.
                  </li>
                  <li>
                    If a Market Disruption Event is continuing, then the respective day relevant for
                    the fixing, observation or valuation of the Underlying and the value for the
                    Underlying for such date shall be determined by the Issuer, in its duly exercised
                    discretion, but in accordance with established market practice.
                  </li>
                </ol>
              </li>
              {/* <li>
                <b>Underlying Illiquidity</b>
                <ol className="nested">
                  <li>
                    Underlying Illiquidity means, in respect of any Underlying, low or no trading
                    volume in the Underlying, the difficulty to buy and/or sell the Underlying in a
                    short period of time without its price being affected, or any comparable event
                    that leads to an extraordinary illiquidity in any Underlying.
                  </li>
                  <li>
                    In case of Underlying Illiquidity, the relevant Redemption Amount may be
                    calculated based on the average execution price (less transaction costs) as it
                    was obtained on a best-efforts basis, instead of using the originally pre-defined
                    fixing or value of the Underlying (e.g., the official close of the Underlying).
                  </li>
                  <li>
                    In case of Underlying Illiquidity, the determination (fixing) and/or the payment
                    of the relevant Redemption Amount shall be postponed accordingly by such number
                    of days necessary to account for such prevailing market conditions.
                  </li>
                </ol>
              </li> */}
              <li>
                <b>Qualified Subordination</b>
                <ol className="nested">
                  <li>
                    The Tokenholders’ claims against the Issuer are subordinated. The Tokenholders’
                    claims rank behind all other creditors of the Issuer who have not subordinated
                    their claims and who will be satisfied preferentially. The same applies in the
                    event of liquidation of the Issuer.
                  </li>
                  <li>
                    The assertion of claims, in particular for participation and repayment, is
                    excluded for as long as and to the extent that the payment of the claims is made.
                  </li>
                  <li>
                    Payment of the claims would give rise to the opening of insolvency proceedings
                    against the assets of the Issuer.
                  </li>
                  <li>
                    Payments of the claims shall only be made if the Issuer is in a position to do so
                    from future profits, from a liquidation surplus or from other free assets.
                  </li>
                  <li>
                    The subordinated claims may not be satisfied by payments by way of set-off. If
                    the subordinated Tokenholder receives payments, including by way of set-off, it
                    must return them, notwithstanding any other agreements.
                  </li>
                </ol>
              </li>
              {/* <li>
                <b>Liability for Losses</b>
                <ol className="nested">
                  <li>
                    The Issuer shall not be liable for fraud, theft, cyber-attacks and/or any
                    analogous or similar event (each, an "<b>Extraordinary Event</b>"). Accordingly,
                    upon the occurrence of an Extraordinary Event with respect to, or affecting the
                    Underlying, the Issuer shall give notice to Tokenholders in accordance with
                    Condition 13 (Notice) and the Redemption Amount for the Tokens shall be reduced
                    to account for such Extraordinary Event and may be as low as zero, as determined
                    by the Issuer.
                  </li>
                  <li>
                    In no event shall the Issuer have any liability for indirect, incidental,
                    consequential or other damages (even if it was advised of the possibility of such
                    damages) other than interest until the date of payment on sums not paid when due.
                    Tokenholders are entitled to damages only and are not entitled to the remedy of
                    specific performance in respect of the Tokens.
                  </li>
                </ol>
              </li> */}
              <li>
                <b>Limited Recourse</b>
                <div>
                  Notwithstanding anything to the contrary herein, no recourse (whether by
                  institution or enforcement of any legal proceeding or assessment or otherwise) in
                  respect of any breaches of any duty, obligation or undertaking of the Issuer
                  arising under or in connection with the Tokens (as from time to time supplemented
                  or modified in accordance with the provisions herein contained) by virtue of any
                  law, statute or otherwise shall be held against any shareholder, officer, manager
                  or corporate services provider of the Issuer in their capacity as such, save in the
                  case of their gross negligence, wilful default or actual fraud, and any and all
                  personal liability of every such shareholder, officer, manager of corporate
                  services provider in their capacity as such for any breaches by the Issuer of any
                  such duty, obligation or undertaking shall be waived and excluded to the extent
                  permitted by law. This provision shall survive the redemption and burning of the
                  Tokens.
                </div>
              </li>
              <li>
                <b>Modification of the Smart Contract</b>
                <ol className="nested">
                  <li>
                    The smart contract underlying the Tokens may have a mechanism that allows the
                    Issuer to modify the corresponding source code. However, this mechanism may only
                    be used in order to
                    <ol type="a">
                      <li>address security issues of the underlying smart contract.</li>
                      <li>
                        correct unintended deviations from the provisions of this Product
                        Documentation.
                      </li>
                      <li>
                        change the structure of the source code, class interfaces, control flow, as
                        far as this does not contradict the Product Documentation.
                      </li>
                      <li>
                        translate the source code into another programming language, provided this
                        does not contradict the Product Documentation; or
                      </li>
                      <li>
                        change elements of the smart contract that have become ineffective or
                        impractical due to external effects.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Other modifications or changes to the underlying smart contract may be made by
                    the Issuer only upon consent of all Tokenholders.
                  </li>
                  <li>
                    If changes to the smart contract are required pursuant to Condition 8.1, the
                    Issuer shall be entitled to amend this Product Documentation accordingly. The
                    Tokenholders shall be notified of any such amendments with a reasonable period of
                    notice according to Condition 12 (Notice) before the amendments take effect in
                    accordance with Condition 8.2. If a Tokenholder does not object to the validity
                    of the amended Product Documentation within four (4) weeks after receipt of the
                    notification of the amendment of this Product Documentation, the amended Product
                    Documentation shall be deemed accepted by the Tokenholder. In the amendment
                    notification, the Issuer shall point out to the Tokenholders the significance of
                    this period and the legal consequences of any silence.
                  </li>
                </ol>
              </li>
              <li>
                <b>Substitution of the Issuer</b>
                <ol className="nested">
                  <li>
                    The Issuer may, without the consent of the Tokenholders, at any time substitute
                    itself in respect of all rights and obligations arising under or in connection
                    with the Tokens with any legal entity of which all shares carrying voting rights
                    are directly or indirectly held by the Issuer (the “New Issuer”), provided that:
                    <ol type="a">
                      <li>
                        the New Issuer is able to fulfil all payment obligations arising from or in
                        connection with the Tokens; and
                      </li>
                      <li>
                        the Issuer has issued an irrevocable and unconditional guarantee in respect
                        of the obligations of the New Issuer under the Tokens.
                      </li>
                    </ol>
                  </li>
                  <li>
                    In the event of a substitution of the Issuer, notice of such substitution shall
                    be made in accordance with Condition 12 (Notice) and any reference to the Issuer
                    shall be deemed to refer to the New Issuer.
                  </li>
                </ol>
              </li>
              <li>
                <b>No Set-off</b>
                <div>
                  No Tokenholder may set-off any claims arising under the Tokens against any claims
                  that the Issuer may have against it. The Issuer may not set-off any claims it may
                  have against the Tokenholder against any of its obligations under the Tokens.
                </div>
              </li>
              <li>
                <b>Amendments of the General Terms and Conditions</b>
                <ol className="nested">
                  <li>
                    The Issuer reserves the right, at its sole discretion, to modify, amend or revise
                    these General Terms and Conditions at any time, in accordance with the laws and
                    regulations governing financial products and services.
                  </li>
                  <li>
                    If an amendment is material, the Issuer will make reasonable efforts to provide
                    at least 30 days’ notice prior to any new terms taking effect. Material changes
                    will generally be those that significantly impact the Tokenholders’ rights,
                    obligations, or the risks associated with holding the Tokens.
                  </li>
                </ol>
              </li>
              <li>
                <b>Notice</b>
                <div>
                  All notices regarding the Tokens shall be published on the Website{' '}
                  <a href="https://www.xdcteq.io" target="_blank">
                    https://www.xdcteq.io
                  </a>
                  .
                </div>
              </li>
              <li>
                <b>Tax</b>
                <div>
                  All payments made by or on behalf of the Issuer in respect of the Tokens will be
                  made free from any restriction or condition and be made without deduction or
                  withholding for or on account of any present or future taxes, duties, assessments
                  or governmental charges of whatever nature imposed or levied by or on behalf of the
                  Federal Republic of Germany, unless deduction or withholding of such taxes, duties,
                  assessments or governmental charges is required to be made by Applicable Law.
                </div>
              </li>
              <li>
                <b>Events of Default and Insolvency Events</b>
                <ol className="nested">
                  <li>
                    An “Event of Default” shall occur if any of the following conditions or events
                    shall occur and be continuing:
                    <ol type="a">
                      <li>
                        The Issuer fails to pay any amount in respect to the Tokens when due and
                        payable in a commercial reasonable timeframe
                      </li>
                      <li>
                        The Issuer commences a voluntary case or other procedure seeking or proposing
                        liquidation, reorganization, an arrangement or composition, a freeze or
                        moratorium or other similar relief with respect to the Issuer or its debts
                        under any bankruptcy, insolvency, or similar law, or
                      </li>
                      <li>
                        An involuntary case or other procedure is commenced against the Issuer
                        seeking or proposing liquidation, reorganization, an arrangement or
                        composition, a freeze or moratorium, or other similar relief with respect to
                        the Issuer or its debts under any bankruptcy, insolvency or similar law.
                      </li>
                    </ol>
                  </li>
                  <li>
                    An “Insolvency Event” shall occur if any of the following conditions or events
                    shall occur and be continuing:
                    <ol type="a">
                      <li>
                        The Issuer becomes unable to pay its debts as they fall due, or is declared
                        insolvent under the German Insolvency Act;
                      </li>
                      <li>
                        The Issuer stops or suspends payments of all or a material part of its debts,
                        or announces an intention to do so;
                      </li>
                      <li>
                        The Issuer commences negotiations with one or more of its creditors (other
                        than the Tokenholders) with a view to rescheduling any of its indebtedness
                        because of actual or anticipated financial difficulties.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <b>Intellectual Property Rights</b>
                <ol className="nested">
                  <li>
                    We own all intellectual property and other rights in each of our products and its
                    respective contents, including, but not limited to, software, text, images,
                    trademarks, service marks, copyrights, patents, designs, and its look and feel.
                    This intellectual property is available under the terms of our copyright licenses
                    and our Trademark Guidelines. Subject to the terms of these General Terms and
                    Conditions, we grant you a limited, revocable, non-exclusive, non-sublicensable,
                    non-transferable license to access and use our products solely in accordance with
                    these General Terms and Conditions. You agree that you will not use, modify,
                    distribute, tamper with, reverse engineer, disassemble or decompile any of our
                    products for any purpose other than as expressly permitted pursuant to these
                    General Terms and Conditions. Except as set forth in these General Terms and
                    Conditions, we grant you no rights to any of our products, including any
                    intellectual property rights.
                  </li>
                  <li>
                    By using any of our products, you grant us a worldwide, non-exclusive,
                    sublicensable, royalty-free license to use, copy, modify, and display any
                    content, including but not limited to text, materials, images, files,
                    communications, comments, feedback, suggestions, ideas, concepts, questions,
                    data, or otherwise, that you post on or through any of our products for our
                    current and future business purposes, including to provide, promote, and improve
                    the services.
                  </li>
                </ol>
              </li>
              <li>
                <b>Limitation of Liability</b>
                <ol className="nested">
                  <li>
                    Unless explicitly otherwise provided in the Product Documentation, (i) any right
                    of you to rescind from these General Terms and Conditions; (ii) any claim for
                    defects of a purchase object under Sections 437 through 441 BGB; (iii) any claim
                    for breach of pre-contractual obligations (culpa in contrahendo, Sections 241
                    (2), 311 (2) (3) BGB); and (iv) any claim for frustration of contract pursuant to
                    Section 313 BGB, shall be excluded, save for any remedies of you based on willful
                    deceit or intentional breach of contract, provided, however, that our liability
                    for willful deceit or intentional breach of contract of any person assisting us
                    in the performance of our obligations in the meaning of Section 278 BGB shall be
                    excluded.
                  </li>
                  <li>
                    Unless explicitly stated otherwise in the Product Documentation, the Issuer shall
                    not be held liable for any damages, losses, claims, costs, expenses or other
                    liabilities, whether direct, indirect, consequential or otherwise, arising from
                    the conduct of any third party not directly under the control and supervision of
                    the Issuer including, but not limited to, independent contractors, partners,
                    affiliates, suppliers, banks, brokerage firms, customers, or any other third
                    parties interacting with, or acting on behalf of, the Issuer. Notwithstanding the
                    foregoing, nothing in this Condition shall limit or exclude the Issuer’s
                    liability where the third party was acting under the direct instruction,
                    authority, or control of the Issuer, or where the conduct of the third party was
                    otherwise foreseeable and preventable.
                  </li>
                </ol>
              </li>
              <li>
                <b>Non-Custodial and No Fiduciary Duties</b>
                <ol className="nested">
                  <li>
                    We do not ever have custody, possession, or control of your digital assets at any
                    time. You are solely responsible for the custody of the cryptographic private
                    keys to the digital asset wallets you hold, and you should never share your
                    wallet credentials or seed phrase with anyone. We accept no responsibility for,
                    or liability to you, in connection with your use of a wallet. Likewise, you are
                    solely responsible for any associated wallet and we are not liable for any acts
                    or omissions by you in connection with or as a result of your wallet being
                    compromised.
                  </li>
                  <li>
                    This Product Documentation is not intended to, and does not, create or impose any
                    fiduciary duties on us. To the fullest extent permitted by law, you acknowledge
                    and agree that we owe no fiduciary duties or liabilities to you or any other
                    party, and that to the extent any such duties or liabilities may exist at law or
                    in equity, those duties and liabilities are hereby irrevocably disclaimed,
                    waived, and eliminated. You further agree that the only duties and obligations
                    that we owe you are those set out expressly in the Product Documentation.
                  </li>
                </ol>
              </li>
              <li>
                <b>Governing Law and Jurisdiction</b>
                <ol className="nested">
                  <li>
                    These General Terms and Conditions and the Tokens are exclusively subject to the
                    laws of the Federal Republic of Germany with the exclusion of the conflict-of-law
                    rules of the international private law and the UN sales convention (CISG).
                  </li>
                  <li>
                    The place of performance and sole legal venue for all disputes arising from the
                    legal relationships regulated under these General Terms and Conditions is the
                    respective business seat of the Issuer unless mandatory statutory provisions
                    contradict and the parties are merchants, legal persons under public law or a
                    special fund under public law or at least one of the parties has no place of
                    general jurisdiction within the Federal Republic of Germany. The Issuer’s
                    business seat at the time of the first launch of the Tokens is in Berlin.
                  </li>
                </ol>
              </li>
              <li>
                <b>Severability</b>
                <div>
                  If at any time one or more of the provisions of the Product Documentation is or
                  becomes unlawful, invalid, illegal or unenforceable in any respect under any
                  Applicable Law, the validity, legality and enforceability of the remaining
                  provisions shall not be in any way affected or impaired thereby.
                </div>
              </li>
              <li>
                <b>Miscellaneous</b>
                <ol className="nested">
                  <li>
                    We are not registered with the German Financial Supervisory Authority
                    (Bundesanstalt für Finanzdienstleistungsaufsicht – BaFin) in any capacity.
                  </li>
                  <li>
                    We do not provide, nor do we accept responsibility for, any legal, tax or
                    accounting advice. If you are unsure regarding any of the legal, tax or
                    accounting aspects of these General Terms and Conditions or dealing in Tokens you
                    should seek independent professional advice.
                  </li>
                  <li>
                    No waiver or variation of any part of the Product Documentation by us shall be
                    effective unless in writing and signed by us. No waiver of any provision in the
                    Product Documentation will be deemed a waiver of a subsequent breach of such
                    provision or a waiver of a similar provision. In addition, a waiver of any breach
                    or a failure to enforce any term or condition of the Product Documentation will
                    not in any way affect, limit, or waive our rights hereunder at any time to
                    enforce strict compliance thereafter with every term and condition of the Product
                    Documentation.
                  </li>
                  <li>
                    No other document or communication may modify or add any additional obligations
                    or covenants on us beyond those set forth in the Product Documentation, unless we
                    clearly, specifically and explicitly state otherwise in that document.
                  </li>
                </ol>
              </li>
            </ol>
          </div>

          <h3 className="!pb-[24px]">SCHEDULE 1</h3>
          <div className="w-full text-center text-[18px] text-stone-blue">
            Certain Risks Relating to Tokens
          </div>
          <div className="mt-[24px] text-justify text-[18px]">
            Dealing in Tokens involves a high degree of risk, and, therefore, should be undertaken
            only if you are capable of evaluating the risks involved and able to bear the risks of a
            complete loss of all capital used to deal in Tokens.
          </div>
          <div className="mt-[24px] text-justify text-[18px]">
            You should carefully consider the risks described below before dealing in Tokens. It
            should be noted that the list of risk factors below is not intended to be exhaustive and
            does not necessarily include all the risks to which we are or may be exposed to, nor are
            they all the risks associated with dealing in Tokens.
          </div>
          <div className="mt-[24px] text-justify text-[18px]">
            <b>
              <i>Important Note</i>
            </b>
            : As noted elsewhere in these General Terms and Conditions, the Tokens are not being
            structured or sold as securities nor any other form of regulated investment product.
            Accordingly, there has not been any consideration as to whether Tokens are a suitable or
            an appropriate thing for you to acquire. The Parties expressly disclaim all
            responsibility for any direct or consequential loss or damage of any kind whatsoever
            arising directly or indirectly from: (i) reliance on any information contained in this
            Schedule 1, (ii) any error, omission or inaccuracy in any such information or (iii) any
            action resulting from such information.
          </div>
          <div className="mt-[24px] text-justify text-[18px]">
            By participating in these General Terms and Conditions, or by otherwise accepting,
            holding or using Tokens, you expressly acknowledge and assume the following risks, and
            the Issuer shall not be liable should they materialise:
          </div>
          <div className="mt-[24px] text-justify text-[18px]">
            <ol className="nested">
              <li>
                <b>Credit risks</b>
                <div>
                  The Issuer may partially or wholly fail to meet its obligations under the Tokens.
                  Investors should therefore take the creditworthiness of the Issuer into account in
                  their investment decision. Credit risk means the risk of insolvency or illiquidity
                  of the Issuer, i.e. a potential, temporary or final inability to fulfil its
                  interest and repayment obligations on time. An increased insolvency risk is typical
                  of issuers that have a low creditworthiness.
                </div>
                <div className="mt-[12px]">
                  Although the return on your Tokens will partly be based on the performance of the
                  Underlying, the payment of any amount due on, or delivery of any asset(s)
                  deliverable under, the Tokens is subject to the credit risk of the Issuer. The
                  Tokens are unsecured obligations. Investors are dependent on our ability to pay all
                  amounts due on, or deliver any asset(s) deliverable under, the Tokens, and
                  therefore investors are subject to our credit risk and to changes in the market’s
                  view of our creditworthiness.
                </div>
                <div className="mt-[12px]">
                  The Tokens are not bank deposits and are not insured or guaranteed by any
                  government or governmental or private agency or deposit protection scheme in any
                  jurisdiction. Investors are dependent on our ability to pay all amounts due on the
                  Tokens.
                </div>
                <div className="mt-[12px]">
                  The Issuer will be exposed to the credit risk of a number of counterparties with
                  whom the Issuer transacts, including, but not limited to wallet providers, and
                  exchanges. Consequently, the Issuer is exposed to risks, including credit risk,
                  reputational risk and settlement risk, arising from the failure of any of its
                  counterparties to fulfil their respective obligations, which, if any such risks
                  occur, may have a material adverse effect on the Issuer’s business and financial
                  position.
                </div>
                <div className="mt-[12px]">
                  The Issuer relies on third parties to provide its services. Any dysfunction of such
                  third parties may result in a loss of value of the Tokens.
                </div>
              </li>
              <li>
                <b>Investing in Tokens</b>
                <div>
                  Tokenholders do not directly hold any Underlying themselves. They hold the Tokens
                  for which the Issuer is the counterparty.
                </div>
              </li>
              <li>
                <b>Performance of the Underlying</b>
                <div>
                  Prospective Tokenholders should note that the Tokens, their value and their
                  performance is at least indirectly linked to the performance of the Underlying.
                  Accordingly, investors should be aware that the Tokens may be adversely affected by
                  risks applicable to the Underlying.
                </div>
                <div className="mt-[12px]">
                  In particular, the value of the Underlying can go down as well as up and the past
                  performance of the Underlying will not be indicative of its future performance.
                  There can be no assurance as to the future performance of the Underlying. The
                  Tokens may trade differently from the performance of the Underlying and changes in
                  the value of the Underlying may not result in a comparable change in the market
                  value of the Tokens.
                </div>
              </li>
              <li>
                <b>Tracking error and delays</b>
                <div>
                  At any time, the price at which the Underlying trades on any exchange or market on
                  which it may be quoted or traded may not accurately reflect the price of the
                  Underlying. The application and redemption procedures for the Issuer are intended
                  to minimize this potential difference or tracking error. However, the market price
                  of the Underlying will also be a function of supply and demand amongst investors.
                  It is not within the Issuer’s control to ensure that the Tokens trade continuously
                  at a price which equates perfectly to the value of the Underlying.
                </div>
              </li>
              <li>
                <b>Price divergences</b>
                <div>
                  The market value of crypto assets is not related to any specific company,
                  government or asset. The valuation of these assets depends on future expectations
                  for the value of the network, number of transactions and the overall usage of the
                  asset. This means that a significant amount of the value in crypto assets is
                  speculative and could lead to increased volatility. Investors could experience
                  significant gains, losses and/or volatility depending on the valuation of crypto
                  assets through the exposure to the Underlying.
                </div>
                <div className="mt-[12px]">
                  Valuation may also vary significantly by geography, as local exchanges are not
                  necessarily compatible with all crypto assets and assets may be difficult to move
                  in and out of any specific market. As a result, geographic arbitrage can have a
                  considerable effect on valuation and, in turn, on the returns from the Underlying.
                </div>
                <div className="mt-[12px]">
                  Momentum pricing of crypto assets has previously resulted, and may continue to
                  result, in speculation regarding future appreciation or depreciation in the value
                  of such assets, further contributing to volatility and potentially inflating prices
                  at any given time. As a result, pricing of crypto assets may change due to shifting
                  investor confidence in future outlook of the asset class. These dynamics may impact
                  the value of an investment in Tokens.
                </div>
              </li>
              <li>
                <b>Risks specific to investing in index-tracking exchange traded funds (ETFs)</b>
                <div>
                  Exchange traded funds are not actively managed and may be affected by a general
                  decline in market segments related to their respective benchmark indices. Exchange
                  traded funds invest in securities included in, or representative of, their
                  respective benchmark indices, and exchange traded funds do not attempt to take
                  defensive positions under any market conditions, including declining markets.
                </div>
                <div className="mt-[12px]">
                  While exchange traded funds, in accordance with their investment objectives, seek
                  to track the performance of their respective benchmark indices, whether through a
                  replication or optimising strategy, there is no guarantee that they will achieve
                  perfect tracking and exchange traded funds may potentially be subject to tracking
                  error risk, which is the risk that their returns may not track exactly those of
                  their respective benchmark indices, from time to time. This tracking error may
                  result from an inability to hold the exact constituents of the benchmark index,
                  (although this is not the expected cause of tracking error for non-replicating
                  exchange traded funds), for example where there are local market trading
                  restrictions, small illiquid components, a temporary unavailability or interruption
                  in trading of certain securities comprising the benchmark index. Where the
                  benchmark index of an exchange traded fund is to be rebalanced and the exchange
                  traded fund seeks to rebalance its portfolio accordingly, the exchange traded fund
                  may nevertheless experience tracking error where the rebalancing of the exchange
                  traded fund’s portfolio does not maintain an exact or contemporaneous alignment,
                  whether on a replicating or an optimised basis, with the benchmark index.
                </div>
                <div className="mt-[12px]">
                  It may not be practical or cost efficient for certain exchange traded funds to
                  replicate their respective benchmark indices. Where it is not part of an exchange
                  traded fund’s investment policy to replicate its benchmark index, such exchange
                  traded funds may use optimisation techniques to track the performance of their
                  respective benchmark indices. Optimisation techniques may include the strategic
                  selection of some (rather than all) of the securities that make up the benchmark
                  index and/or holding securities in proportions that differ from the proportions of
                  the benchmark index.
                </div>
                <div className="mt-[12px]">
                  In order to meet its investment objective, each exchange traded fund seeks to
                  achieve a return which corresponds generally to the price and yield performance,
                  before fees and expenses, of the relevant benchmark index as published by the index
                  provider. There is no assurance that the index provider will compile the benchmark
                  index accurately, or that the benchmark index will be determined, composed or
                  calculated accurately. While the index provider does provide descriptions of what
                  the benchmark index is designed to achieve, the index provider does not provide any
                  warranty or accept any liability in relation to the quality, accuracy or
                  completeness of data in respect of the benchmark index and does not guarantee that
                  the benchmark index will be in line with the described index methodology.
                </div>
              </li>
              <li>
                <b>Lack of operating history</b>
                <div>
                  Acquisitions of tokens from start-ups, including the Issuer, involve a high degree
                  of risk. The Tokens have all of the risks and uncertainties normally associated
                  with an early-stage business, including establishing the business operations, lack
                  of name recognition, lack of adequate capital to progress the business,
                  difficulties hiring and retaining qualified employees, and difficulties in
                  complying with all Applicable Laws. Financial and operational risks confronting
                  start-ups are significant and the Issuer is not immune to these. The start-up
                  market in which the Issuer competes is highly competitive, and the percentage of
                  companies that survive and prosper is small.
                </div>
                <div className="mt-[12px]">
                  The Issuer’s lack of an operating history may make it difficult for you to evaluate
                  the Issuer’s business and operating prospects. These risks include, but are not
                  limited to, the Issuer’s ability to: (i) increase revenues and manage costs
                  relating to the Issuer’s operations; (ii) increase awareness of the Issuer and the
                  Tokens; (iii) maintain current and develop new strategic relationships; (iv)
                  respond effectively to competitive pressures; (v) continue to develop and upgrade
                  technology; (vi) attract, retain, and motivate qualified personnel; and (vii) raise
                  any additional capital as potentially required. There are no assurances that the
                  Issuer’s business strategy will be successful, nor that the Issuer will address
                  these risks successfully. In addition, the Issuer has not prepared financial
                  statements for potential investors of Tokens.
                </div>
              </li>
              <li>
                <b>Risk of losing access to Tokens due to loss of private key(s)</b>
                <div>
                  A private key, or a combination of private keys, is necessary to control and
                  dispose of Tokens stored in a digital wallet or vault. Accordingly, loss of
                  requisite private key(s) associated with a digital wallet or vault storing Tokens
                  will result in a loss of such Tokens. Moreover, any third party that gains access
                  to such private key(s), including by gaining access to login credentials of a
                  hosted wallet service you or we use, may be able to misappropriate Tokens.
                </div>
                <div className="mt-[12px]">
                  Any errors or malfunctions caused by or otherwise related to the wallet you use to
                  receive and store Tokens, including your own failure to properly maintain or use
                  such wallet, may also result in the loss of your Tokens. Failure to precisely
                  follow any procedures set forth by the Issuer for transferring and receiving
                  Tokens, including, for instance, providing an incorrect wallet address, or using
                  wallet(s) that do not accept Tokens, may result in the loss of Tokens you hold.
                </div>
              </li>
              <li>
                <b>Regulatory risks</b>
                <div>
                  Regulation of crypto assets such as the Tokens and the offering thereof, as well as
                  blockchain technologies, and crypto asset exchanges is currently underdeveloped and
                  likely to rapidly evolve. Regulation varies significantly between different
                  jurisdictions and is subject to significant uncertainty. Regulators may in the
                  future adopt laws, regulations, guidance or other actions that may severely impact
                  the development, operations and growth of the Issuer. Failure by the Issuer to
                  comply with any Applicable Laws, some of which may not exist yet or are subject to
                  interpretation and may be subject to change, could result in a variety of adverse
                  consequences, including civil penalties and fines, which could have an adverse
                  effect on the development or operations of the Issuer and the value of Tokens.
                </div>
                <div className="mt-[12px]">
                  If the Issuer is required to obtain a licence, there is a risk that the Issuer may
                  not be able to obtain it, either within a reasonable time period or at all, and the
                  consequence of this will accordingly be to restrict the Issuer’s business and
                  ability to support Tokens.
                </div>
              </li>
              <li>
                <b>Legal proceedings and litigation</b>
                <div>
                  From time to time, the Issuer may be involved in legal proceedings or litigation.
                  The results of such legal proceedings and claims cannot be predicted with certainty
                  and, regardless of the outcome, legal proceedings could have an adverse impact on
                  the Issuer’s business or development, because of defence and settlement costs,
                  diversion of resources, and other factors. As of the date of these General Terms
                  and Conditions, the Issuer is not subject to any material legal proceedings, nor,
                  to the Issuer’s knowledge, are any material legal proceedings pending or threatened
                  against the Issuer.
                </div>
                <div className="mt-[12px]">
                  Generally, if third parties are successful in their claims, the Issuer may have to
                  pay substantial damages, account for profits derived from the alleged infringing
                  acts, and cease to use certain technologies or take other actions that could be
                  detrimental to the Issuer’s business. If there is an intellectual property
                  infringement claim, or to avoid such claims, the Issuer may be prohibited from
                  selling or licensing to others any product that it may develop, unless the patent
                  or copyright holder grants a license of the relevant intellectual property to the
                  Issuer, which the patent or copyright holder is not obligated to do.
                </div>
                <div className="mt-[12px]">
                  There also may be adverse publicity associated with litigation that could
                  negatively affect people’s perception of the Issuer or the Tokens, regardless of
                  whether the allegations are valid or the Issuer is ultimately found liable.
                  Accordingly, litigation may adversely affect the Issuer’s business and financial
                  condition.
                </div>
              </li>
              <li>
                <b>Risks associated with the blockchain protocol</b>
                <div>
                  Because Tokens are based on blockchain protocols, any malfunction, breakdown or
                  abandonment of a blockchain protocol may have a material adverse effect on the
                  Tokens. Moreover, advances in cryptography, or technical advances such as the
                  development of quantum computing, could present risks to the Tokens by rendering
                  ineffective the cryptographic consensus mechanism that underpins the blockchain
                  protocol.
                </div>
                <ol className="nested">
                  <li className="pt-[12px]">
                    <b>Technical risk related to blockchain</b>
                    <div>
                      There are a number of technical risks to which investors in crypto assets are
                      exposed including, but not limited to, flaws in the code, forks in the
                      underlying protocols, double spend and 51% attacks.
                    </div>
                    <div className="mt-[12px]">
                      Crypto assets are often built on open-source code available to the general
                      public. This makes the underlying source code of these crypto assets visible
                      publicly to anyone, anywhere. While the top crypto assets sometimes have
                      dedicated teams of contributors, it is often the case that they are unpaid and
                      not full-time employees or contractors. For these reasons, it is possible that
                      flaws or mistakes in the released and public source code could lead to
                      catastrophic damage to the underlying technology, crypto assets and networks.
                      It is possible that the volunteer or undedicated team members are unable to
                      stop this damage before it spreads further. It is further possible that a
                      dedicated team or a group of contributors or other technical group may attack
                      the code, directly leading to catastrophic damage. In any of these situations,
                      the value of investors’ holdings can be severely and detrimentally affected.
                    </div>
                    <div className="mt-[12px]">
                      If a single miner, or a group of miners acting in concert, control (even
                      temporarily) a majority of the network mining power of a particular
                      distributed-ledger network, they could use this control to undertake harmful
                      acts. Such an attack is called a 51% attack. Furthermore, they could allow for
                      their coins to be spent on multiple occasions and would, in this scenario, have
                      enough network control to confirm and post these transactions to the
                      distributed-ledger network, in an attack referred to as double spending. In a
                      double spending situation, the related record of the transaction, posted on the
                      public ledger, would become falsified. This could have a detrimental effect on
                      both the sender and the receiver. There are several ways a nefarious
                      cybercriminal could attempt a double-spend, including, but not limited to,
                      sending two conflicting transactions to the network, and creating one
                      transaction but sending the crypto assets before releasing that associated
                      block to the distributed-ledger network, which would invalidate it.
                    </div>
                    <div className="mt-[12px]">
                      The infrastructure and ecosystem that power crypto assets, including affiliated
                      and non-affiliated engineers, engineers, developers, miners, platform
                      developers, evangelists, marketers, exchange operators and other companies
                      based around crypto services, each of whom may have different motivations,
                      drivers, philosophies and incentives. There is, accordingly, a risk that these
                      parties disagree on the future direction of these technologies, which may
                      impede or otherwise negatively affect the development of the technology and, in
                      turn, lead to losses with respect to an investor’s investment.
                    </div>
                  </li>
                  <li className="pt-[12px]">
                    <b>Settlement risk on blockchain</b>
                    <div>
                      There have been times when settlements have been unable to keep pace with the
                      volume of transactions, thereby making it difficult to conduct such
                      transactions.
                    </div>
                  </li>
                  <li className="pt-[12px]">
                    <b>Transaction fees on blockchain</b>
                    <div>
                      Crypto asset miners and validators, functioning in their transaction
                      confirmation capacity, collect fees for each transaction they confirm. Miners
                      and validators validate unconfirmed transactions by adding the previously
                      unconfirmed transactions to new blocks in the distributed-ledger network.
                      Miners and validators are not forced to confirm any specific transaction, but
                      they are economically incentivized to confirm valid transactions as a means of
                      collecting fees. Miners and validators have historically accepted relatively
                      low transaction confirmation fees, because they have a very low marginal cost
                      of validating unconfirmed transactions. If miners or validators collude in an
                      anticompetitive manner to reject low transaction fees, then investors could be
                      forced to pay higher fees, thus reducing the attractiveness of the relevant
                      network. Mining and staking occur globally, and it may be difficult for
                      authorities to apply antitrust regulations across multiple jurisdictions. Any
                      collusion among miners or validators may adversely impact the attractiveness of
                      networks and may adversely impact the value of an investment in the Tokens or
                      the ability of the Issuer to operate.
                    </div>
                  </li>
                  <li className="pt-[12px]">
                    <b>Risk of blockchain forks</b>
                    <div>
                      In cases of particularly strong disagreements, a developer or group of
                      developers can split the code base into two or more branches of variations of
                      development, in what is called a fork. Forks may have a detrimental effect on
                      the value of crypto assets, including by negatively affecting cryptocurrency
                      allocations or by failing to capture of the full value of the newly-forked
                      cryptocurrency.
                    </div>
                  </li>
                </ol>
              </li>
              <li>
                <b>Risk of hacking and software and security weaknesses</b>
                <div>
                  Hackers or other malicious groups or organizations may attempt to interfere with
                  Tokens in a variety of ways, including malware attacks, denial of service attacks,
                  consensus-based attacks, Sybil attacks, smurfing and spoofing, as well as attacks
                  which overpower the consensus-based mechanism on which the blockchain in built and
                  attacks which interfere with or otherwise cause nodes to malfunction (nodes are
                  computers / hardware devices that help maintain the blockchain).
                </div>
              </li>
              <li>
                <b>Risks associated with uncertain regulations and enforcement actions</b>
                <div>
                  The regulatory status of the Tokens and distributed ledger technology is unclear or
                  unsettled in many jurisdictions. It is difficult to predict how or whether
                  regulatory agencies may apply existing regulation with respect to such technology
                  and its applications. It is likewise difficult to predict how or whether
                  legislatures or regulatory agencies may implement changes to law and regulation
                  affecting distributed ledger technology and its applications. Regulatory actions
                  could negatively impact the Tokens in various ways, including, for purposes of
                  illustration only, through a determination that Tokens are a regulated financial
                  instrument that require registration or licensing. The Issuer may cease operations
                  in a jurisdiction in the event that regulatory actions, or changes to law or
                  regulation, make it illegal to operate in such jurisdiction, or commercially
                  undesirable to obtain the necessary regulatory approval(s) to operate in such
                  jurisdiction.
                </div>
              </li>
              <li>
                <b>Risk of closure, changes or abandonment</b>
                <div>
                  It is possible that, due to any number of reasons, including technical, commercial,
                  regulatory and other reasons (such as failure to comply with all Applicable Laws,
                  the failure of commercial relationships or intellectual property ownership
                  challenges), the Issuer may not be able support the Tokens or the Issuer’s business
                  as intended, and so the Issuer may dissolve.
                </div>
              </li>
              <li>
                <b>Liquidity risk</b>
                <div>
                  There is presently no established trading market for the Tokens. The Issuer can
                  provide no assurance that an exchange will accept any attempted listing of the
                  Tokens or maintain the listing if accepted, or that any trading market will be
                  successfully developed or launched. Moreover, even if such a market is established,
                  any such trading market may not be widely adopted, may have limited users, and
                  could be subject to significant competition. As a result, the Issuer can provide no
                  assurance as to the liquidity of the Tokens on any such market, and the value of
                  the Tokens over time may experience extreme volatility or depreciate in full.
                </div>
              </li>
              <li>
                <b>Intellectual property</b>
                <div>
                  Companies, organisations, or individuals, including competitors, may hold or obtain
                  patents, trademarks, or other proprietary rights that would prevent, limit, or
                  interfere with the Issuer’s ability to make, use, sell, or market the Tokens, which
                  could make it more difficult for the Issuer to operate its business. These third
                  parties may have applied for, been granted, or obtained, patents that relate to
                  intellectual property, and which compete with the Issuer’s intellectual property or
                  technology. This may require the Issuer to develop or obtain alternative
                  technology, or obtain appropriate licenses under these patents, which may not be
                  available on acceptable terms or at all. Such a circumstance may result in the
                  Issuer having to significantly increase development efforts and resources to
                  redesign the technology in order to safeguard its competitive edge against
                  competitors in the same industry. There is a risk that the Issuer’s means of
                  protecting its intellectual property rights may not be adequate, and weaknesses or
                  failures in this area could adversely affect the Issuer’s business or reputation.
                </div>
                <div className="mt-[12px]">
                  From time to time, the Issuer may receive communications from holders of patents or
                  trademarks regarding their proprietary rights. Companies holding patents or other
                  intellectual property rights may bring suits alleging infringement of such rights
                  or otherwise assert their rights and urge the Issuer to take licenses. The Issuer
                  may also need to file lawsuits to protect its intellectual property rights from
                  infringement from third parties, which could be expensive, time consuming, and
                  distract management's attention from our core operations.
                </div>
                <div className="mt-[12px]">
                  The Issuer’s ability to serve its customers will depend upon its intellectual
                  property. The Issuer will rely on copyright, trade secret and trademark laws, trade
                  secret protection, and confidentiality or license agreements with its employees,
                  customers, and other third parties to protect the Issuer’s intellectual property
                  rights. However, the steps the Issuer takes to protect its intellectual property
                  rights may be inadequate. In order to protect its intellectual property rights, the
                  Issuer may be required to spend significant resources to monitor and protect these
                  rights. Litigation brought to protect and enforce the Issuer’s intellectual
                  property rights could be costly, time-consuming and distracting to management and
                  could result in the impairment or loss of portions of the Issuer’s intellectual
                  property. Furthermore, the Issuer’s efforts to enforce the Issuer’s intellectual
                  property rights may be met with defences, counterclaims, and countersuits attacking
                  the validity and enforceability of the Issuer’s intellectual property rights. The
                  Issuer’s failure to secure, protect, and enforce the Issuer’s intellectual property
                  rights could adversely affect the Issuer’s business, reputation, financial
                  condition, or operating results.
                </div>
              </li>
              <li>
                <b>No management rights in the Issuer</b>
                <div>
                  Holders of Tokens will have no voting rights or other management or control rights
                  in the Issuer by virtue of the Tokens, and, consequently, the acquisition of the
                  Tokens does not carry with it any right to take part in the control or management
                  of the Issuer’s business. Accordingly, the Issuer’s directors and shareholders who
                  hold voting shares will control the Issuer’s decisions. Holders of Tokens will have
                  no influence or vote on any corporate matters, and the voting shareholders and
                  directors may take actions of which a majority of holders of Tokens disapprove. In
                  assessing the risks and rewards of dealing in Tokens, you must be aware that you
                  are relying solely on the good faith, judgment and ability of the Issuer’s
                  directors, officers and employees to make appropriate decisions with respect to the
                  Issuer’s management.
                </div>
              </li>
              <li>
                <b>The prices of digital assets are extremely volatile</b>
                <div>
                  Fluctuations in the price of digital assets could materially and adversely affect
                  the Issuer’s business and the Tokens may also be subject to significant price
                  volatility. The prices of crypto assets have historically been subject to dramatic
                  fluctuations and are highly volatile, and the market price of the Tokens may also
                  be highly volatile. Several factors may influence the market price, if any, of the
                  Tokens, including, but not limited to:
                  <ul>
                    <li>the ability (if any) of the Tokens to trade on a secondary market;</li>
                    <li>global digital asset and token supply;</li>
                    <li>
                      global digital asset and token demand, which can be influenced by the growth of
                      retail merchants’ and commercial businesses’ acceptance of crypto assets, the
                      security of online digital asset exchanges and digital wallets that hold
                      digital assets, the perception that the use and holding of digital assets is
                      safe and secure, and the regulatory restrictions on their use;
                    </li>
                    <li>
                      general expectations with respect to the rate of inflation, interest rates and
                      exchange rates;
                    </li>
                    <li>
                      changes in the software, software requirements or hardware requirements
                      underlying Tokens;
                    </li>
                    <li>
                      changes in the rights, obligations, incentives, or rewards for the various
                      holders of Tokens;
                    </li>
                    <li>
                      interruptions in service from or failures of major digital asset and token
                      exchanges on which digital assets and tokens are traded;
                    </li>
                    <li>
                      investment and trading activities of large purchasers, including private and
                      registered funds, that may directly or indirectly invest in tokens or other
                      digital assets;
                    </li>
                    <li>
                      monetary policies of governments, trade restrictions, currency devaluations and
                      revaluations;
                    </li>
                    <li>
                      regulatory measures, if any, that affect the use of crypto assets and changes
                      in Applicable Law;
                    </li>
                    <li>
                      global or regional political, economic or financial events and situations; and
                    </li>
                    <li>
                      expectations among digital assets participants that the value of tokens or
                      other digital assets will soon change.
                    </li>
                  </ul>
                </div>
                <div className="mt-[12px]">
                  A decrease in the price of a single digital asset may cause volatility in the
                  entire digital asset and token industry and may affect other digital assets
                  including the Tokens.For example, a security breach that affects confidence in
                  Bitcoin or Ether may affect the industry as a whole and may also cause the price of
                  the Tokens and other digital assets to fluctuate. Such volatility in the price of
                  the Tokens may result in significant loss over a short period of time.
                </div>
              </li>
              <li>
                <b>Market sentiment risk</b>
                <div>
                  There is a tendency in the media to group crypto assets. Therefore, the market
                  perception of one crypto asset may influence another, even if there is no direct
                  link between the two. Also, a hack or issue with platforms or a smart contract may
                  lead to a loss of investor confidence more generally in crypto assets as an
                  investment. A loss of confidence in a crypto asset can lead to large losses in the
                  value of crypto assets generally and therefore may cause the price of the Tokens to
                  decrease.
                </div>
              </li>
              <li>
                <b>Purchasers may lack information for monitoring their investment</b>
                <div>
                  You may not be able to obtain all information you want from time to time regarding
                  the Issuer, or the Tokens. Even if you do receive such information, you may not
                  receive it on a timely basis. It is possible that you may not be aware of
                  materially adverse changes that have occurred with respect to the Issuer or the
                  Tokens in a timely manner. As a result of these difficulties, as well as other
                  uncertainties, you may not have accurate or accessible information relating to your
                  acquisition, which could prevent you from taking actions with the potential to
                  prevent adverse consequences relating to your holding of Tokens.
                </div>
              </li>
              <li>
                <b>General economic risks</b>
                <div>
                  Please be aware that the value of the Tokens can fall as well as rise. If you buy
                  Tokens, you may not get back the full amount you spent on the Tokens, or anything
                  at all. The value of Tokens may depend on fluctuations in the financial markets, or
                  other economic factors, which are outside our control. The past performance of
                  other crypto assets is not necessarily a guide to the future performance of Tokens.
                </div>
              </li>
              <li>
                <b>Risk of extraordinary event</b>
                <div>
                  Investors bear the risks of an Extraordinary Event and of a partial or complete
                  loss of their investment. Moreover, the risks of an Extraordinary Event are greater
                  than for similar events with respect to other asset classes and, unlike in the case
                  of other asset classes, are unable to be mitigated. In addition, it is not
                  presently practical to insure against an Extraordinary Event. If an Extraordinary
                  Event occurs, neither the Issuer nor any other person shall be liable to compensate
                  investors for any losses that they may bear.
                </div>
              </li>
              <li>
                <b>Unanticipated risks</b>
                <div>
                  Crypto assets such as the Tokens are a relatively new and untested technology. In
                  addition to the risks included in this Schedule 1, there are other risks associated
                  with your acceptance, holding and use of Tokens, including those that we cannot
                  reasonably foresee.
                </div>
                <div className="mt-[12px]">
                  Additional risks may also materialize as unanticipated variations or combinations
                  of the risks discussed above in this Schedule 1.
                </div>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </main>
  );
};
