import { useMediaQuery } from 'hooks/useMediaQuery';
import { usePriceData } from 'hooks/usePriceData';
import { useEffect, useMemo, useRef, useState } from 'react';
import type { DotProps } from 'recharts';
import { AreaChart, XAxis, YAxis, Tooltip, CartesianGrid, Area } from 'recharts';
import { formatForChart } from 'utils/date';

import type { PlatformToken } from 'constants/tokens';

import { ChartTooltip } from './ChartTooltip';

const now = Math.floor(Date.now() / 1000);
const januaryFirst2023 = Math.floor(new Date('2023-01-01').getTime() / 1000);

export const TokenChart = ({ token }: { token: PlatformToken }) => {
  const isMobile = useMediaQuery('only screen and (max-width: 670px)');
  const isDesktopVertical = useMediaQuery('(min-width: 1025px) and (max-width: 1350px)');
  const { data: priceData } = usePriceData(token, januaryFirst2023, now);

  const [chartSize, setChartSize] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });
  const chartWrapperRef = useRef<HTMLDivElement | null>(null);

  const data = useMemo(() => (priceData ? formatForChart(priceData) : []), [priceData]);

  const [minPrice, maxPrice] = useMemo(() => {
    if (data.length > 0) {
      const prices = data.map(d => parseFloat(d.price));
      return [Math.min(...prices), Math.max(...prices)];
    }
    return [0, 0];
  }, [data]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      if (entries[0].target) {
        const { width, height } = entries[0].contentRect;
        setChartSize({ width, height: height || 280 });
      }
    });

    if (chartWrapperRef.current) {
      resizeObserver.observe(chartWrapperRef.current);
    }

    return () => {
      if (chartWrapperRef.current) {
        resizeObserver.unobserve(chartWrapperRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={chartWrapperRef}
      className="flex h-[280px] w-full items-center justify-center rounded-[24px] bg-transparent [&>div]:overflow-visible"
    >
      <AreaChart
        width={chartSize.width}
        height={chartSize.height}
        data={data}
        margin={{
          top: 20,
          right: 15,
          left: 0,
          bottom: 45,
        }}
      >
        <CartesianGrid horizontal={true} vertical={false} />
        <XAxis
          tickLine={false}
          tick={({ x, y, payload }) => {
            return isMobile || isDesktopVertical ? (
              <g transform={`translate(${x - 12},${y + (isMobile ? 24 : 32)})`}>
                <text
                  x={0}
                  y={0}
                  dy={16}
                  fontSize={isMobile ? 10 : 12}
                  height={16}
                  textAnchor="middle"
                  fill="#a2a3a5"
                  transform={'rotate(-90)'}
                >
                  {payload.value instanceof Date
                    ? `${payload.value.toLocaleString('en-US', {
                        month: 'short',
                      })} ${payload?.value?.getFullYear()}`
                    : ''}
                </text>
              </g>
            ) : (
              <g transform={`translate(${x},${y})`}>
                <text
                  x={0}
                  y={0}
                  dy={16}
                  fontSize={12}
                  height={16}
                  textAnchor="middle"
                  fill="#a2a3a5"
                >
                  {payload.value instanceof Date
                    ? payload.value.toLocaleString('en-US', { month: 'short' })
                    : ''}
                </text>
                <text
                  x={0}
                  y={0}
                  dy={30}
                  fontSize={12}
                  height={16}
                  textAnchor="middle"
                  fill="#a2a3a5"
                >
                  {payload.value instanceof Date ? payload.value.getFullYear() : ''}
                </text>
              </g>
            );
          }}
          interval={0}
          dataKey="keyDate"
          axisLine={false}
          stroke="#a2a3a5"
        />
        <YAxis
          fontSize={12}
          height={16}
          tickLine={false}
          axisLine={false}
          stroke="#a2a3a5"
          domain={[minPrice, maxPrice]}
          tick={({ x, y, payload, ...props }) => (
            <g transform={`translate(${x},${y})`}>
              <text
                x={0}
                y={0}
                dy={4}
                fontSize={12}
                height={16}
                textAnchor="middle"
                fill="#a2a3a5"
                {...props}
              >
                {payload.value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
              </text>
            </g>
          )}
        />
        <Tooltip content={<ChartTooltip />} />
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#E4F8F4" stopOpacity={1} />
            <stop offset="56.4%" stopColor="#F3E1F6" stopOpacity={0.3} />
            <stop offset="99.9%" stopColor="#FCFCD3" stopOpacity={0.1} />
          </linearGradient>
        </defs>
        <defs>
          <filter id="activeDotShadows" x="-50%" y="-50%" width="200%" height="200%">
            <feOffset result="offsetBlur1" in="SourceAlpha" dx="0" dy="2.67" />
            <feGaussianBlur result="blur1" in="offsetBlur1" stdDeviation="2.665" />
            <feComponentTransfer result="fade1">
              <feFuncA type="linear" slope="0.06" />
            </feComponentTransfer>
            <feMerge result="shadow1">
              <feMergeNode in="fade1" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>

            <feOffset result="offsetBlur2" in="SourceAlpha" dx="0" dy="2.67" />
            <feGaussianBlur result="blur2" in="offsetBlur2" stdDeviation="1.335" />
            <feComponentTransfer result="fade2">
              <feFuncA type="linear" slope="0.06" />
            </feComponentTransfer>
            <feMerge>
              <feMergeNode in="fade2" />
              <feMergeNode in="shadow1" />
            </feMerge>
          </filter>
        </defs>
        <Area
          dot={false}
          type="monotone"
          dataKey="price"
          stroke="#051c2c"
          fill="url(#colorUv)"
          activeDot={(props: DotProps) => (
            <circle
              cx={props.cx}
              cy={props.cy}
              r={8}
              fill="#051c2c"
              stroke="#ffffff"
              strokeWidth={4}
              filter="url(#activeDotShadows)"
            />
          )}
        />
      </AreaChart>
    </div>
  );
};
