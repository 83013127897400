import { useEmail } from 'hooks/useEmail';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useState } from 'react';
import { newTxToast } from 'toasts/newTxToast';

import { FooterDesktop } from './footer-desktop';
import { FooterMobile } from './footer-mobile';

export function Footer() {
  const [email, setEmail] = useState('');
  const { subscribeOnUpdates, isSubscribingOnUpdates } = useEmail();

  const handleSubscribeOnUpdates = async () => {
    if (isSubscribingOnUpdates) return;
    await subscribeOnUpdates({ email });
    newTxToast({
      type: 'success',
      message: 'Thank you for subscribing',
    });
  };

  const isDesktop = useMediaQuery('(min-width: 1024px)');

  return isDesktop ? (
    <FooterDesktop setEmail={setEmail} onSubscribeOnUpdates={handleSubscribeOnUpdates} />
  ) : (
    <FooterMobile setEmail={setEmail} onSubscribeOnUpdates={handleSubscribeOnUpdates} />
  );
}
