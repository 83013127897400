import type { ReactNode } from 'react';

import { Blogpost } from './blogpost';
import { MidasLaunchesMbasis } from './blogposts/midas-launches-mbasis';
import MidasLeadBlock from './blogposts/midas-lead-block';
// import { MidasMBasisPR } from './blogposts/midas-mbasis-pr';
import MidasRaises from './blogposts/midas-raises';

import MidasLeadBlockPreview from '../../assets/imgs/blogposts/midas-leadblock.png';
import MidasRaisesPreview from '../../assets/imgs/blogposts/midas-raises.png';

export interface IBlogpost {
  id: string;
  date: string;
  preview: string;
  title: string;
  component: ReactNode;
}

export const blogposts: IBlogpost[] = [
  {
    id: 'midas-launches-mbasis-a-tokenized-basis-trading-strategy',
    date: '20 June, 2024',
    preview: '/mbasis-blog-banner.png',
    title:
      'Midas is thrilled to announce the launch of mBASIS, a tokenized delta-neutral basis trading strategy',
    component: <MidasLaunchesMbasis />,
  },
  // {
  //   id: 'midas-is-thrilled-to-announce-the-launch-of-mbasis,-a-tokenized-delta-neutral-basis-trading-strategy',
  //   date: '20 June, 2024',
  //   preview: '/mbasis-blog-banner.png',
  //   title: 'Midas Launches mBASIS: A Tokenized Basis Trading Strategy',
  //   component: <MidasMBasisPR />,
  // },
  {
    id: 'midas-raises-8-75-million-funding-round-led-by-framework-ventures-blocktower-and-hv-capital',
    date: 'Mar 26, 2024',
    preview: MidasRaisesPreview,
    title:
      'Midas Raises $8.75 Million Funding Round Led by Framework Ventures, BlockTower and HV Capital',
    component: <MidasRaises />,
  },
  {
    id: 'midas-and-midasleadblock-pioneering-the-mtbill-backed-lending-market-on-morpho-blue',
    date: 'Apr 5, 2024',
    preview: MidasLeadBlockPreview,
    title: 'Midas and MidasLeadBlock: Pioneering the mTBILL-Backed Lending Market on Morpho Blue',
    component: <MidasLeadBlock />,
  },
];

export function Blog() {
  return (
    <div className="mx-auto max-w-[1240px] px-5 pb-[190px] pt-[130px] max-sm:pb-[90px] max-sm:pt-[30px]">
      <h1 className="mb-[120px] text-center text-[64px] font-semibold text-stone-blue max-sm:mb-[40px] max-sm:text-[48px]">
        Stay up to date with Midas!
      </h1>

      <div className="flex flex-wrap justify-between gap-[40px] max-lg:justify-center">
        {blogposts.map(blogpost => (
          <Blogpost key={blogpost.id} blogpost={blogpost} />
        ))}
      </div>
    </div>
  );
}

export default Blog;
