import { BigNumber, ethers } from 'ethers';
import Aside from 'layouts/adminTab';
import Header from 'layouts/headerAdminTab';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNetwork } from 'wagmi';

import { NotificationModal } from '../../components/NotificationModal';
import { GET_ALL_DEPOSIT_REQUESTS } from '../../graphql/queries.js/transactions';
// import { useCompleteDeposit } from '../../hooks/useCompleteDeposit';
import { useDefaultSubgraphQuery } from '../../hooks/useDefaultSubgraphQuery';
// import { useManualDeposit } from '../../hooks/useManualDeposit';
import { comaFormat } from '../../utils/comaFormat';
import { dateToShorten } from '../../utils/date';
import { formatBN } from '../../utils/formatBN';
import { transactionsToComponentData } from '../../utils/parsers';
import { shortenAddress } from '../../utils/shortenAddress';

interface RedemptionRowProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  txData: any;
  onCompleteDepositClick: (requestId: ethers.BigNumber) => void;
  isLoading?: boolean;
}

const RedemptionRow = ({ txData, onCompleteDepositClick, isLoading }: RedemptionRowProps) => {
  return (
    <tr className="odd:bg-[#F0F0F0]">
      <td className="p-[16px]">{dateToShorten(txData.date)}</td>
      <td className="p-[16px]">{shortenAddress(txData.wallet)}</td>
      <td className="p-[16px]">{txData.transactionType}</td>
      <td className="p-[16px]">{txData.asset}</td>
      <td className="p-[16px]">{txData.investment}</td>
      <td className="p-[16px]">{txData.exchangeRatio.toString()}</td>
      <td className="p-[16px]">{txData.fee}</td>
      <td className="p-[16px]">
        <Link
          target="_blank"
          rel="noopener noreferrer"
          to={txData.explorerLink}
          className="text-[16px] text-[#1127E3] no-underline hover:underline"
        >
          Link
        </Link>
      </td>
      <td className="h-full items-center justify-center p-[16px]">
        {txData.asset === 'Pending' ? (
          <button
            className="cursor-pointer rounded-[100px] border-[1px] border-[#1127E3] bg-[#1127E3] px-[12px] py-[6px] text-[14px] text-white transition-all hover:bg-white hover:text-[#1127E3]"
            disabled={isLoading}
            onClick={() => onCompleteDepositClick(ethers.BigNumber.from(txData.id))}
          >
            {isLoading ? 'Processing...' : 'Complete Deposit'}
          </button>
        ) : null}
      </td>
    </tr>
  );
};

export const DepositRequests = () => {
  // const { manuallyDepositAsync, usdc } = useManualDeposit();
  const [isLoading] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const closeModal = () => setIsModalOpened(false);
  const [errorText] = useState('');
  const { chain } = useNetwork();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { data: oldData } = useDefaultSubgraphQuery<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    depositVaultDepositRequests?: any[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    manualDepositEntities?: any[];
  }>(GET_ALL_DEPOSIT_REQUESTS);
  const data = useMemo(() => {
    return {
      deposits: (oldData?.depositVaultDepositRequests ?? []).concat(
        oldData?.manualDepositEntities ?? [],
      ),
      redemptions: [],
    };
  }, [oldData]);
  const parsedTxData = transactionsToComponentData(chain?.name, data);

  // const { fulfillRequestAsync } = useCompleteDeposit();

  // const exchangeRatio =
  //   isNaN(parseFloat(amountIn)) || isNaN(parseFloat(amountOut)) || parseFloat(amountOut) === 0
  //     ? 'N/A'
  //     : (parseFloat(amountIn) / parseFloat(amountOut)).toFixed(2);

  // const onCompleteDepositClick = async (requestId: ethers.BigNumber) => {
  //   setIsLoading(true);
  //   const amountStUsdOut = prompt("Enter a valid StUSD amount to send to the user's wallet");

  //   if (!amountStUsdOut || isNaN(parseFloat(amountStUsdOut))) {
  //     txErrorToast('Invalid number');
  //     setIsLoading(false);
  //     return;
  //   }
  //   try {
  //     await publicClient.waitForTransactionReceipt(
  //       await fulfillRequestAsync({
  //         args: [BigInt(requestId.toString())],
  //       }),
  //     );
  //     successToast('Transaction sent successfully.');
  //     refetch();
  //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //   } catch (e: any) {
  //     const error = getHumanError(e.shortMessage);
  //     setErrorText(
  //       `Error while sending transaction: ${isCustomError(error) ? e.shortMessage : error}`,
  //     );
  //     setIsModalOpened(true);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // const onManualDepositClick = async () => {
  //   setIsLoading(true);

  //   try {
  //     if (!userAddress || !isAddress(userAddress)) {
  //       txErrorToast('Invalid address provided');
  //       setIsLoading(false);
  //       return;
  //     }

  //     if (!amountIn || isNaN(parseFloat(amountIn))) {
  //       txErrorToast('Invalid amount in');
  //       setIsLoading(false);
  //       return;
  //     }

  //     if (!amountOut || isNaN(parseFloat(amountOut))) {
  //       txErrorToast('Invalid amount out');
  //       setIsLoading(false);
  //       return;
  //     }

  //     await publicClient.waitForTransactionReceipt(
  //       await manuallyDepositAsync({
  //         args: [userAddress, usdc.address, parseUnits(amountIn, 18), parseUnits(amountOut, 18)],
  //       }),
  //     );
  //     refetch();
  //     successToast('Deposited successfully');

  //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //   } catch (e: any) {
  //     const error = getHumanError(e.shortMessage);
  //     setErrorText(
  //       `Error while sending transaction: ${isCustomError(error) ? e.shortMessage : error}`,
  //     );
  //     setIsModalOpened(true);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const totalStUsdRequested = parsedTxData.investments.reduce(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (prev: any, cur: any) => BigNumber.from(cur.rawValues.amountStUsdOut ?? '0').add(prev),
    ethers.constants.Zero,
  );
  const totalUsdReceived = parsedTxData.investments.reduce(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (prev: any, cur: any) => BigNumber.from(cur.rawValues.amountUsdIn ?? '0').add(prev),
    ethers.constants.Zero,
  );

  return (
    <div className="admin">
      <NotificationModal
        isModalOpened={isModalOpened}
        closeModal={closeModal}
        onBtnClick={closeModal}
        content={{
          bodyContent: errorText,
          headerContent: <>Sorry.</>,
          btnContent: 'Close',
        }}
      />
      <Aside />
      <section>
        <Header />
        <div className="lists">
          <h5 className="!text-black">Deposit Requests.</h5>
        </div>
        <div className="relative">
          <table className="w-full table-fixed border-collapse">
            <thead
              style={{
                borderTop: '1px solid #A5A5A5',
                borderBottom: '1px solid #A5A5A5',
              }}
            >
              <tr className="!text-[14px] text-black">
                <td className="p-[16px] font-semibold">Date</td>
                <td className="p-[16px] font-semibold">Wallet</td>
                <td className="p-[16px] font-semibold">Transaction</td>
                <td className="p-[16px] font-semibold">Assets (mTBILL)</td>
                <td className="p-[16px] font-semibold">Investment (USDC)</td>
                <td className="p-[16px] font-semibold">Exchange Ratio</td>
                <td className="p-[16px] font-semibold">Fees (USDC)</td>
                <td className="p-[16px] font-semibold">Etherscan</td>
                <td className="p-[16px] font-semibold"></td>
              </tr>
            </thead>
            <tbody>
              {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
              {parsedTxData.investments?.map((txData: any) => (
                <RedemptionRow
                  key={txData.id}
                  txData={txData}
                  onCompleteDepositClick={() => {
                    console.log('deposit');
                  }}
                  isLoading={isLoading}
                />
              ))}
              <tr
                className="bg-[#F0F0F0]"
                style={{
                  borderTop: '1px solid #A5A5A5',
                  borderBottom: '1px solid #A5A5A5',
                }}
              >
                <td></td>
                <td></td>
                <td></td>
                <td className="p-[16px] font-semibold">
                  {comaFormat(formatBN(totalStUsdRequested ?? ethers.constants.Zero, 0))} mTBILL
                </td>
                <td className="p-[16px] font-semibold">
                  {comaFormat(formatBN(totalUsdReceived ?? ethers.constants.Zero, 0))} USDC
                </td>
                <td />
                <td />
                <td />
                <td />
              </tr>
            </tbody>
          </table>
        </div>
        {/* <div className="flex flex-wrap gap-4">
          <div className="flex min-w-[452px] flex-col gap-[16px] rounded-[24px] bg-[#F3F4FE] p-[40px]">
            <div className="text-[20px] font-semibold leading-[32px] text-[#0C1C46]">
              Manual USDC Deposit
            </div>
            <div className="flex flex-col gap-[4px]">
              <div className="text-[14px] font-medium leading-[24px] text-[#6D7790]">
                Select User
              </div>
              <div className="relative w-full">
                <input
                  className="flex h-[48px] w-available rounded-full border-none bg-white px-[24px] py-0 text-[18px] leading-[24px] text-[#0C1C46] outline-none"
                  value={userAddress}
                  onChange={e => setUserAddress(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-col gap-[4px]">
              <div className="flex justify-between text-[14px] font-medium leading-[24px] text-[#6D7790]">
                Amount In (USD received)
              </div>
              <div className="relative w-full">
                <input
                  type="number"
                  step={0.1}
                  min={0}
                  className="flex h-[48px] w-available rounded-full border-none bg-white px-[24px] py-0 text-[18px] leading-[24px] text-[#0C1C46] outline-none"
                  value={amountIn}
                  onChange={e => setAmountIn(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-col gap-[4px]">
              <div className="flex justify-between text-[14px] font-medium leading-[24px] text-[#6D7790]">
                <div>Amount Out (mTBILL)</div>
                <div>Ratio: {exchangeRatio}</div>
              </div>
              <div className="relative w-full">
                <input
                  type="number"
                  step={0.1}
                  min={0}
                  className="flex h-[48px] w-available rounded-full border-none bg-white px-[24px] py-0 text-[18px] leading-[24px] text-[#0C1C46] outline-none"
                  value={amountOut}
                  onChange={e => setAmountOut(e.target.value)}
                />
              </div>
            </div>
            <button
              onClick={onManualDepositClick}
              disabled={isLoading}
              className="mt-[20px] cursor-pointer rounded-[40px] border-[1px] border-[#1127E3] bg-[#1127E3] px-[16px] py-[8px] text-[16px] font-medium text-white transition-all hover:bg-white hover:text-[#1127E3]"
            >
              {isLoading ? 'Processing...' : 'Deposit'}
            </button>
          </div>
        </div> */}
      </section>
    </div>
  );
};
