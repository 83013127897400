import { parseUnits } from '@ethersproject/units';
import { ethers } from 'ethers';
import { BigNumber } from 'ethers';

export const toBN = (bn: ethers.BigNumber | undefined | null) => {
  bn ??= ethers.constants.Zero;
  return BigNumber.from(bn.toString());
};

export const convertToBase18 = (bn: ethers.BigNumber, decimals: number) => {
  bn ??= ethers.constants.Zero;

  return BigNumber.from(bn.toString()).mul(parseUnits('1')).div(parseUnits('1', decimals));
};

export const convertFromBase18 = (bn: ethers.BigNumber, decimals: number) => {
  bn ??= ethers.constants.Zero;

  return BigNumber.from(bn.toString()).mul(parseUnits('1', decimals)).div(parseUnits('1'));
};
