import type { TooltipProps } from 'recharts';
import type { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

export const ChartTooltip = (props: TooltipProps<ValueType, NameType>) => {
  const { coordinate, payload, active } = props;
  const values = payload?.[0]?.payload;
  if (active && coordinate && payload && payload.length) {
    return (
      <div
        style={{
          background: 'white',
          padding: '10px 14px',
          borderRadius: '8px',
          border: '1px solid #ccc',
          whiteSpace: 'nowrap',
          boxShadow: '0px 3px 6px #00000029',
          pointerEvents: 'none',
        }}
      >
        <p className="text-[22px] font-bold leading-[28px] text-[#11263C]">{`$${Number(
          values.price,
        ).toFixed(2)}`}</p>
        <p className="text-[12px] leading-[20px] text-[rgba(60,60,67,0.6)]">
          {new Date(values.date).toLocaleString('default', { day: '2-digit' })}{' '}
          {new Date(values.date).toLocaleString('default', { month: 'short' })}{' '}
          {new Date(values.date).getFullYear()}
        </p>
      </div>
    );
  }

  return null;
};
