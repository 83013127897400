import { zodResolver } from '@hookform/resolvers/zod';
import { Dialog, DialogContent } from 'components/Dialog';
import { EmailIcon } from 'components/svg/Email';
import { UserIcon } from 'components/svg/User';
import { useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm, Controller } from 'react-hook-form';
import { z } from 'zod';

import { useEmail } from '../hooks/useEmail';

interface FormData {
  name: string;
  email: string;
  message: string;
}

const schema = z.object({
  name: z.string().min(1, 'Name is required'),
  email: z.string().email('Enter a valid email').min(1, 'Email is required'),
  message: z.string().min(1, 'Message is required'),
});

export function Contact() {
  const { contactUs, isContactingUs } = useEmail();
  const [dialogOpen, setDialogOpen] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit: SubmitHandler<FormData> = async data => {
    if (isContactingUs) return;
    const { name, email, message } = data;

    try {
      await contactUs({
        name,
        email,
        message,
      });
      setDialogOpen(true);
      reset();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <main className="mb-[240px] mt-40 min-h-[calc(100%_-_61px)] w-full flex-shrink-0 flex-grow px-2 max-lg:mb-[200px] max-lg:mt-12 sm:px-4 md:min-h-[calc(100%_-_72px)] lg:px-16">
        <div className="flex flex-col gap-8 max-lg:gap-12 lg:flex-row">
          <div className="flex w-full flex-col gap-6 max-lg:gap-4 max-lg:text-center lg:w-[55%]">
            <h1 className="text-[56px] font-semibold leading-[64px] text-[#051C2C] max-lg:text-[40px] max-lg:leading-[48px]">
              Contact Us
            </h1>
            <p className="text-[22px] leading-[32px] max-lg:text-base">
              Send us a message using the form, email us at&nbsp;
              <a className="font-medium text-[#ffad53] underline" href="mailto:team@midas.app">
                team@midas.app
              </a>{' '}
              , or reach out via Telegram&nbsp;
              <a className="font-medium text-[#ffad53] underline" href="https://t.me/midasrwa">
                @midasrwa
              </a>
            </p>
          </div>
          <div className="w-full lg:w-[45%]">
            <div className="contact-us-gradient flex flex-col items-center rounded-[24px] border border-white px-[72px] py-12 text-center max-xl:px-6 max-xl:py-10">
              <h2 className="text-[40px] font-semibold leading-[40px] text-[#051C2C] max-lg:text-[32px]">
                Have A Question?
              </h2>
              <p className="mt-6 text-[18px] leading-[28px] text-[#051C2C] max-lg:mt-4 max-lg:text-base">
                Simply complete this form and we will reply within the next business day.
              </p>
              <form className="mt-6 flex w-full flex-col gap-6" onSubmit={handleSubmit(onSubmit)}>
                <div className="flex w-full flex-col gap-2 text-start max-lg:gap-[6px]">
                  <label
                    htmlFor="name"
                    className="text-[18px] font-medium leading-[24px] text-[#051C2C] max-lg:text-[16px]"
                  >
                    Name
                  </label>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <div className="relative">
                        <UserIcon className="absolute left-4 top-[50%] translate-y-[-50%]" />
                        <input
                          {...field}
                          className="h-[64px] w-full rounded-[8px] border border-solid border-[#F0F0F7] bg-white pl-[50px] pr-[24px] text-[18px] leading-[24px] text-[#7E8BA4] max-lg:h-[44px] max-lg:text-base"
                          type="text"
                          placeholder="Please enter your name"
                          id="name"
                        />
                      </div>
                    )}
                  />
                  {errors.name && (
                    <p className="pl-[20px] text-sm text-red-500">{errors.name.message}</p>
                  )}
                </div>
                <div className="flex w-full flex-col gap-2 text-start max-lg:gap-[6px]">
                  <label
                    htmlFor="email"
                    className="text-[18px] font-medium leading-[24px] text-[#051C2C] max-lg:text-[16px]"
                  >
                    Email
                  </label>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <div className="relative">
                        <EmailIcon className="absolute left-4 top-[50%] translate-y-[-50%]" />
                        <input
                          {...field}
                          className="h-[64px] w-full rounded-[8px] border border-solid border-[#F0F0F7] bg-white pl-[50px] pr-[24px] text-[18px] leading-[24px] text-[#7E8BA4] max-lg:h-[44px] max-lg:text-base"
                          type="text"
                          placeholder="Please enter your email address"
                          id="email"
                        />
                      </div>
                    )}
                  />
                  {errors.email && (
                    <p className="pl-[20px] text-sm text-red-500">{errors.email.message}</p>
                  )}
                </div>
                <div className="flex w-full flex-col gap-2 text-start max-lg:gap-[6px]">
                  <label
                    htmlFor="message"
                    className="text-[18px] font-medium leading-[24px] text-[#051C2C] max-lg:text-[16px]"
                  >
                    Message
                  </label>
                  <Controller
                    name="message"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <textarea
                        {...field}
                        className="min-h-[237px] w-full resize-none rounded-[8px] border border-solid border-[#F0F0F7] bg-white px-[16px] py-[20px] text-[18px] leading-[24px] text-[#7E8BA4] max-lg:text-base"
                        id="message"
                        placeholder="Type your message..."
                      ></textarea>
                    )}
                  />
                  {errors.message && (
                    <p className="pl-[20px] text-sm text-red-500">{errors.message.message}</p>
                  )}
                </div>
                <button
                  type="submit"
                  disabled={isContactingUs}
                  className="flex h-[64px] w-full cursor-pointer items-center justify-center rounded-[8px] border-0 bg-orange text-[20px] font-semibold text-[#051C2C] outline-0 transition-colors hover:bg-[#f5a957] max-lg:h-[48px]"
                >
                  {isContactingUs ? 'Processing...' : 'Submit'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </main>
      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogContent closeBtnEnabled={false} className="w-[343px] rounded-[24px] sm:w-[462px]">
          <div className="flex flex-col gap-12">
            <div className="flex flex-col gap-6 text-center">
              <h4 className="text-[28px] font-medium leading-[36px] text-[#051C2C]">Thank you</h4>
              <p className="text-base font-medium text-[#14223D]">
                We will get back to you shortly.
              </p>
            </div>
            <button
              onClick={() => setDialogOpen(false)}
              className="mx-auto flex h-[48px] w-full cursor-pointer items-center justify-center rounded-[8px] border-0 bg-orange text-[20px] font-semibold text-[#051C2C] outline-0 transition-colors hover:bg-[#f5a957] sm:w-[200px]"
            >
              Dismiss
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Contact;
