import { BlogText, BlogTextLink, BlogTitle } from './ui';

import BannerImg from '../../../assets/imgs/blogposts/midas-leadblock.png';
import TwitterImg from '../../../assets/imgs/social/twitter-lg.svg';

const ShareOnTwitter = () => {
  return (
    <a
      className="twitter-share-button absolute right-[-355px] top-0 max-xl:hidden"
      href="https://twitter.com/intent/tweet?text=https://midas.app/blog"
      target="_blank"
      style={{ textDecoration: 'unset' }}
    >
      <div className="flex h-[145px] items-center gap-[25px] rounded-[16px] border-2 border-solid border-[#000] bg-waveGradient pl-5 pr-3 shadow-3xl">
        <img src={TwitterImg} alt="twitter-ico" />
        <p className="w-[138px] text-base font-medium text-stone-blue">
          Click to share this post on Twitter!
        </p>
      </div>
    </a>
  );
};

export function MidasLeadBlock() {
  return (
    <main className="detail-desktop relative flex w-full max-w-[900px] flex-col items-center gap-[40px] pb-[200px] pt-[100px]">
      <div className="px-5 text-[24px] font-[600] leading-[48px] text-[#0C1C46] md:text-[48px] md:leading-[72px]">
        Midas and LeadBlock: Pioneering the mTBILL-Backed Lending Market on Morpho Blue
      </div>
      <div className="box-border w-full px-2 md:px-0">
        <img src={BannerImg} alt="banner" className="w-full" />
      </div>

      <div className="relative flex flex-col gap-[40px]">
        <BlogText>
          In decentralized finance’s (DeFi) ever-evolving landscape, finding innovative ways to
          secure and enhance yields remains a top priority for savvy investors. That’s why we at
          Midas, in collaboration with LeadBlock Partners, are thrilled to unveil the mTBILL/USDC
          lending market and LeadBlock-managed USDC RWA MetaMorpho vault on Morpho Blue.
        </BlogText>
        <BlogText>
          The mTBILL/USDC market is a strategic approach to leveraging U.S. Treasury Bills for
          enhanced yields. Managed by LeadBlock, the USDC RWA MetaMorpho Vault enables users to put
          their USDC to work, earning the risk-free rate of return alongside DeFi yield. <br /> This
          introduction of a yield-bearing stablecoin into a top decentralised money market grants
          lower and more consistent stablecoin lending rates, while offering a medium for
          capital-efficient borrowing.
        </BlogText>

        <ShareOnTwitter />
      </div>

      <BlogTitle>Introducing Morpho Blue</BlogTitle>
      <BlogText>
        Morpho Blue blends traditional finance principles with cutting-edge innovation. Its isolated
        money markets enable verified, overcollateralized mTBILL suppliers to confidently borrow
        USDC. The unique design also facilitates exceptional lending rate splits, offering users a
        competitive loan-to-value (LTV) ratio within a stable, dollar-denominated environment.
      </BlogText>

      <BlogText>
        Further, Morpho Blue paves the way for the seamless creation of MetaMorpho Vaults, which are
        directly integrated with Morpho Blue lending markets. These vaults consolidate various
        USDC-utilizing markets (or markets with the same loan token) into a curated, risk-managed
        yield strategy.
      </BlogText>

      <BlogText>
        Instead of juggling multiple positions, Morpho users can earn passive income by depositing
        idle USDC into the LeadBlock USDC RWA Vault, accessing both U.S. Treasury and DeFi-sourced
        yields.
      </BlogText>

      <BlogTitle>Why We Chose Morpho</BlogTitle>
      <BlogText>
        <ul className="m-0 pl-5">
          <li>
            <span className="font-bold">High LTV Lending:</span> Morpho enables Midas users to
            collateralize mTBILL for USDC loans at over 90% LTV, offering the most stable rates
            available in DeFi.
          </li>
          <li>
            <span className="font-bold">Enhanced Yield:</span> For those looking to maximize their
            USDC’s potential, Morpho presents a risk-managed pathway to an enhanced yield, backed by
            a regulatory-compliant, yield-bearing token in mTBILL.
          </li>
          <li>
            <span className="font-bold">Top-Notch Security:</span> With measures like Liquidation
            Loan-to-Value (LLTV), adaptive interest rate models, and market selection for risk
            management, users enjoy a secure investment environment.
          </li>
        </ul>
      </BlogText>

      <BlogTitle>Who can invest?</BlogTitle>
      <BlogText>
        While the mTBILL token is only available to verified Midas users outside the U.S., the
        LeadBlock USDC RWA MetaMorpho vault is available to all Morpho users and USDC holders.
      </BlogText>

      <BlogTitle>Keen to Learn More?</BlogTitle>

      <BlogText>
        This announcement only scratches the surface of what we're offering. If you're intrigued by
        the potential of this pioneering venture and wish to explore how it can benefit your
        investment strategy, don’t hesitate to reach out! Contact us at:{' '}
        <BlogTextLink href="https://midas.app/">https://midas.app/</BlogTextLink>
      </BlogText>

      <BlogText>
        To check out the new vault, visit:{' '}
        <BlogTextLink href="https://app.morpho.org/vault?vault=0x4cA0E178c94f039d7F202E09d8d1a655Ed3fb6b6">
          https://app.morpho.org/vault?vault=0x4cA0E178c94f039d7F202E09d8d1a655Ed3fb6b6
        </BlogTextLink>
      </BlogText>

      <BlogText>
        For more information on Midas and the mTBILL token, navigate to&nbsp;
        <BlogTextLink href="https://midas.app/">https://midas.app/</BlogTextLink>, or contact us via
        <br />
        team@midas.app. For a technical deep dive, visit our documentation at&nbsp;
        <BlogTextLink href="https://midas-docs.gitbook.io/midas-docs">
          https://midas-docs.gitbook.io/midas-docs
        </BlogTextLink>
        .
      </BlogText>
    </main>
  );
}

export default MidasLeadBlock;
