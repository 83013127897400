export const MbasisLogo = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="9"
      height="29"
      viewBox="0 0 9 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.10203 15.7355L7.01546 14.5079C7.01546 14.5079 7.03403 8.27286 6.98665 7.25726C6.93927 6.24166 6.75462 4.82355 6.15216 3.22843C5.6317 1.85046 4.96207 0.770791 4.39692 -2.29233e-07C3.86067 0.73426 3.26332 1.69125 2.75683 2.86875C2.09918 4.39748 1.81682 5.78077 1.69222 6.82558C1.69222 11.8916 1.69222 9.41042 1.69222 14.4764L3.60087 15.7354L3.56251 7.07737C3.56399 6.06869 3.64677 4.90222 3.88861 3.62415C4.01868 2.93692 4.17904 2.30023 4.3538 1.7177C4.57854 2.31341 4.79321 3.01884 4.94368 3.82201C5.17952 5.08112 5.1843 6.19041 5.11635 7.0594C5.11158 12.4671 5.10681 10.3277 5.10203 15.7355Z"
        fill="#051C2C"
      />
      <path
        d="M6.0509 24.11C6.0417 24.2102 6.00256 24.4828 5.77756 24.7394C5.5589 24.9888 5.29112 25.0788 5.18768 25.1081C5.27489 25.1345 6.11814 25.4049 6.41057 26.2412C6.73119 27.1582 6.0896 27.8978 6.0509 27.9408C5.9525 28.0434 5.50206 28.4902 4.76085 28.5434C3.84279 28.6092 3.24649 28.016 3.17352 27.9408C3.09672 27.8605 2.65739 27.3853 2.72751 26.6998C2.77818 26.2043 3.06644 25.879 3.17352 25.7691C3.33743 25.6219 3.59723 25.4079 3.95039 25.1958C4.29401 24.9894 4.51736 24.9101 4.93587 24.7101C5.20634 24.5809 5.59352 24.3845 6.0509 24.11Z"
        fill="#051C2C"
      />
      <path
        d="M6.05078 23.0181L6.05078 21.5883L3.06546 23.3081L3.06546 24.6772L6.05078 23.0181Z"
        fill="#051C2C"
      />
      <path
        d="M4.5072 18.4142L8.09856 15.5157L9 16.3612L8.97908 16.3808L9 16.3808L9 21.5485L7.49883 21.5485L7.49884 18.2376L4.49326 20.6725L1.50163 18.2507L1.50162 21.5485L-1.35791e-06 21.5485L7.52509e-07 16.3808L0.922825 15.5157L4.5072 18.4142Z"
        fill="#2400FF"
      />
    </svg>
  );
};
