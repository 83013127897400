import { ethers } from 'ethers';
import { convertFromBase18 } from 'utils/decimalsConvertor';
import { erc20ABI, useContractWrite, useNetwork, useWaitForTransaction } from 'wagmi';

import { getAddressesByChain } from 'constants/addresses';

import { PlatformToken } from '../constants/tokens';
import type { Token } from '../constants/tokens';

export const useApproveToken = (
  token: Token,
  amountBase18: ethers.BigNumber,
  to: 'depositVault' | 'redemptionVault',
  platformToken: PlatformToken,
) => {
  const { chain } = useNetwork();

  const amountBaseOriginal = amountBase18.eq(ethers.constants.MaxUint256)
    ? amountBase18
    : convertFromBase18(amountBase18, token.decimals);

  const addresses = getAddressesByChain(chain?.network);

  let vaultAddress: `0x${string}`;

  if (platformToken === PlatformToken.MTBILL) {
    if (to === 'depositVault') {
      vaultAddress = addresses.mtbillDepositVault;
    } else if (to === 'redemptionVault') {
      vaultAddress = addresses.mtbillRedemptionVault;
    } else {
      throw new Error('Invalid "to" value');
    }
  } else if (platformToken === PlatformToken.MBASIS) {
    if (to === 'depositVault') {
      vaultAddress = addresses.mbasisDepositVault;
    } else if (to === 'redemptionVault') {
      vaultAddress = addresses.mbasisRedemptionVault;
    } else {
      throw new Error('Invalid "to" value');
    }
  } else {
    throw new Error('Invalid "platformToken" value');
  }

  const { data, status, write, writeAsync, isLoading, isSuccess } = useContractWrite({
    abi: erc20ABI,
    address: token.address as `0x${string}`,
    args: [vaultAddress, BigInt(amountBaseOriginal.toString())],
    functionName: 'approve',
  });

  const {
    isLoading: isLoadingWait,
    isSuccess: isSuccessWait,
    error,
  } = useWaitForTransaction({
    hash: data?.hash,
    enabled: Boolean(data?.hash),
  });

  return {
    data,
    status,
    write,
    writeAsync,
    isLoading: isLoadingWait || isLoading,
    isSuccess: isSuccessWait && isSuccess,
    error,
  };
};
