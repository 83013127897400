import { useQuery } from '@tanstack/react-query';

import { getMtbillPrice } from '../../api/data';

export const useGetMtbillPrice = (timestampFrom: number, timestampTo: number) => {
  return useQuery({
    queryKey: ['mtbill-price', timestampFrom, timestampTo],
    queryFn: () => getMtbillPrice({ timestampFrom, timestampTo }),
  });
};
