import { PlatformToken } from 'constants/tokens';

type FeeDetail = {
  title: string;
  value: string;
};

export type FeeStructure = Record<PlatformToken, FeeDetail[]>;

export const feeStructure: FeeStructure = {
  [PlatformToken.MBASIS]: [
    {
      title: 'Management Fee',
      value: '0%',
    },
    {
      title: 'Performance Fee',
      value: '10% (Waived)',
    },
    {
      title: 'Minting Fee',
      value: '0%',
    },
    {
      title: 'Redemption Fee',
      value: '0%',
    },
  ],
  [PlatformToken.MTBILL]: [
    {
      title: 'Midas Management Fee',
      value: 'Waived',
    },
    {
      title: 'Intermediary Fees',
      value: '0%',
    },
    {
      title: 'ETF Management Fees',
      value: '0.07%',
    },
  ],
};
