import type { ChangeEvent } from 'react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useNewKyc } from '../../hooks/useNewKyc';
import { NotificationModal } from '../NotificationModal';

const Step1 = () => {
  const [data, setData] = useState({ email: '', name: '' });

  const [isLoading, setIsLoading] = useState(false);
  const { sendNewKyc } = useNewKyc();
  const navigate = useNavigate();

  const [errors, setErrors] = useState<{ [k in keyof typeof data]: boolean }>({
    email: false,
    name: false,
  });

  const onNext = async () => {
    if (isLoading) {
      return;
    }
    if (
      data?.email !== undefined &&
      data.email.trim() !== '' &&
      validateEmail(data.email.trim()) &&
      data?.name !== undefined &&
      data.name.trim() !== ''
    ) {
      try {
        setIsLoading(true);
        await sendNewKyc(data);
        setIsModalOpened(true);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setErrors({
        email:
          data.email === undefined || data.email.trim() === '' || !validateEmail(data.email.trim()),
        name: data.name === undefined || data.name.trim() === '',
      });
    }
  };

  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Регулярное выражение для проверки формата электронной почты
    return regex.test(email);
  };

  const [isModalOpened, setIsModalOpened] = useState(false);
  const closeModal = () => {
    setIsModalOpened(false);
  };

  const onInputChange = (name: keyof typeof data) => (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setData(prev => ({ ...prev, [name]: value }));
    setErrors(prev => ({
      ...prev,
      [name]:
        value === undefined ||
        value.trim() === '' ||
        (name === 'email' ? !validateEmail(value) : false),
    }));
  };

  return (
    <div className="inner-forms">
      <NotificationModal
        onBtnClick={() => navigate('/')}
        isModalOpened={isModalOpened}
        closeModal={closeModal}
        content={{
          bodyContent: (
            <>
              Thank you for beginning the onboarding process.
              <br />
              Our team will contact you shortly to assist with the next steps.
            </>
          ),
          headerContent: <>Thank you.</>,
          btnContent: 'Back to home',
        }}
      />
      <h1 className="!text-stone-blue">Onboarding Process</h1>

      <div className="form-inside !bg-transparent">
        <div className={`input-block ${errors.name ? 'error' : ''}`}>
          <p className="!mb-2 pl-[20px] !text-[18px] !font-normal !text-stone-blue">Name</p>
          <input
            type="text"
            name="name"
            value={data.name}
            onChange={onInputChange('name')}
            className="!fz-[16px] !h-[64px] !border-[3px] !border-solid !border-[#E8E8E8] !bg-white !px-[24px] !text-[16px] !font-medium !text-stone-blue"
          />
        </div>
        <div className={`input-block ${errors.email ? 'error' : ''}`}>
          <p className="!mb-2 pl-[20px] !text-[18px] !font-normal !text-stone-blue">
            E-Mail Address
          </p>
          <input
            type="email"
            name="email"
            value={data.email}
            onChange={onInputChange('email')}
            className="!fz-[16px] !h-[64px] !border-[3px] !border-solid !border-[#E8E8E8] !bg-white !px-[24px] !text-[16px] !font-medium !text-stone-blue"
          />
        </div>
        <button
          className="line-[32px] mx-auto flex h-[58px] w-[273px] cursor-pointer items-center justify-center rounded-[16px] border-0 bg-orange text-[20px] font-semibold text-stone-blue outline-0 max-lg:h-[56px] max-lg:rounded-[40px]"
          onClick={onNext}
        >
          {isLoading ? 'Processing...' : 'Proceed'}
        </button>
      </div>
    </div>
  );
};

export default Step1;
