import { TokenChart } from 'components/TokenChart';
// import { usePriceUpdates } from 'hooks/usePriceUpdates';

import type { PlatformToken } from 'constants/tokens';

// import arrowDown from '../../assets/imgs/new/icons/arrow_down.svg';
// import arrowUp from '../../assets/imgs/new/icons/arrow_up.svg';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Performance = ({ token }: { token: PlatformToken }) => {
  // const priceUpdates = usePriceUpdates(token);
  // const dayPriceGrown = parseFloat(priceUpdates?.dayUpdate || '0') > 0;

  return (
    <div className="flex w-full flex-col gap-6 md:gap-[40px]">
      <div className="text-2xl font-bold text-[#0C1C46] md:text-[40px] md:leading-[48px]">
        Performance
      </div>
      {/* <div className="flex w-full items-center justify-between gap-[40px]">
        <div className="flex flex-col gap-[8px]">
          <div className="text-[16px] leading-[24px] text-[#14223D]">Net Asset Value</div>
          <div className="text-[24px] font-medium leading-[32px] text-[#051C2C]">
            ${priceUpdates?.lastPrice}
          </div>
          <div className="flex items-center gap-[8px]">
            {dayPriceGrown ? (
              <img src={arrowUp} alt="arrow-up" />
            ) : (
              <img className="rotate-180" src={arrowDown} alt="arrow-down" />
            )}
            <div className={cn('text-base', dayPriceGrown ? 'text-[#179386]' : 'text-red-500')}>
              ${Math.abs(parseFloat(priceUpdates?.dayUpdate || '0'))} today
            </div>
          </div>
        </div>
        <div className="h-[96px] w-[1px] bg-[#E3E3E3]" />
        <div className="flex flex-col gap-[8px]">
          <div className="text-[16px] leading-[24px] text-[#14223D]">7-day Yield</div>
          <div className="text-[16px] font-bold leading-[24px] text-[#051C2C]">12.05%</div>
        </div>
        <div className="h-[96px] w-[1px] bg-[#E3E3E3]" />
        <div className="flex flex-col gap-[8px]">
          <div className="text-[16px] leading-[24px] text-[#14223D]">Total Value Locked</div>
          <div className="text-[16px] font-bold leading-[24px] text-[#051C2C]">$23.3m</div>
        </div>
        <div className="h-[96px] w-[1px] bg-[#E3E3E3]" />
        <div className="flex flex-col gap-[8px]">
          <div className="text-[16px] leading-[24px] text-[#14223D]">Year-to-Date Performance</div>
          <div className="text-[16px] font-bold leading-[24px] text-[#051C2C]">+1.45%</div>
        </div>
        <div className="h-[96px] w-[1px] bg-[#E3E3E3]" />
        <div className="flex flex-col gap-[8px]">
          <div className="text-[16px] leading-[24px] text-[#14223D]">Underlying Composition</div>
          <div className="text-[16px] font-bold leading-[24px] text-[#051C2C]">
            50% BTC • 40% ETH • 10% mTBILL
          </div>
        </div>
      </div> */}
      <TokenChart token={token} />
    </div>
  );
};
