type FaqConfigItem = {
  value: string;
  title: string;
  description: string;
};

export const faqConfig: FaqConfigItem[] = [
  {
    value: 'value-1',
    title: 'What can I do with the mTBILL and mBASIS tokens?',
    description:
      'mTBILL and mBASIS are permissionless ERC-20 tokens compatible with all dApps on EVM blockchains like Ethereum. Investors can interact with various dApps, such as DeFi lending and borrowing platforms, or engage in peer-to-peer transactions.',
  },
  {
    value: 'value-2',
    title: 'What are the benefits of holding mTBILL or mBASIS?',
    description:
      'By holding mTBILL or mBASIS, investors gain value from the assets these tokens track. Additionally, they can leverage a full suite of DeFi dApps, enjoying benefits such as instant settlement, 24/7 availability, onchain transparency, and fractional transactions.',
  },
  {
    value: 'value-3',
    title: 'What fees do investors pay explicitly and implicitly?',
    description:
      'mTBILL is an efficient instrument for gaining exposure to US Treasuries. During the launch phase, Midas does not charge an issuance fee, providing a cost-effective entry point for investors.',
  },
  {
    value: 'value-4',
    title: 'Can anyone redeem the mTBILL and mBASIS tokens?',
    description:
      'Any token holder can redeem mTBILL and mBASIS tokens for their net asset value (NAV) upon successful completion of KYC and AML verifications.',
  },
  {
    value: 'value-5',
    title: 'What is the minimum investment amount in mTBILL or mBASIS?',
    description:
      'When purchasing tokens on midas.app, the initial minimum investment amount is $120,000. There is no minimum for subsequent transactions.',
  },
];
