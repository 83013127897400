import { useContractWrite, useNetwork } from 'wagmi';

import { getAddressesByChain } from 'constants/addresses';
import { acRoles } from 'constants/roles';

import { MidasAccessControl } from '../abis/MidasAccessControl';

export const useGreenListUser = () => {
  const { chain } = useNetwork();

  const { writeAsync: greenListAsync } = useContractWrite({
    abi: MidasAccessControl.abi,
    address: getAddressesByChain(chain?.network).ac,
    functionName: 'grantRole',
  });

  const { writeAsync: revokeGreenListAsync } = useContractWrite({
    abi: MidasAccessControl.abi,
    address: getAddressesByChain(chain?.network).ac,
    functionName: 'revokeRole',
  });

  return {
    greenListAsync,
    revokeGreenListAsync,
    role: acRoles.greenListed,
  };
};
