import { useMediaQuery } from 'hooks/useMediaQuery';

import { featureHighlightsConfig } from 'constants/features/featureHighlights';

export const FeatureHighlights = () => {
  const isMedium = useMediaQuery('(min-width: 801px) and (max-width: 1024px)');
  const isSmall = useMediaQuery('(min-width: 430px) and (max-width: 800px)');
  const isExtraSmall = useMediaQuery('(max-width: 429px)');

  return (
    <section className="mt-[64px] grid grid-cols-1 gap-6 lg:mt-[120px] lg:grid-cols-3 lg:gap-4">
      {featureHighlightsConfig.map(({ id, name, icon, color, features }, index) => (
        <div
          key={id}
          style={{ width: isExtraSmall ? '100%' : isSmall ? '92%' : isMedium ? '60%' : '100%' }}
          className="mx-auto flex h-full flex-grow flex-col justify-between rounded-[24px]"
        >
          <div
            style={{ background: color }}
            className="flex items-center justify-center gap-4 rounded-t-[24px] p-6 md:justify-start"
          >
            <img src={icon} alt={name} />
            <h5 className="m-0 text-2xl font-medium text-deep-blue">{name}</h5>
          </div>
          <div className="flex flex-grow flex-col rounded-b-[24px] bg-white p-4 shadow-[0_2px_16px_0px_rgba(20,34,61,0.05)]">
            {features.map((feature, i) => (
              <div
                key={`feature-${index}-${i}`}
                style={{ borderBottom: i < features.length - 1 ? '1px solid #e3e3e3' : '' }}
                className="p-4 text-sm text-deep-blue"
              >
                {feature}
              </div>
            ))}
          </div>
        </div>
      ))}
    </section>
  );
};
