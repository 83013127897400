import { cn } from 'utils/cn';

import { feeStructure } from 'constants/fees/feeStructure';
import type { PlatformToken } from 'constants/tokens';

export const FeeStructure = ({ token }: { token: PlatformToken }) => {
  return (
    <div className="mt-[56px] flex w-full flex-col gap-[16px] md:mt-[80px]">
      <p className="text-2xl font-bold text-[#0C1C46] md:text-[40px] md:leading-[48px]">
        Fee Structure
      </p>
      <div className="flex flex-col">
        {feeStructure[token].map(({ title, value }, i) => (
          <div
            key={`${title}-${value}-${i}`}
            className={cn(
              'flex w-full items-center py-[24px] text-[16px] leading-[24px] text-[#14223D]',
              i % 2 === 0 ? 'bg-white' : 'bg-[#f9f9f9]',
            )}
          >
            <div className="w-full pl-3 md:pl-[32px]">{title}</div>
            <div className="pr-3 text-end md:w-full md:pr-[32px] md:text-start">{value}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
