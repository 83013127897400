import { SuccessfulApproveModal, SuccessfulTxModal } from 'components/SuccessfullTxModal';
import { BigNumber, ethers } from 'ethers/lib';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { useApproveToken } from 'hooks/useApproveToken';
import { useDeposit } from 'hooks/useDeposit';
import type { useExchangeData } from 'hooks/useExchangeData';
import { useGreenListed } from 'hooks/useGreenListed';
import { usePriceUpdates } from 'hooks/usePriceUpdates';
import type { useTokensData } from 'hooks/useTokensData';
import { useTotalDeposited } from 'hooks/useTotalDeposited';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, type NavigateFunction } from 'react-router-dom';
import { newTxToast } from 'toasts/newTxToast';
import { comaFormat } from 'utils/comaFormat';
import { formatBN } from 'utils/formatBN';
import { getEtherscanLink } from 'utils/getEtherscanLink';
import { openInNewTab } from 'utils/openInNewTab';
import { useAccount, useChainId, useConfig, useNetwork } from 'wagmi';

import type { PlatformToken } from 'constants/tokens';
import { getTokenByName } from 'constants/tokens';

import { BigExchangerBody } from './BigExchangerBody';

import { getHumanError, isCustomError } from '../../utils/errors';
import { NotificationModal } from '../NotificationModal';

interface InvestBigExchangerProps {
  btnMessage?: string;
  tokenData: ReturnType<typeof useTokensData>;
  exchangeData: ReturnType<typeof useExchangeData>;
  platformToken: PlatformToken;
}

export const InvestBigExchanger = ({
  btnMessage: externalBtnMessage,
  tokenData,
  exchangeData,
  platformToken,
}: InvestBigExchangerProps) => {
  const { chain } = useNetwork();
  const chainId = useChainId();
  const { address, isConnected } = useAccount();
  const navigate = useNavigate();
  const { data: totalDeposited, isLoading: totalDepositedLoading } =
    useTotalDeposited(platformToken);
  const { isSuccess: isGreenListedLoaded } = useGreenListed();
  const { lastPrice } = usePriceUpdates(platformToken);
  const { publicClient } = useConfig();

  const [isDepositSuccessModalOpen, setIsDepositSuccessModalOpen] = useState(false);
  const [isApproveSuccessModalOpen, setIsApproveSuccessModalOpen] = useState(false);

  const [isInsufficientAllowance, setIsInsufficientAllowance] = useState(false);
  const [isInsufficientBalance, setIsInsufficientBalance] = useState(false);

  const [depositInputValueRaw, setDepositInputValueRaw] = useState<string>('');
  const [depositInputValue, setDepositInputValue] = useState<string>('');
  const [selectedInputToken, setSelectedInputToken] = useState(
    getTokenByName(chain?.network, 'USDC'),
  );

  const [isModalOpened, setIsModalOpened] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [errorBtnText, setErrorBtnText] = useState<string | undefined>(undefined);
  const [errorBtnOnClick, setErrorBtnOnClick] = useState<() => (() => void) | undefined>(
    () => () => undefined,
  );

  const { data, isSuccess: isExchangeDataLoaded } = exchangeData;
  const { token, depositAllowances, tokenBalances, isSuccess: isTokensDataSuccess } = tokenData;

  const isInputInvalid =
    !depositInputValue.trim() || +depositInputValue === 0 || isNaN(+depositInputValue);

  const calcDepositTokenOutput = () =>
    isInputInvalid
      ? undefined
      : parseUnits(depositInputValue)
          .sub(parseUnits(depositInputValue).mul(data.deposit.fee).div(parseUnits('100')))
          .mul(parseUnits('1'))
          .div(parseUnits(lastPrice || '1'));

  const [depositInputTokenValue, setDepositInputTokenValue] = useState(() =>
    calcDepositTokenOutput(),
  );

  const { writeAsync: writeDeposit, isLoading: isWriteDepositLoading } = useDeposit(
    parseUnits(isInputInvalid ? '0' : depositInputValue),
    selectedInputToken.address,
    platformToken,
  );

  const { writeAsync: writeApprove, isLoading: isWriteApproveLoading } = useApproveToken(
    selectedInputToken,
    ethers.constants.MaxUint256,
    'depositVault',
    platformToken,
  );

  const isWaitingForTx = isWriteDepositLoading || isWriteApproveLoading;

  const isLoading = !isTokensDataSuccess || !isExchangeDataLoaded || !isGreenListedLoaded;

  const isMinAmountPassed = useMemo(
    () =>
      (!totalDepositedLoading &&
        !isInputInvalid &&
        parseUnits(formatUnits(totalDeposited || '0'))?.gte(data.deposit.minDepositAmount)) ||
      parseUnits(isInputInvalid ? '0' : depositInputValue)?.gte(data.deposit.minDepositAmount),
    [depositInputValue, data.deposit.minDepositAmount],
  );

  useEffect(() => {
    setDepositInputValue(
      depositInputValueRaw.endsWith('.')
        ? depositInputValueRaw.slice(0, depositInputValueRaw.length - 1)
        : depositInputValueRaw,
    );
  }, [depositInputValueRaw]);

  useEffect(() => {
    setDepositInputTokenValue(calcDepositTokenOutput());
  }, [depositInputValue]);

  useEffect(() => {
    setIsInsufficientAllowance(
      isLoading || isInputInvalid
        ? false
        : depositAllowances[selectedInputToken.name]?.lt(parseUnits(depositInputValue)),
    );
  }, [depositAllowances, depositInputValue, address]);

  useEffect(() => {
    setIsInsufficientBalance(
      isLoading || isInputInvalid
        ? false
        : tokenBalances[selectedInputToken.name]?.lt(parseUnits(depositInputValue)),
    );
  }, [tokenBalances, depositInputValue, address]);

  const btnMessage =
    externalBtnMessage ||
    (!depositInputTokenValue || depositInputTokenValue.isZero()
      ? `Enter an amount`
      : isWaitingForTx
      ? 'Processing the transaction'
      : !isMinAmountPassed
      ? `Minimum Amount is ${comaFormat(formatBN(data.deposit.minDepositAmount, 0, 18))} USDC`
      : isInsufficientBalance
      ? `Insufficient ${selectedInputToken.name} balance`
      : isInsufficientAllowance
      ? 'Approve'
      : 'Invest');

  const isBtnDisabled =
    isConnected &&
    (!depositInputTokenValue ||
      depositInputTokenValue.isZero() ||
      !isMinAmountPassed ||
      isInsufficientBalance);

  const handleBtnClick = async () => {
    if (isInsufficientBalance) return;
    if (isWaitingForTx) return;

    newTxToast({
      type: 'info',
      message: 'Please confirm the transaction in your wallet',
    });

    try {
      if (isInsufficientAllowance) {
        const approveResult = await writeApprove();
        newTxToast({
          type: 'info',
          message: 'Transaction submitted',
          btnMessage: 'View Details',
          onButtonClick: () => openInNewTab(getEtherscanLink(approveResult.hash, 'tx', chainId)),
        });
        await publicClient.waitForTransactionReceipt(approveResult);
        setIsInsufficientAllowance(false);
        newTxToast({
          type: 'success',
          message: 'Transaction confirmed',
          btnMessage: 'View Details',
          onButtonClick: () => openInNewTab(getEtherscanLink(approveResult.hash, 'tx', chainId)),
        });
      } else {
        const depositResult = await writeDeposit();
        newTxToast({
          type: 'info',
          message: 'Transaction submitted',
          btnMessage: 'View Details',
          onButtonClick: () => openInNewTab(getEtherscanLink(depositResult.hash, 'tx', chainId)),
        });
        await publicClient.waitForTransactionReceipt(depositResult);
        newTxToast({
          type: 'success',
          message: 'Transaction confirmed',
          btnMessage: 'View Details',
          onButtonClick: () => openInNewTab(getEtherscanLink(depositResult.hash, 'tx', chainId)),
        });
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const hErr = getHumanError(err.shortMessage);

      let errText: string;
      let errBtnText: string | undefined;
      let errBtnOnClick: undefined | ((navFunc: NavigateFunction) => void);

      if (isCustomError(hErr)) {
        errText = hErr.text;
        errBtnText = hErr.btnText;
        errBtnOnClick = hErr.btnOnClick;
      } else {
        errText = hErr;
      }

      newTxToast({
        type: 'danger',
        message: `Error while sending the transaction: ${errText}`,
        btnMessage: errBtnText,
        onButtonClick: () => errBtnOnClick?.bind(this, navigate),
      });
    }
  };

  const balance = +formatUnits(tokenBalances[selectedInputToken.name] ?? ethers.constants.Zero);
  const outputBalance = +formatUnits(tokenBalances[token.name] ?? ethers.constants.Zero);
  const inputUsdValue = +formatUnits(
    isInputInvalid ? BigNumber.from(0) : parseUnits(depositInputValue),
  );

  const closeModal = () => {
    setIsModalOpened(false);

    setErrorText('');
    setErrorBtnText(undefined);
    setErrorBtnOnClick(() => () => undefined);
  };

  return (
    <>
      <NotificationModal
        onBtnClick={() => {
          errorBtnOnClick?.()?.();
          closeModal();
        }}
        isModalOpened={isModalOpened}
        closeModal={closeModal}
        content={{
          bodyContent: errorText,
          headerContent: <>Sorry.</>,
          btnContent: errorBtnText,
        }}
      />
      <SuccessfulTxModal
        isModalOpened={isDepositSuccessModalOpen}
        setIsModalOpened={setIsDepositSuccessModalOpen}
      />
      <SuccessfulApproveModal
        isModalOpened={isApproveSuccessModalOpen}
        setIsModalOpened={setIsApproveSuccessModalOpen}
      />
      <BigExchangerBody
        platformToken={platformToken}
        onSelectedTokenChange={setSelectedInputToken}
        firstInput={{
          tokens: [selectedInputToken],
          min: data.deposit.minDepositAmount,
          initValue: data.deposit.minDepositAmount,
          balance,
          maxAvailable: true,
          usdValue: inputUsdValue,
          onValueChange: setDepositInputValueRaw,
          value: depositInputValueRaw,
        }}
        secondInput={{
          tokens: [token],
          min: data.deposit.minDepositAmount,
          initValue: ethers.constants.Zero,
          balance: outputBalance,
          maxAvailable: false,
          value: depositInputTokenValue
            ? parseFloat(formatBN(depositInputTokenValue)).toString()
            : undefined,
        }}
        minInvestment={
          data.deposit.minDepositAmount.gt(0)
            ? isExchangeDataLoaded
              ? formatBN(data.deposit.minDepositAmount, 0, 18)
              : '-'
            : undefined
        }
        handleBtnClick={handleBtnClick}
        isBtnDisabled={isBtnDisabled}
        btnMessage={btnMessage}
      />
    </>
  );
};
