export const MtbillLogo = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.18391 15.384C6.68969 15.0467 5.99429 15.1115 5.65629 15.6052C5.39289 15.99 5.13546 16.3798 4.88399 16.774C4.62084 17.1865 4.7036 17.7317 5.09544 18.0247C6.5024 19.077 8.14694 19.8395 9.85175 20.3496C10.0287 20.4026 10.2175 20.4025 10.3945 20.3496C15.3219 18.8743 19.4045 15.3434 19.3422 9.67743C19.3208 7.99057 19.3312 6.29444 19.338 4.60282C19.3411 3.82868 18.5023 3.35173 17.852 3.77168C14.1404 6.16837 10.82 9.10861 8.02292 12.4772C7.68232 12.8874 7.7346 13.4975 8.16085 13.8178C8.58096 14.1335 8.98748 14.3916 9.3804 14.592C9.75382 14.7824 10.1976 14.6573 10.4728 14.3412C11.9581 12.6349 13.5836 11.049 15.3272 9.59259C15.5827 9.37918 15.9788 9.56257 15.9788 9.89547C16.018 13.454 13.4805 15.6873 10.3936 16.6418C10.2174 16.6963 10.0287 16.6963 9.85239 16.642C8.9064 16.3503 7.99139 15.935 7.18391 15.384Z"
        fill="#14223D"
      />
      <path
        d="M1.88602 14.2443C2.1982 14.8821 3.0639 14.8711 3.46955 14.2882C6.57576 9.82507 10.5259 5.96511 15.1094 2.91867C15.8106 2.45262 15.6986 1.37897 14.8909 1.14142C14.3508 0.982589 13.803 0.836651 13.2477 0.704668C12.978 0.640568 12.6939 0.695481 12.4667 0.85428C8.1269 3.88758 4.39277 7.65883 1.44589 11.9921C1.28757 12.2249 1.2356 12.5154 1.31621 12.7851C1.46868 13.2954 1.65947 13.7814 1.88602 14.2443Z"
        fill="#1AAB9B"
      />
      <path
        d="M0.890613 5.99914C0.893093 6.94804 2.1215 7.38313 2.75706 6.67851C4.95097 4.24621 7.42573 2.0549 10.1366 0.158137C10.1501 0.14871 10.1428 0.127034 10.1282 0.119498C10.1246 0.117645 10.1205 0.116647 10.1165 0.117214C6.98679 0.56071 4.0977 1.37831 1.45394 2.58362C1.10494 2.74273 0.88619 3.0945 0.886415 3.47805C0.886965 4.41831 0.888679 5.25898 0.890613 5.99914Z"
        fill="#14223D"
      />
    </svg>
  );
};
