import { NEXT_STEP, PREVIOUS_STEP, SET_STEP, UPDATE_FORM } from '../actions/actionTypes';

type FormState = {
  currentStep: number;
  name: string;
  email: string;
  walletAddress: string;
  selectedOption: string;
  checkboxChecked: boolean;
  firstName: string;
  lastName: string;
  placeOfBirth: string;
  dateOfBirth: string;
  phone: string;
  nationality: string;
  residentialAddress: string;
  countryOfResidence: string;
  tin: string;
  profession: string;
  legalCompanyName: string;
  domicileCountry: string;
  dateOfEstablishment: string;
  registerNumber: string;
  dateOfRegistration: string;
  personsAuthorizedToSign: string;
};

const initialState: FormState = {
  currentStep: 0,
  email: '',
  walletAddress: '',
  name: '',
  selectedOption: '',
  checkboxChecked: false,
  firstName: '',
  lastName: '',
  placeOfBirth: '',
  dateOfBirth: '',
  phone: '',
  nationality: '',
  residentialAddress: '',
  countryOfResidence: '',
  tin: '',
  profession: '',
  domicileCountry: '',
  legalCompanyName: '',
  dateOfEstablishment: '',
  dateOfRegistration: '',
  personsAuthorizedToSign: '',
  registerNumber: '',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const formReducer = (state = initialState, action: { type: string; payload?: any }) => {
  switch (action.type) {
    case NEXT_STEP:
      return { ...state, currentStep: state.currentStep + 1 };
    case PREVIOUS_STEP:
      return { ...state, currentStep: state.currentStep - 1 };
    case SET_STEP:
      return { ...state, currentStep: action.payload };
    case UPDATE_FORM:
      return { ...state, [action.payload.name]: action.payload.value };
    default:
      return state;
  }
};

export default formReducer;
