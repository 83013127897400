import logo from 'assets/imgs/midas_f.svg';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

export function AdminTab() {
  const location = useLocation();

  if (location.pathname.includes('/admin')) {
    return (
      <>
        <aside className="admin__aside fixed">
          <div className="logo">
            <img src={logo} />
          </div>

          <ul>
            <li
              className={
                'green__list ' + (location.pathname === '/admin/green-list' ? 'active' : '')
              }
            >
              <Link to={'/admin/green-list'}>Green-list</Link>
            </li>
            <li className={'stusd ' + (location.pathname === '/admin/mtbill' ? 'active' : '')}>
              <Link to={'/admin/mtbill'}>mTBILL</Link>
            </li>
            <li className={'status ' + (location.pathname === '/admin' ? 'active' : '')}>
              <Link to={'/admin'}>Status</Link>
            </li>
            <li
              className={
                'redemption_requests ' +
                (location.pathname === '/admin/deposit-requests' ? 'active' : '')
              }
            >
              <Link to={'/admin/deposit-requests'}>Deposit Requests</Link>
            </li>
            <li
              className={
                'redemption_requests ' +
                (location.pathname === '/admin/redemption-requests' ? 'active' : '')
              }
            >
              <Link to={'/admin/redemption-requests'}>Redemption Requests</Link>
            </li>
            <li
              className={
                'redemption_requests ' +
                (location.pathname === '/admin/manage-deposit-vault' ? 'active' : '')
              }
            >
              <Link to={'/admin/manage-deposit-vault'}>Manage Deposit</Link>
            </li>
            <li
              className={
                'redemption_requests ' +
                (location.pathname === '/admin/manage-redemption-vault' ? 'active' : '')
              }
            >
              <Link to={'/admin/manage-redemption-vault'}>Manage Redemption</Link>
            </li>
            <li
              className={
                'black__list ' + (location.pathname === '/admin/black-list' ? 'active' : '')
              }
            >
              <Link to={'/admin/black-list'}>Black-List</Link>
            </li>
            <li className={'user__list ' + (location.pathname === '/admin/users' ? 'active' : '')}>
              <Link to={'/admin/users'}>Users</Link>
            </li>
            <li className={'user__list ' + (location.pathname === '/admin/wallets' ? 'active' : '')}>
              <Link to={'/admin/wallets'}>Connected Wallets</Link>
            </li>
            <li
              className={'user__list ' + (location.pathname === '/admin/waitlist' ? 'active' : '')}
            >
              <Link to={'/admin/waitlist'}>Waitlist</Link>
            </li>
            <li
              className={
                'user__list ' + (location.pathname === '/admin/subscribers' ? 'active' : '')
              }
            >
              <Link to={'/admin/subscribers'}>Subscribers</Link>
            </li>
            <li
              className={
                'user__list ' + (location.pathname === '/admin/contact-requests' ? 'active' : '')
              }
            >
              <Link to={'/admin/contact-requests'}>Contact Requests</Link>
            </li>
          </ul>
        </aside>
      </>
    );
  } else {
    return null;
  }
}

export default AdminTab;
