import Aside from 'layouts/adminTab';
import Header from 'layouts/headerAdminTab';
import { Link } from 'react-router-dom';

import type { KycStatus } from '../../api/kyc';
import { acRoles } from '../../constants/roles';
import { GET_USERS_WITH_ROLE } from '../../graphql/queries.js/roles';
import { useDefaultSubgraphQuery } from '../../hooks/useDefaultSubgraphQuery';
import { useKyc } from '../../hooks/useKyc';
import { dateToShorten } from '../../utils/date';

const getName = (data: NonNullable<KycStatus['data']>) => {
  if ((data.investorType === 0 || data.investorType === 2) && data.individualKycData) {
    return `${data.individualKycData.firstName} ${data.individualKycData.lastName}`;
  }

  if (data.investorType === 1 && data.entityKycData) {
    return data.entityKycData.legalCompanyName;
  }

  return '';
};

const getStatus = (data: NonNullable<KycStatus['data']>) => {
  if (data.isAccepted) {
    return (
      <p
        className={`m-0 flex items-center gap-[4px] before:h-[8px] before:w-[8px] before:rounded-full before:bg-[#C7F2AB] before:content-[""]`}
      >
        Accepted
      </p>
    );
  }

  if (data.isRejected) {
    return (
      <p
        className={`m-0 flex items-center gap-[4px] before:h-[8px] before:w-[8px] before:rounded-full before:bg-red-600 before:content-[""]`}
      >
        Rejected
      </p>
    );
  }

  if (data.isChangesRequested) {
    return (
      <p
        className={`before:bg-orange-600 m-0 flex items-center gap-[4px] before:h-[8px] before:w-[8px] before:rounded-full before:content-[""]`}
      >
        Changes Requested
      </p>
    );
  }

  if (data.isSealed) {
    return (
      <p
        className={`m-0 flex items-center gap-[4px] before:h-[8px] before:w-[8px] before:rounded-full before:bg-yellow-400 before:content-[""]`}
      >
        Review
      </p>
    );
  }

  return (
    <p
      className={`m-0 flex items-center gap-[4px] before:h-[8px] before:w-[8px] before:rounded-full before:bg-[#1127e3] before:content-[""]`}
    >
      Pending
    </p>
  );
};

export function Users() {
  const { allKycData } = useKyc();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { data: greenListedUsers } = useDefaultSubgraphQuery<any>(GET_USERS_WITH_ROLE, {
    variables: {
      role: acRoles.greenListed,
    },
  });
  const greenlistedAddresses: string[] = greenListedUsers
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      greenListedUsers.userRoles.map((role: any) => role.user.toLowerCase())
    : [];

  return (
    <div className="admin">
      <Aside />
      <section>
        <Header />
        <div className="lists">
          <h5 className="!text-black">Users.</h5>
        </div>
        <div className="relative">
          <table className="w-full table-auto border-collapse">
            <thead
              style={{
                borderTop: '1px solid rgba(17, 39, 227, 0.20)',
                borderBottom: '1px solid rgba(17, 39, 227, 0.20)',
              }}
            >
              <tr className="!text-[14px] text-black">
                <td className="p-[16px] font-semibold">Date</td>
                <td className="p-[16px] font-semibold">Wallet</td>
                <td className="p-[16px] font-semibold">Name</td>
                <td className="p-[16px] font-semibold">Email</td>
                <td className="p-[16px] font-semibold">Greenlist</td>
                <td className="p-[16px] font-semibold">Details</td>
                <td className="p-[16px] font-semibold">Status</td>
              </tr>
            </thead>
            <tbody>
              {allKycData?.data.map(kyc => (
                <tr key={kyc.id} className="odd:bg-[#F0F0F0]">
                  <td className="p-[16px]">{dateToShorten(new Date(kyc.createdAt))}</td>
                  <td className="p-[16px]">{kyc.ethAddress}</td>
                  <td className="p-[16px]">{getName(kyc)}</td>
                  <td className="p-[16px]">{kyc.user?.email}</td>
                  <td className="p-[16px]">
                    {greenlistedAddresses.includes(kyc.ethAddress?.toLowerCase() ?? '')
                      ? 'Yes'
                      : 'No'}
                  </td>
                  <td className="p-[16px]">
                    <Link
                      to={`/admin/users/${kyc.ethAddress}`}
                      className="text-[16px] text-black no-underline hover:underline"
                    >
                      Link
                    </Link>
                  </td>
                  <td className="p-[16px]">{getStatus(kyc)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
}

export default Users;
