import { FaqAccordion } from 'components/FaqAccordion';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import arrow2 from '../assets/imgs/arrow-left-blue.svg';
import arrow1 from '../assets/imgs/arrow-right-blue.svg';
import howItWorks2 from '../assets/imgs/how_it_works2.svg';
import howItWorks1 from '../assets/imgs/how_works_1.svg';
import midas from '../assets/imgs/midas-blue.svg';
import money from '../assets/imgs/money.svg';
import mtbill from '../assets/imgs/mTBILL.svg';
import usd from '../assets/imgs/usd-blue.svg';

const features = [
  {
    title: 'DeFi Composability',
    desc: ['Permissionless secondary transfer', '24/7 instant settlement', 'On-chain transparency'],
    bgColor: '#fdf7fe',
  },
  {
    title: '1:1 Asset-Backed',
    desc: [
      'Each mTBILL token tracks one share of the BlackRock IB01 ETF',
      'Fully redeemable at the net asset value (NAV) of the tracked ETF',
    ],
    bgColor: '#def3ff',
  },
  {
    title: 'Fully Regulatory Compliant',
    desc: [
      'mTBILL and mBASIS are fully compliant with European Securities regulations',
      // 'Token holders have full legal rights to collateral',
    ],
    bgColor: '#eefbf9',
  },
  {
    title: 'Non-Custodial',
    desc: ['mTBILL and mBASIS tokens are held in self-custody by investors'],
    bgColor: '#fff4de',
  },
];

export function Works() {
  const topRef = useRef<HTMLHeadingElement>(null);
  const faqRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#faq' && faqRef.current) {
      faqRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (location.pathname === '/how-it-works' && topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  return (
    <main ref={topRef} className="works-p mt-10 w-full max-w-[1440px] flex-1 md:mt-16">
      <h1 className="text-center text-3xl font-bold text-[#14223D] lg:text-[56px]">How It Works</h1>
      <h1 className="pt-6 text-center text-[18px] font-[700] text-[#14223D] lg:hidden">
        Permissionless ERC-20 tokens
      </h1>
      <div className="mx-auto flex max-w-[90%] flex-col items-center justify-between gap-4 lg:max-w-full lg:flex-row lg:gap-[100px] lg:px-[100px]">
        <img src={howItWorks1} className="mt-4 w-[170px] lg:mt-0 lg:w-[300px]" />
        <div className="flex flex-col gap-[24px]">
          <div className="hidden text-[32px] font-bold text-[#14223D] lg:block">
            Permissionless ERC-20 tokens
          </div>
          <div className="text-center text-[12px] font-medium leading-8 text-[#14223D99] lg:text-start lg:text-[20px]">
            mTBILL and mBASIS are freely transferable and permissionless ERC20 Tokens. Harness the
            full power of DeFi with 24/7 settlement or lending and borrowing on DeFi money markets.
          </div>
        </div>
      </div>
      <div className="mx-auto mt-[25px] flex max-w-[90%] flex-col items-center justify-between gap-4 lg:max-w-full lg:flex-row lg:gap-[100px] lg:px-[100px]">
        <div className="mt-6 flex flex-col gap-[24px] lg:mt-0">
          <div className="text-[18px] font-bold text-[#14223D] lg:text-[32px]">
            100% tracked and fully transparent
          </div>
          <div className="flex flex-col gap-4">
            <p className="hidden text-[20px] font-medium leading-8 text-[#14223D99] lg:block">
              mTBILL tracks U.S. Treasury Bond Funds 1:1.
            </p>
            <p className="hidden text-[20px] font-medium leading-8 text-[#14223D99] lg:block">
              mBASIS tracks a professionally managed crypto delta neutral strategy.
            </p>
          </div>
        </div>
        <div className="mt-[12px] lg:mt-0">
          <img src={howItWorks2} className="w-[115px] lg:w-[212px]" />
        </div>

        <div className="flex flex-col gap-0">
          <p className="block text-center text-[12px] font-medium leading-8 text-[#14223D99] lg:hidden">
            mTBILL tracks U.S. Treasury Bond Funds 1:1.
          </p>
          <p className="block text-center text-[12px] font-medium leading-8 text-[#14223D99] lg:hidden">
            mBASIS tracks a professionally managed crypto delta neutral strategy.
          </p>
        </div>
      </div>
      <h3 className="mb-6 mt-[54px] text-2xl font-bold text-[#0C1C46] max-sm:pl-4 md:mt-[85px] md:text-[40px] md:leading-[48px]">
        mTBILL Example
      </h3>
      <section className="midas">
        <div>
          <div className="bank items-center ">
            <div className="pattern1">
              <div className="flex items-center gap-2">
                <img className="md:w-[70px] lg:w-[80px] xl:w-auto" src={usd} />
                <div className="arrow">
                  <div className="!text-[8px] !text-stone-blue lg:!text-[18px]">Deposit USDC</div>
                  <img src={arrow1} />
                </div>
              </div>
              <div className="flex items-center gap-2">
                <img className="md:w-[70px] lg:w-[80px] xl:w-auto" src={mtbill} />
                <div className="arrow">
                  <div className="!text-[8px] !text-stone-blue lg:!text-[18px]">Receive mTBILL</div>
                  <img src={arrow2} />
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center">
              <img src={midas} className="w-[100px] md:w-[266px]" />
            </div>
            <div className="rec gap-4">
              <div className="arrow_forward">
                <img src={arrow1} />
                <img src={arrow2} />
              </div>
              <img className="md:w-[75px] lg:w-[85px] xl:w-auto" src={money} />
            </div>
          </div>
          <div className="classes lg:!px-[80px]">
            <div className="flex items-center justify-center lg:w-[calc(50%_-_92px)]">
              Investor receives permissionless ERC&#8288;-&#8288;20 token
            </div>
            <div className="flex items-center justify-center lg:w-[calc(50%_-_92px)]">
              1:1 tracking of US Treasury Funds
            </div>
          </div>
        </div>
      </section>

      <section className="mt-[100px] grid gap-x-[46px] gap-y-6 px-[10px] lg:grid-cols-2 lg:gap-y-[49px]">
        {features.map(({ title, desc, bgColor }) => (
          <div
            key={title}
            className="flex flex-col rounded-[24px] shadow-[0px_2px_16px_0px_#14223D0D]"
          >
            <div
              style={{ background: bgColor }}
              className="flex h-[81px] gap-4 rounded-t-[24px] p-6"
            >
              <p className="text-2xl font-medium text-[#14223D]">{title}</p>
            </div>
            <ul className="flex h-full list-inside list-disc flex-col gap-4 rounded-b-[24px] p-6">
              {desc.map(d => (
                <li key={d} className="text-base text-[#14223D]">
                  {d}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </section>

      <section
        ref={faqRef}
        className="faq !grid grid-cols-1 justify-between rounded-[24px] px-[10px] py-[24px] md:grid-cols-2 lg:mb-[100px]"
      >
        <div className="name block w-full !pb-0 !text-start !text-2xl !font-[600] !text-[#14223D] md:hidden">
          FAQ
        </div>
        <div className="name mx-auto hidden !items-center !text-5xl !font-bold !text-[#14223D] md:flex lg:!text-[64px] lg:!leading-[72px]">
          Frequently Asked Questions.
        </div>
        <FaqAccordion />
      </section>
    </main>
  );
}

export default Works;
