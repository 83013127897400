export const comaFormat = (number: number | string, precision?: number, forceDecimal?: boolean) => {
  const numberString = String(number);
  const parts = [];
  let decimalPart = '';

  const [integerPart, fractionalPart] = numberString.split('.');

  let i = integerPart.length - 1;

  while (i >= 0) {
    const part = integerPart.substring(i - 2, i + 1);
    parts.unshift(part);
    i -= 3;
  }

  const formattedIntegerPart = parts.join(',');

  if (fractionalPart) {
    decimalPart = `.${fractionalPart}`;

    if (precision && precision > fractionalPart.length) {
      decimalPart = decimalPart.padEnd(precision + 1, '0');
    } else if (precision && precision <= fractionalPart.length) {
      decimalPart = decimalPart.slice(0, precision + 1);
    }
  } else if (forceDecimal) {
    decimalPart = numberString.endsWith('.') ? '.' : '';
  }

  return formattedIntegerPart + decimalPart;
};
