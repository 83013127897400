import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { cn } from 'utils/cn';

export const ListItem = React.forwardRef<
  React.ElementRef<typeof Link>,
  React.ComponentPropsWithoutRef<typeof Link> & { hoverColor: string }
>(({ className, children, hoverColor, ...props }, ref) => {
  const [isHovered, setIsHovered] = useState(false);

  const style = {
    backgroundColor: isHovered ? hoverColor : 'transparent',
  };

  return (
    <li>
      <Link
        ref={ref}
        style={style}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={cn(
          'block select-none space-y-1 rounded-md leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground',
          className,
        )}
        {...props}
      >
        {children}
      </Link>
    </li>
  );
});
ListItem.displayName = 'ListItem';
