import { ConnectWalletButton } from 'components/ConnectWalletButton';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
} from 'components/NavigationMenu';
import { MidasMLogo, MbasisLogo, MtbillLogo, Menu } from 'components/svg';
import { forwardRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useAccount } from 'wagmi';

import { ListItem } from './headerListItem';

export default forwardRef<HTMLDivElement, unknown>(function Header(props, ref) {
  const [active, setActive] = useState(false);
  const [show, setShow] = useState(false);
  const { isConnected } = useAccount();

  const init = (value: boolean) => {
    if (value) {
      setShow(value);
      setTimeout(() => setActive(value), 20);
    } else {
      setActive(value);
      setTimeout(() => setShow(value), 500);
    }
  };

  const location = useLocation();

  if (!location.pathname.includes('/profile') && !location.pathname.includes('/admin')) {
    return (
      <>
        <header className="w-full pt-6 max-md:px-2" ref={ref}>
          <div className="desktop relative !flex-row md:!px-16 2xl:!px-0">
            <div className="relative">
              <div className="flex items-center md:gap-12">
                <Link onClick={() => init(false)} to={`/`}>
                  <MidasMLogo className="max-sm:max-w-[137px]" />
                </Link>
                <div className="flex items-center gap-[40px]">
                  <NavigationMenu delayDuration={0} className="hidden lg:flex">
                    <NavigationMenuList className="gap-8">
                      <NavigationMenuItem>
                        <NavigationMenuTrigger
                          onClick={e => e.preventDefault()}
                          className="unset !flex !cursor-pointer !items-center !font-medium"
                        >
                          Invest
                        </NavigationMenuTrigger>
                        <NavigationMenuContent>
                          <ul className="flex w-full flex-col gap-2 p-3">
                            <ListItem hoverColor="#eefbf9" to="/mtbill">
                              <div className="flex items-center gap-4 p-3">
                                <div>
                                  <div className="flex h-9 w-9 items-center justify-center rounded-full bg-[#c8f2ab]">
                                    <MtbillLogo className="max-md:h-[25px] max-md:w-[23px]" />
                                  </div>
                                </div>
                                <div className="flex w-full flex-col">
                                  <h6 className="m-0 text-base font-medium text-deep-blue">
                                    mTBILL
                                  </h6>
                                  <p className="text-nowrap text-xs text-light-blue">
                                    Short Term US Treasuries
                                  </p>
                                </div>
                              </div>
                            </ListItem>
                            <ListItem hoverColor="#eefbf9" to="/mbasis">
                              <div className="flex items-center gap-4 p-3">
                                <div>
                                  <div className="flex h-9 w-9 items-center justify-center rounded-full bg-[#e8e5ff]">
                                    <MbasisLogo className="max-md:h-[25px] max-md:w-[23px]" />
                                  </div>
                                </div>
                                <div className="flex w-full flex-col">
                                  <h6 className="m-0 text-base font-medium text-deep-blue">
                                    mBASIS
                                  </h6>
                                  <p className="text-nowrap text-xs text-light-blue">
                                    Delta Neutral Strategy
                                  </p>
                                </div>
                              </div>
                            </ListItem>
                          </ul>
                        </NavigationMenuContent>
                      </NavigationMenuItem>

                      <NavigationMenuItem>
                        <Link
                          to="/how-it-works"
                          className="text-[16px] font-medium text-[#14223D] no-underline"
                        >
                          How It Works
                        </Link>
                      </NavigationMenuItem>
                      <NavigationMenuItem>
                        <Link
                          to="https://midas-docs.gitbook.io/midas-docs/"
                          className="text-[16px] font-medium text-[#14223D] no-underline"
                          target="_blank"
                        >
                          Docs
                        </Link>
                      </NavigationMenuItem>
                    </NavigationMenuList>
                  </NavigationMenu>

                  <NavigationMenu delayDuration={0} className="hidden lg:flex">
                    <NavigationMenuList className="gap-8">
                      <NavigationMenuItem>
                        <NavigationMenuTrigger className="unset !flex !cursor-pointer !items-center !font-medium">
                          About
                        </NavigationMenuTrigger>
                        <NavigationMenuContent>
                          <ul className="flex w-full flex-col gap-1 p-3">
                            <ListItem
                              hoverColor="#fdf7fe"
                              to="https://wellfound.com/company/midas-10"
                              target="_blank"
                            >
                              <div className="flex items-center gap-4 p-3">Careers</div>
                            </ListItem>
                            <ListItem hoverColor="#fdf7fe" to="/contact-us">
                              <div className="flex items-center gap-4 p-3">Contact</div>
                            </ListItem>
                          </ul>
                        </NavigationMenuContent>
                      </NavigationMenuItem>
                    </NavigationMenuList>
                  </NavigationMenu>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-center gap-6">
              <div className="navigation">
                <div className="actions">
                  {isConnected ? (
                    <ConnectWalletButton style={{ marginLeft: '10px' }} isHeader />
                  ) : null}
                </div>
              </div>
              <div onClick={() => init(true)} className="flex cursor-pointer items-center lg:hidden">
                <Menu className="min-h-8 min-w-8" />
              </div>
            </div>
          </div>
        </header>
        <nav className={'nav-mobile ' + (active ? 'active' : '') + (show ? ' show' : '')}>
          <div onClick={() => init(false)} className="close">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M14.0805 12.5019L20.462 6.12029C21.1781 5.40793 21.1781 4.25406 20.4694 3.53798C19.7571 2.82191 18.6032 2.82191 17.8871 3.53056L17.8834 3.53427L11.5019 9.91584L5.11658 3.53798C4.40422 2.82562 3.24663 2.82562 2.53427 3.53798C1.82191 4.25035 1.82191 5.40793 2.53427 6.12029L8.91584 12.5019L2.53427 18.8834C1.82191 19.5958 1.82191 20.7534 2.53427 21.4657C3.24663 22.1781 4.40422 22.1781 5.11658 21.4657L11.4981 15.0842L17.8797 21.4657C18.5921 22.1781 19.7497 22.1781 20.462 21.4657C21.1744 20.7534 21.1744 19.5958 20.462 18.8834L14.0805 12.5019Z"
                fill="white"
              />
            </svg>
          </div>
          <ul>
            <li>
              <Link onClick={() => init(false)} to={`/mTBILL`}>
                mTBILL
              </Link>
            </li>{' '}
            <li>
              <Link onClick={() => init(false)} to={`/mBASIS`}>
                mBASIS
              </Link>
            </li>
            <li>
              <Link onClick={() => init(false)} to={`/how-it-works`}>
                How It Works
              </Link>
            </li>
            <li>
              <Link
                onClick={() => init(false)}
                to={`https://midas-docs.gitbook.io/midas-docs/`}
                target="_blank"
              >
                Docs
              </Link>
            </li>
          </ul>
        </nav>
      </>
    );
  } else {
    return null;
  }
});
