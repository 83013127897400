import { mainnet, sepolia } from 'wagmi';

const getChainIdToEtherscanUrl = (id: number | string) => {
  const chainIdToEtherscanUrl: Record<string, string> = {
    [mainnet.id]: 'https://etherscan.io',
    [sepolia.id]: 'https://sepolia.etherscan.io/',
  };

  if (Object.keys(chainIdToEtherscanUrl).includes(String(id))) {
    return chainIdToEtherscanUrl[String(id)];
  }

  return chainIdToEtherscanUrl[mainnet.id];
};

export const getEtherscanLink = (
  resource: string,
  type: 'tx' | 'address' | 'block',
  chainId: string | number,
) => {
  const etherscanBaseUrl = getChainIdToEtherscanUrl(chainId);

  if (!etherscanBaseUrl) {
    throw new Error(`Etherscan URL not found for chain ID: ${chainId}`);
  }

  let url;
  switch (type) {
    case 'tx':
      url = `${etherscanBaseUrl}/tx/${resource}`;
      break;
    case 'address':
      url = `${etherscanBaseUrl}/address/${resource}`;
      break;
    case 'block':
      url = `${etherscanBaseUrl}/block/${resource}`;
      break;
    default:
      throw new Error(`Unknown etherscan resource type: ${type}`);
  }

  return url;
};
