type TitleConfig = Record<string, string>;

export const titleConfig: TitleConfig = {
  '/': 'Midas: Structured Products Onchain | Home of mTBILL and mBASIS',
  '/mtbill': 'mTBILL: Tokenized US Treasury Bonds by Midas',
  '/mbasis': 'mBASIS: Professionally Managed Crypto Delta Neutral Strategy, by Midas',
  '/how-it-works': 'How It Works: Understanding Midas DeFi Products',
  '/contact-us': 'Contact Us: Get in Touch with Midas',
  '/terms-and-conditions': 'Terms and Conditions',
  '/imprint': 'Imprint',
  '/privacy-policy': 'Privacy Policy',
};

export const defaultTitle = 'Midas';
