import { BlogSubtitle, BlogText, BlogTextLink, BlogTitle, ShareOnTwitter } from './ui';

import AltcoinFundingFinImg from '../../../assets/imgs/blogposts/midas-launches-mbasis/altcoin-funding-fin.png';
import BtcFundingFinImg from '../../../assets/imgs/blogposts/midas-launches-mbasis/btc-funding-fin.png';
import BtcHalvingImg from '../../../assets/imgs/blogposts/midas-launches-mbasis/btc-halving.png';
import BtcUsdImg from '../../../assets/imgs/blogposts/midas-launches-mbasis/btc-usdt.png';

export function MidasLaunchesMbasis() {
  return (
    <main className="relative mx-auto flex w-full max-w-[900px] flex-col items-center gap-[40px] pb-[200px] pt-[100px]">
      <div className="px-5 text-[24px] font-[600] leading-[48px] text-[#0C1C46] md:text-[48px] md:leading-[72px]">
        Midas is thrilled to announce the launch of mBASIS, a tokenized delta neutral basis trading
        strategy
      </div>
      <div className="box-border w-full px-2 md:px-0">
        <img src={'/mbasis-blog-banner.png'} alt="banner" className="w-full" />
      </div>
      <div className="relative flex flex-col gap-[40px]">
        <BlogText>
          Midas’s mBASIS gives token-holders exposure to an institutional-grade basis trading
          strategy.
        </BlogText>
        <BlogText>
          This strategy takes advantage of profitable crypto basis positions during bull cycles when
          markets are in contango. The mBASIS token differentiates itself through its actively
          managed approach that dynamically allocates basis positions in the major large-caps (BTC &
          ETH), while also incorporating yield from the top 20 altcoins. This meaningfully enhances
          the return profile, with market-neutral returns between 20% and 40% observed in past bull
          cycles.
        </BlogText>
        <BlogText>
          During declining markets, the strategy adjusts to reverse basis trading, taking advantage
          of backwardation of the yield curve, or allocates into US T-Bills via Midas’s core product,
          mTBILL.
        </BlogText>
        <BlogText>
          Midas partnered with a high-quality asset manager, while providing a best-in-class
          tokenization infrastructure. As with all of Midas products, this allows users to manage
          their assets on chain effectively, harnessing the flexibility of a permissionless ERC-20
          token.
        </BlogText>

        <ShareOnTwitter />
      </div>
      <BlogTitle>Institutional-Grade All-weather Strategy</BlogTitle>
      <BlogText>
        The structure of the mBASIS token enables the allocation of funds into top 20 altcoins and US
        Treasuries further increasing potential yield and highlighting the strategy’s adaptability
        under all market conditions.
      </BlogText>
      <div className="box-border w-full px-2 md:px-0">
        <img src={BtcUsdImg} alt="banner" className="w-full" />
      </div>
      <div className="box-border w-full px-2 md:px-0">
        <img src={BtcFundingFinImg} alt="banner" className="w-full" />
      </div>
      <div className="box-border w-full px-2 md:px-0">
        <img src={AltcoinFundingFinImg} alt="banner" className="w-full" />
      </div>
      <BlogTitle>Midas’s institutional-grade tokenization offering</BlogTitle>
      <BlogText>
        <ul className="m-0 list-disc pl-5">
          <li>
            <span className="font-bold">Professional Asset Management:</span> Assets invested in
            mBASIS are managed by a licensed asset manager with client fiduciary duties, exceeding
            current industry practices.
          </li>
          <li>
            <span className="font-bold">Active Management:</span> The strategy dynamically adjusts
            between the most attractive basis positions and US T-Bills across market cycles
          </li>
          <li>
            <span className="font-bold">Transparent and Verified Reporting:</span> The investment
            portfolio and its performance are prepared by an independent regulated fund administrator
          </li>
          <li>
            <span className="font-bold">Bankruptcy Protection:</span> Client assets are held in a
            bankruptcy protected SPV. In the unlikely event of a default, a security agent
            distributes assets to creditors.
          </li>
        </ul>
      </BlogText>
      <BlogTitle>Use cases</BlogTitle>
      <BlogText>
        <ul className="m-0 list-disc pl-5">
          <li>
            <span className="font-bold">Treasury management:</span> Invest assets in mBASIS as a
            treasury management solution and accumulate capital.
          </li>
          <li>
            <span className="font-bold">Borrow/lend:</span> mBASIS can be used as yielding collateral
            in DeFi money markets to further enhance returns across the strategy.
          </li>
          <li>
            <span className="font-bold">Transact:</span> As a permissionless ERC-20 token, users are
            free to engage in a wide range of onchain use cases and can transact peer-to-peer.
          </li>
        </ul>
      </BlogText>
      <BlogTitle>Background</BlogTitle>
      <BlogText>
        <BlogSubtitle className="mb-2">The basis trade</BlogSubtitle>
        <BlogText className="mb-4">
          The basis trade involves the arbitrage of the price difference between the spot and
          perpetual futures markets. The strategy is composed of a spot long position (e.g., BTC) and
          the sale of an equivalent amount into the perpetual futures market (short position). This
          locks the difference between the spot and futures prices, or the basis, as profit. This
          approach ensures regular earnings from positive funding rates paid by long traders and
          shields the principal from market price fluctuations by balancing long and short positions.
        </BlogText>
        <BlogSubtitle className="mb-2">Crypto market cycles</BlogSubtitle>
        <BlogText className="mb-4">
          Crypto markets are cyclical, often influenced by significant events like a Bitcoin halving.
          These halving events contract the supply, leading to sharp price increases. Significant
          retail participation further drives up prices, reinforcing this trend.
        </BlogText>
        <div className="box-border w-full px-2 md:px-0">
          <img src={BtcHalvingImg} alt="banner" className="w-full" />
        </div>
        <BlogText className="mt-4">
          The periods following Bitcoin halving events are historically associated with significant
          positive price action, which presents an opportunity for basis trades to harvest funding
          rates.
        </BlogText>
        <div className="overflow-x-auto">
          <table className="mt-8 min-w-full leading-normal">
            <thead>
              <tr>
                <th
                  className="border-gray-200 bg-gray-100 text-gray-600 border-b-2 px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider"
                  style={{ width: '19%' }}
                >
                  Market Cycle
                </th>
                <th
                  className="border-gray-200 bg-gray-100 text-gray-600 border-b-2 px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider"
                  style={{ width: '18%' }}
                >
                  Start Price
                </th>
                <th
                  className="border-gray-200 bg-gray-100 text-gray-600 border-b-2 px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider"
                  style={{ width: '15%' }}
                >
                  End Price
                </th>
                <th
                  className="border-gray-200 bg-gray-100 text-gray-600 border-b-2 px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider"
                  style={{ width: '19%' }}
                >
                  Implied Price Appreciation
                </th>
                <th
                  className="border-gray-200 bg-gray-100 text-gray-600 border-b-2 px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider"
                  style={{ width: '39%' }}
                >
                  Time Period
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border-gray-200 border-b bg-white px-5 py-5 text-sm">2012-2013</td>
                <td className="border-gray-200 border-b bg-white px-5 py-5 text-center text-sm">
                  ~$2.00
                </td>
                <td className="border-gray-200 border-b bg-white px-5 py-5 text-center text-sm">
                  ~$13.00
                </td>
                <td className="border-gray-200 border-b bg-white px-5 py-5 text-center text-sm">
                  ~550%
                </td>
                <td className="border-gray-200 border-b bg-white px-5 py-5 text-sm">
                  Late 2011 to early 2013
                </td>
              </tr>
              <tr>
                <td className="border-gray-200 border-b bg-white px-5 py-5 text-sm">2013-2014</td>
                <td className="border-gray-200 border-b bg-white px-5 py-5 text-center text-sm">
                  $135.30
                </td>
                <td className="border-gray-200 border-b bg-white px-5 py-5 text-center text-sm">
                  $350.93
                </td>
                <td className="border-gray-200 border-b bg-white px-5 py-5 text-center text-sm">
                  159%
                </td>
                <td className="border-gray-200 border-b bg-white px-5 py-5 text-sm">
                  Early 2014 to early 2015
                </td>
              </tr>
              <tr>
                <td className="border-gray-200 border-b bg-white px-5 py-5 text-sm">2016-2017</td>
                <td className="border-gray-200 border-b bg-white px-5 py-5 text-center text-sm">
                  $350.51
                </td>
                <td className="border-gray-200 border-b bg-white px-5 py-5 text-center text-sm">
                  $650.96
                </td>
                <td className="border-gray-200 border-b bg-white px-5 py-5 text-center text-sm">
                  86%
                </td>
                <td className="border-gray-200 border-b bg-white px-5 py-5 text-sm">
                  Late 2014 to mid-2016
                </td>
              </tr>
              <tr>
                <td className="border-gray-200 border-b bg-white px-5 py-5 text-sm">2018-2020</td>
                <td className="border-gray-200 border-b bg-white px-5 py-5 text-center text-sm">
                  $6,955.27
                </td>
                <td className="border-gray-200 border-b bg-white px-5 py-5 text-center text-sm">
                  $29,001.72
                </td>
                <td className="border-gray-200 border-b bg-white px-5 py-5 text-center text-sm">
                  317%
                </td>
                <td className="border-gray-200 border-b bg-white px-5 py-5 text-sm">
                  Early 2018 to mid-2020
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </BlogText>
      <BlogTitle>About Midas</BlogTitle>
      <BlogText>
        Midas is an asset tokenization protocol bringing regulatory-compliant exposure to
        institutional-grade assets onchain, unlocking DeFi composability for real-world assets (RWA).
      </BlogText>
      <BlogText>
        Tokenizing securities in a compliant manner is complex, requiring deep legal expertise and
        iterative development. Each Midas-issued token represents a bold step forward, merging the
        reliability of traditional financial instruments with the efficiency and accessibility of
        DeFi.
      </BlogText>
      <BlogText>
        For additional details on the mBASIS token or to explore investment possibilities with Midas,
        please visit <BlogTextLink href="https://midas.app/">https://midas.app/</BlogTextLink>.
      </BlogText>
      <BlogTitle>Funding Round</BlogTitle>
      <BlogText>
        Midas is backed by the leading investors in the RWA space as BlockTower, alongside Framework
        Ventures and HV Capital co-lead its recent $8.75 funding round.
      </BlogText>
      <BlogText>
        The round also saw participation from Cathay Ledger, 6th Man Ventures, Coinbase Ventures,
        Hack VC, GSR, Lattice Capital, Phaedrus, Theia Blockchain, Pareto, Axelar Foundation, Peer VC
        and FJ Labs.
      </BlogText>
      <BlogTitle>Disclosure</BlogTitle>
      <BlogText>
        The mBASIS token is not available to US persons and entities, or those from sanctioned
        jurisdictions.
      </BlogText>
      <BlogText>
        The minimum investment amount is EUR 100,000. Certain investors, such as Qualified Investors,
        may be able to invest at lower minimum amounts.
      </BlogText>
      <BlogText>
        For more information on Midas and the mBASIS token, navigate to&nbsp;
        <BlogTextLink href="https://midas.app">https://midas.app</BlogTextLink>, or contact us via{' '}
        <a href="mailto:team@midas.app">team@midas.app</a>. For a technical deep dive, visit our
        documentation at&nbsp;
        <BlogTextLink href="https://docs.midas.app">https://docs.midas.app</BlogTextLink>.
      </BlogText>
    </main>
  );
}
