import { useContractWrite, useNetwork } from 'wagmi';

import { getAddressesByChain } from 'constants/addresses';

import { mTBILL } from '../abis/mTBILL';

export const useMintStUsd = () => {
  const { chain } = useNetwork();

  const { writeAsync: mintAsync } = useContractWrite({
    abi: mTBILL.abi,
    address: getAddressesByChain(chain?.network).mTBILL,
    functionName: 'mint',
  });

  return {
    mintAsync,
  };
};
