import type { PriceData } from 'hooks/usePriceData';

const toZeroPrefixed = (val: number) => {
  return val > 9 ? val.toString() : '0' + val;
};

export const dateToShorten = (date: Date, withTime = false) => {
  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1;
  const year = date.getUTCFullYear();

  const shortDate = `${toZeroPrefixed(day)}/${toZeroPrefixed(month)}/${year}`;

  if (!withTime) {
    return shortDate;
  }

  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();

  return (
    shortDate + ` ${toZeroPrefixed(hours)}:${toZeroPrefixed(minutes)}:${toZeroPrefixed(seconds)}`
  );
};

type PriceDataChartResponse = {
  price: string;
  keyDate?: Date;
  date: Date;
}[];

export const formatForChart = (data: PriceData[]): PriceDataChartResponse => {
  const seenDates = new Set<string>();
  return data.map(item => {
    const yearMonth = `${item.date.getFullYear()}-${item.date.getMonth() + 1}`;

    if (seenDates.has(yearMonth)) {
      return { price: item.price, date: item.date };
    } else {
      seenDates.add(yearMonth);
      return { price: item.price, date: item.date, keyDate: item.date };
    }
  });
};
