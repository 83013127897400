import { SuccessfulApproveModal, SuccessfulTxModal } from 'components/SuccessfullTxModal';
import { BigNumber } from 'ethers';
import { ethers } from 'ethers';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { useApproveToken } from 'hooks/useApproveToken';
import type { useExchangeData } from 'hooks/useExchangeData';
import { useRedeem } from 'hooks/useRedeem';
import type { useTokensData } from 'hooks/useTokensData';
import { useMemo, useState } from 'react';
import { newTxToast } from 'toasts/newTxToast';
import { getEtherscanLink } from 'utils/getEtherscanLink';
import { openInNewTab } from 'utils/openInNewTab';
import { useChainId, useConfig, useNetwork } from 'wagmi';

import type { PlatformToken } from 'constants/tokens';
import { getTokenByName } from 'constants/tokens';

import { BigExchangerBody } from './BigExchangerBody';

import { getHumanError, isCustomError } from '../../utils/errors';
import { NotificationModal } from '../NotificationModal';

interface RedeemBigExchangerProps {
  btnMessage?: string;
  tokenData: ReturnType<typeof useTokensData>;
  exchangeData: ReturnType<typeof useExchangeData>;
  platformToken: PlatformToken;
}

export const RedeemBigExchanger = ({
  btnMessage: externalBtnMessage,
  tokenData,
  exchangeData,
  platformToken,
}: RedeemBigExchangerProps) => {
  const { chain } = useNetwork();
  const chainId = useChainId();
  const { publicClient } = useConfig();

  const [selectedToken, setSelectedToken] = useState(getTokenByName(chain?.network, 'USDC'));
  const [isApproveSuccessModalOpen, setIsApproveSuccessModalOpen] = useState(false);
  const [isRedeemSuccessModalOpen, setIsRedeemSuccessModalOpen] = useState(false);

  const [errorText, setErrorText] = useState('');
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [depositInputValue, setDepositInputValue] = useState<string>('');

  const { token, tokenBalances, isSuccess: isTokensDataSuccess, redeemAllowance } = tokenData;
  const { data, isSuccess: isExchangeDataLoaded } = exchangeData;

  const isInputInvalid =
    !depositInputValue.trim() || +depositInputValue === 0 || isNaN(+depositInputValue);

  const balance = +formatUnits(tokenBalances[token.name] ?? ethers.constants.Zero);
  const inputUsdValue = +formatUnits(
    isInputInvalid
      ? BigNumber.from(0)
      : parseUnits(depositInputValue).mul(data.etfPrice).div(parseUnits('1')),
  );

  const isLoading = useMemo(
    () => !isTokensDataSuccess || !isExchangeDataLoaded,
    [isExchangeDataLoaded, isTokensDataSuccess],
  );

  const isInsufficientAllowance = useMemo(() => {
    return isLoading || isInputInvalid
      ? false
      : parseUnits(depositInputValue)?.gt(redeemAllowance ?? 0);
  }, [redeemAllowance, depositInputValue]);

  const isInsufficientBalance = useMemo(() => {
    return isLoading || isInputInvalid
      ? false
      : tokenBalances[token.name].lt(parseUnits(depositInputValue));
  }, [tokenBalances, token, depositInputValue]);

  const { writeAsync: writeApprove, isLoading: isWriteApproveLoading } = useApproveToken(
    token,
    ethers.constants.MaxUint256,
    'redemptionVault',
    platformToken,
  );

  const { writeAsync: writeRedeem, isLoading: isWriteDepositLoading } = useRedeem(
    parseUnits(isInputInvalid ? '0' : depositInputValue),
    selectedToken.address,
  );

  const isWaitingForTx = useMemo(
    () => isWriteDepositLoading || isWriteApproveLoading,
    [isWriteDepositLoading, isWriteApproveLoading],
  );

  const isBtnDisabled = useMemo(
    () => isWaitingForTx || isInsufficientBalance,
    [isWaitingForTx, isInsufficientBalance],
  );

  const btnMessage = useMemo(
    () =>
      externalBtnMessage ||
      (depositInputValue.trim() === '' || +depositInputValue.trim() === 0
        ? 'Enter an amount'
        : isWaitingForTx
        ? 'Processing the transaction'
        : isInsufficientBalance
        ? `Insufficient ${token.name} balance`
        : isInsufficientAllowance
        ? 'Approve'
        : 'Redeem'),
    [externalBtnMessage, isWaitingForTx, isInsufficientBalance, token, isInsufficientAllowance],
  );

  const handleBtnClick = async () => {
    if (isInsufficientBalance) return;
    if (isWaitingForTx || !writeRedeem) return;

    newTxToast({
      type: 'info',
      message: 'Please confirm the transaction in your wallet',
    });

    try {
      if (isInsufficientAllowance) {
        const approveResult = await writeApprove();
        newTxToast({
          type: 'info',
          message: 'Transaction submitted',
          btnMessage: 'View Details',
          onButtonClick: () => openInNewTab(getEtherscanLink(approveResult.hash, 'tx', chainId)),
        });
        await publicClient.waitForTransactionReceipt(approveResult);
        newTxToast({
          type: 'success',
          message: 'Transaction confirmed',
          btnMessage: 'View Details',
          onButtonClick: () => openInNewTab(getEtherscanLink(approveResult.hash, 'tx', chainId)),
        });
      } else {
        const redeemResult = await writeRedeem();
        newTxToast({
          type: 'info',
          message: 'Transaction submitted',
          btnMessage: 'View Details',
          onButtonClick: () => openInNewTab(getEtherscanLink(redeemResult.hash, 'tx', chainId)),
        });
        await publicClient.waitForTransactionReceipt(redeemResult);
        newTxToast({
          type: 'success',
          message: 'Transaction confirmed',
          btnMessage: 'View Details',
          onButtonClick: () => openInNewTab(getEtherscanLink(redeemResult.hash, 'tx', chainId)),
        });
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const hErr = getHumanError(err.shortMessage);

      let errText: string;

      if (isCustomError(hErr)) {
        errText = hErr.text;
      } else {
        errText = hErr;
      }

      setErrorText(`Error while sending the transaction: ${errText}`);
      setIsModalOpened(true);
    }
  };

  const closeModal = () => {
    setIsModalOpened(false);
    setErrorText('');
  };

  return (
    <>
      <NotificationModal
        onBtnClick={closeModal}
        isModalOpened={isModalOpened}
        closeModal={closeModal}
        content={{
          bodyContent: errorText,
          headerContent: <>Sorry.</>,
          btnContent: 'Ok',
        }}
      />
      <SuccessfulTxModal
        isModalOpened={isRedeemSuccessModalOpen}
        setIsModalOpened={setIsRedeemSuccessModalOpen}
      />
      <SuccessfulApproveModal
        isModalOpened={isApproveSuccessModalOpen}
        setIsModalOpened={setIsApproveSuccessModalOpen}
      />
      <BigExchangerBody
        platformToken={platformToken}
        onSelectedTokenChange={setSelectedToken}
        firstInput={{
          tokens: [token],
          min: ethers.constants.Zero,
          initValue: ethers.constants.Zero,
          balance,
          maxAvailable: true,
          usdValue: inputUsdValue,
          onValueChange: setDepositInputValue,
          value: depositInputValue,
        }}
        handleBtnClick={handleBtnClick}
        isBtnDisabled={isBtnDisabled}
        btnMessage={btnMessage}
        isRedeem={true}
      />
    </>
  );
};
