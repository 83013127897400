import { faqConfig } from 'constants/faq';

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from './Accordion';

export const FaqAccordion = () => {
  return (
    <Accordion type="single" collapsible className="w-full">
      {faqConfig.map(({ title, description, value }) => (
        <AccordionItem key={value} className="border-b-[#b1b1b1]" value={value}>
          <AccordionTrigger className="py-6 text-start text-sm font-bold text-[#14223D] hover:no-underline lg:text-xl">
            {title}
          </AccordionTrigger>
          <AccordionContent className="text-sm text-[#14223D] lg:text-base">
            {description}
          </AccordionContent>
        </AccordionItem>
      ))}
    </Accordion>
  );
};
