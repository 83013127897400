import { Link } from 'react-router-dom';

export const GeoBlock = () => {
  return (
    <div
      className="mx-auto mb-[58px] mt-[24px] flex w-full max-w-[90%] justify-center rounded-[16px] pb-[96px] pt-[95px] shadow-[0_4px_4px_rgba(0,0,0,0.25)] lg:mt-[56px] lg:max-w-[70%]"
      style={{ backgroundImage: 'linear-gradient(90deg, #D7FAFF, #F7FFEC)' }}
    >
      <main className="flex flex-col items-center px-[10px] lg:px-0">
        {/* <div className="flex gap-[10px]">
          <img
            className="h-[42px]"
            src={token === PlatformToken.MTBILL ? mtbillLogo : mbasisLogo}
            alt="token"
          />
          <div className="text-[32px] leading-[40px] text-[#14223D]">{token}</div>
        </div>
        <div className="mt-[20px] text-center text-[14px] leading-[20px] text-[#14223D] opacity-[0.8]">
          Midas Short-Term US Government Treasuries ETF Note
        </div> */}
        <div className="mt-[16px] flex flex-col-reverse items-center gap-[8px] lg:flex-row">
          <div
            className="text-center text-[42px] font-medium leading-[72px] text-[#14223D] lg:text-[72px]"
            style={{ textShadow: '0 4px 4px rgba(0,0,0,0.25)' }}
          >
            Access Restricted
          </div>
          <svg
            width="82"
            height="88"
            viewBox="0 0 82 88"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_d_4001_183)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M35.4998 79C51.8383 79 65.0832 65.5685 65.0832 49C65.0832 32.4315 51.8383 19 35.4998 19C19.1614 19 5.9165 32.4315 5.9165 49C5.9165 65.5685 19.1614 79 35.4998 79Z"
                stroke="#14223D"
                strokeWidth="2"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M35.4998 76C35.4998 76 47.3332 66.1818 47.3332 49C47.3332 31.8182 35.4998 22 35.4998 22C35.4998 22 23.6665 31.8182 23.6665 49C23.6665 66.1818 35.4998 76 35.4998 76Z"
                stroke="#14223D"
                strokeWidth="2"
              />
              <path d="M7.39567 40H63.604" stroke="#14223D" strokeWidth="2" strokeLinecap="round" />
              <path d="M7.39567 58H63.604" stroke="#14223D" strokeWidth="2" strokeLinecap="round" />
            </g>
            <g filter="url(#filter1_d_4001_183)">
              <circle cx="52.5" cy="25.5" r="17.5" fill="#FFAEAE" />
              <path
                d="M57.6558 30.6559L47.3438 20.3439"
                stroke="#14223D"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M47.3442 30.6559L57.6562 20.3439"
                stroke="#14223D"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_4001_183"
                x="-4"
                y="13"
                width="79"
                height="80"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_4001_183"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_4001_183"
                  result="shape"
                />
              </filter>
              <filter
                id="filter1_d_4001_183"
                x="23.7515"
                y="0.751221"
                width="57.4971"
                height="57.4974"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_4001_183"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_4001_183"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </div>
        <div className="mt-[16px] max-w-[827px] text-center text-[18px] leading-[28px] text-[#14223D]">
          The content you are trying to access is not accessible in your region. mTBILL and mBASIS
          are only offered to organisations and individual purchasers outside the US and other
          restricted jurisdictions.
        </div>
        <div className="mt-[34px]">
          <Link
            to="/"
            className="flex w-fit items-center justify-center rounded-[12px] border-[1px] border-solid border-[#0000001F] bg-[#FFAD53] px-[24px] py-[6px] text-[16px] font-[600] leading-[32px] text-[#06070A] no-underline shadow-[0_4px_4px_rgba(0,0,0,0.25)]"
          >
            Back to Home
          </Link>
        </div>
      </main>
    </div>
  );
};
