import Aside from 'layouts/adminTab';
import Header from 'layouts/headerAdminTab';
import React from 'react';
import { useParams } from 'react-router-dom';

import { useContactUsRequest } from '../../hooks/useContactUsRequest';
import { downloadCsv } from '../../utils/csv';

export const ContactRequest = () => {
  const { id } = useParams();
  const { contactRequest, markAsAnswered, isMarkingAsAnswered } = useContactUsRequest(
    !id || isNaN(parseInt(id)) ? undefined : parseInt(id),
  );
  const [isDownloading, setIsDownloading] = React.useState(false);

  const dataToShow: [string, string][] = contactRequest
    ? [
        ['Id', contactRequest.id.toString()],
        ['Email', contactRequest.email.email],
        [
          'Name',
          contactRequest.firstName
            ? `${contactRequest.firstName} ${contactRequest.secondName}`
            : `${contactRequest.name}`,
        ],
        ['Message', contactRequest.message ?? ''],
        ['Status', contactRequest.isFulfilled ? 'Answered' : 'Pending'],
      ]
    : [];

  return (
    <div className="admin">
      <Aside />
      <section>
        <Header />
        <div className="lists">
          <h5 className="!text-black">Contact Request {contactRequest?.id ?? ''}.</h5>
        </div>
        <div className="relative">
          <table className="w-full table-auto border-collapse">
            <thead
              style={{
                borderTop: '1px solid #A5A5A5',
                borderBottom: '1px solid #A5A5A5',
              }}
            >
              <tr className="!text-[14px] text-black">
                <td className="p-[16px] font-semibold">Property</td>
                <td className="p-[16px] font-semibold">Value</td>
              </tr>
            </thead>
            <tbody>
              {dataToShow.map(([key, value]) => (
                <tr key={key} className="odd:bg-[#F0F0F0]">
                  <td className="p-[16px]">{key}</td>
                  <td className="p-[16px]">{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex items-center gap-[8px] !py-0">
          <button
            onClick={async () => {
              if (contactRequest && !isDownloading) {
                setIsDownloading(true);
                try {
                  await downloadCsv(`${contactRequest.id}.csv`, dataToShow);
                } catch (error) {
                  console.error("Couldn't download CSV", error);
                } finally {
                  setIsDownloading(false);
                }
              }
            }}
            className="cursor-pointer rounded-[40px] border-[1px] border-black px-[16px] py-[8px] text-[16px] font-medium text-white transition-all hover:bg-white hover:text-black"
          >
            {isDownloading ? 'Processing...' : 'Export to CSV'}
          </button>
          {!contactRequest?.isFulfilled && (
            <button
              onClick={async () => {
                if (contactRequest && !isMarkingAsAnswered) {
                  try {
                    await markAsAnswered();
                  } catch (e) {
                    console.error("Couldn't mark as answered", e);
                  }
                }
              }}
              className="cursor-pointer rounded-[40px] border-[1px] border-black bg-white px-[16px] py-[8px] text-[16px] font-medium text-black transition-all hover:bg-black hover:text-white"
            >
              Mark as Answered
            </button>
          )}
        </div>
      </section>
    </div>
  );
};
