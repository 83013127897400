import { useExchangeData } from 'hooks/useExchangeData';
import { useTokensData } from 'hooks/useTokensData';
import { useState } from 'react';
import { cn } from 'utils/cn';

import type { PlatformToken } from 'constants/tokens';

import { InvestBigExchanger } from './InvestBigExchanger';
import { RedeemBigExchanger } from './RedeemBigExchanger';

export interface BigExchangerProps {
  platformToken: PlatformToken;
}

export const BigExchanger = ({ platformToken }: BigExchangerProps) => {
  const [nav, setNav] = useState<'Invest' | 'Redeem'>('Invest');
  const tokenData = useTokensData(platformToken);
  const exchangeData = useExchangeData(platformToken);

  return (
    <div className="transaction__worker mx-auto w-full lg:max-w-[722px]">
      <div className="convert-currency-big detail w-full">
        <div className="flex justify-between divide-x divide-[rgb(240,240,247)] border-b border-solid border-[rgb(240,240,247)]">
          <div
            onClick={() => setNav('Invest')}
            className={cn(
              nav === 'Invest' ? 'text-[rgb(5,28,44)]' : 'bg-[#efeff7] text-[rgb(154,158,167)]',
              'flex flex-grow cursor-pointer justify-center rounded-tl-[24px] py-5 font-medium',
            )}
          >
            Invest
          </div>
          <div
            onClick={() => setNav('Redeem')}
            className={cn(
              nav === 'Redeem' ? 'text-[rgb(5,28,44)]' : 'bg-[#efeff7] text-[rgb(154,158,167)]',
              'flex flex-grow cursor-pointer justify-center rounded-tr-[24px] py-5 font-medium',
            )}
          >
            Redeem
          </div>
        </div>

        <div className="p-6">
          {nav === 'Invest' ? (
            <InvestBigExchanger
              tokenData={tokenData}
              exchangeData={exchangeData}
              platformToken={platformToken}
            />
          ) : (
            <RedeemBigExchanger
              tokenData={tokenData}
              exchangeData={exchangeData}
              platformToken={platformToken}
            />
          )}
        </div>
      </div>
    </div>
  );
};
