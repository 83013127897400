import type { BaseEntity } from './client';
import { api } from './client';

export const POST_CONNECTED_WALLET = '/connected-wallet';
export const GET_CONNECTED_WALLET_ADMIN = '/connected-wallet/admin';

interface ConnectedWalletRequest {
  address: string;
}

export const postConnectedWallet = async (data: ConnectedWalletRequest) => {
  const response = await api.post(POST_CONNECTED_WALLET, data);
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Unable to post connected wallet');
};

interface ConnectedWalletsAdminResponse {
  wallets: ({
    address: string;
  } & BaseEntity)[];
}

export const getConnectedWalletsAdmin = async (
  jwt: string | null | undefined,
  isAdmin: boolean | undefined,
) => {
  if (!jwt || !isAdmin) {
    return null;
  }
  const response = await api.get<ConnectedWalletsAdminResponse>(GET_CONNECTED_WALLET_ADMIN, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Unable to get connected wallet');
};
