import { DepositVault } from 'abis/DepositVault';
import { zeroAddress } from 'viem';
import { useAccount, useContractRead, useNetwork } from 'wagmi';

import { getAddressesByChain } from 'constants/addresses';
import { PlatformToken } from 'constants/tokens';

export const useTotalDeposited = (platformToken: PlatformToken) => {
  const { chain } = useNetwork();
  const { address } = useAccount();
  const { mtbillDepositVault, mbasisDepositVault } = getAddressesByChain(
    chain?.network ?? 'homestead',
  );

  const activeVault =
    platformToken === PlatformToken.MTBILL ? mtbillDepositVault : mbasisDepositVault;

  return useContractRead({
    abi: DepositVault.abi,
    address: activeVault,
    functionName: 'totalDeposited',
    args: [address ?? zeroAddress],
    enabled: !!address && !!activeVault,
  });
};
