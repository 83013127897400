import axios from 'axios';

const defaultUrl = 'https://midas-api.azurewebsites.net/api/';

export const BASE_API_URL = (process.env.REACT_APP_API_URL ?? defaultUrl) as string;

export interface BaseEntity {
  createdAt: string;
  updatedAt: string;
}

export const api = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});
