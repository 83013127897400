import { AxiosError } from 'axios';
import Aside from 'layouts/adminTab';
import Header from 'layouts/headerAdminTab';
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { BASE_API_URL } from '../../api/client';
import { GET_KYC_ADMIN_FILE } from '../../api/kyc';
import { useAuth } from '../../hooks/useAuth';
import { useKycByAddress } from '../../hooks/useKycByAddress';
import { downloadCsv } from '../../utils/csv';
import { dateToShorten } from '../../utils/date';
import { shortenAddress } from '../../utils/shortenAddress';

export const UserKyc = () => {
  const { userAddress } = useParams();
  const { jwt } = useAuth();
  const {
    kycData,
    acceptKyc,
    rejectKyc,
    requestChangesKyc,
    isAcceptingKyc,
    isRejectingKyc,
    isRequestingChangesKyc,
    refetchKycData,
  } = useKycByAddress(userAddress);
  const [revisionComment, setRevisionComment] = React.useState('');
  const [isDownloading, setIsDownloading] = React.useState(false);

  const isLoading = isAcceptingKyc || isRejectingKyc || isRequestingChangesKyc;

  const data = kycData?.data;
  const investorType = data?.investorType;
  const individual = data?.individualKycData;
  const entity = data?.entityKycData;
  const isSealed = data?.isSealed;
  const revisionCommentFromData = data?.revisionComment;

  useEffect(() => {
    if (revisionCommentFromData) {
      setRevisionComment(revisionCommentFromData);
    }
  }, [revisionCommentFromData]);

  const individualData: [string, string][] =
    (investorType === 0 || investorType === 2) && individual
      ? [
          ['First Name', individual.firstName ?? ''],
          ['Last Name', individual.lastName ?? ''],
          ['Place of Birth', individual.placeOfBirth ?? ''],
          [
            'Date of Birth',
            individual.dateOfBirth ? dateToShorten(new Date(individual.dateOfBirth)) : '',
          ],
          ['Nationality', individual.nationality ?? ''],
          ['Residential Address', individual.residentialAddress ?? ''],
          ['Country of Residence', individual.countryOfResidence ?? ''],
          ['TIN', individual.tin ?? ''],
          ['Profession', individual.profession ?? ''],
          [
            'Document',
            individual?.documentFile?.id
              ? `${BASE_API_URL}${GET_KYC_ADMIN_FILE}/${
                  individual?.documentFile?.id
                }?token=${encodeURIComponent(jwt ?? '')}`
              : '',
          ],
        ]
      : [];

  const entityData: [string, string][] =
    investorType === 1 && entity
      ? [
          ['Legal Company Name', entity.legalCompanyName ?? ''],
          ['Register Number', entity.registerNumber ?? ''],
          ['Domicile Country', entity.domicileCountry ?? ''],
          [
            'Date of Establishment',
            entity.dateOfEstablishment ? dateToShorten(new Date(entity.dateOfEstablishment)) : '',
          ],
          [
            'Date of Registration',
            entity.dateOfRegistration ? dateToShorten(new Date(entity.dateOfRegistration)) : '',
          ],
          ['Persons Authorized to Sign', entity.personsAuthorizedToSign ?? ''],
          [
            'Ultimate Beneficial Owner Form',
            entity?.ultimateBeneficialOwnerForm?.id
              ? `${BASE_API_URL}${GET_KYC_ADMIN_FILE}/${
                  entity?.ultimateBeneficialOwnerForm?.id
                }?token=${encodeURIComponent(jwt ?? '')}`
              : '',
          ],
          [
            'Passport of UBO',
            entity?.passportOfUBO?.id
              ? `${BASE_API_URL}${GET_KYC_ADMIN_FILE}/${
                  entity?.passportOfUBO?.id
                }?token=${encodeURIComponent(jwt ?? '')}`
              : '',
          ],
          [
            'Commercial Register',
            entity?.commercialRegister?.id
              ? `${BASE_API_URL}${GET_KYC_ADMIN_FILE}/${
                  entity?.commercialRegister?.id
                }?token=${encodeURIComponent(jwt ?? '')}`
              : '',
          ],
        ]
      : [];

  const dataToShow: [string, string][] = [
    ['Created', data?.createdAt ? dateToShorten(new Date(data.createdAt), true) : ''],
    ['Updated', data?.updatedAt ? dateToShorten(new Date(data.updatedAt), true) : ''],
    ['Address', data?.ethAddress ?? ''],
    ['Email', data?.user?.email ?? ''],
    [
      'Investor Type',
      data?.investorType === 0 ? 'Individual' : data?.investorType === 1 ? 'Entity' : 'Other',
    ],
    ['Phone Number', data?.phoneNumber ?? ''],
    ['Is Sealed', data?.isSealed ? 'Yes' : 'No'],
    ['Is Accepted', data?.isAccepted ? 'Yes' : 'No'],
    ['Is Rejected', data?.isRejected ? 'Yes' : 'No'],
    ['Is Changes Requested', data?.isChangesRequested ? 'Yes' : 'No'],
    ['Revision Comment', data?.revisionComment ?? ''],
    ...individualData,
    ...entityData,
  ];

  const accept = async () => {
    if (isLoading) return;
    try {
      await acceptKyc();
      await refetchKycData();
    } catch (error) {
      if (error instanceof AxiosError) {
        alert(error.response?.data?.message ?? 'Something went wrong');
      }
    }
  };

  const reject = async () => {
    if (isLoading) return;
    try {
      await rejectKyc(revisionComment);
      await refetchKycData();
    } catch (error) {
      if (error instanceof AxiosError) {
        alert(error.response?.data?.message ?? 'Something went wrong');
      }
    }
  };

  const requestChanges = async () => {
    if (isLoading) return;
    try {
      await requestChangesKyc(revisionComment);
      await refetchKycData();
    } catch (error) {
      if (error instanceof AxiosError) {
        alert(error.response?.data?.message ?? 'Something went wrong');
      }
    }
  };

  return (
    <div className="admin">
      <Aside />
      <section>
        <Header />
        <div className="lists">
          <h5 className="!text-black">{shortenAddress(kycData?.data.ethAddress ?? '')} KYC.</h5>
        </div>
        <div className="relative">
          <table className="w-full table-auto border-collapse">
            <thead
              style={{
                borderTop: '1px solid rgba(17, 39, 227, 0.20)',
                borderBottom: '1px solid rgba(17, 39, 227, 0.20)',
              }}
            >
              <tr className="!text-[14px] text-black">
                <td className="p-[16px] font-semibold">Property</td>
                <td className="p-[16px] font-semibold">Value</td>
              </tr>
            </thead>
            <tbody>
              {dataToShow.map(([key, value]) => (
                <tr key={key} className="odd:bg-[#F0F0F0]">
                  <td className="p-[16px]">{key}</td>
                  <td className="p-[16px]">
                    {value.startsWith(`${BASE_API_URL}${GET_KYC_ADMIN_FILE}`) ? (
                      <Link
                        to={value}
                        className="text-[16px] text-black no-underline hover:underline"
                        target="_blank"
                      >
                        Link
                      </Link>
                    ) : (
                      value
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="!py-0">
          <button
            onClick={async () => {
              if (data && !isDownloading) {
                setIsDownloading(true);
                try {
                  await downloadCsv(`${data.id}.csv`, dataToShow);
                } catch (error) {
                  console.error("Couldn't download CSV", error);
                } finally {
                  setIsDownloading(false);
                }
              }
            }}
            className="cursor-pointer rounded-[40px] border-[1px] border-black bg-black px-[16px] py-[8px] text-[16px] font-medium text-white transition-all hover:bg-white hover:text-black"
          >
            {isDownloading ? 'Processing...' : 'Export to CSV'}
          </button>
        </div>
        {isSealed && (
          <>
            <div className="relative flex items-center gap-[8px]">
              <button
                onClick={accept}
                className="cursor-pointer rounded-[40px] border-[1px] border-black bg-black px-[16px] py-[8px] text-[16px] font-medium text-white transition-all hover:bg-white hover:text-black"
              >
                {isAcceptingKyc ? 'Processing...' : 'Accept'}
              </button>
            </div>
            <div className="!py-0">
              <textarea
                className="min-w-full max-w-full rounded-[10px]"
                rows={10}
                value={revisionComment}
                onChange={e => setRevisionComment(e.target.value)}
              />
            </div>
            <div className="flex items-center gap-[8px]">
              <button
                onClick={reject}
                className="cursor-pointer rounded-[40px] border-[1px] border-black bg-black px-[16px] py-[8px] text-[16px] font-medium text-white transition-all hover:bg-white hover:text-black"
              >
                {isRejectingKyc ? 'Processing...' : 'Reject'}
              </button>
              <button
                onClick={requestChanges}
                className="cursor-pointer rounded-[40px] border-[1px] border-black bg-white px-[16px] py-[8px] text-[16px] font-medium text-[#1127E3] transition-all hover:bg-black hover:text-white"
              >
                {isRequestingChangesKyc ? 'Processing...' : 'Request Changes'}
              </button>
            </div>
          </>
        )}
      </section>
    </div>
  );
};
