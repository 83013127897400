import { useEffect, useMemo, useState } from 'react';

import { ONE_DAY_IN_SECONDS, now, yearAgo } from 'constants/time';
import type { PlatformToken } from 'constants/tokens';

import { usePriceData } from './usePriceData';

type PriceUpdates = {
  lastPrice: string | null;
  dayUpdate: string | null;
  yearUpdate: string | null;
  apy: string | null;
};

export const usePriceUpdates = (token: PlatformToken): PriceUpdates => {
  const { data, isLoading } = usePriceData(token, yearAgo, now);

  const [lastPrice, setLastPrice] = useState<string | null>(null);
  const [dayUpdate, setDayUpdate] = useState<string | null>(null);
  const [yearUpdate, setYearUpdate] = useState<string | null>(null);
  const [apy, setApy] = useState<string | null>(null);

  useEffect(() => {
    if (!data) return;

    const lastPriceEntry = data[data.length - 1];

    const lastPriceValue = parseFloat(lastPriceEntry?.price || '0');
    setLastPrice(lastPriceValue.toFixed(2));

    const calculateUpdates = () => {
      const oneDayAgoTimestamp =
        Math.floor(lastPriceEntry.date.getTime() / 1000) - ONE_DAY_IN_SECONDS;

      const currentYear = new Date().getFullYear();
      const janFirstThisYear = new Date(currentYear, 0, 1, 0, 0, 0, 0).getTime();

      const findLastPriceBefore = (targetTimestamp: number): number => {
        let low = 0;
        let high = data.length - 1;
        let lastValidIndex = -1;

        while (low <= high) {
          const mid = Math.floor((low + high) / 2);
          if (Math.floor(data[mid].date.getTime() / 1000) <= targetTimestamp) {
            lastValidIndex = mid;
            low = mid + 1;
          } else {
            high = mid - 1;
          }
        }

        return lastValidIndex !== -1
          ? parseFloat(data[lastValidIndex].price)
          : parseFloat(data[0].price);
      };

      const lastPriceDayAgo = findLastPriceBefore(oneDayAgoTimestamp);
      const lastPriceYearStart = findLastPriceBefore(janFirstThisYear);

      setDayUpdate((lastPriceValue - lastPriceDayAgo).toFixed(2));
      setYearUpdate((lastPriceValue - lastPriceYearStart).toFixed(2));
      setApy(((lastPriceValue / (lastPriceYearStart || 1) - 1) * 100).toFixed(2));
    };

    calculateUpdates();
  }, [token, data]);

  return useMemo(
    () => ({
      isLoading,
      lastPrice,
      dayUpdate,
      yearUpdate,
      apy: lastPrice !== '0' ? apy : '0',
    }),
    [lastPrice, dayUpdate, yearUpdate, apy, isLoading],
  );
};
