import { getAddress, isAddress } from 'ethers/lib/utils.js';
import { GET_USERS_WITH_ROLE } from 'graphql/queries.js/roles';
import { useBlackListUser } from 'hooks/useBlackListUser';
import { useDefaultSubgraphQuery } from 'hooks/useDefaultSubgraphQuery';
import Aside from 'layouts/adminTab';
import Header from 'layouts/headerAdminTab';
import { Fragment, useState } from 'react';
import { successToast, txErrorToast } from 'toasts';
import { useConfig } from 'wagmi';

import { acRoles } from 'constants/roles';

import { NotificationModal } from '../../components/NotificationModal';
import { getHumanError, isCustomError } from '../../utils/errors';

export function BlackList() {
  const { publicClient } = useConfig();
  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [isModalOpened, setIsModalOpened] = useState(false);
  const closeModal = () => setIsModalOpened(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { data: blackListedUsers, refetch } = useDefaultSubgraphQuery<any>(GET_USERS_WITH_ROLE, {
    variables: {
      role: acRoles.blackListed,
    },
  });

  const { blackListAsync, revokeBlackListAsync, role } = useBlackListUser();

  const onAddToBlackList = async () => {
    setIsLoading(true);
    const address = prompt('Enter a valid user address');

    if (!address || !isAddress(address)) {
      txErrorToast('Invalid address provided');
      setIsLoading(false);
      return;
    }
    try {
      await publicClient.waitForTransactionReceipt(
        await blackListAsync({ args: [role as `0x${string}`, address as `0x${string}`] }),
      );
      successToast('User added to black list');
      refetch();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      const error = getHumanError(e.shortMessage);
      setErrorText(
        `Error while sending transaction: ${isCustomError(error) ? e.shortMessage : error}`,
      );
      setIsModalOpened(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onRemoveFromBlackList = async (address: string) => {
    setIsLoading(true);
    if (!isAddress(address)) {
      txErrorToast('Invalid address provided');
      setIsLoading(false);
      return;
    }

    try {
      await publicClient.waitForTransactionReceipt(
        await revokeBlackListAsync({ args: [role as `0x${string}`, address as `0x${string}`] }),
      );
      successToast('User removed from black list');
      refetch();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      const error = getHumanError(e.shortMessage);
      setErrorText(
        `Error while sending transaction: ${isCustomError(error) ? e.shortMessage : error}`,
      );
      setIsModalOpened(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="admin">
      <NotificationModal
        isModalOpened={isModalOpened}
        closeModal={closeModal}
        onBtnClick={closeModal}
        content={{
          bodyContent: errorText,
          headerContent: <>Sorry.</>,
          btnContent: 'Close',
        }}
      />
      <Aside />
      <section>
        <Header></Header>
        <div className="lists">
          <h5 className="!text-black">Black-List.</h5>
          <button
            className="add-wallet-to-list remove-btn"
            onClick={onAddToBlackList}
            disabled={isLoading}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M14.5938 6.59375H9.40625V1.40625C9.40625 0.629594 8.77666 0 8 0C7.22334 0 6.59375 0.629594 6.59375 1.40625V6.59375H1.40625C0.629594 6.59375 0 7.22334 0 8C0 8.77666 0.629594 9.40625 1.40625 9.40625H6.59375V14.5938C6.59375 15.3704 7.22334 16 8 16C8.77666 16 9.40625 15.3704 9.40625 14.5938V9.40625H14.5938C15.3704 9.40625 16 8.77666 16 8C16 7.22334 15.3704 6.59375 14.5938 6.59375Z"
                fill="white"
              />
            </svg>
            {isLoading ? 'Processing...' : 'Add to Black-list'}
          </button>
          <div className="table-list two-items">
            <div className="table-info">
              <div className="head-table">
                <div className="name-cell">
                  <p>Wallet ID</p>
                </div>
                <div className="name-cell">
                  <p></p>
                </div>
              </div>

              {blackListedUsers?.userRoles?.map(({ user }: { user: string }) => (
                <Fragment key={user}>
                  <div className="table-row">
                    <div className="table-cell">
                      <p>{getAddress(user)}</p>
                    </div>
                    <div
                      className="table-cell"
                      onClick={() => onRemoveFromBlackList(getAddress(user))}
                    >
                      <p className="link">Remove</p>
                    </div>
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default BlackList;
