import type { FeatureCard } from 'components/PlatformToken/FeatureCard';

import defiSvg from '../../assets/imgs/feature-highlights/defi.svg';
import gradeSvg from '../../assets/imgs/feature-highlights/grade.svg';
import regulatorySvg from '../../assets/imgs/feature-highlights/regulatory.svg';

export const mtbillCardsData: FeatureCard[] = [
  {
    bgColor: 'bg-[#FDF7FE]',
    imageSrc: gradeSvg,
    title: 'Institutional-Grade',
    description: (
      <>
        <span className="font-bold">mTBILL</span> offers exposure to high-quality, short-dated US
        Treasury Bills managed by BlackRock, while also retaining the transparency provided by an
        immutable blockchain ledger.
      </>
    ),
    linkText: 'Learn more',
    linkHref:
      'https://docs.midas.app/token-mechanics/mtbill/introduction-to-mtbill#institutional-grade',
    isExternalLink: true,
  },
  {
    bgColor: 'bg-[#def3ff]',
    imageSrc: regulatorySvg,
    title: 'Regulatory Compliant',
    description: (
      <>
        <span className="font-bold">mTBILL</span> is fully compliant with European Securities Laws.
      </>
    ),
    linkText: 'Learn more',
    linkHref:
      'https://docs.midas.app/token-mechanics/mtbill/introduction-to-mtbill#regulatory-compliant',
    isExternalLink: true,
  },
  {
    bgColor: 'bg-[#eefbf9]',
    imageSrc: defiSvg,
    title: 'DeFi Composability',
    description: (
      <>
        <span className="font-bold">mTBILL</span> is a non-custodial, permissionless token with daily
        auto-compounding interest. Use mTBILL as collateral in DeFi protocols like{' '}
        <a
          href="https://app.morpho.org/market?id=0xbe4c211adca4400078db69af91ea0df98401adb5959510ae99edd06fee5146f7&morphoPrice=0.6"
          target="_blank"
          className="font-bold underline"
        >
          Morpho Blue
        </a>{' '}
        to continue accruing yield while utilizing those platforms.
      </>
    ),
    linkText: 'Learn more',
    linkHref:
      'https://docs.midas.app/token-mechanics/mtbill/introduction-to-mtbill#defi-composability',
    isExternalLink: true,
  },
];
