import { useMutation, useQuery } from '@tanstack/react-query';

import { useAuth } from './useAuth';

import { getConnectedWalletsAdmin, postConnectedWallet } from '../api/wallet';

export const useApiWallet = () => {
  const { jwt, isAdmin } = useAuth();

  const { data: connectedWallets } = useQuery({
    queryKey: ['connected-wallet-admin', jwt, isAdmin],
    queryFn: () => getConnectedWalletsAdmin(jwt, isAdmin),
  });

  const { mutateAsync: saveWallet } = useMutation({
    mutationFn: postConnectedWallet,
  });

  return {
    saveWallet,
    connectedWallets,
  };
};
