import { BigNumber, constants, ethers } from 'ethers';
import { parseUnits } from 'ethers/lib/utils';
import { useMemo } from 'react';
import { formatBN } from 'utils/formatBN';
import { useAccount, useContractReads, useNetwork } from 'wagmi';

import { getAddressesByChain } from 'constants/addresses';
import { PlatformToken } from 'constants/tokens';

import { DataFeed } from '../abis/DataFeed';
import { DepositVault } from '../abis/DepositVault';

const zero = ethers.constants.Zero;

const toUpperTens = (num: string | number) => {
  num = parseInt(num.toString());

  if (num === 0) return 0;
  const c = Math.ceil(num / 10_000);
  return c * 10_000;
};

export const useExchangeData = (platformToken: PlatformToken) => {
  const { address } = useAccount();
  const { chain } = useNetwork();

  const addresses = useMemo(() => getAddressesByChain(chain?.network), [chain]);

  const depositVault = useMemo(() => {
    switch (platformToken) {
      case PlatformToken.MTBILL:
        return addresses.mtbillDepositVault;
      case PlatformToken.MBASIS:
        return addresses.mbasisDepositVault;
      default:
        throw new Error('Invalid platformToken value');
    }
  }, [addresses, platformToken]);

  const dataFeed = addresses.dataFeed;

  const { data, refetch, isLoading } = useContractReads({
    contracts: [
      {
        abi: DepositVault.abi,
        address: depositVault,
        functionName: 'minAmountToDepositInEuro',
      },
      {
        abi: DataFeed.abi,
        address: dataFeed,
        functionName: 'getDataInBase18',
      },
      {
        abi: DepositVault.abi,
        address: depositVault,
        args: address ? [address] : undefined,
        functionName: 'totalDeposited',
      },
    ],
  });

  const minDepositAmountRaw = data?.[0]?.result ? ethers.BigNumber.from(data[0].result) : zero;

  const etfPrice = ethers.BigNumber.from(data?.[1].result ?? '0');
  const minDepositAmount = parseUnits(
    (toUpperTens(formatBN(minDepositAmountRaw)) * 1.2).toFixed(18),
  );
  const oneUsdInStUSD = parseUnits('1')
    .mul(parseUnits('1'))
    .div(etfPrice.isZero() ? 1 : etfPrice);
  const oneStUSDInUsd = etfPrice;

  return {
    data: {
      etfPrice,
      deposit: {
        fee: constants.Zero,
        minDepositAmount,
        oneUsdInStUSD,
      },
      redeem: {
        fee: constants.Zero,
        minRedeemAmount: BigNumber.from(0),
        oneStUSDInUsd,
      },
    },
    isLoading: isLoading,
    isSuccess: !!data,
    refetch,
  };
};
