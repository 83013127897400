import Aside from 'layouts/adminTab';
import Header from 'layouts/headerAdminTab';
import React from 'react';
import { Link } from 'react-router-dom';

import { useAdminInfo } from '../../hooks/useAdminInfo';
import { dateToShorten } from '../../utils/date';

const getStatus = (isFulfilled: boolean) => {
  if (isFulfilled) {
    return (
      <p
        className={`m-0 flex items-center gap-[4px] before:h-[8px] before:w-[8px] before:rounded-full before:bg-[#C7F2AB] before:content-[""]`}
      >
        Answered
      </p>
    );
  }

  return (
    <p
      className={`m-0 flex items-center gap-[4px] before:h-[8px] before:w-[8px] before:rounded-full before:bg-red-600 before:content-[""]`}
    >
      Pending
    </p>
  );
};

export const ContactRequests = () => {
  const { contactRequests } = useAdminInfo();

  return (
    <div className="admin">
      <Aside />
      <section>
        <Header />
        <div className="lists">
          <h5 className="!text-black">Contact Requests.</h5>
        </div>
        <div className="relative">
          <table className="w-full table-auto border-collapse">
            <thead
              style={{
                borderTop: '1px solid #A5A5A5',
                borderBottom: '1px solid #A5A5A5',
              }}
            >
              <tr className="!text-[14px] text-black">
                <td className="p-[16px] font-semibold">Date, UTC</td>
                <td className="p-[16px] font-semibold">Email</td>
                <td className="p-[16px] font-semibold">Name</td>
                <td className="p-[16px] font-semibold">Message</td>
                <td className="p-[16px] font-semibold">Status</td>
              </tr>
            </thead>
            <tbody>
              {contactRequests?.map(request => (
                <tr key={request.id} className="odd:bg-[#F0F0F0]">
                  <td className="p-[16px]">{dateToShorten(new Date(request.createdAt), true)}</td>
                  <td className="p-[16px]">{request.email.email}</td>
                  <td className="p-[16px]">{request.firstName}</td>
                  <td className="p-[16px]">
                    <Link
                      to={`/admin/contact-requests/${request.id}`}
                      className="text-[16px] text-black"
                    >
                      Link
                    </Link>
                  </td>
                  <td className="p-[16px]">{getStatus(request.isFulfilled)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};
