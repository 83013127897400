import { useQuery } from '@tanstack/react-query';

import { getMtbillEtf } from '../../api/data';

export const useGetMtbillEtf = () => {
  return useQuery({
    queryKey: ['mtbill-etf'],
    queryFn: getMtbillEtf,
  });
};
