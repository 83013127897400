export const setMetaMaskInstalled = isInstalled => {
  return {
    type: 'SET_METAMASK_INSTALLED',
    payload: isInstalled,
  };
};

export const setCoinbaseInstalled = isInstalled => {
  return {
    type: 'SET_COINBASE_INSTALLED',
    payload: isInstalled,
  };
};

export const setAuthenticated = isAuthenticated => {
  return {
    type: 'SET_AUTHENTICATED',
    payload: isAuthenticated,
  };
};

export const setNetwork = network => {
  return {
    type: 'SET_NETWORK',
    payload: network,
  };
};

export const setEthereumAddress = ethereumAddress => {
  return {
    type: 'SET_ETHEREUM_ADDRESS',
    payload: ethereumAddress,
  };
};

export const setCoinbaseAddress = coinbaseAddress => {
  return {
    type: 'SET_COINBASE_ADDRESS',
    payload: coinbaseAddress,
  };
};

export const checkMetaMaskInstallation = () => {
  return dispatch => {
    if (typeof window.ethereum !== 'undefined') {
      dispatch(setMetaMaskInstalled(true));
    } else {
      dispatch(setMetaMaskInstalled(false));
    }
  };
};

export const checkCoinbaseInstallation = () => {
  return dispatch => {
    if (typeof window.coinbase !== 'undefined') {
      dispatch(setCoinbaseInstalled(true));
    } else {
      dispatch(setCoinbaseInstalled(false));
    }
  };
};

export const authenticateWithMetaMask = () => {
  return async dispatch => {
    try {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const network = window.ethereum.networkVersion;
      let networkName = '';
      switch (network) {
        case '1':
          networkName = 'Ethereum';
          break;
        case '3':
          networkName = 'Ropsten';
          break;
        case '4':
          networkName = 'Rinkeby';
          break;
        case '5':
          networkName = 'Goerli';
          break;
        case '42':
          networkName = 'Kovan';
          break;
        default:
          networkName = 'Ethereum';
      }
      dispatch(setNetwork(networkName));
      dispatch(setAuthenticated(true));
      dispatch(setEthereumAddress(accounts[0]));
    } catch (error) {
      console.error('Error authenticating with MetaMask:', error);
    }
  };
};

export const authenticateWithCoinbase = () => {
  return async dispatch => {
    try {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const network = window.ethereum.networkVersion;
      let networkName = '';
      switch (network) {
        case '1':
          networkName = 'Ethereum';
          break;
        case '3':
          networkName = 'Ropsten';
          break;
        case '4':
          networkName = 'Rinkeby';
          break;
        case '5':
          networkName = 'Goerli';
          break;
        case '42':
          networkName = 'Kovan';
          break;
        default:
          networkName = 'Ethereum';
      }
      dispatch(setNetwork(networkName));
      dispatch(setAuthenticated(true));
      dispatch(setCoinbaseAddress(accounts[0]));
    } catch (error) {
      console.error('Error authenticating with Coinbase Wallet:', error);
    }
  };
};

export const setCustomAddress = customAddress => {
  return dispatch => {
    dispatch({ type: 'SET_CUSTOM_ADDRESS', payload: customAddress });
  };
};
