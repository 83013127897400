import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useAuth } from './useAuth';

import { getContactUsAdminById, patchContactUsAdminFulfill } from '../api/admin';

export const useContactUsRequest = (id?: number) => {
  const { jwt, isAdmin } = useAuth();
  const queryClient = useQueryClient();

  const { data: contactRequest } = useQuery({
    queryKey: ['contact-us-admin-request', id, jwt],
    queryFn: () => getContactUsAdminById(jwt, id),
    enabled: isAdmin && !!id,
  });

  const { mutateAsync: markAsAnswered, isLoading: isMarkingAsAnswered } = useMutation({
    mutationFn: () => patchContactUsAdminFulfill(jwt, id),
    onSuccess: () => {
      return queryClient.invalidateQueries(['contact-us-admin-request', 'contact-us-admin']);
    },
  });

  return {
    contactRequest,
    markAsAnswered,
    isMarkingAsAnswered,
  };
};
