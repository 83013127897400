import type { ethers } from 'ethers';
import type { ChangeEvent } from 'react';
import { comaFormat } from 'utils/comaFormat';

interface BigExchangerInputProps {
  min: ethers.BigNumber;
  initValue: ethers.BigNumber;
  value?: string;
  onValueChange?: (value: string) => void;
  disabled?: boolean;
}

export const BigExchangerInput = ({ onValueChange, value, disabled }: BigExchangerInputProps) => {
  const handleInputChange = (ev: ChangeEvent<HTMLInputElement>) => {
    let valReplaced = ev.target.value.replaceAll(',', '');

    if (valReplaced.trim() === '') return onValueChange?.('');
    if (isNaN(+valReplaced)) return;

    valReplaced = valReplaced.trim();

    if (/^0{2,}/.test(valReplaced)) {
      valReplaced = valReplaced.replace(/^0+/, '0');
    }

    if (/^0[^.]/.test(valReplaced)) {
      valReplaced = '0';
    }

    onValueChange?.(valReplaced);
  };

  return (
    <input
      placeholder="0"
      type="text"
      disabled={disabled}
      value={
        typeof value !== 'undefined' && value !== null && value !== ''
          ? comaFormat(value, undefined, true)
          : ''
      }
      className="!max-w-[120px] !rounded-[24px] !p-0 !text-[24px] sm:!max-w-[240px] lg:!max-w-[360px] lg:!text-[48px]"
      onChange={handleInputChange}
    />
  );
};
