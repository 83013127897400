import { useMemo } from 'react';
import {
  erc20ABI,
  useAccount,
  useContractRead,
  useContractReads,
  useContractWrite,
  useNetwork,
} from 'wagmi';

import { RedemptionVault } from '../abis/RedemptionVault';
import { getAddressesByChain } from '../constants/addresses';
import { getMtbillToken, getTokenByName } from '../constants/tokens';

export const useManageRedemptionVault = () => {
  const { address } = useAccount();
  const { chain } = useNetwork();
  const contract = useMemo(
    () => ({
      abi: RedemptionVault.abi,
      address: getAddressesByChain(chain?.network).mtbillRedemptionVault,
    }),
    [chain],
  );
  const usdcContract = useMemo(() => {
    const { address, decimals } = getTokenByName(chain?.network, 'USDC');
    return { abi: erc20ABI, address, decimals };
  }, [chain?.network]);
  const stUsdContract = useMemo(() => {
    const { address, decimals } = getMtbillToken(chain?.network);
    return { abi: erc20ABI, address, decimals };
  }, [chain?.network]);

  const { data } = useContractReads({
    contracts: [
      {
        ...contract,
        functionName: 'accessControl',
      },
      {
        ...contract,
        functionName: 'mTBILL',
      },
    ],
  });

  const { data: isOnPauseRaw } = useContractRead({
    ...contract,
    functionName: 'paused',
    watch: true,
  });

  const { data: usdcBalanceRaw } = useContractRead({
    ...usdcContract,
    functionName: 'balanceOf',
    args: [contract.address],
    watch: true,
  });

  const { data: stUsdBalanceRaw } = useContractRead({
    ...stUsdContract,
    functionName: 'balanceOf',
    args: [contract.address],
    watch: true,
  });

  const { data: usdcAccountBalanceRaw } = useContractRead({
    ...usdcContract,
    functionName: 'balanceOf',
    args: address ? [address] : undefined,
    watch: true,
  });

  const accessControl = data?.[0]?.result ?? '';
  // const etfDataFeed = data?.[1]?.result ?? '';
  // const minUsdAmountToRedeem = data?.[2]?.result ?? BigInt(0);
  const stUSD = data?.[1]?.result ?? '';
  const isOnPause = isOnPauseRaw ?? false;
  const usdcBalance = usdcBalanceRaw ?? BigInt(0);
  const usdcAccountBalance = usdcAccountBalanceRaw ?? BigInt(0);
  const stUsdBalance = stUsdBalanceRaw ?? BigInt(0);

  const { writeAsync: withdrawTokenAsync } = useContractWrite({
    ...contract,
    functionName: 'withdrawToken',
  });

  const { writeAsync: pauseAsync } = useContractWrite({
    ...contract,
    functionName: 'pause',
  });

  const { writeAsync: unpauseAsync } = useContractWrite({
    ...contract,
    functionName: 'unpause',
  });

  const { writeAsync: depositUsdcAsync } = useContractWrite({
    ...usdcContract,
    functionName: 'transfer',
  });

  return {
    address: contract.address,
    accessControl,
    stUSD,
    isOnPause,
    usdcBalance,
    unpauseAsync,
    usdc: usdcContract,
    withdrawTokenAsync,
    pauseAsync,
    usdcAccountBalance,
    depositUsdcAsync,
    stUsdBalance,
  };
};
