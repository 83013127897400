import { useMemo } from 'react';
import { successToast, txErrorToast } from 'toasts';
import { cn } from 'utils/cn';
import { shortenAddress } from 'utils/shortenAddress';
import { useNetwork } from 'wagmi';

import { getAddressesByChain } from 'constants/addresses';
import { PlatformToken } from 'constants/tokens';

import copy from '../../assets/imgs/new/icons/copy.svg';
import redirect from '../../assets/imgs/new/icons/redirect.svg';

export const OnchainAddresses = ({ token }: { token: PlatformToken }) => {
  const { chain } = useNetwork();
  const addressesByChain = getAddressesByChain(chain?.network ?? 'homestead');
  const addresses = useMemo(
    () => [
      {
        name: token === PlatformToken.MBASIS ? 'mBASIS' : 'mTBILL',
        address: token === PlatformToken.MBASIS ? addressesByChain.mBASIS : addressesByChain.mTBILL,
      },
      ...(token === PlatformToken.MTBILL
        ? [
            {
              name: 'Chainlink Oracle',
              address: addressesByChain.dataFeed,
            },
          ]
        : []),
      {
        name: 'USDC Recipient Address',
        address:
          token === PlatformToken.MBASIS
            ? addressesByChain.usdcMbasisRecipient
            : addressesByChain.usdcMtbillRecipient,
      },
    ],
    [token, addressesByChain],
  );

  const handleCopyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      successToast(`Address copied to clipboard`, {
        position: 'bottom-right',
      });
    } catch (err) {
      console.error('Error copying text: ', err);
      txErrorToast('Error copying address', {
        position: 'bottom-right',
      });
    }
  };

  return (
    <div className="my-[56px] flex w-full flex-col gap-[16px] md:my-[80px]">
      <p className="text-2xl font-bold text-[#0C1C46] md:text-[40px] md:leading-[48px]">
        Onchain Addresses
      </p>
      {addresses.map(({ name, address }, i) => (
        <div
          key={`${name}-${address}-${i}`}
          className={cn(
            'flex w-full items-center px-3 py-[24px] text-[16px] leading-[24px] text-[#14223D] md:px-[32px]',
            i % 2 === 0 ? 'bg-white' : 'bg-[#f9f9f9]',
          )}
        >
          <div className="w-full text-sm md:text-base">{name}</div>
          <div className="w-full pr-[26px] text-end text-sm md:pr-[32px] md:text-start md:text-[18px] md:leading-6">
            {shortenAddress(address)}
          </div>
          <div className="flex items-center justify-end gap-[16px]">
            <button onClick={() => handleCopyToClipboard(address)}>
              <img className="h-6 min-h-6 w-6 min-w-6" src={copy} alt="copy" />
            </button>
            <a
              href={`${
                chain?.blockExplorers?.default?.url ?? 'https://etherscan.io'
              }/token/${address}`}
              target="_blank"
            >
              <img className="h-6 min-h-6 w-6 min-w-6" src={redirect} alt="redirect" />
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};
