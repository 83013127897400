import pause from 'assets/imgs/admin/pause 1.svg';
import play from 'assets/imgs/admin/play-button-arrowhead 1.svg';
import { GET_LAST_ST_USD_PAUSED_STATUS } from 'graphql/queries.js/stUSD';
import { useDefaultSubgraphQuery } from 'hooks/useDefaultSubgraphQuery';
import { usePauseStUsd } from 'hooks/usePauseStUsd';
import Aside from 'layouts/adminTab';
import Header from 'layouts/headerAdminTab';
import { useState } from 'react';
import { useConfig } from 'wagmi';

import { NotificationModal } from '../../components/NotificationModal';
import { successToast } from '../../toasts';
import { getHumanError, isCustomError } from '../../utils/errors';

export function Status() {
  const { publicClient } = useConfig();
  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [isModalOpened, setIsModalOpened] = useState(false);
  const closeModal = () => setIsModalOpened(false);

  const { pauseAsync, unpauseAsync } = usePauseStUsd();

  const {
    data: pausedSubgraphData,
    loading: pauseDataLoading,
    refetch,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = useDefaultSubgraphQuery<any>(GET_LAST_ST_USD_PAUSED_STATUS);

  const isNotLoaded =
    !pausedSubgraphData || pauseDataLoading || !pausedSubgraphData.stUsdPauses.length;
  const isPaused = isNotLoaded ? false : Boolean(pausedSubgraphData?.stUsdPauses[0]?.paused);
  const lastChanged = isNotLoaded
    ? new Date()
    : new Date(+pausedSubgraphData?.stUsdPauses[0]?.timestamp * 1000);

  const onPause = async () => {
    setIsLoading(true);
    try {
      await publicClient.waitForTransactionReceipt(await (isPaused ? unpauseAsync() : pauseAsync()));
      successToast('Status changed');
      refetch();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      const error = getHumanError(e.shortMessage);
      setErrorText(
        `Error while sending transaction: ${isCustomError(error) ? e.shortMessage : error}`,
      );
      setIsModalOpened(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="status__admin admin">
      <NotificationModal
        isModalOpened={isModalOpened}
        closeModal={closeModal}
        onBtnClick={closeModal}
        content={{
          bodyContent: errorText,
          headerContent: <>Sorry.</>,
          btnContent: 'Close',
        }}
      />
      <Aside />
      <section>
        <Header />
        <div className="status__content">
          <h5 className="!text-black">Status.</h5>
          <p>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
            laudantium, totam rem.
          </p>
          {!pauseDataLoading && (
            <div className="status__block">
              <div className="status__mode">
                <div>Status:</div>
                <div className={'dot ' + (isPaused ? 'none' : '')}>
                  {isPaused ? 'Non-Active' : 'Active'}
                </div>
              </div>
              <div className="status__mode">
                <div>Last changes:</div>
                <div>{lastChanged.toUTCString()}</div>
              </div>
              <div>
                <button onClick={onPause} disabled={isLoading}>
                  <img src={isPaused ? play : pause} />
                  {isLoading
                    ? 'Processing...'
                    : isPaused
                    ? 'Resume Trading Function'
                    : 'Pause Trading Function'}
                </button>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}

export default Status;
