import type { BaseEntity } from './client';
import { api } from './client';

export const GET_WAITLIST_ADMIN = '/waitlist/admin';
export const GET_SUBSCRIBE_ON_UPDATES_ADMIN = '/subscribe-on-updates/admin';
export const GET_CONTACT_US_ADMIN = '/contact-us/admin';
export const PATCH_CONTACT_US_ADMIN_FULFILL = '/contact-us/admin/fulfill';

interface WaitlistAdminResponse {
  joined: ({
    id: number;
    email: {
      id: number;
      email: string;
    } & BaseEntity;
  } & BaseEntity)[];
}
export const getWaitlistAdmin = async (jwt: string | undefined | null) => {
  if (!jwt) {
    return null;
  }
  const response = await api.get<WaitlistAdminResponse>(GET_WAITLIST_ADMIN, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Unable to get waitlist');
};

interface SubscribeOnUpdatesAdminResponse {
  subscribes: ({
    id: number;
    email: {
      id: number;
      email: string;
    } & BaseEntity;
  } & BaseEntity)[];
}
export const getSubscribeOnUpdatesAdmin = async (jwt: string | undefined | null) => {
  if (!jwt) {
    return null;
  }
  const response = await api.get<SubscribeOnUpdatesAdminResponse>(GET_SUBSCRIBE_ON_UPDATES_ADMIN, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Unable to get subscribe on updates');
};
type ContactUsAdminByIdResponse = {
  id: number;
  firstName?: string;
  secondName?: string;
  name?: string;
  isFulfilled: boolean;
  message?: string;
  email: {
    id: number;
    email: string;
  } & BaseEntity;
} & BaseEntity;
type ContactUsAdminResponse = Array<ContactUsAdminByIdResponse>;
export const getContactUsAdminById = async (jwt: string | undefined | null, id?: number) => {
  if (!jwt || !id) {
    return null;
  }
  const response = await api.get<ContactUsAdminByIdResponse>(`${GET_CONTACT_US_ADMIN}/${id}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Unable to get contact us');
};

export const getContactUsAdmin = async (jwt: string | undefined | null) => {
  if (!jwt) {
    return null;
  }
  const response = await api.get<ContactUsAdminResponse>(GET_CONTACT_US_ADMIN, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Unable to get contact us');
};

export const patchContactUsAdminFulfill = async (jwt: string | undefined | null, id?: number) => {
  if (!jwt || !id) {
    return null;
  }
  const response = await api.patch<ContactUsAdminByIdResponse>(
    `${PATCH_CONTACT_US_ADMIN_FULFILL}/${id}`,
    undefined,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Unable to fulfill contact us');
};
