import { api } from './client';

export const POST_NEW_KYC_URL = 'new-kyc';

export interface NewKycPostRequest {
  email: string;
  name: string;
}
export const postNewKyc = async (data: NewKycPostRequest) => {
  const response = await api.post(POST_NEW_KYC_URL, data);
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  throw new Error('Unable to post new kyc');
};
