import type { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { EthSmall } from './svg/EthSmall';

export const AssetCard = ({
  name,
  description,
  icon,
  iconColor,
  apy,
  tvl,
  link,
}: {
  name: string;
  description: string;
  icon: ReactElement;
  iconColor: string;
  apy: string;
  tvl: number;
  link: string;
}) => {
  return (
    <div className="relative flex h-full w-full max-w-[480px] flex-col justify-between rounded-[20px] border border-solid border-white bg-card-gradient px-6 py-8 text-white shadow-[0px_4px_24px_0px_rgba(19,28,44,0.08)] max-md:mx-auto sm:pb-[27px] lg:px-10">
      <div className="absolute right-0 top-0 min-h-[50px] min-w-[50px] rounded-bl-[20px] rounded-tr-[20px] bg-[#627eea] p-2">
        <EthSmall />
      </div>
      <div className="flex h-full w-full justify-center gap-[10px] border-0 border-b-[1px] border-solid border-b-[rgba(5,28,44,0.1)] pb-7 max-md:gap-[8px]">
        <div className="flex flex-col items-center gap-3">
          <div className="font-base flex items-center gap-3 text-[48px] text-stone-blue max-md:text-[22px]">
            <div
              style={{ background: iconColor }}
              className="flex h-9 w-9 items-center justify-center rounded-full"
            >
              {icon}
            </div>
            <span className="text-[32px] font-medium leading-[40px]">{name}</span>
          </div>
          <div className="font-base text-center text-base text-deep-blue">{description}</div>
        </div>
      </div>
      <div className="mt-6 flex flex-col items-center justify-items-center gap-4 px-0 md:px-[30px] lg:gap-6">
        <div className="flex flex-col items-center text-deep-blue">
          <div className="text-nowrap text-[40px] font-semibold leading-[48px]">{apy}% APY</div>
          <span className="text-base text-light-blue">Variable APY is subject to change</span>
        </div>
        <div />
        <div className="flex flex-col items-center text-deep-blue">
          <div className="text-[40px] font-semibold leading-[48px]">${tvl}m</div>
          <span className="text-base text-light-blue">Assets Under Management</span>
        </div>
        <div />
        <Link
          to={link}
          className="flex h-12 w-full max-w-[240px] items-center justify-center self-center rounded-[8px] border-none bg-orange text-base font-semibold text-deep-blue no-underline transition-colors hover:bg-[#f5a957] max-md:bottom-[-19px] max-md:h-[38px] max-md:w-[166px]"
        >
          Invest in {name}
        </Link>
      </div>
    </div>
  );
};
