import type { ReactNode } from 'react';
import React, { createContext, useContext, useState } from 'react';

interface ContactUsModalContextProps {
  isModalOpened: boolean;
  modalStep: number;
  openModal: () => void;
  closeModal: () => void;
  setModalStep: (step: number) => void;
}

const ContactUsModalContext = createContext<ContactUsModalContextProps | undefined>(undefined);

export const useContactUsModal = () => {
  const context = useContext(ContactUsModalContext);
  if (!context) {
    throw new Error('useContactUsModal must be used within a ContactUsModalProvider');
  }
  return context;
};

interface ContactUsModalProviderProps {
  children: ReactNode;
}

export const ContactUsModalProvider: React.FC<ContactUsModalProviderProps> = ({ children }) => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [modalStep, setModalStep] = useState(0);

  const openModal = () => {
    setIsModalOpened(true);
  };
  const closeModal = () => {
    setIsModalOpened(false);
    setModalStep(0);
  };

  return (
    <ContactUsModalContext.Provider
      value={{ isModalOpened, modalStep, openModal, closeModal, setModalStep }}
    >
      {children}
    </ContactUsModalContext.Provider>
  );
};
