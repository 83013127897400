import type { BaseEntity } from './client';
import { api } from './client';

const GET_SIGNING_MESSAGE_URL = 'auth/message';
const SIGN_IN_URL = 'auth/sign-in';
const GET_USER_URL = 'user';
const POST_USER_URL = 'user';
export const PUT_USER_CONFIRMATION = 'user/confirmation';

export interface SigningMessage {
  message: string;
}
export const getSigningMessage = async () => {
  const response = await api.get<SigningMessage>(GET_SIGNING_MESSAGE_URL);
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Unable to get signing message');
};

export interface Account extends BaseEntity {
  address: string;
}
export interface User extends BaseEntity {
  id: number;
  email: string;
  isEmailVerified: boolean;
  account: Account;
}
export interface AdminUser extends BaseEntity {
  id: number;
  account: Account;
}
export interface UserData {
  userData: {
    account: Account;
    user?: User;
    adminUser?: AdminUser;
  };
  accessToken: string;
}
export const postSignIn = async ({ address, signature }: { address: string; signature: string }) => {
  const response = await api.post<UserData>(SIGN_IN_URL, { address, signature });
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Unable to sign in');
};

export const getUser = async (jwt: string | null | undefined) => {
  if (!jwt) {
    return null;
  }
  const response = await api.get<UserData['userData']>(GET_USER_URL, {
    headers: { Authorization: `Bearer ${jwt}` },
  });
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Unable to get user');
};

export const postUser = async (jwt: string | null | undefined, email: string) => {
  if (!email) {
    return null;
  }
  const response = await api.post<UserData['userData']['user']>(
    POST_USER_URL,
    { email },
    {
      // headers: { Authorization: `Bearer ${jwt}` },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Unable to create user');
};

export const putUserConfirmation = async (email: string | null | undefined) => {
  // if (!jwt) {
  //   return null;
  // }
  const response = await api.put(PUT_USER_CONFIRMATION, { email });
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Unable to confirm user');
};
