import clsx from 'clsx';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import money_bundle from '../assets/imgs/cabinet/money bundle.svg';
// import wallet_send_vertical from '../assets/imgs/cabinet/wallet-send-vertical.svg';
import logo from '../assets/imgs/midas-cabinet2.svg';

export const CabinetMenu = () => {
  const { pathname: path } = useLocation();

  return (
    <div className="menu-block !bg-black">
      <div className="logo-cabinet">
        <Link to={`/`}>
          <img src={logo} alt="midas" />
        </Link>
      </div>
      <div className="cabinet-menu">
        {/* <Link to={'/profile'} className={clsx('item-menu', { focused: path.endsWith('/profile') })}>
          <img src={wallet_send_vertical} alt="Investments" />
          <p>My Investments</p>
        </Link> */}
        <Link
          to={'/profile/history'}
          className={clsx('item-menu', { focused: path.endsWith('/profile/history') })}
        >
          <img src={money_bundle} alt="Transaction History" />
          <p>Transaction History</p>
        </Link>
        {/*<Link*/}
        {/*  to={'/profile/requests'}*/}
        {/*  className={clsx('item-menu', { focused: path.endsWith('/profile/requests') })}*/}
        {/*>*/}
        {/*  <img src={wallet_receive_vertical} alt="Redemption Request" />*/}
        {/*  <p>Redemption Requests</p>*/}
        {/*</Link>*/}
        {/*<Link*/}
        {/*  to={'/profile/chain'}*/}
        {/*  className={clsx('item-menu', { focused: path.endsWith('/profile/chain') })}*/}
        {/*>*/}
        {/*  <img src={coin_bundle} alt="On-Chain Protocol Data" />*/}
        {/*  <p>On-chain Data</p>*/}
        {/*</Link>*/}
        {/* <Link to={'/cabinet/refferal'} className="item-menu">
                                <img src={scan_coin} alt="Referral Earnings" />
                                <p>Referral Earnings</p>
                            </Link> */}
      </div>
    </div>
  );
};
