import { getClient } from 'graphql/client';
import { mainnet, sepolia } from 'wagmi';

const subgraphs: Record<string, string> = {
  [sepolia.network]: 'https://api.studio.thegraph.com/query/9868/midas-v-1-1/version/latest',
  [mainnet.network]: 'https://api.studio.thegraph.com/query/9868/midas/version/latest',
};

const clientsPerNetwork = Object.fromEntries(
  Object.entries(subgraphs).map(([key, val]) => [key, getClient(val)]),
);

export const getSubgraphByNetwork = (network: string) => {
  network = network ?? sepolia.network;
  if (!subgraphs[network]) network = sepolia.network;
  return subgraphs[network];
};

export const getClientByNetwork = (network?: string) => {
  network = network ?? sepolia.network;
  if (!clientsPerNetwork[network]) network = sepolia.network;
  return clientsPerNetwork[network];
};
