import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAppSelector } from 'store';

import { useAuth } from './useAuth';

import type { KycPatchRequest } from '../api/kyc';
import {
  getKycAdminAll,
  getKycStatus,
  patchKycStatus,
  postKycSeal,
  putEntityKycDocument,
  putIndividualKycDocument,
} from '../api/kyc';

export const useKyc = () => {
  const { email } = useAppSelector(({ form }) => form);

  const { jwt, isAdmin, jwtEmail } = useAuth(email);
  const queryClient = useQueryClient();

  const { data: kycStatus } = useQuery({
    queryKey: ['kyc', jwtEmail],
    queryFn: () => getKycStatus(jwtEmail),
  });

  const { mutateAsync: patchKyc, isLoading: isPatchLoading } = useMutation({
    mutationFn: (data: KycPatchRequest) => patchKycStatus(jwtEmail, data),
    onSuccess: () => {
      return queryClient.invalidateQueries(['kyc']);
    },
  });

  const { mutateAsync: putIndividualKyc, isLoading: isLoadingIndividualKyc } = useMutation({
    mutationFn: (data: FormData) => putIndividualKycDocument(jwtEmail, data),
    onSuccess: () => {
      return queryClient.invalidateQueries(['kyc']);
    },
  });

  const { mutateAsync: putCommercialRegister, isLoading: isLoadingCommercialRegister } = useMutation(
    {
      mutationFn: (data: FormData) => putEntityKycDocument(jwtEmail, 'commercialRegister', data),
      onSuccess: () => {
        return queryClient.invalidateQueries(['kyc']);
      },
    },
  );

  const {
    mutateAsync: putUltimateBeneficialOwnerForm,
    isLoading: isLoadingUltimateBeneficialOwnerForm,
  } = useMutation({
    mutationFn: (data: FormData) =>
      putEntityKycDocument(jwtEmail, 'ultimateBeneficialOwnerForm', data),
    onSuccess: () => {
      return queryClient.invalidateQueries(['kyc']);
    },
  });

  const { mutateAsync: putPassportOfUBO, isLoading: isLoadingPassportOfUBO } = useMutation({
    mutationFn: (data: FormData) => putEntityKycDocument(jwtEmail, 'passportOfUBO', data),
    onSuccess: () => {
      return queryClient.invalidateQueries(['kyc']);
    },
  });

  const { mutateAsync: sealKyc, isLoading: isSealLoading } = useMutation({
    mutationFn: () => postKycSeal(jwtEmail),
    onSuccess: () => {
      return queryClient.invalidateQueries(['kyc']);
    },
  });

  const { data: allKycData } = useQuery({
    queryKey: ['adminKycAll', jwt],
    queryFn: () => getKycAdminAll(jwt),
    enabled: isAdmin,
  });

  return {
    kycStatus,
    patchKyc,
    sealKyc,
    isSealLoading,
    isPatchLoading,
    putIndividualKyc,
    isLoadingIndividualKyc,
    putCommercialRegister,
    isLoadingCommercialRegister,
    putUltimateBeneficialOwnerForm,
    isLoadingUltimateBeneficialOwnerForm,
    putPassportOfUBO,
    isLoadingPassportOfUBO,
    allKycData,
  };
};
