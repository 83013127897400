import clsx from 'clsx';
import type { FC, ReactNode } from 'react';

import { BlogText, BlogTextLink, BlogTitle } from './ui';

import BG from '../../../assets/imgs/bg-main.avif';
import BannerImg from '../../../assets/imgs/blogposts/midas-raises.png';
import Team1 from '../../../assets/imgs/team/1.png';
import Team2 from '../../../assets/imgs/team/2.jpeg';
import Team3 from '../../../assets/imgs/team/3.jpeg';
import Logo1 from '../../../assets/imgs/team/logo1.svg';
import Logo2 from '../../../assets/imgs/team/logo2.svg';
import Logo3 from '../../../assets/imgs/team/logo3.svg';

const teamConfig = [
  {
    desc: 'We’re excited about the differentiated approach to yield-bearing stablecoins that Midas is building. Beyond pulling new users into the space with attractive yield opportunities, we think Midas’ products will also allow a variety of new DeFi use cases during periods of normalized interest rates, encouraging users to stay on-chain for the long term.',
    name: 'Vance Spencer',
    role: 'Co-Founder of Framework Ventures',
    subDesc:
      'A trailblazer in crypto venture capital, Framework Ventures is renowned for propelling multi-billion-dollar DeFi and web3 gaming projects to success.',
    photo: Team1,
    logo: <img src={Logo1} alt="logo" />,
  },
  {
    desc: 'At BlockTower, we aim to partner with teams that are in the early stages of building paradigm-shifting technology, both from the standpoint of investors and users. Midas’ asset tokenization protocol mTBILL aligns with our vision given it is at the forefront of innovation. Our team looks forward to collaborating with Midas to forge a new synthesis of traditional finance and blockchain innovation.',
    name: 'Thomas Klocanas',
    role: 'General Partner and Head of Venture',
    subDesc:
      'This leading investment firm merges professional trading and portfolio management expertise with digital asset strategies.',
    photo: Team2,
    logo: <img src={Logo2} alt="logo" />,
  },
  {
    desc: 'In our eyes, Midas has the most exciting product vision we have seen in the Real-World Asset space - with stablecoins being the only RWA that has found Product-Market-Fit to date. Midas is bringing US-treasuries on-chain via its Permissionless ERC-20 token and taking advantage of an accelerating global demand for yield-bearing tokens.',
    name: 'Susie Meier',
    role: 'Principal',
    subDesc:
      'With a legacy of 23 years and €2.8 billion in managed assets, HV Capital stands as a pillar of early-stage investment in Europe.',
    photo: Team3,
    logo: <img src={Logo3} alt="logo" />,
  },
];

const BlogCard: FC<{
  desc: string;
  name: string;
  role: string;
  subDesc: string;
  photo: string;
  logo: ReactNode;
}> = ({ desc, name, role, photo, logo, subDesc }) => {
  return (
    <div className="flex w-full flex-col gap-4">
      <div className="relative box-border w-full overflow-hidden rounded-[25px] border-[1px] border-solid border-[#5DA8FF] p-[43px] pb-8 shadow-[1px_4px_6px_#00000040]">
        <img
          src={BG}
          alt="bt-top"
          className={clsx(`absolute left-1/2 top-1/2 z-0 h-full opacity-60 md:h-auto md:w-full`)}
          style={{ transform: 'translate(-50%, -50%) rotate(180deg)' }}
        />
        <div className="relative flex w-full flex-col gap-8">
          <div className="text-[16px] italic leading-[30px] tracking-wide text-black">{desc}</div>
          <div className="flex w-full flex-col items-center justify-between gap-4 md:flex-row">
            <div className="flex items-center gap-[22px]">
              <img
                src={photo}
                alt="member"
                className="h-[77px] w-[77px] overflow-hidden rounded-full shadow-[0_3px_4px_#00000040]"
              />
              <div className="flex flex-col gap-1">
                <div className="text-[14px] font-[700] leading-5 text-black">{name}</div>
                <div className="text-[12px] leading-5 text-black">{role}</div>
              </div>
            </div>
            {logo}
          </div>
        </div>
      </div>
      <div className="text-center text-[14px] italic leading-[30px] tracking-wide text-black">
        {subDesc}
      </div>
    </div>
  );
};

export function MidasRaises() {
  return (
    <main className="detail-desktop relative flex w-full max-w-[900px] flex-col items-center gap-[40px] pb-[200px] pt-[100px]">
      <div className="px-5 text-[24px] font-[600] leading-[48px] text-[#0C1C46] md:text-[48px] md:leading-[72px]">
        Midas Raises $8.75 Million Funding Round Led by Framework Ventures, BlockTower and HV Capital
      </div>
      <div className="box-border w-full px-2 md:px-0">
        <img src={BannerImg} alt="banner" className="w-full" />
      </div>

      <BlogText>
        Today marks a new chapter in decentralised finance (DeFi) as Midas announces an $8.75 million
        funding round, led by visionary firms &nbsp;
        <BlogTextLink href="https://framework.ventures/">Framework Ventures</BlogTextLink>, &nbsp;
        <BlogTextLink href="https://www.blocktower.com/">BlockTower</BlogTextLink> and &nbsp;
        <BlogTextLink href="https://www.hvcapital.com/">HV Capital</BlogTextLink>. We're also honored
        by the commitments from Cathay Ledger, 6th Man Ventures, Hack VC, GSR, Lattice Capital,
        Phaedrus, Theia Blockchain, Pareto, Axelar Foundation, Peer VC, FJ Labs, and Coinbase
        Ventures, whose commitments reinforce our mission to bridge the realms of traditional and
        decentralised finance.
      </BlogText>
      <BlogText>
        After more than two years of intensive research, we’re thrilled to unveil mTBILL. This ERC-20
        token, tracking short-dated U.S. Treasury Bills via an ultra-liquid BlackRock Treasury fund,
        offers an innovative way for stablecoin investors to earn yield on-chain, seamlessly melding
        the worlds of traditional finance and decentralized finance.
      </BlogText>
      <BlogText>
        Stablecoins represent more than just an anchor in the volatile seas of cryptocurrency;
        they’re a lifeline for efficient, cross-border transactions. As traditional financial systems
        grapple with layers of intermediaries and protracted processing times, stablecoins emerged as
        the swift, cost-effective alternative for global transfers.
      </BlogText>
      <BlogText>
        However, the last few years have seen a dramatic shift in their supply, coinciding with
        rising interest rates. The pivot of capital to traditional securities underscores the need
        for a DeFi solution that can offer competitive returns. mTBILL is engineered precisely to
        meet this challenge, distributing yield on-chain while enabling free participation in the
        DeFi ecosystem.
      </BlogText>
      <BlogText>
        In the rapidly evolving world of finance, Midas stands at the forefront of innovation. With
        the introduction of mTBILL, we're not just participating in the market; we're aiming to
        redefine it. Our token represents a bold step forward, merging the reliability of traditional
        financial instruments with the efficiency and accessibility of DeFi. <br />
        mTBILL offers a sustainable, equitable, and fully compliant method for investors to tap into
        the yield potential of U.S. Treasury Bills, leveraging the best of both worlds to secure a
        future where financial empowerment is accessible to all.
      </BlogText>
      <BlogTitle>Hear from Those Who Believe in Our Vision</BlogTitle>
      <BlogText>
        As we chart this ambitious course, the support and insight of our investors reinforce the
        potential of Midas to make a lasting impact. From leading venture capitalists to seasoned
        finance professionals, the consensus is clear: Midas's approach to bridging the gap between
        traditional finance and DeFi through mTBILL is not only innovative but necessary for the next
        stage of financial evolution.
      </BlogText>
      <div className="box-border flex w-full flex-col items-center gap-[43px] px-8 py-6">
        {teamConfig.map((data, i) => (
          <BlogCard key={i} {...data} />
        ))}
      </div>
      <BlogTitle>Who can invest?</BlogTitle>
      <BlogText>
        mTBILL is meticulously designed for a global audience, adhering to German regulatory
        standards to ensure wide accessibility while prioritizing security and compliance. This
        approach opens opportunities for investors outside the U.S. to engage with a stable,
        yield-generating digital asset, underpinned by one of the most secure financial instruments
        available.
      </BlogText>
      <BlogTitle>Interested in Investing or Learning More?</BlogTitle>
      <BlogText>
        We invite you to explore the potential of mTBILL and discover how Midas is paving the way for
        a new era of financial innovation. Whether you're an investor seeking enhanced yield or a
        blockchain enthusiast interested in the future of DeFi, Midas offers a gateway to the next
        frontier in stable capital.
      </BlogText>
      <BlogText>
        For more information on Midas and the mTBILL token, navigate to&nbsp;
        <BlogTextLink href="https://midas.app/">https://midas.app/</BlogTextLink>, or contact us via
        <br />
        team@midas.app. For a technical deep dive, visit our documentation at&nbsp;
        <BlogTextLink href="https://midas-docs.gitbook.io/midas-docs">
          https://midas-docs.gitbook.io/midas-docs
        </BlogTextLink>
        .
      </BlogText>
    </main>
  );
}

export default MidasRaises;
