import type { BigNumber } from 'ethers';
import { ethers } from 'ethers';
import { useMemo } from 'react';
import { convertToBase18 } from 'utils/decimalsConvertor';
import { useAccount, useContractRead, useNetwork } from 'wagmi';

import { getAddressesByChain } from 'constants/addresses';
import type { Token } from 'constants/tokens';
import { getMtbillToken, getTokenByAddress } from 'constants/tokens';

import { DepositVault } from '../abis/DepositVault';
import { ERC20 } from '../abis/ERC20';
import { RedemptionVault } from '../abis/RedemptionVault';

export const useMtbillTokenData = () => {
  const { address } = useAccount();
  const { chain } = useNetwork();

  const mtbillToken = useMemo(() => getMtbillToken(chain?.network), [chain]);
  const addresses = useMemo(() => getAddressesByChain(chain?.network), [chain]);

  const { data: depositTokensRaw } = useContractRead({
    abi: DepositVault.abi,
    address: addresses.mtbillDepositVault,
    functionName: 'getPaymentTokens',
    structuralSharing: (prev, next) => (prev?.toString() === next?.toString() ? prev : next),
  });

  const { data: redeemTokensRaw } = useContractRead({
    abi: RedemptionVault.abi,
    address: addresses.mtbillRedemptionVault,
    functionName: 'getPaymentTokens',
    structuralSharing: (prev, next) => (prev?.toString() === next?.toString() ? prev : next),
  });

  const depositTokens = useMemo(
    () =>
      depositTokensRaw
        ? (depositTokensRaw
            .map(token => getTokenByAddress(chain?.network, token))
            .filter(t => t !== undefined) as Token[])
        : [],
    [depositTokensRaw],
  );

  const redeemTokens = useMemo(
    () =>
      redeemTokensRaw
        ? (redeemTokensRaw
            .map(token => getTokenByAddress(chain?.network, token))
            .filter(t => t !== undefined) as Token[])
        : [],
    [redeemTokensRaw],
  );

  const {
    data: mtbillBalanceRaw,
    isLoading: isLoadingMtbillBalance,
    isError: isErrorMtbillBalance,
    isSuccess: isSuccessMtbillBalance,
  } = useContractRead({
    abi: ERC20.abi,
    address: mtbillToken.address,
    functionName: 'balanceOf',
    args: address ? [address] : undefined,
    watch: true,
    enabled: !!address,
  });

  const {
    data: usdcBalanceRaw,
    isLoading: isLoadingUsdcBalance,
    isError: isErrorUsdcBalance,
    isSuccess: isSuccessUsdcBalance,
  } = useContractRead({
    abi: ERC20.abi,
    address: depositTokens[0]?.address,
    functionName: 'balanceOf',
    args: address ? [address] : undefined,
    watch: true,
    enabled: !!address && !!depositTokens?.[0]?.address,
  });

  const {
    data: usdcMtbillAllowanceRaw,
    isLoading: isLoadingUsdcMtbillAllowance,
    isError: isErrorUsdcMtbillAllowance,
    isSuccess: isSuccessUsdcMtbillAllowance,
  } = useContractRead({
    abi: ERC20.abi,
    address: depositTokens[0]?.address,
    functionName: 'allowance',
    args: address ? [address, addresses.mtbillDepositVault] : undefined,
    watch: true,
    enabled: !!address && !!depositTokens?.[0]?.address,
  });

  const {
    data: mtbillAllowanceRaw,
    isLoading: isLoadingMtbillAllowance,
    isError: isErrorMtbillAllowance,
    isSuccess: isSuccessMtbillAllowance,
  } = useContractRead({
    abi: ERC20.abi,
    address: mtbillToken.address,
    functionName: 'allowance',
    args: address ? [address, addresses.mtbillRedemptionVault] : undefined,
    watch: true,
    enabled: !!address && !!mtbillToken?.address,
  });

  const allowances = useMemo(
    () =>
      depositTokens?.[0]
        ? {
            [depositTokens[0].name]: convertToBase18(
              ethers.BigNumber.from(usdcMtbillAllowanceRaw?.toString() ?? '0'),
              depositTokens[0].decimals,
            ),
          }
        : {},
    [depositTokens, usdcMtbillAllowanceRaw, address],
  );

  const redeemAllowance = useMemo(
    () =>
      convertToBase18(
        ethers.BigNumber.from(mtbillAllowanceRaw?.toString() ?? '0'),
        mtbillToken?.decimals ?? 18,
      ),
    [mtbillToken, mtbillAllowanceRaw, address],
  );

  const balances = useMemo<Record<string, BigNumber>>(
    () =>
      depositTokens?.[0]
        ? {
            [depositTokens[0].name]: convertToBase18(
              ethers.BigNumber.from(usdcBalanceRaw?.toString() ?? '0'),
              depositTokens[0].decimals,
            ),
            [mtbillToken.name]: convertToBase18(
              ethers.BigNumber.from(mtbillBalanceRaw?.toString() ?? '0'),
              mtbillToken.decimals,
            ),
          }
        : {},
    [depositTokens, usdcBalanceRaw, mtbillToken, mtbillBalanceRaw, address],
  );

  return {
    token: mtbillToken,
    depositAllowances: allowances,
    redeemAllowance: redeemAllowance,
    tokenBalances: balances,
    depositTokens,
    redeemTokens,
    isLoading:
      isLoadingUsdcMtbillAllowance ||
      isLoadingMtbillBalance ||
      isLoadingUsdcBalance ||
      isLoadingMtbillAllowance,
    isError:
      isErrorUsdcMtbillAllowance ||
      isErrorMtbillBalance ||
      isErrorUsdcBalance ||
      isErrorMtbillAllowance,
    isSuccess:
      isSuccessUsdcMtbillAllowance ||
      isSuccessMtbillBalance ||
      isSuccessUsdcBalance ||
      isSuccessMtbillAllowance,
  };
};
