import { useMemo } from 'react';
import { erc20ABI, useContractRead, useContractReads, useContractWrite, useNetwork } from 'wagmi';

import { DepositVault } from '../abis/DepositVault';
import { getAddressesByChain } from '../constants/addresses';
import { getTokenByName } from '../constants/tokens';

export const useManageDepositVault = () => {
  const { chain } = useNetwork();
  const contract = useMemo(
    () => ({
      abi: DepositVault.abi,
      address: getAddressesByChain(chain?.network).mtbillDepositVault,
    }),
    [chain],
  );
  const usdcContract = useMemo(() => {
    const { address, decimals } = getTokenByName(chain?.network, 'USDC');
    return { abi: erc20ABI, address, decimals };
  }, [chain?.network]);

  const { data } = useContractReads({
    contracts: [
      {
        ...contract,
        functionName: 'accessControl',
      },
      {
        ...contract,
        functionName: 'eurUsdDataFeed',
      },
      {
        ...contract,
        functionName: 'mTBILL',
      },
    ],
  });

  const { data: isOnPauseRaw } = useContractRead({
    ...contract,
    functionName: 'paused',
    watch: true,
  });

  const { data: usdcBalanceRaw } = useContractRead({
    ...usdcContract,
    functionName: 'balanceOf',
    args: [contract.address],
    watch: true,
  });

  const accessControl = data?.[0]?.result ?? '';
  // const etfDataFeed = data?.[1]?.result ?? '';
  const eurUsdDataFeed = data?.[1]?.result ?? '';
  const stUSD = data?.[2]?.result ?? '';
  const usdcBalance = usdcBalanceRaw ?? BigInt(0);
  const isOnPause = isOnPauseRaw ?? false;

  const { writeAsync: withdrawTokenAsync } = useContractWrite({
    ...contract,
    functionName: 'withdrawToken',
  });

  const { writeAsync: pauseAsync } = useContractWrite({
    ...contract,
    functionName: 'pause',
  });

  const { writeAsync: unpauseAsync } = useContractWrite({
    ...contract,
    functionName: 'unpause',
  });

  return {
    address: contract.address,
    accessControl,
    eurUsdDataFeed,
    stUSD,
    isOnPause,
    usdcBalance,
    usdc: usdcContract,
    withdrawTokenAsync,
    pauseAsync,
    unpauseAsync,
  };
};
