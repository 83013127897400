import { useQuery } from '@tanstack/react-query';

import { getMbasisPrice } from '../../api/data';

export const useGetMbasisPrice = (timestampFrom: number, timestampTo: number) => {
  return useQuery({
    queryKey: ['mbasis-price', timestampFrom, timestampTo],
    queryFn: () => getMbasisPrice({ timestampFrom, timestampTo }),
  });
};
