import { PlatformToken } from 'constants/tokens';

import CaretRight from '../../assets/imgs/caret-right.svg';

const OverviewLink = ({ text, href }: { text: string; href: string }) => {
  return (
    <a
      href={href}
      target="_blank"
      className="group flex w-fit items-center gap-2 text-base text-[#14223D]"
    >
      {text}
      <img
        className="mt-[2px] opacity-0 transition-opacity group-hover:opacity-100"
        src={CaretRight}
        alt="chevron"
      />
    </a>
  );
};

const mtbillData = (
  <>
    <p className="text-[18px] font-medium leading-6 text-[#0C1C46] md:text-2xl">
      US Short-Term Treasuries
    </p>
    <div className="flex flex-col gap-4 text-base text-[#14223D]">
      <p>
        Put your stablecoins to work with mTBILL, which gives you onchain exposure to the Federal
        Reserve's risk-free rate through the BlackRock iShares IB01 ETF. Interest is automatically
        reinvested by the asset manager, enhancing the Net Asset Value (NAV) and increasing the value
        of each mTBILL token. Your investment grows in line with the ETF’s performance.
      </p>
      <p>Investors can easily buy and redeem mTBILL tokens through Midas using USDC or USD.</p>{' '}
      <div className="flex flex-col gap-2">
        <h6 className="text-[18px] font-medium">Learn more</h6>
        <div className="flex flex-col">
          <OverviewLink
            text="mTBILL Documentation"
            href="https://docs.midas.app/token-mechanics/mtbill"
          />
          <OverviewLink
            text="Legal Terms"
            href="https://docs.midas.app/token-mechanics/mtbill/legal-structure"
          />
        </div>
      </div>
    </div>
  </>
);

const mbasisData = (
  <div className="flex flex-col gap-4 text-base text-[#14223D]">
    <p>
      The mBASIS token represents exposure to a bankruptcy-protected vehicle, investing stablecoins
      into a Special Purpose Company (SPC) that executes a delta-neutral basis trading strategy. A
      top-tier asset manager executes the strategy.
    </p>
    <p>
      With mBASIS, you accumulate value from the interest earned while enjoying the full suite of
      DeFi platforms, services, and strategies via a permissionless token.
    </p>
    <p>Investors can easily buy and redeem mBasis tokens through Midas using USDC.</p>
    <div className="flex flex-col gap-2">
      <h6 className="text-[18px] font-medium">Learn more</h6>
      <div className="flex flex-col">
        <OverviewLink
          text="mBASIS Documentation"
          href="https://docs.midas.app/token-mechanics/mbasis"
        />
        <OverviewLink
          text="Legal Terms"
          href="https://docs.midas.app/token-mechanics/mbasis/legal-structure"
        />
      </div>
    </div>
  </div>
);

export const Overview = ({ token }: { token: PlatformToken }) => {
  return (
    <div className="flex flex-col gap-6 rounded-[24px] md:gap-8">
      <h3 className="text-2xl font-bold text-[#0C1C46] md:text-[40px] md:leading-[48px]">
        Overview
      </h3>
      <div className="flex flex-col gap-4">
        {token === PlatformToken.MBASIS ? mbasisData : mtbillData}
      </div>
    </div>
  );
};
