export const PriceChangeArrow = ({ color, className }: { color: string; className?: string }) => {
  return (
    <svg
      className={className}
      width="12"
      height="6"
      viewBox="0 0 12 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 -5.24537e-07L12 6L0 6L6 -5.24537e-07Z" fill={color} />
    </svg>
  );
};
