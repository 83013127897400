import close from '../../assets/imgs/mtbill/close.svg';

export interface ContactModalProps {
  onClose: () => void;
}

export const ThankForInterestModal = ({ onClose }: ContactModalProps) => {
  return (
    <div className="relative flex flex-col gap-4 p-4">
      <div className="absolute right-4 top-4 cursor-pointer" onClick={onClose}>
        <img src={close} alt={close} className="h-[24px] w-[24px]" />
      </div>
      <div className="flex flex-col items-center gap-2 text-center">
        <h3 className="text-xl font-semibold sm:text-2xl">Thank you for your interest</h3>
        <p className="text-base">
          We have successfully received your information. You will be contacted shortly with further
          details.
        </p>
      </div>
      <button
        className="w-full cursor-pointer rounded-[8px] border-none bg-black p-[10px] text-[18px] text-white"
        onClick={onClose}
      >
        Dismiss
      </button>
    </div>
  );
};
