import React from 'react';
import type { RouteObject } from 'react-router-dom';

import Status from '../pages/admin';
import { UserKyc } from '../pages/admin/[address]';
import { ContactRequest } from '../pages/admin/[contact-request]';
import BlackList from '../pages/admin/blackList';
import { ContactRequests } from '../pages/admin/contact-requests';
import { DepositRequests } from '../pages/admin/depositRequests';
import GreenList from '../pages/admin/greenList';
import { AdminLayout } from '../pages/admin/layout';
import { ManageDepositVault } from '../pages/admin/manageDeposit';
import { ManageRedemptionVault } from '../pages/admin/manageRedemption';
import { RedemptionRequests } from '../pages/admin/redemptionRequests';
import { Stusd } from '../pages/admin/stUSD';
import { Subscribers } from '../pages/admin/subscribers';
import Users from '../pages/admin/Users';
import { Waitlist } from '../pages/admin/waitlist';
import { Wallets } from '../pages/admin/wallets';
import Cabinet from '../pages/cabinet';
import Investments from '../pages/investments';

export const ROUTES = {
  HOME: '/',
  CONTACT_US: '/contact-us',
  HOW_IT_WORKS: '/how-it-works',
  KYC: '/KYC',
  TRUST: '/trust',
  DETAIL: '/detail',

  CHAIN: '/profile/chain',
  REFERRAL: '/profile/referral',
  INVESTMENTS: '/profile',
  REQUESTS: '/profile/requests',
  HISTORY: '/profile/history',

  ADMIN: '/admin',
  STATUS: '/admin/',
  BLACK_LIST: '/admin/black-list',
  USERS: '/admin/users',
  USER_KYC: '/admin/users/:userAddress',
  GREEN_LIST: '/admin/green-list',
  STUSD: '/admin/mtbill',
  ADMIN_REQUESTS: '/admin/redemption-requests',
  ADMIN_DEPOSIT_REQUESTS: '/admin/deposit-requests',
  ADMIN_MANAGE_DEPOSIT: '/admin/manage-deposit-vault',
  ADMIN_MANAGE_REDEMPTION: '/admin/manage-redemption-vault',
  ADMIN_WALLETS: '/admin/wallets',
  ADMIN_WAITLIST: '/admin/waitlist',
  ADMIN_SUBSCRIBERS: '/admin/subscribers',
  ADMIN_CONTACT_REQUESTS: '/admin/contact-requests',
  ADMIN_CONTACT_REQUESTS_MESSAGE: '/admin/contact-requests/:id',
} as const;

export const dashboardRoutesConfig: RouteObject[] = [
  // {
  //   path: ROUTES.CHAIN,
  //   element: <Chain />,
  // },
  // {
  //   path: ROUTES.REFERRAL,
  //   element: <Refferal />,
  // },
  {
    path: ROUTES.INVESTMENTS,
    element: <Investments />,
  },
  // {
  //   path: ROUTES.REQUESTS,
  //   element: <Requests />,
  // },
  {
    path: ROUTES.HISTORY,
    element: <Cabinet />,
  },
  {
    path: ROUTES.ADMIN,
    element: <AdminLayout />,
    children: [
      {
        path: ROUTES.STATUS,
        element: <Status />,
      },
      {
        path: ROUTES.BLACK_LIST,
        element: <BlackList />,
      },
      {
        path: ROUTES.USERS,
        element: <Users />,
      },
      {
        path: ROUTES.USER_KYC,
        element: <UserKyc />,
      },
      {
        path: ROUTES.GREEN_LIST,
        element: <GreenList />,
      },
      {
        path: ROUTES.STUSD,
        element: <Stusd />,
      },
      {
        path: ROUTES.ADMIN_DEPOSIT_REQUESTS,
        element: <DepositRequests />,
      },
      {
        path: ROUTES.ADMIN_REQUESTS,
        element: <RedemptionRequests />,
      },
      {
        path: ROUTES.ADMIN_MANAGE_DEPOSIT,
        element: <ManageDepositVault />,
      },
      {
        path: ROUTES.ADMIN_MANAGE_REDEMPTION,
        element: <ManageRedemptionVault />,
      },
      {
        path: ROUTES.ADMIN_WALLETS,
        element: <Wallets />,
      },
      {
        path: ROUTES.ADMIN_WAITLIST,
        element: <Waitlist />,
      },
      {
        path: ROUTES.ADMIN_SUBSCRIBERS,
        element: <Subscribers />,
      },
      {
        path: ROUTES.ADMIN_CONTACT_REQUESTS,
        element: <ContactRequests />,
      },
      {
        path: ROUTES.ADMIN_CONTACT_REQUESTS_MESSAGE,
        element: <ContactRequest />,
      },
    ],
  },
];
