import { MidasMLogo } from 'components/svg';
import { useEmail } from 'hooks/useEmail';
import type { Dispatch } from 'react';
import { Link, useLocation } from 'react-router-dom';

export function FooterMobile({
  onSubscribeOnUpdates,
  setEmail,
}: {
  onSubscribeOnUpdates: () => void;
  setEmail: Dispatch<string>;
}) {
  const { isSubscribingOnUpdates } = useEmail();
  const location = useLocation();
  if (location.pathname.includes('/profile') || location.pathname.includes('/admin')) return null;

  return (
    <footer className="m-0 border-0 border-t-2 border-solid border-[rgb(204,204,204)] bg-[#f4f4f4] px-4 py-0">
      <div className="flex flex-col pb-8 pt-10">
        <div className="flex flex-col sm:flex-row sm:justify-between">
          <div className="flex flex-col gap-6">
            <Link to={`/`}>
              <MidasMLogo />
            </Link>
            <p className="mb-8 inline-flex flex-col text-base leading-[30px] text-[rgb(99,101,105)]">
              <span>Midas Software GmbH</span>
              <span>Kurfürstendamm 15</span>
              <span>10719 Berlin</span>
            </p>
          </div>
          <div className="grid grid-cols-2 gap-8 sm:gap-12">
            <div className="flex flex-col gap-4">
              <div>
                <Link to={`/mTBILL`} className="!opacity-100 hover:underline md:!text-[18px]">
                  mTBILL
                </Link>
              </div>
              <div>
                <Link
                  to={`/mBASIS`}
                  className="!text-deep-blue !opacity-100 hover:underline md:!text-[18px]"
                >
                  mBASIS
                </Link>
              </div>
              <div>
                <Link to={`/how-it-works`} className="!opacity-100 hover:underline md:!text-[18px]">
                  How It Works
                </Link>
              </div>
              <div>
                <Link
                  to={`/how-it-works#faq`}
                  className="!opacity-100 hover:underline md:!text-[18px]"
                >
                  FAQ
                </Link>
              </div>
              <div>
                <Link
                  to={`https://midas-docs.gitbook.io/midas-docs`}
                  target="_blank"
                  className="!opacity-100 hover:underline md:!text-[18px]"
                >
                  Docs
                </Link>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div>
                <Link to={`/contact-us`} className="!opacity-100 hover:underline md:!text-[18px]">
                  Contact Us
                </Link>
              </div>
              <div>
                <Link to={`/blog`} className="!opacity-100 hover:underline md:!text-[18px]">
                  Blog
                </Link>
              </div>
              <div>
                <Link
                  to="https://wellfound.com/company/midas-10"
                  target="_blank"
                  className="!opacity-100 hover:underline md:!text-[18px]"
                >
                  Career
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 flex flex-col sm:items-center sm:text-center">
          <h6 className="m-0 p-0 text-[20px] font-semibold leading-[28px] text-deep-blue">
            Stay Informed
          </h6>
          <p className="m-0 p-0 pt-2 text-base font-light text-[rgb(99,101,105)]">
            Stay connected with Midas. Expect only valuable updates and insights — no spam, just the
            essential news.
          </p>
          <form className="footer-form pt-6" onSubmit={e => e.preventDefault()}>
            <input
              onChange={e => setEmail(e.target.value)}
              type="email"
              placeholder="Email"
              required
            />
            <button onClick={onSubscribeOnUpdates}>
              {isSubscribingOnUpdates ? 'Processing...' : 'Subscribe'}
            </button>
          </form>
        </div>
        <div className="mt-8 flex items-center justify-center gap-6">
          <h3 className="text-base font-semibold leading-[32px] text-[#14223D]">Follow Us</h3>
          <div className="footer-items">
            <Link to={`https://www.linkedin.com/company/midasrwa`} target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M15.8556 15.856V10.834C15.8556 8.36587 15.3243 6.48047 12.4448 6.48047C11.0564 6.48047 10.1309 7.23463 9.7538 7.95451H9.71952V6.70329H6.99426V15.856H9.8395V11.3139C9.8395 10.1141 10.0623 8.96577 11.5364 8.96577C12.9933 8.96577 13.0104 10.3198 13.0104 11.3825V15.8389H15.8556V15.856Z"
                  fill="white"
                />
                <path d="M2.36646 6.70325H5.21169V15.856H2.36646V6.70325Z" fill="white" />
                <path
                  d="M3.78912 2.14404C2.8807 2.14404 2.14368 2.88106 2.14368 3.78948C2.14368 4.6979 2.8807 5.45206 3.78912 5.45206C4.69754 5.45206 5.43456 4.6979 5.43456 3.78948C5.43456 2.88106 4.69754 2.14404 3.78912 2.14404Z"
                  fill="white"
                />
              </svg>
            </Link>
            <Link to={`https://t.me/midasrwa`} target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <g clipPath="url(#clip0_950_123)">
                  <path
                    d="M17.5367 2.23341C17.5229 2.17014 17.4926 2.11167 17.4488 2.06395C17.4051 2.01623 17.3494 1.98098 17.2876 1.9618C17.0625 1.9174 16.8296 1.93405 16.6131 2.01001C16.6131 2.01001 1.59328 7.40841 0.735602 8.00626C0.550245 8.13484 0.488638 8.2093 0.458102 8.29769C0.309709 8.72305 0.771495 8.91055 0.771495 8.91055L4.64257 10.1722C4.70796 10.1835 4.77507 10.1794 4.83864 10.1604C5.71935 9.6043 13.6988 4.56537 14.1601 4.39609C14.2324 4.37412 14.286 4.39609 14.2742 4.44966C14.0856 5.09573 7.19471 11.2195 7.15667 11.257C7.13819 11.2721 7.12365 11.2916 7.11432 11.3136C7.10499 11.3356 7.10114 11.3596 7.1031 11.3834L6.7431 15.1607C6.7431 15.1607 6.59203 16.3393 7.76846 15.1607C8.60257 14.3255 9.40292 13.6345 9.80417 13.2986C11.1354 14.2173 12.5679 15.2336 13.1856 15.7629C13.2892 15.8635 13.4121 15.9423 13.5469 15.9944C13.6816 16.0465 13.8255 16.0709 13.9699 16.0661C14.5592 16.0436 14.7199 15.3991 14.7199 15.3991C14.7199 15.3991 17.4563 4.38751 17.5485 2.91162C17.557 2.76698 17.5694 2.6743 17.5704 2.57519C17.5753 2.46024 17.5639 2.34518 17.5367 2.23341Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_950_123">
                    <rect
                      width="17.1429"
                      height="17.1429"
                      fill="white"
                      transform="translate(0.428589 0.428589)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </Link>
            <Link to="https://twitter.com/MidasRWA" target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <rect width="40" height="40" rx="20" />
                <g clipPath="url(#clip0_2517_4428)">
                  <path
                    d="M28 15.039C27.405 15.3 26.771 15.473 26.11 15.557C26.79 15.151 27.309 14.513 27.553 13.744C26.919 14.122 26.219 14.389 25.473 14.538C24.871 13.897 24.013 13.5 23.077 13.5C21.261 13.5 19.799 14.974 19.799 16.781C19.799 17.041 19.821 17.291 19.875 17.529C17.148 17.396 14.735 16.089 13.114 14.098C12.831 14.589 12.665 15.151 12.665 15.756C12.665 16.892 13.25 17.899 14.122 18.482C13.595 18.472 13.078 18.319 12.64 18.078C12.64 18.088 12.64 18.101 12.64 18.114C12.64 19.708 13.777 21.032 15.268 21.337C15.001 21.41 14.71 21.445 14.408 21.445C14.198 21.445 13.986 21.433 13.787 21.389C14.212 22.688 15.418 23.643 16.852 23.674C15.736 24.547 14.319 25.073 12.785 25.073C12.516 25.073 12.258 25.061 12 25.028C13.453 25.965 15.175 26.5 17.032 26.5C23.068 26.5 26.368 21.5 26.368 17.166C26.368 17.021 26.363 16.881 26.356 16.742C27.007 16.28 27.554 15.703 28 15.039Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2517_4428">
                    <rect width="16" height="16" fill="white" transform="translate(12 12)" />
                  </clipPath>
                </defs>
              </svg>
            </Link>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center gap-8 border-0 border-b border-solid border-[rgb(214,214,214)] pb-6 text-xs text-[rgb(99,101,105)] [&>a:hover]:underline">
        <Link target="_blank" to="https://docs.midas.app/resources/terms-and-conditions">
          Terms & Conditions
        </Link>
        <Link to={`/imprint`}>Imprint</Link>
        <Link to={`/privacy-policy`}>Privacy Policy</Link>
      </div>
      <p className="py-4 text-center text-xs text-[rgba(99,101,105,0.8)]">
        © {new Date().getUTCFullYear()} MIDAS. All rights reserved.
      </p>
    </footer>
  );
}
