import { DefaultMeta } from 'components/Meta/default-mets';
import { Footer } from 'layouts/footer';
import Header from 'layouts/header';
import { Loader } from 'layouts/loader';
import { Contact } from 'pages/contact';
import Home from 'pages/home';
import { Works } from 'pages/works';
import React, { useState, useLayoutEffect, useEffect, useRef } from 'react';
import type { FallbackProps } from 'react-error-boundary';
import { ErrorBoundary } from 'react-error-boundary';
import { Routes, Route, useRoutes, useLocation, useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { useAccount } from 'wagmi';

import { PlatformToken } from 'constants/tokens';

import close from '../../assets/imgs/close 1.svg';
import { restrictedCountries } from '../../constants/restricted-countries';
import { useApiWallet } from '../../hooks/useApiWallet';
import { useEmail } from '../../hooks/useEmail';
import Blog, { blogposts } from '../../pages/blog/blog';
import { BookADemo } from '../../pages/book-a-demo';
import { GeoBlock } from '../../pages/geo-block';
import { Imprint } from '../../pages/imprint';
import { PlatformTokenPage } from '../../pages/platform-token';
import { PrivacyPolicy } from '../../pages/privacy-policy';
import { TermsAndConditions } from '../../pages/terms-and-conditions';
import { dashboardRoutesConfig } from '../../routes/config';
import ConnectionWallet from '../ConnectionWallet';
import Form from '../Form';

const Fallback = ({ resetErrorBoundary }: FallbackProps) => {
  return (
    <div className="relative flex h-[75%] w-full flex-col items-center justify-center gap-2">
      <p className="text-2xl font-medium">Something went wrong. Please try again.</p>
      <button
        className="cursor-pointer rounded-[40px] border border-[#1127E3] bg-white px-[24px] py-[6px] text-[16px] font-medium text-[#1127E3]"
        onClick={resetErrorBoundary}
      >
        Retry
      </button>
    </div>
  );
};

const App = () => {
  const [waiting, setWaiting] = useState(true);
  const [email, setEmail] = useState('');
  const [form, setForm] = useState(false);
  const [open, setOpen] = useState(false);
  const [openWaitingList, setOpenWaitingList] = useState(false);
  const [successMes, setSuccess] = useState(2);

  const headerRef = useRef<HTMLDivElement>(null);
  const { joinWaitlist, isJoiningWaitingList } = useEmail();
  const { address } = useAccount();
  const { saveWallet } = useApiWallet();
  const [country, setCountry] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const abortController = new AbortController();

    fetch('https://ipapi.co/json/', { signal: abortController.signal })
      .then(response => response.json())
      .then(data => {
        setCountry(data.country_code);
      })
      .catch(e => console.error("Couldn't fetch country:", e));

    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (address) {
      saveWallet({ address }).catch(e => console.error("Couldn't save wallet:", e));
    }
  }, [address]);

  const location = useLocation();

  useLayoutEffect(() => {
    if (
      restrictedCountries.includes(country.toLowerCase()) &&
      (location.pathname.toLowerCase() === '/how-it-works' ||
        location.pathname.toLowerCase() === '/mtbill' ||
        location.pathname.toLowerCase() === '/mbasis')
    ) {
      navigate('/geo-block');
    }
    headerRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [location.pathname, country]);

  // const location = useLocation();
  const dashboardRoutes = useRoutes(dashboardRoutesConfig);

  // const onSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();
  //   success({ type: 'EMAIL', email: email });
  //   setWaiting(false);
  //   setForm(true);
  //   setSuccess(2);
  // };

  const closeModal = () => {
    setWaiting(true);
    setForm(false);
    setOpenWaitingList(false);
    setOpen(false);
  };

  // const openModal = () => {
  //   setOpen(true);
  // };

  // const success = async (data: { type: string } & Record<string, unknown>) => {
  //   if (data.type === 'EMAIL') {
  //     setSuccess(2);
  //   } else {
  //     setSuccess(3);
  //   }
  //   await axios.get('https://midas.work-api.space/mail.php', { params: data });
  //   setOpen(true);
  //   setWaiting(false);
  //   setForm(true);
  // };

  const onJoinWaitingList = async () => {
    if (isJoiningWaitingList) return;
    console.log('WAITLIST', await joinWaitlist({ email }));
    setOpen(true);
    setWaiting(false);
    setForm(true);
    setSuccess(2);
  };

  return (
    <>
      <Header ref={headerRef} />
      <DefaultMeta />
      {/*<TransitionGroup component={null}>*/}
      {/*  <CSSTransition key={location.key} classNames="fade" timeout={300}>*/}
      <ErrorBoundary FallbackComponent={Fallback}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact-us" element={<Contact />} />
          {/* <Route path="/about" element={<About />} /> */}
          <Route path="/how-it-works" element={<Works />} />
          <Route path="/KYC" element={<Form />} />
          {/* <Route path="/trust" element={<Trust />} /> */}
          <Route path="/mTBILL" element={<PlatformTokenPage token={PlatformToken.MTBILL} />} />
          <Route path="/mBASIS" element={<PlatformTokenPage token={PlatformToken.MBASIS} />} />
          <Route path="/blog" element={<Blog />} />
          {blogposts.map((blogpost, i) => (
            <Route
              key={`blogpost-route-${i}`}
              path={`/blog/${blogpost.id}`}
              element={blogpost.component}
            />
          ))}
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/imprint" element={<Imprint />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/demo" element={<BookADemo />} />
          <Route path="/geo-block" element={<GeoBlock />} />
        </Routes>
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={Fallback}>
        {/*  </CSSTransition>*/}
        {/*</TransitionGroup>*/}
        {dashboardRoutes}
      </ErrorBoundary>
      <Loader />
      <Footer />

      <div className={(open ? 'active' : '') + ' waiting-list-modal'}>
        <div className="modal">
          <img src={close} alt="close" onClick={() => closeModal()} />
          {waiting ? (
            <CSSTransition in={waiting} classNames="fade" timeout={300}>
              <div>
                <ConnectionWallet closeModal={closeModal} />
                {/*<form onSubmit={onSubmitHandler}>*/}
                {/*  <h5>Join Waiting List</h5>*/}
                {/*  <p>*/}
                {/*    Be the first to learn about updates & news by joining our waiting list.*/}
                {/*  </p>*/}
                {/*  <div className='form-control'>*/}
                {/*    <label htmlFor="email">Email address</label>*/}
                {/*    <input type='email' id='email' onChange={(e)=> setEmail(e.target.value)} name='email' required />*/}
                {/*  </div>*/}
                {/*  <div className='submit'>*/}
                {/*    <button type='submit'>Join Waiting List</button>*/}
                {/*  </div>*/}
                {/*</form>*/}
              </div>
            </CSSTransition>
          ) : (
            <CSSTransition in={form} classNames="fade" timeout={300}>
              <div className="success">
                <div className="!text-black">Subscription Confirmed!</div>
                <p className="!text-black">
                  {successMes == 3
                    ? 'Thank you for your message. Our team will be in touch with you shortly.'
                    : 'Thank you for subscribing to our updates.'}
                </p>
              </div>
            </CSSTransition>
          )}
        </div>
      </div>

      <div className={(openWaitingList ? 'active' : '') + ' waiting-list-modal'}>
        <div className="modal">
          <img src={close} alt="close" onClick={() => closeModal()} />
          {waiting ? (
            <CSSTransition in={waiting} classNames="fade" timeout={300}>
              <div>
                <div>
                  <h5>Join Waiting List</h5>
                  <p>Be the first to learn about updates & news by joining our waiting list.</p>
                  <div className="form-control">
                    <label htmlFor="email">Email address</label>
                    <input
                      type="email"
                      id="email"
                      onChange={e => setEmail(e.target.value)}
                      name="email"
                      required
                    />
                  </div>
                  <div className="submit">
                    <button
                      className="h-[56px] cursor-pointer rounded-[40px] border-[1px] border-[#1127E3] bg-[#1127E3] px-[32px] text-[16px] font-medium text-white outline-none transition-all hover:bg-white hover:text-[#1127E3]"
                      onClick={onJoinWaitingList}
                    >
                      {isJoiningWaitingList ? 'Processing...' : 'Join Waiting List'}
                    </button>
                  </div>
                </div>
              </div>
            </CSSTransition>
          ) : (
            <CSSTransition in={form} classNames="fade" timeout={300}>
              <div className="success">
                <div>Thank You.</div>
                <p>
                  {successMes == 3
                    ? 'Thank you for your message. Our team will be in touch with you shortly.'
                    : 'Thank you for joining our waiting list.'}
                </p>
              </div>
            </CSSTransition>
          )}
        </div>
      </div>
    </>
  );
};

export default App;
