import { zodResolver } from '@hookform/resolvers/zod';
import type { SubmitHandler } from 'react-hook-form';
import { useForm, Controller } from 'react-hook-form';
import { z } from 'zod';

import close from '../../assets/imgs/mtbill/close.svg';
import { useEmail } from '../../hooks/useEmail';
import { timeout } from '../../utils/timeout';

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  organisation?: string;
  investorType: string;
  interestAmount: string;
}

const schema = z.object({
  firstName: z.string().min(1, 'First name is required'),
  lastName: z.string().min(1, 'Last name is required'),
  email: z.string().email('Enter a valid email').min(1, 'Email is required'),
  organisation: z.string().optional(),
  investorType: z.string().min(1, 'Investor type is required'),
  interestAmount: z.string().min(1, 'Amount of interest is required'),
});

export interface ContactModalProps {
  setModalStep: (step: number) => void;
  onClose: () => void;
}

export const ContactModal = ({ setModalStep, onClose }: ContactModalProps) => {
  const { contactUs, isContactingUs } = useEmail();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit: SubmitHandler<FormData> = async data => {
    const { firstName, lastName, email, interestAmount, investorType, organisation } = data;
    const name = `${firstName} ${lastName}`;
    const message = `${
      organisation ? 'Organisation: ' + organisation + '\n' : ''
    }Investor Type: ${investorType}\nInterest Amount: ${interestAmount}`;

    try {
      await contactUs({ name, email, message });
      await timeout(1000);
      setModalStep(2);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="relative flex flex-col p-4">
      <div className="absolute right-4 top-4 cursor-pointer" onClick={onClose}>
        <img src={close} alt={close} className="h-[24px] w-[24px]" />
      </div>
      <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="text-[18px] font-medium text-black">Please fill out your details below</div>
        <div className="flex flex-col gap-[12px] text-[18px]">
          <div className="flex w-full flex-col gap-1">
            <Controller
              name="firstName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <input
                  {...field}
                  placeholder="First Name *"
                  className="rounded-[4px] border-none bg-[#F2F3F6] p-[10px]"
                />
              )}
            />
            {errors.firstName && <p className="text-sm text-red-500">{errors.firstName.message}</p>}
          </div>

          <div className="flex w-full flex-col gap-1">
            <Controller
              name="lastName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <input
                  {...field}
                  placeholder="Last Name *"
                  className="rounded-[4px] border-none bg-[#F2F3F6] p-[10px]"
                />
              )}
            />
            {errors.lastName && <p className="text-sm text-red-500">{errors.lastName.message}</p>}
          </div>

          <div className="flex w-full flex-col gap-1">
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <input
                  {...field}
                  placeholder="Email *"
                  className="rounded-[4px] border-none bg-[#F2F3F6] p-[10px]"
                />
              )}
            />
            {errors.email && <p className="text-sm text-red-500">{errors.email.message}</p>}
          </div>

          <Controller
            name="organisation"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <input
                {...field}
                placeholder="Organisation"
                className="rounded-[4px] border-none bg-[#F2F3F6] p-[10px]"
              />
            )}
          />

          <div className="flex w-full flex-col gap-1">
            <Controller
              name="investorType"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <select {...field} className="rounded-[4px] border-none bg-[#F2F3F6] p-[10px]">
                  <option value="">Investor Type *</option>
                  <option value="Individual">Individual</option>
                  <option value="Entity">Entity</option>
                  <option value="Other">Other</option>
                </select>
              )}
            />
            {errors.investorType && (
              <p className="text-sm text-red-500">{errors.investorType.message}</p>
            )}
          </div>

          <div className="flex w-full flex-col gap-1">
            <Controller
              name="interestAmount"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <select {...field} className="rounded-[4px] border-none bg-[#F2F3F6] p-[10px]">
                  <option value="">Amount of Interest *</option>
                  <option value="$0-$100,000">$0-$100,000</option>
                  <option value=">$100,000">$100,000</option>
                  <option value=">$500,000">$500,000</option>
                  <option value=">$1,000,000">$1,000,000</option>
                </select>
              )}
            />
            {errors.interestAmount && (
              <p className="text-sm text-red-500">{errors.interestAmount.message}</p>
            )}
          </div>
        </div>
        <button
          type="submit"
          className="w-full cursor-pointer rounded-[8px] border-none bg-black p-[10px] text-[18px] text-white"
        >
          {isContactingUs ? 'Processing...' : 'Submit'}
        </button>
      </form>
    </div>
  );
};
