import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import Modal from 'react-modal';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Toaster as SonnerToaster } from 'sonner';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { Buffer } from 'buffer';

import App from './components/App';
import { ClientProviders } from './components/ClientProviders';
import ContactUsModal from './components/ContactUsModal';
import { BackgroundProvider } from './providers/BackgroundProvider';
import { ContactUsModalProvider } from './providers/ContactUsModalProvider';
import reportWebVitals from './reportWebVitals';
import store from './store';

import 'assets/css/style.scss';
import 'assets/css/media.scss';
import './index.css';

if (typeof window !== 'undefined') {
  if (!window.Buffer) {
    window.Buffer = Buffer;
  }
  if (!window.global) {
    window.global = window;
  }
}

Modal.setAppElement('#root');

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ClientProviders>
      <Provider store={store}>
        <HelmetProvider>
          <BrowserRouter>
            <QueryParamProvider adapter={ReactRouter6Adapter}>
              <BackgroundProvider>
                <ContactUsModalProvider>
                  <App />
                  <ContactUsModal />
                </ContactUsModalProvider>
              </BackgroundProvider>
            </QueryParamProvider>
          </BrowserRouter>
          <Toaster />
          <SonnerToaster toastOptions={{ unstyled: true, className: 'w-[488px] right-0' }} />
        </HelmetProvider>
      </Provider>
    </ClientProviders>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
