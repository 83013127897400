import { useEffect, useState } from 'react';
import Select from 'react-select';

import type { Token } from 'constants/tokens';

const tokenToSelectOption = (token: Token) => {
  return {
    value: token.name,
    label: (
      <>
        <div className="flex items-center">
          <img
            src={token.icon ?? ''}
            alt="token"
            className="h-[24px] w-[24px] lg:h-[24px] lg:w-[24px]"
          />
          <span className="pl-[10px] text-[16px] text-[#0C1C46] opacity-80 lg:text-[24px]">
            {token.name}
          </span>
        </div>
      </>
    ),
  };
};

interface SelectTokensProps {
  tokens: Token[];
  onSelectedTokenChanged: (token: Token) => void;
}

export const SelectTokens = ({ tokens, onSelectedTokenChanged }: SelectTokensProps) => {
  const [selectedOption, setSelectedOption] = useState(tokens[0]);
  useEffect(() => {
    setSelectedOption(selectedOption ?? tokens[0]);
  }, [tokens]);

  useEffect(() => {
    if (tokens.length <= 1) return;
    onSelectedTokenChanged?.(selectedOption);
  }, [selectedOption]);

  return (
    <>
      {tokens.length > 1 ? (
        <>
          <Select
            className="select-menu"
            styles={{
              menu: provided => ({
                ...provided,
                zIndex: 9999,
              }),
              menuList: provided => ({
                ...provided,
                border: '1px solid transparent',
                borderRadius: '8px',
              }),
              option: provided => ({
                ...provided,
                backgroundColor: 'white',
              }),
              control: provided => {
                return {
                  ...provided,
                  height: '100%',
                  width: '170px',
                  backgroundColor: 'transparent',
                  borderStyle: 'hidden',
                };
              },
              indicatorsContainer: provided => ({
                ...provided,
                backgroundColor: 'transparent',
              }),
              valueContainer: provided => ({
                ...provided,
                borderStyle: 'hidden',
                backgroundColor: 'transparent',
              }),
            }}
            options={tokens.map(tokenToSelectOption)}
            value={tokenToSelectOption(selectedOption)}
            isSearchable={false}
            onChange={e => {
              if (e) {
                const option = tokens.find(option => option.name === e.value);
                if (option) {
                  setSelectedOption(option);
                }
              }
            }}
          />
        </>
      ) : (
        <>
          <div className="select top-[34px]">{tokenToSelectOption(tokens[0]).label}</div>
        </>
      )}
    </>
  );
};
