import { combineReducers } from 'redux';

import authReducer from './authReducer';
import formReducer from './formReducer';

const rootReducer = combineReducers({
  form: formReducer,
  auth: authReducer,
});

export default rootReducer;
