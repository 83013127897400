import type { ethers } from 'ethers';
import { useContractWrite, useNetwork, useWaitForTransaction } from 'wagmi';

import { getAddressesByChain } from 'constants/addresses';
import { PlatformToken } from 'constants/tokens';

import { DepositVault } from '../abis/DepositVault';

export const useDeposit = (
  inputAmount: ethers.BigNumber,
  inputToken: string,
  depositToken: PlatformToken,
) => {
  const { chain } = useNetwork();
  const addresses = getAddressesByChain(chain?.network);
  const vaultAddress =
    depositToken === PlatformToken.MTBILL
      ? addresses.mtbillDepositVault
      : addresses.mbasisDepositVault;

  const { data, status, write, writeAsync, isLoading, isSuccess } = useContractWrite({
    abi: DepositVault.abi,
    address: vaultAddress,
    args: [inputToken as `0x${string}`, BigInt(inputAmount.toString())],
    functionName: 'deposit',
  });

  const {
    isLoading: isLoadingWait,
    isSuccess: isSuccessWait,
    error,
  } = useWaitForTransaction({
    hash: data?.hash,
    enabled: Boolean(data?.hash),
    confirmations: 1,
  });

  return {
    data,
    status,
    write,
    writeAsync,
    isLoading: isLoadingWait || isLoading,
    isSuccess: isSuccessWait && isSuccess,
    error,
  };
};
