import { successToast, txErrorToast } from 'toasts';

import copyIcon from '../../assets/imgs/new/icons/copy_simple.svg';
import paperPlaneIcon from '../../assets/imgs/new/icons/paper_plane.svg';

export const GetAssistance = () => {
  const handleCopyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      successToast(`Email copied to clipboard`, {
        position: 'bottom-right',
      });
    } catch (err) {
      console.error('Error copying email: ', err);
      txErrorToast('Error copying address', {
        position: 'bottom-right',
      });
    }
  };

  return (
    <div className="mt-8 flex items-center gap-6 md:gap-4">
      <p className="text-sm leading-6 text-[#14223D] md:text-base">
        Get assistance via team@midas.app
      </p>
      <button onClick={() => handleCopyToClipboard('team@midas.app')}>
        <img src={copyIcon} alt="copy" />
      </button>
      <a href="mailto:team@midas.app">
        <img src={paperPlaneIcon} alt="message" />
      </a>
    </div>
  );
};
