import type { ethers } from 'ethers';
import { useContractWrite, useNetwork, useWaitForTransaction } from 'wagmi';

import { getAddressesByChain } from 'constants/addresses';

import { RedemptionVault } from '../abis/RedemptionVault';

export const useRedeem = (
  inputStUsdAmount: ethers.BigNumber,
  outputToken = '0xF55588f2f8CF8E1D9C702D169AF43c15f5c85f12',
) => {
  const { chain } = useNetwork();

  const { data, status, write, writeAsync, isLoading, isSuccess, reset } = useContractWrite({
    abi: RedemptionVault.abi,
    address: getAddressesByChain(chain?.network).mtbillRedemptionVault,
    args: [outputToken as `0x${string}`, BigInt(inputStUsdAmount.toString())],
    functionName: 'redeem',
  });
  const {
    isLoading: isLoadingWait,
    isSuccess: isSuccessWait,
    error,
  } = useWaitForTransaction({
    hash: data?.hash,
    enabled: Boolean(data?.hash),
    confirmations: 1,
  });

  return {
    data,
    reset,
    status,
    write,
    writeAsync,
    isLoading: isLoadingWait || isLoading,
    isSuccess: isSuccessWait && isSuccess,
    error,
  };
};
