import { useMemo } from 'react';
import { getAddress } from 'viem';
import { useNetwork } from 'wagmi';

import { useDefaultSubgraphQuery } from './useDefaultSubgraphQuery';

import { getAddressesByChain } from '../constants/addresses';
import { getExplorerByNetwork } from '../constants/explorers';
import { getTokenByName } from '../constants/tokens';
import { GET_DEPOSIT_VAULT_TOKEN_WITHDRAWALS_AND_DEPOSIT } from '../graphql/queries.js/transactions';
import { comaFormat } from '../utils/comaFormat';
import { txToExplorerUri } from '../utils/explorer';
import { formatBN } from '../utils/formatBN';

type DepositVaultWithdrawal = {
  id: string;
  caller: `0x${string}`;
  token: `0x${string}`;
  withdrawTo: `0x${string}`;
  amount: `${number}`;
  timestamp: `${number}`;
  transactionHash: `0x${string}`;
};

type DepositVaultDeposit = {
  id: string;
  from: `0x${string}`;
  sender: `0x${string}`;
  amount: `${number}`;
  timestamp: `${number}`;
  transactionHash: `0x${string}`;
};

type DepositVaultWithdrawalsAndDepositsQuery = {
  withdrawTokenEntityDeposits: DepositVaultWithdrawal[];
  usdctransfers: DepositVaultDeposit[];
};

type DepositVaultWithdrawalOrDeposit = {
  id: string;
  type: 'Withdraw' | 'Deposit';
  caller: `0x${string}`;
  recipient: `0x${string}`;
  amount: string;
  explorerLink: string;
  date: Date;
};

export const useDepositVaultWithdrawalsAndDeposits = () => {
  const { chain } = useNetwork();
  const { usdc, depositVault } = useMemo(
    () => ({
      usdc: getTokenByName(chain?.network, 'USDC'),
      depositVault: getAddress(getAddressesByChain(chain?.network).mtbillDepositVault),
    }),
    [chain],
  );
  const { data: rawData, refetch } =
    useDefaultSubgraphQuery<DepositVaultWithdrawalsAndDepositsQuery>(
      GET_DEPOSIT_VAULT_TOKEN_WITHDRAWALS_AND_DEPOSIT,
    );

  const { withdrawTokenEntityDeposits, usdctransfers } = rawData ?? {
    withdrawTokenEntityDeposits: [],
    usdctransfers: [],
  };

  const [data, total] = useMemo(
    () => [
      withdrawTokenEntityDeposits
        .map<DepositVaultWithdrawalOrDeposit>(v => ({
          id: v.id,
          type: 'Withdraw',
          caller: getAddress(v.caller),
          recipient: getAddress(v.withdrawTo),
          amount: comaFormat(formatBN(v.amount, 0, usdc.decimals)),
          explorerLink: txToExplorerUri(getExplorerByNetwork(chain?.name), v.transactionHash),
          date: new Date(+v.timestamp * 1000),
        }))
        .concat(
          usdctransfers.map<DepositVaultWithdrawalOrDeposit>(v => ({
            id: v.id,
            type: 'Deposit',
            caller: getAddress(v.from),
            recipient: depositVault,
            amount: comaFormat(formatBN(v.amount, 0, usdc.decimals)),
            explorerLink: txToExplorerUri(getExplorerByNetwork(chain?.name), v.transactionHash),
            date: new Date(+v.timestamp * 1000),
          })),
        )
        .sort((a, b) => b.date.getTime() - a.date.getTime()),
      comaFormat(formatBN(BigInt(0), 0, usdc.decimals)),
    ],
    [withdrawTokenEntityDeposits, usdctransfers],
  );

  return { data, total, refetch };
};
