import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useGetMtbillEtf } from 'hooks/data/useGetMtbillEtf';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useMemo } from 'react';
import { cn } from 'utils/cn';

type Kpi = { kpi: string; value: string };
const columnHelper = createColumnHelper<Kpi>();

export const ETFDetails = () => {
  const isMobile = useMediaQuery('only screen and (max-width: 640px)');
  const { data: etf } = useGetMtbillEtf();

  const { data, columns, lastUpdatedAt } = useMemo(() => {
    if (!etf) return { data: [], lastUpdatedAt: '', columns: [] };

    const {
      creditQuality,
      lastUpdatedAt: lastUpdatedAtRaw,
      liquidity,
      standardDeviation,
      totalAssets,
      weightedAvgYieldToMaturity,
      weightedAvgMaturity,
    } = etf;

    const lastUpdatedAt = new Date(lastUpdatedAtRaw).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

    return {
      data: [
        {
          kpi: 'Weighted Avg yield to Maturity',
          value: `${weightedAvgYieldToMaturity}%`,
        },
        {
          kpi: 'Total Assets',
          value: `$${totalAssets}b`,
        },
        {
          kpi: 'Liquidity',
          value: `$${liquidity}m`,
        },
        {
          kpi: 'Weighted Average Maturity',
          value: `${weightedAvgMaturity} years`,
        },
        {
          kpi: 'Credit Quality (S&P)',
          value: creditQuality,
        },
        {
          kpi: 'Standard Deviation (Annualized)',
          value: `${standardDeviation}%`,
        },
      ],
      lastUpdatedAt,
      columns: [
        columnHelper.accessor('kpi', {
          header: 'ETF Product Page',
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          link: 'https://www.blackrock.com/uk/individual/products/307243/ishares-treasury-bond-0-1yr-ucits-etf',
        }),
        columnHelper.accessor('value', {
          header: 'ETF Fact Sheet',
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          link: 'https://www.blackrock.com/uk/literature/fact-sheet/ib01-ishares-treasury-bond-0-1yr-ucits-etf-fund-fact-sheet-en-gb.pdf',
        }),
      ],
    };
  }, [etf]);

  const table = useReactTable({ data, columns, getCoreRowModel: getCoreRowModel() });

  return (
    <div className="mt-[56px] flex w-full flex-col gap-4 md:mt-[80px]">
      <div className="flex flex-col gap-2 md:gap-4">
        <p className="text-2xl font-bold text-[#0C1C46] md:text-[40px] md:leading-[48px]">
          ETF Details
        </p>
        <p className="text-[16px] leading-[24px] text-[#14223D]">Last updated on: {lastUpdatedAt}</p>
      </div>
      <table className="border-collapse">
        {isMobile ? (
          <thead>
            <tr className="bg-[#EEFBF9]">
              <th className="rounded-tl-[16px] pb-3 pl-3 pt-7 text-start text-base font-medium text-[#14223D] md:py-[21px] md:pl-8 md:font-bold">
                <div className="flex items-center gap-1 text-nowrap">
                  ETF Product Page
                  <a
                    target="_blank"
                    href="https://www.blackrock.com/uk/individual/products/307243/ishares-treasury-bond-0-1yr-ucits-etf"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="rotate-[315deg]"
                    >
                      <path
                        d="M17.414 10.586L13.121 6.29303L11.707 7.70703L15 11L5 11L5 13L15 13L11.707 16.293L13.121 17.707L17.414 13.414C17.7889 13.039 17.9996 12.5304 17.9996 12C17.9996 11.4697 17.7889 10.9611 17.414 10.586Z"
                        fill="#0C1C46"
                      ></path>
                    </svg>
                  </a>
                </div>
              </th>
              <th className="rounded-tr-[16px]" />
            </tr>
            <tr className="bg-[#EEFBF9]">
              <th className="py-3 pl-3  text-start text-base font-medium text-[#14223D] md:py-[21px] md:font-bold">
                <div className="flex items-center gap-1 text-nowrap">
                  ETF Fact Sheet
                  <a
                    target="_blank"
                    href="https://www.blackrock.com/uk/literature/fact-sheet/ib01-ishares-treasury-bond-0-1yr-ucits-etf-fund-fact-sheet-en-gb.pdf"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="rotate-[315deg]"
                    >
                      <path
                        d="M17.414 10.586L13.121 6.29303L11.707 7.70703L15 11L5 11L5 13L15 13L11.707 16.293L13.121 17.707L17.414 13.414C17.7889 13.039 17.9996 12.5304 17.9996 12C17.9996 11.4697 17.7889 10.9611 17.414 10.586Z"
                        fill="#0C1C46"
                      ></path>
                    </svg>
                  </a>
                </div>
              </th>
              <th />
            </tr>
          </thead>
        ) : (
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id} className="bg-[#EEFBF9]">
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    className={cn(
                      'pb-3 pt-7 text-start text-base font-medium text-[#14223D] md:py-[21px] md:font-bold',
                      header.id === 'kpi' ? 'rounded-tl-[16px] pl-3 md:pl-8' : 'rounded-tr-[16px]',
                    )}
                  >
                    <div className="flex items-center gap-1">
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      <a
                        target="_blank"
                        href={
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          //@ts-ignore
                          header.column.columnDef.link
                        }
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="rotate-[315deg]"
                        >
                          <path
                            d="M17.414 10.586L13.121 6.29303L11.707 7.70703L15 11L5 11L5 13L15 13L11.707 16.293L13.121 17.707L17.414 13.414C17.7889 13.039 17.9996 12.5304 17.9996 12C17.9996 11.4697 17.7889 10.9611 17.414 10.586Z"
                            fill="#0C1C46"
                          ></path>
                        </svg>
                      </a>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        )}
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id} className="even:bg-[#F9F9F9]">
              {row.getVisibleCells().map(cell => (
                <td
                  key={cell.id}
                  className={`${
                    cell.column.id === 'kpi' ? 'pl-3 md:pl-8' : 'pr-3 max-sm:text-end md:pr-8'
                  } w-[50%] py-4 text-start text-sm text-[#14223D] md:py-6 md:text-base`}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
