import { gql } from '@apollo/client';

export const GET_USERS_WITH_ROLE = gql`
  query GetUsersWithRole($role: Bytes) {
    userRoles(where: { role: $role, has: true }) {
      id
      role
      user
      has
    }
  }
`;
