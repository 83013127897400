import type { PlatformToken } from 'constants/tokens';

export const NotUsBanner = ({ token }: { token: PlatformToken }) => {
  return (
    <div className="bg-[#fff]/50 py-3 shadow-[0px_4px_32px_0px_#131C2C0F] lg:mt-0">
      <div className="mx-auto flex max-w-[1320px] items-center justify-center">
        <p className="px-4 text-center text-[10px] leading-4 text-[#051C2C] sm:text-xs">
          Disclaimer: THE {token} TOKENS ARE NOT BEING OFFERED OR SOLD, AND WILL NOT BE OFFERED OR
          SOLD, IN THE UNITED STATES OR TO US PERSONS. In addition, the {token} Tokens have not been
          registered under the Securities Act of 1933, as amended (the “Act”) or the securities laws
          of any other jurisdiction, and may not be offered, sold or otherwise transferred in the
          United States or to US persons unless the securities are registered under the Act, or an
          exemption from the registration requirements of the Act is available.
        </p>
      </div>
    </div>
  );
};
