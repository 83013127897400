import { useMemo } from 'react';

import { PlatformToken } from 'constants/tokens';

import { useGetMbasisPrice } from './data/useGetMbasisPrice';
import { useGetMtbillPrice } from './data/useGetMtbillPrice';

export type PriceData = {
  price: string;
  date: Date;
};

export const usePriceData = (token: PlatformToken, timestampFrom: number, timestampTo: number) => {
  const { data: mbasisPrice, isLoading: mbasisLoading } = useGetMbasisPrice(
    timestampFrom,
    timestampTo,
  );
  const { data: mtbillprice, isLoading: mtbillLoading } = useGetMtbillPrice(
    timestampFrom,
    timestampTo,
  );

  const data: PriceData[] | null = useMemo(() => {
    const data = token === PlatformToken.MBASIS ? mbasisPrice : mtbillprice;
    if (!data) return null;

    const mapped = data
      .sort((a, b) => a.timestamp - b.timestamp)
      .map(({ price, timestamp }) => ({ price, date: new Date(timestamp * 1000) }));
    const grouped = mapped.reduce((acc, x) => {
      const date = x.date.toDateString();
      acc[date] = x;
      return acc;
    }, {} as Record<string, { price: string; date: Date }>);
    return Object.values(grouped);
  }, [token, mbasisPrice, mtbillprice]);

  return { data, isLoading: mbasisLoading || mtbillLoading };
};
