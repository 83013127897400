import { useMutation } from '@tanstack/react-query';

import type { NewKycPostRequest } from '../api/new-kyc';
import { postNewKyc } from '../api/new-kyc';

export const useNewKyc = () => {
  const { mutateAsync: sendNewKyc } = useMutation({
    mutationFn: (data: NewKycPostRequest) => postNewKyc(data),
  });

  return { sendNewKyc };
};
