import { useNavigate } from 'react-router-dom';

import type { IBlogpost } from './blog';

export function Blogpost({ blogpost }: { blogpost: IBlogpost }) {
  const navigate = useNavigate();

  return (
    <div
      className="w-full max-w-[550px] cursor-pointer"
      onClick={() => navigate(`/blog/${blogpost.id}`)}
    >
      <img
        className="mb-[15px] h-[308px] w-full rounded-[10px] object-cover shadow-3xl hover:shadow-green"
        src={blogpost.preview}
        alt={blogpost.title}
      />
      <time className="text-lg font-semibold text-text-600">{blogpost.date}</time>
      <p className="line-[36px] m-0 text-[25px] font-semibold text-stone-blue hover:text-text-700">
        {blogpost.title}
      </p>
    </div>
  );
}
