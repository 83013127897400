import { AxiosError } from 'axios';

import { api } from './client';

export const POST_CONTACT_US = '/contact-us';
export const POST_WAITLIST = '/waitlist';
export const POST_SUBSCRIBE_ON_UPDATES = '/subscribe-on-updates';

interface EmailRequest {
  email: string;
}

interface ContactUsRequest extends EmailRequest {
  name: string;
  message: string;
}

export const postContactUs = async (data: ContactUsRequest) => {
  const response = await api.post(POST_CONTACT_US, data);
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Unable to post contact us');
};

export const postWaitlist = async (data: EmailRequest) => {
  try {
    const response = await api.post(POST_WAITLIST, data);
    if (response.status === 200) {
      return response.data;
    }
    throw new Error('Unable to post waitlist');
  } catch (e) {
    if (e instanceof AxiosError && e.response?.data?.message === 'Already joined') {
      return;
    }
    throw e;
  }
};

export const postSubscribeOnUpdates = async (data: EmailRequest) => {
  try {
    const response = await api.post(POST_SUBSCRIBE_ON_UPDATES, data);
    if (response.status === 200) {
      return response.data;
    }
    throw new Error('Unable to post subscribe on updates');
  } catch (e) {
    if (e instanceof AxiosError && e.response?.data?.message === 'Already subscribed') {
      return;
    }
    throw e;
  }
};
