import { api } from './client';

export const GET_MTBILL_PRICE = '/data/mtbill/price';
export const GET_MBASIS_PRICE = '/data/mbasis/price';
export const GET_KPI = '/data/kpi';
export const GET_MTBILL_ETF = '/data/mtbill/etf';
export const GET_MBASIS_PORTFOLIO = '/data/mbasis/portfolio';

export interface GetPriceQuery {
  timestampFrom: number;
  timestampTo: number;
}

export type GetPriceResponse = {
  timestamp: number;
  price: string;
}[];

export const getMtbillPrice = async (params: GetPriceQuery) => {
  const response = await api.get(GET_MTBILL_PRICE, { params });
  if (response.status === 200) {
    return response.data as GetPriceResponse;
  }
  return [] as GetPriceResponse;
};

export const getMbasisPrice = async (params: GetPriceQuery) => {
  const response = await api.get(GET_MBASIS_PRICE, { params });
  if (response.status === 200) {
    return response.data as GetPriceResponse;
  }
  return [] as GetPriceResponse;
};

export interface GetKpiResponse {
  lastUpdatedAt: string;
  mBasisCurrentCapacity: string;
  mBasisAPY: string;
  mBasisAUM: string;
  mBasisMaxCapacity: string;
  mTbillAPY: string;
  mTbillAUM: string;
}

export interface GetKpiReturn {
  lastUpdatedAt: Date;
  mBasisCurrentCapacity: number;
  mBasisAPY: string;
  mBasisAUM: number;
  mBasisMaxCapacity: number;
  mTbillAPY: string;
  mTbillAUM: number;
}

const parsePercents = (value: string) => ((parseFloat(value || '0') || 0) * 100).toFixed(2);

export const getKpi = async (): Promise<GetKpiReturn> => {
  const response = await api.get(GET_KPI);
  if (response.status === 200) {
    const data = response.data as GetKpiResponse;
    return {
      lastUpdatedAt: new Date(data.lastUpdatedAt),
      mBasisCurrentCapacity: parseFloat(data.mBasisCurrentCapacity),
      mBasisAPY: parsePercents(data.mBasisAPY),
      mBasisAUM: parseFloat(data.mBasisAUM),
      mBasisMaxCapacity: parseFloat(data.mBasisMaxCapacity),
      mTbillAPY: parsePercents(data.mTbillAPY),
      mTbillAUM: parseFloat(data.mTbillAUM),
    };
  }
  return {
    lastUpdatedAt: new Date(),
    mBasisCurrentCapacity: 0,
    mBasisAPY: '0.00',
    mBasisAUM: 0,
    mBasisMaxCapacity: 0,
    mTbillAPY: '0.00',
    mTbillAUM: 0,
  };
};

export interface GetMtbillEtfResponse {
  lastUpdatedAt: string;
  creditQuality: string;
  liquidity: string;
  standardDeviation: string;
  weightedAvgYieldToMaturity: string;
  weightedAvgMaturity: number;
  totalAssets: string;
}

export interface GetMtbillEtfReturn {
  lastUpdatedAt: string;
  creditQuality: string;
  liquidity: number;
  standardDeviation: string;
  weightedAvgYieldToMaturity: string;
  weightedAvgMaturity: number;
  totalAssets: number;
}

export const getMtbillEtf = async (): Promise<GetMtbillEtfReturn> => {
  const response = await api.get(GET_MTBILL_ETF);
  if (response.status === 200) {
    const data = response.data as GetMtbillEtfResponse;
    return {
      lastUpdatedAt: data.lastUpdatedAt,
      creditQuality: data.creditQuality,
      liquidity: parseFloat(data.liquidity),
      standardDeviation: parsePercents(data.standardDeviation),
      weightedAvgYieldToMaturity: parsePercents(data.weightedAvgYieldToMaturity),
      weightedAvgMaturity: data.weightedAvgMaturity ?? 0,
      totalAssets: parseFloat(data.totalAssets),
    };
  }
  return {
    lastUpdatedAt: '',
    creditQuality: '',
    liquidity: 0,
    standardDeviation: '0.00',
    weightedAvgYieldToMaturity: '0.00',
    weightedAvgMaturity: 0,
    totalAssets: 0,
  };
};

export type GetMbasisPortfolioResponse = {
  token: string;
  exchanger: string;
  collateralValueNotional: string;
  shortPositionNotional: string;
}[];

export type MbasisPositions = Record<string, Record<string, GetMbasisPortfolioResponse[number]>>;

export interface GetMbasisPortfolioReturn {
  distinctExchangers: string[];
  positions: MbasisPositions;
}

export const getMbasisPortfolio = async (): Promise<GetMbasisPortfolioReturn> => {
  const response = await api.get(GET_MBASIS_PORTFOLIO);
  if (response.status === 200) {
    const data = response.data as GetMbasisPortfolioResponse;
    const distinctExchangers = Array.from(new Set(data.map(x => x.exchanger)));
    const positions: GetMbasisPortfolioReturn['positions'] = {};
    for (const exchanger of distinctExchangers) {
      positions[exchanger] = data
        .filter(x => x.exchanger === exchanger)
        .reduce((acc, x) => {
          acc[x.token] = x;
          return acc;
        }, {} as Record<string, GetMbasisPortfolioResponse[number]>);
    }
    return { distinctExchangers, positions };
  }
  return { distinctExchangers: [], positions: {} };
};
