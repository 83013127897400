import axelar from 'assets/imgs/companies-gray/axelar.svg';
import blocktower from 'assets/imgs/companies-gray/blocktower.svg';
import cathay from 'assets/imgs/companies-gray/cathay.svg';
import fjLabs from 'assets/imgs/companies-gray/fjLabs.svg';
import framework from 'assets/imgs/companies-gray/framework.svg';
import gsr from 'assets/imgs/companies-gray/gsr.svg';
import hack from 'assets/imgs/companies-gray/hack.svg';
import hv from 'assets/imgs/companies-gray/hv.svg';
import peer from 'assets/imgs/companies-gray/peer.svg';
import sixV from 'assets/imgs/companies-gray/sixV.svg';
import theia from 'assets/imgs/companies-gray/theia.svg';
import cVentures from 'assets/imgs/companies-gray/ventures.svg';
import axelarMobile from 'assets/imgs/companies-gray-mobile/axelar.svg';
import blocktowerMobile from 'assets/imgs/companies-gray-mobile/blocktower.svg';
import cathayMobile from 'assets/imgs/companies-gray-mobile/cathay.svg';
import fjLabsMobile from 'assets/imgs/companies-gray-mobile/fjLabs.svg';
import frameworkMobile from 'assets/imgs/companies-gray-mobile/framework.svg';
import gsrMobile from 'assets/imgs/companies-gray-mobile/gsr.svg';
import hackMobile from 'assets/imgs/companies-gray-mobile/hack.svg';
import hvMobile from 'assets/imgs/companies-gray-mobile/hv.svg';
import peerMobile from 'assets/imgs/companies-gray-mobile/peer.svg';
import sixVMobile from 'assets/imgs/companies-gray-mobile/sixV.svg';
import theiaMobile from 'assets/imgs/companies-gray-mobile/theia.svg';
import cVenturesMobile from 'assets/imgs/companies-gray-mobile/ventures.svg';

import { useMediaQuery } from '../hooks/useMediaQuery';

const companies: string[] = [
  blocktower,
  framework,
  sixV,
  fjLabs,
  axelar,
  peer,
  cathay,
  cVentures,
  hv,
  theia,
  hack,
  gsr,
];

const companiesMobile: string[] = [
  blocktowerMobile,
  frameworkMobile,
  sixVMobile,
  fjLabsMobile,
  axelarMobile,
  peerMobile,
  cathayMobile,
  cVenturesMobile,
  hvMobile,
  theiaMobile,
  hackMobile,
  gsrMobile,
];

export const TvlCompanies = () => {
  const isMobile = useMediaQuery('only screen and (max-width: 640px)');

  return (
    <section className="relative mt-[64px] flex flex-col items-center justify-between gap-6 lg:mt-[120px] xl:flex-row xl:gap-[50px]">
      <div className="text-[24px] font-medium text-[#A1A1A1] max-lg:text-[12px]">
        Backed&nbsp;by&nbsp;the&nbsp;best:
      </div>
      <div className="grid w-full grid-cols-6 grid-rows-2 place-items-center items-center justify-center gap-x-[25px] gap-y-[12px] lg:gap-x-[60px] lg:gap-y-10 lg:px-0">
        {(isMobile ? companiesMobile : companies).map((company, index) => (
          <img
            className="max-lg:max-h-[28px]"
            style={{ filter: 'grayscale(100%)' }}
            src={company}
            alt={`company-${index}`}
            key={`company-${index}`}
          />
        ))}
      </div>
    </section>
  );
};
