import { ethers } from 'ethers';
import { isAddress, parseUnits } from 'ethers/lib/utils.js';
import { useBurnStUsd } from 'hooks/useBurnStUsd';
import { useMintStUsd } from 'hooks/useMintStUsd';
import Aside from 'layouts/adminTab';
import Header from 'layouts/headerAdminTab';
import { useEffect, useState } from 'react';
import { successToast, txErrorToast } from 'toasts';
import { useConfig } from 'wagmi';

import { NotificationModal } from '../../components/NotificationModal';
import { getHumanError, isCustomError } from '../../utils/errors';

interface AmountInputProps {
  value: string;
  setValue: (value: string) => void;
}

const AmountInput = ({ value, setValue }: AmountInputProps) => {
  return (
    <input
      type="number"
      id="amount"
      value={value}
      onChange={ev => {
        const valReplaced = ev.target.value.replace(',', '.') || '0';

        setValue(valReplaced);
      }}
    />
  );
};
export const Stusd = () => {
  const [type, setType] = useState<'burn' | 'mint'>('burn');

  const [amount, setAmount] = useState('0');
  const [amountParsed, setAmountParsed] = useState(ethers.constants.Zero);

  useEffect(() => {
    setAmountParsed(parseUnits(amount));
  }, [amount]);

  const [wallet, setWallet] = useState(ethers.constants.AddressZero);
  const { publicClient } = useConfig();
  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [isModalOpened, setIsModalOpened] = useState(false);
  const closeModal = () => setIsModalOpened(false);

  const { burnAsync } = useBurnStUsd();
  const { mintAsync } = useMintStUsd();

  const onClickBaseCallback = async () => {
    setIsLoading(true);
    if (!isAddress(wallet) || wallet === ethers.constants.AddressZero) {
      txErrorToast('Invalid wallet address provided');
      return;
    }

    if (amountParsed.eq(ethers.constants.Zero)) {
      txErrorToast('Invalid amount provided');
      return;
    }

    try {
      await (type === 'burn' ? burnAsync : mintAsync)({
        args: [wallet as `0x${string}`, BigInt(amountParsed.toString())],
      }).then(publicClient.waitForTransactionReceipt);
      successToast('Transaction sent successfully');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      const error = getHumanError(e.shortMessage);
      setErrorText(
        `Error while sending transaction: ${isCustomError(error) ? e.shortMessage : error}`,
      );
      setIsModalOpened(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="admin">
      <NotificationModal
        isModalOpened={isModalOpened}
        closeModal={closeModal}
        onBtnClick={closeModal}
        content={{
          bodyContent: errorText,
          headerContent: <>Sorry.</>,
          btnContent: 'Close',
        }}
      />
      <Aside />
      <section>
        <Header />
        <div>
          <h5>
            mTBILL<span style={{ color: '#1127E3' }}>.</span>
          </h5>
          <div className="action__crypto">
            <div className="tabs">
              <div onClick={() => setType('mint')} className={type === 'mint' ? 'active' : ''}>
                Mint
              </div>
              <div onClick={() => setType('burn')} className={type === 'burn' ? 'active' : ''}>
                Burn
              </div>
            </div>
            {(() => {
              if (type === 'burn') {
                return (
                  <div className="body">
                    <div className="form-control">
                      <label htmlFor="amount">select amount</label>
                      <AmountInput value={amount} setValue={setAmount} />
                      <span>mTBILL</span>
                    </div>
                    <div className="form-control">
                      <label htmlFor="id">wallet ID</label>
                      <input
                        id="id"
                        value={wallet}
                        onChange={ev => {
                          setWallet(ev.target.value);
                        }}
                      />
                    </div>
                    <button onClick={onClickBaseCallback} disabled={isLoading}>
                      {isLoading ? 'Processing...' : 'Burn Token'}
                    </button>
                  </div>
                );
              } else {
                return (
                  <div className="body">
                    <div className="form-control">
                      <label htmlFor="amount">select amount</label>
                      <AmountInput value={amount} setValue={setAmount} />

                      <span>mTBILL</span>
                    </div>
                    <div className="form-control">
                      <label htmlFor="id">recipient wallet ID</label>
                      <input
                        id="id"
                        value={wallet}
                        onChange={ev => {
                          setWallet(ev.target.value);
                        }}
                      />
                    </div>
                    <button onClick={onClickBaseCallback} disabled={isLoading}>
                      {isLoading ? 'Processing...' : 'Mint Token'}
                    </button>
                  </div>
                );
              }
            })()}
          </div>
        </div>
      </section>
    </div>
  );
};
