import React from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';

import close from '../../assets/imgs/close 1.svg';
import { ROUTES } from '../../routes/config';

export interface NotificationModalProps {
  isModalOpened: boolean;
  closeModal: () => void;
  onBtnClick: () => void;
  content: {
    headerContent: React.ReactNode;
    bodyContent: React.ReactNode;
    btnContent: React.ReactNode;
  };
  maxWidth?: string;
  maxHeight?: string;
}

export const NotificationModal = ({
  isModalOpened,
  closeModal,
  onBtnClick,
  content: { headerContent, bodyContent, btnContent },
  maxWidth,
  maxHeight,
}: NotificationModalProps) => {
  const navigate = useNavigate();
  return (
    <>
      <Modal
        isOpen={isModalOpened}
        ariaHideApp={false}
        style={{
          content: {
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: maxWidth || '673px',
            maxHeight: maxHeight || '372px',
            borderRadius: '24px',
            border: 'none',
            height: 'fit-content',
            paddingBottom: '20px',
          },
          overlay: {
            zIndex: 999999,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          },
        }}
      >
        <div className="modal error-green-list">
          <img
            src={close}
            alt="close"
            onClick={closeModal}
            className="close-modal absolute cursor-pointer"
          />
          <h1 className="text-center !text-black">{headerContent}</h1>

          {bodyContent && <p className="!text-black">{bodyContent}</p>}

          <div className="relative mt-4 flex w-full justify-end">
            {btnContent && (
              <>
                <div>
                  <button
                    className="absolute left-[50%] top-0 cursor-pointer rounded-[40px] border-[1px] border-black bg-black px-[16px] py-[8px] text-[16px] font-medium text-white transition-all hover:bg-white hover:text-black"
                    onClick={onBtnClick}
                    style={{ transform: 'translateX(-50%)' }}
                  >
                    {btnContent}
                  </button>
                </div>
                {btnContent !== 'Contact Us' && (
                  <div>
                    <button
                      className="ml-auto cursor-pointer rounded-[40px] border-[1px] border-black bg-white px-[16px] py-[8px] text-[16px] font-medium text-black transition-all hover:bg-black hover:text-white"
                      onClick={() => navigate(ROUTES.CONTACT_US)}
                    >
                      Contact Us
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};
