import { Link } from 'react-router-dom';
import { dateToShorten } from 'utils/date';
import { shortenAddress } from 'utils/shortenAddress';
import { useNetwork } from 'wagmi';

import { getTokenByAddress } from 'constants/tokens';

interface TransactionsTableProps {
  title: string;
  parsedTxData: {
    id: string;
    mToken: string;
    wallet: string;
    transactionType: string;
    asset: string;
    assetName: string;
    investment: string;
    date: Date;
    exchangeRatio: number;
    explorerLink: string;
    fee: string;
  }[];
  sumRow?: { fundingTotal: string; proceedsTotal: string };
}

export const TransactionsTable = ({ title, parsedTxData, sumRow }: TransactionsTableProps) => {
  const { chain } = useNetwork();
  return (
    <div className="content-table">
      <p className="name-table">{title}</p>
      <div className="table-info">
        <div className="head-table">
          <div className="name-cell">
            <p>Date</p>
          </div>
          <div className="name-cell">
            <p>Token</p>
          </div>
          <div className="name-cell">
            <p>Wallet</p>
          </div>
          <div className="name-cell">
            <p>Transaction</p>
          </div>
          <div className="name-cell">
            <p>Assets</p>
          </div>
          <div className="name-cell">
            <p>Etherscan</p>
          </div>
        </div>

        {parsedTxData.map(txData => (
          <div key={`${txData.id}-${txData.transactionType}`} className="table-row">
            <div className="table-cell">
              <p>{dateToShorten(txData.date)}</p>
            </div>
            <div className="table-cell">
              <p>
                {chain && txData.mToken
                  ? getTokenByAddress(chain?.network, txData.mToken)?.name
                  : '-'}
              </p>
            </div>
            <div className="table-cell">
              <p>{shortenAddress(txData.wallet)}</p>
            </div>
            <div className="table-cell">
              <p>{txData.transactionType}</p>
            </div>
            <div className="table-cell">
              <p>
                {txData.asset} {txData.assetName}
              </p>
            </div>
            <div className="table-cell">
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={txData.explorerLink}
                className="item-menu !text-black"
              >
                Link
              </Link>
            </div>
          </div>
        ))}

        {sumRow && Boolean(parsedTxData.length) && (
          <div className="last-row">
            <div className="table-cell-invest">
              <p></p>
            </div>
            <div className="table-cell-invest">
              <p></p>
            </div>
            <div className="table-cell-invest">
              <p></p>
            </div>
            <div className="table-cell-invest">
              <p>{sumRow.fundingTotal ?? ''}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
