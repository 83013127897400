import { useContractWrite, useNetwork } from 'wagmi';

import { getAddressesByChain } from 'constants/addresses';
import { acRoles } from 'constants/roles';

import { MidasAccessControl } from '../abis/MidasAccessControl';

export const useBlackListUser = () => {
  const { chain } = useNetwork();

  const { writeAsync: blackListAsync } = useContractWrite({
    abi: MidasAccessControl.abi,
    address: getAddressesByChain(chain?.network).ac,
    functionName: 'grantRole',
  });

  const { writeAsync: revokeBlackListAsync } = useContractWrite({
    abi: MidasAccessControl.abi,
    address: getAddressesByChain(chain?.network).ac,
    functionName: 'revokeRole',
  });

  return {
    blackListAsync,
    revokeBlackListAsync,
    role: acRoles.blackListed,
  };
};
