const initialState = {
  isMetaMaskInstalled: false,
  isCoinbaseInstalled: false,
  isAuthenticated: false,
  ethereumAddress: sessionStorage.getItem('WalletAddress') || '',
  network: sessionStorage.getItem('network') || false,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const authReducer = (state = initialState, action: { type: string; payload?: any }) => {
  switch (action.type) {
    case 'SET_METAMASK_INSTALLED':
      return {
        ...state,
        isMetaMaskInstalled: action.payload,
      };
    case 'SET_COINBASE_INSTALLED':
      return {
        ...state,
        isCoinbaseInstalled: action.payload,
      };
    case 'SET_AUTHENTICATED':
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case 'SET_ETHEREUM_ADDRESS':
      sessionStorage.setItem('WalletAddress', action.payload);
      return {
        ...state,
        ethereumAddress: action.payload,
      };
    case 'SET_COINBASE_ADDRESS':
      sessionStorage.setItem('WalletAddress', action.payload);
      return {
        ...state,
        ethereumAddress: action.payload,
      };
    case 'SET_CUSTOM_ADDRESS':
      sessionStorage.setItem('WalletAddress', action.payload);
      return {
        ...state,
        isAuthenticated: true,
        ethereumAddress: action.payload,
      };
    case 'SET_NETWORK':
      sessionStorage.setItem('network', action.payload);
      return {
        ...state,
        network: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
