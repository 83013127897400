import React from 'react';
import { InlineWidget } from 'react-calendly';

export const BookADemo = () => {
  return (
    <main className="works mb-[200px]">
      <div className="block !pt-[100px]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1320"
          height="939"
          viewBox="0 0 1320 939"
          fill="none"
        >
          <path
            d="M282.315 961.548C342.093 1066.39 385.16 1167.58 406.473 1246.43C417.13 1285.86 422.339 1319.67 421.493 1345.55C420.645 1371.46 413.734 1389.24 400.349 1396.88C386.964 1404.51 368.14 1401.4 345.41 1388.93C322.709 1376.48 296.262 1354.78 267.757 1325.53C210.752 1267.03 145.599 1178.43 85.8203 1073.59C26.0421 968.753 -17.0254 867.56 -38.3377 788.709C-48.9949 749.28 -54.2044 715.466 -53.3576 689.588C-52.5097 663.678 -45.5993 645.894 -32.2143 638.262C-18.8294 630.63 -0.00542131 633.74 22.7249 646.206C45.4261 658.656 71.8733 680.361 100.378 709.613C157.383 768.111 222.536 856.712 282.315 961.548Z"
            stroke="white"
            strokeOpacity="0.15"
          />
          <path
            d="M317.402 941.542C377.18 1046.38 420.247 1147.57 441.56 1226.42C452.217 1265.85 457.426 1299.67 456.579 1325.54C455.732 1351.45 448.821 1369.24 435.436 1376.87C422.051 1384.5 403.227 1381.39 380.497 1368.93C357.796 1356.48 331.349 1334.77 302.844 1305.52C245.839 1247.02 180.685 1158.42 120.907 1053.58C61.129 948.747 18.0615 847.554 -3.25078 768.703C-13.908 729.274 -19.1175 695.46 -18.2707 669.583C-17.4228 643.672 -10.5123 625.888 2.87259 618.256C16.2575 610.624 35.0815 613.734 57.8118 626.2C80.513 638.65 106.96 660.355 135.465 689.607C192.47 748.105 257.623 836.706 317.402 941.542Z"
            stroke="white"
            strokeOpacity="0.15"
          />
          <path
            d="M352.489 921.535C412.267 1026.37 455.334 1127.57 476.646 1206.42C487.304 1245.85 492.513 1279.66 491.666 1305.54C490.819 1331.45 483.908 1349.23 470.523 1356.86C457.138 1364.49 438.314 1361.38 415.584 1348.92C392.883 1336.47 366.436 1314.76 337.93 1285.51C280.926 1227.01 215.772 1138.41 155.994 1033.58C96.2159 928.74 53.1485 827.546 31.8361 748.695C21.179 709.266 15.9694 675.452 16.8162 649.575C17.6641 623.665 24.5746 605.881 37.9595 598.249C51.3444 590.617 70.1684 593.726 92.8987 606.193C115.6 618.643 142.047 640.347 170.552 669.599C227.557 728.098 292.71 816.698 352.489 921.535Z"
            stroke="white"
            strokeOpacity="0.15"
          />
          <path
            d="M387.575 901.529C447.354 1006.37 490.421 1107.56 511.733 1186.41C522.391 1225.84 527.6 1259.65 526.753 1285.53C525.905 1311.44 518.995 1329.22 505.61 1336.86C492.225 1344.49 473.401 1341.38 450.671 1328.91C427.97 1316.46 401.522 1294.76 373.017 1265.51C316.013 1207.01 250.859 1118.41 191.081 1013.57C131.303 908.734 88.2354 807.54 66.923 728.689C56.2659 689.26 51.0563 655.446 51.9031 629.569C52.751 603.659 59.6615 585.875 73.0464 578.243C86.4313 570.611 105.255 573.72 127.986 586.187C150.687 598.637 177.134 620.341 205.639 649.593C262.644 708.092 327.797 796.692 387.575 901.529Z"
            stroke="white"
            strokeOpacity="0.15"
          />
          <path
            d="M422.662 881.521C482.441 986.358 525.508 1087.55 546.82 1166.4C557.478 1205.83 562.687 1239.65 561.84 1265.52C560.992 1291.43 554.082 1309.22 540.697 1316.85C527.312 1324.48 508.488 1321.37 485.758 1308.91C463.057 1296.46 436.609 1274.75 408.104 1245.5C351.1 1187 285.946 1098.4 226.168 993.563C166.39 888.726 123.322 787.532 102.01 708.682C91.3528 669.253 86.1432 635.439 86.99 609.561C87.8379 583.651 94.7484 565.867 108.133 558.235C121.518 550.603 140.342 553.713 163.073 566.179C185.774 578.629 212.221 600.333 240.726 629.586C297.731 688.084 362.884 776.685 422.662 881.521Z"
            stroke="white"
            strokeOpacity="0.15"
          />
          <path
            d="M457.749 861.515C517.527 966.352 560.595 1067.55 581.907 1146.4C592.564 1185.83 597.774 1219.64 596.927 1245.52C596.079 1271.43 589.169 1289.21 575.784 1296.84C562.399 1304.48 543.575 1301.37 520.845 1288.9C498.143 1276.45 471.696 1254.74 443.191 1225.49C386.187 1166.99 321.033 1078.39 261.255 973.557C201.477 868.72 158.409 767.527 137.097 688.676C126.44 649.247 121.23 615.433 122.077 589.555C122.925 563.645 129.835 545.861 143.22 538.229C156.605 530.597 175.429 533.707 198.159 546.173C220.861 558.623 247.308 580.327 275.813 609.58C332.818 668.078 397.971 756.679 457.749 861.515Z"
            stroke="white"
            strokeOpacity="0.15"
          />
          <path
            d="M492.836 841.509C552.614 946.346 595.682 1047.54 616.994 1126.39C627.651 1165.82 632.861 1199.63 632.014 1225.51C631.166 1251.42 624.256 1269.2 610.871 1276.84C597.486 1284.47 578.662 1281.36 555.932 1268.89C533.23 1256.44 506.783 1234.74 478.278 1205.49C421.273 1146.99 356.12 1058.39 296.342 953.551C236.564 848.714 193.496 747.521 172.184 668.67C161.527 629.241 156.317 595.427 157.164 569.549C158.012 543.639 164.922 525.855 178.307 518.223C191.692 510.591 210.516 513.701 233.246 526.167C255.948 538.617 282.395 560.322 310.9 589.574C367.904 648.072 433.058 736.673 492.836 841.509Z"
            stroke="white"
            strokeOpacity="0.15"
          />
          <path
            d="M527.923 821.502C587.701 926.339 630.769 1027.53 652.081 1106.38C662.738 1145.81 667.948 1179.63 667.101 1205.5C666.253 1231.41 659.343 1249.2 645.958 1256.83C632.573 1264.46 613.749 1261.35 591.019 1248.89C568.317 1236.44 541.87 1214.73 513.365 1185.48C456.36 1126.98 391.207 1038.38 331.429 933.543C271.65 828.707 228.583 727.513 207.271 648.662C196.614 609.233 191.404 575.419 192.251 549.542C193.099 523.631 200.009 505.848 213.394 498.216C226.779 490.584 245.603 493.693 268.333 506.159C291.034 518.61 317.482 540.314 345.987 569.566C402.991 628.065 468.145 716.665 527.923 821.502Z"
            stroke="white"
            strokeOpacity="0.15"
          />
          <path
            d="M563.009 801.496C622.787 906.333 665.855 1007.53 687.167 1086.38C697.824 1125.81 703.034 1159.62 702.187 1185.5C701.339 1211.41 694.429 1229.19 681.044 1236.82C667.659 1244.46 648.835 1241.35 626.104 1228.88C603.403 1216.43 576.956 1194.73 548.451 1165.47C491.446 1106.97 426.293 1018.37 366.515 913.537C306.736 808.701 263.669 707.507 242.357 628.656C231.699 589.227 226.49 555.413 227.337 529.536C228.185 503.625 235.095 485.842 248.48 478.21C261.865 470.578 280.689 473.687 303.419 486.153C326.12 498.604 352.568 520.308 381.073 549.56C438.077 608.059 503.231 696.659 563.009 801.496Z"
            stroke="white"
            strokeOpacity="0.15"
          />
          <path
            d="M598.096 781.49C657.874 886.327 700.942 987.52 722.254 1066.37C732.911 1105.8 738.121 1139.61 737.274 1165.49C736.426 1191.4 729.515 1209.19 716.131 1216.82C702.746 1224.45 683.922 1221.34 661.191 1208.87C638.49 1196.42 612.043 1174.72 583.538 1145.47C526.533 1086.97 461.38 998.368 401.602 893.531C341.823 788.695 298.756 687.501 277.444 608.65C266.786 569.221 261.577 535.407 262.424 509.53C263.272 483.619 270.182 465.836 283.567 458.204C296.952 450.572 315.776 453.681 338.506 466.147C361.207 478.598 387.655 500.302 416.16 529.554C473.164 588.053 538.318 676.653 598.096 781.49Z"
            stroke="white"
            strokeOpacity="0.15"
          />
          <path
            d="M633.183 761.482C692.961 866.319 736.029 967.513 757.341 1046.36C767.998 1085.79 773.208 1119.61 772.361 1145.48C771.513 1171.39 764.602 1189.18 751.217 1196.81C737.833 1204.44 719.009 1201.33 696.278 1188.87C673.577 1176.42 647.13 1154.71 618.625 1125.46C561.62 1066.96 496.467 978.361 436.688 873.524C376.91 768.687 333.843 667.493 312.53 588.643C301.873 549.214 296.664 515.4 297.511 489.522C298.358 463.612 305.269 445.828 318.654 438.196C332.039 430.564 350.863 433.674 373.593 446.14C396.294 458.59 422.741 480.294 451.246 509.547C508.251 568.045 573.405 656.645 633.183 761.482Z"
            stroke="white"
            strokeOpacity="0.15"
          />
          <path
            d="M1054.22 521.404C1114 626.241 1157.07 727.435 1178.38 806.285C1189.04 845.714 1194.25 879.528 1193.4 905.406C1192.55 931.316 1185.64 949.1 1172.26 956.732C1158.87 964.364 1140.05 961.254 1117.32 948.788C1094.62 936.338 1068.17 914.634 1039.67 885.382C982.661 826.883 917.508 738.283 857.729 633.446C797.951 528.609 754.884 427.415 733.571 348.565C722.914 309.136 717.705 275.322 718.552 249.444C719.399 223.534 726.31 205.75 739.695 198.118C753.08 190.486 771.904 193.596 794.634 206.062C817.335 218.512 843.782 240.216 872.288 269.469C929.292 327.967 994.446 416.567 1054.22 521.404Z"
            stroke="white"
            strokeOpacity="0.15"
          />
          <path
            d="M668.27 741.476C728.048 846.313 771.115 947.507 792.428 1026.36C803.085 1065.79 808.294 1099.6 807.448 1125.48C806.6 1151.39 799.689 1169.17 786.304 1176.8C772.919 1184.44 754.095 1181.33 731.365 1168.86C708.664 1156.41 682.217 1134.71 653.712 1105.45C596.707 1046.95 531.554 958.355 471.775 853.518C411.997 748.681 368.93 647.487 347.617 568.637C336.96 529.208 331.751 495.394 332.597 469.516C333.445 443.606 340.356 425.822 353.741 418.19C367.126 410.558 385.95 413.668 408.68 426.134C431.381 438.584 457.828 460.288 486.333 489.541C543.338 548.039 608.492 636.639 668.27 741.476Z"
            stroke="white"
            strokeOpacity="0.15"
          />
          <path
            d="M1089.31 501.397C1149.09 606.233 1192.16 707.427 1213.47 786.278C1224.13 825.707 1229.34 859.521 1228.49 885.398C1227.64 911.309 1220.73 929.092 1207.35 936.724C1193.96 944.357 1175.14 941.247 1152.41 928.781C1129.71 916.33 1103.26 894.626 1074.75 865.374C1017.75 806.875 952.596 718.275 892.817 613.438C833.039 508.602 789.972 407.408 768.659 328.557C758.002 289.128 752.793 255.314 753.639 229.437C754.487 203.526 761.398 185.743 774.783 178.111C788.168 170.478 806.992 173.588 829.722 186.054C852.423 198.504 878.87 220.209 907.375 249.461C964.38 307.96 1029.53 396.56 1089.31 501.397Z"
            stroke="white"
            strokeOpacity="0.15"
          />
          <path
            d="M703.357 721.469C763.135 826.305 806.202 927.499 827.515 1006.35C838.172 1045.78 843.381 1079.59 842.535 1105.47C841.687 1131.38 834.776 1149.16 821.391 1156.8C808.006 1164.43 789.182 1161.32 766.452 1148.85C743.751 1136.4 717.304 1114.7 688.799 1085.45C631.794 1026.95 566.64 938.347 506.862 833.51C447.084 728.674 404.017 627.48 382.704 548.629C372.047 509.2 366.838 475.386 367.684 449.509C368.532 423.598 375.443 405.815 388.828 398.183C402.213 390.55 421.037 393.66 443.767 406.126C466.468 418.577 492.915 440.281 521.42 469.533C578.425 528.032 643.578 616.632 703.357 721.469Z"
            stroke="white"
            strokeOpacity="0.15"
          />
          <path
            d="M1124.4 481.391C1184.18 586.227 1227.24 687.421 1248.56 766.272C1259.21 805.701 1264.42 839.515 1263.58 865.392C1262.73 891.303 1255.82 909.086 1242.43 916.718C1229.05 924.351 1210.22 921.241 1187.49 908.775C1164.79 896.325 1138.34 874.62 1109.84 845.368C1052.83 786.869 987.682 698.269 927.903 593.432C868.125 488.596 825.058 387.402 803.745 308.551C793.088 269.122 787.879 235.308 788.725 209.431C789.573 183.52 796.484 165.737 809.869 158.105C823.254 150.472 842.078 153.582 864.808 166.048C887.509 178.499 913.956 200.203 942.461 229.455C999.466 287.954 1064.62 376.554 1124.4 481.391Z"
            stroke="white"
            strokeOpacity="0.15"
          />
          <path
            d="M738.443 701.463C798.221 806.3 841.288 907.493 862.601 986.344C873.258 1025.77 878.467 1059.59 877.621 1085.46C876.773 1111.37 869.862 1129.16 856.477 1136.79C843.092 1144.42 824.268 1141.31 801.538 1128.85C778.837 1116.4 752.39 1094.69 723.885 1065.44C666.88 1006.94 601.726 918.341 541.948 813.504C482.17 708.668 439.103 607.474 417.79 528.623C407.133 489.194 401.924 455.38 402.77 429.503C403.618 403.592 410.529 385.809 423.914 378.177C437.299 370.545 456.123 373.654 478.853 386.12C501.554 398.571 528.001 420.275 556.506 449.527C613.511 508.026 678.664 596.626 738.443 701.463Z"
            stroke="white"
            strokeOpacity="0.15"
          />
          <path
            d="M1159.48 461.385C1219.26 566.222 1262.33 667.415 1283.64 746.266C1294.3 785.695 1299.51 819.509 1298.66 845.386C1297.81 871.297 1290.9 889.08 1277.52 896.712C1264.13 904.345 1245.31 901.235 1222.58 888.769C1199.88 876.319 1173.43 854.614 1144.93 825.362C1087.92 766.863 1022.77 678.263 962.99 573.426C903.212 468.59 860.145 367.396 838.832 288.545C828.175 249.116 822.966 215.302 823.812 189.425C824.66 163.514 831.571 145.731 844.956 138.099C858.341 130.466 877.165 133.576 899.895 146.042C922.596 158.493 949.043 180.197 977.548 209.449C1034.55 267.948 1099.71 356.548 1159.48 461.385Z"
            stroke="white"
            strokeOpacity="0.15"
          />
          <path
            d="M773.531 681.457C833.309 786.294 876.376 887.487 897.688 966.338C908.346 1005.77 913.555 1039.58 912.708 1065.46C911.861 1091.37 904.95 1109.15 891.565 1116.78C878.18 1124.42 859.356 1121.31 836.626 1108.84C813.925 1096.39 787.478 1074.69 758.972 1045.43C701.968 986.935 636.814 898.335 577.036 793.498C517.258 688.662 474.19 587.468 452.878 508.617C442.221 469.188 437.011 435.374 437.858 409.497C438.706 383.587 445.617 365.803 459.001 358.171C472.386 350.539 491.21 353.648 513.941 366.115C536.642 378.565 563.089 400.269 591.594 429.521C648.599 488.02 713.752 576.62 773.531 681.457Z"
            stroke="white"
            strokeOpacity="0.15"
          />
          <path
            d="M1194.57 441.377C1254.35 546.214 1297.42 647.407 1318.73 726.258C1329.39 765.687 1334.6 799.501 1333.75 825.379C1332.9 851.289 1325.99 869.073 1312.61 876.705C1299.22 884.337 1280.4 881.227 1257.67 868.761C1234.97 856.311 1208.52 834.607 1180.01 805.354C1123.01 746.856 1057.86 658.255 998.077 553.419C938.299 448.582 895.231 347.388 873.919 268.538C863.262 229.109 858.052 195.294 858.899 169.417C859.747 143.507 866.658 125.723 880.043 118.091C893.427 110.459 912.251 113.569 934.982 126.035C957.683 138.485 984.13 160.189 1012.64 189.441C1069.64 247.94 1134.79 336.54 1194.57 441.377Z"
            stroke="white"
            strokeOpacity="0.15"
          />
          <path
            d="M808.616 661.449C868.395 766.286 911.462 867.48 932.774 946.33C943.432 985.759 948.641 1019.57 947.794 1045.45C946.946 1071.36 940.036 1089.14 926.651 1096.78C913.266 1104.41 894.442 1101.3 871.712 1088.83C849.011 1076.38 822.563 1054.68 794.058 1025.43C737.054 966.928 671.9 878.328 612.122 773.491C552.344 668.654 509.276 567.46 487.964 488.61C477.307 449.181 472.097 415.367 472.944 389.489C473.792 363.579 480.703 345.795 494.087 338.163C507.472 330.531 526.296 333.641 549.027 346.107C571.728 358.557 598.175 380.261 626.68 409.513C683.685 468.012 748.838 556.612 808.616 661.449Z"
            stroke="white"
            strokeOpacity="0.15"
          />
          <path
            d="M1229.66 421.371C1289.44 526.208 1332.5 627.402 1353.82 706.252C1364.47 745.681 1369.68 779.495 1368.84 805.373C1367.99 831.283 1361.08 849.067 1347.69 856.699C1334.31 864.331 1315.48 861.221 1292.75 848.755C1270.05 836.305 1243.6 814.601 1215.1 785.348C1158.09 726.85 1092.94 638.25 1033.16 533.413C973.385 428.576 930.317 327.382 909.005 248.532C898.348 209.103 893.138 175.289 893.985 149.411C894.833 123.501 901.744 105.717 915.128 98.0851C928.513 90.4529 947.337 93.5627 970.068 106.029C992.769 118.479 1019.22 140.183 1047.72 169.435C1104.73 227.934 1169.88 316.534 1229.66 421.371Z"
            stroke="white"
            strokeOpacity="0.15"
          />
          <path
            d="M843.703 641.443C903.482 746.28 946.549 847.474 967.861 926.324C978.519 965.753 983.728 999.568 982.881 1025.44C982.033 1051.36 975.123 1069.14 961.738 1076.77C948.353 1084.4 929.529 1081.29 906.799 1068.83C884.098 1056.38 857.65 1034.67 829.145 1005.42C772.141 946.922 706.987 858.322 647.209 753.485C587.431 648.648 544.363 547.454 523.051 468.604C512.394 429.175 507.184 395.361 508.031 369.483C508.879 343.573 515.789 325.789 529.174 318.157C542.559 310.525 561.383 313.635 584.114 326.101C606.815 338.551 633.262 360.255 661.767 389.508C718.772 448.006 783.925 536.607 843.703 641.443Z"
            stroke="white"
            strokeOpacity="0.15"
          />
          <path
            d="M1264.75 401.363C1324.52 506.2 1367.59 607.394 1388.9 686.245C1399.56 725.674 1404.77 759.488 1403.92 785.365C1403.08 811.275 1396.16 829.059 1382.78 836.691C1369.4 844.323 1350.57 841.214 1327.84 828.747C1305.14 816.297 1278.69 794.593 1250.19 765.341C1193.18 706.842 1128.03 618.242 1068.25 513.405C1008.47 408.568 965.405 307.375 944.093 228.524C933.436 189.095 928.226 155.281 929.073 129.404C929.921 103.493 936.831 85.7095 950.216 78.0774C963.601 70.4452 982.425 73.555 1005.16 86.0211C1027.86 98.4713 1054.3 120.176 1082.81 149.428C1139.81 207.927 1204.97 296.527 1264.75 401.363Z"
            stroke="white"
            strokeOpacity="0.15"
          />
          <path
            d="M878.79 621.437C938.569 726.274 981.636 827.468 1002.95 906.318C1013.61 945.747 1018.81 979.562 1017.97 1005.44C1017.12 1031.35 1010.21 1049.13 996.825 1056.77C983.44 1064.4 964.616 1061.29 941.886 1048.82C919.184 1036.37 892.737 1014.67 864.232 985.415C807.228 926.916 742.074 838.316 682.296 733.479C622.518 628.642 579.45 527.449 558.138 448.598C547.481 409.169 542.271 375.355 543.118 349.477C543.966 323.567 550.876 305.783 564.261 298.151C577.646 290.519 596.47 293.629 619.2 306.095C641.902 318.545 668.349 340.249 696.854 369.502C753.859 428 819.012 516.601 878.79 621.437Z"
            stroke="white"
            strokeOpacity="0.15"
          />
          <path
            d="M1299.83 381.358C1359.61 486.194 1402.68 587.388 1423.99 666.239C1434.65 705.668 1439.86 739.482 1439.01 765.359C1438.16 791.27 1431.25 809.053 1417.87 816.685C1404.48 824.317 1385.66 821.208 1362.93 808.742C1340.23 796.291 1313.78 774.587 1285.27 745.335C1228.27 686.836 1163.12 598.236 1103.34 493.399C1043.56 388.562 1000.49 287.369 979.179 208.518C968.522 169.089 963.312 135.275 964.159 109.398C965.007 83.4873 971.917 65.7036 985.302 58.0715C998.687 50.4394 1017.51 53.5492 1040.24 66.0153C1062.94 78.4654 1089.39 100.17 1117.89 129.422C1174.9 187.921 1240.05 276.521 1299.83 381.358Z"
            stroke="white"
            strokeOpacity="0.15"
          />
          <path
            d="M913.877 601.43C973.655 706.267 1016.72 807.46 1038.04 886.311C1048.69 925.74 1053.9 959.554 1053.06 985.431C1052.21 1011.34 1045.3 1029.13 1031.91 1036.76C1018.53 1044.39 999.703 1041.28 976.973 1028.81C954.271 1016.36 927.824 994.659 899.319 965.407C842.314 906.908 777.161 818.308 717.383 713.471C657.605 608.635 614.537 507.441 593.225 428.59C582.568 389.161 577.358 355.347 578.205 329.47C579.053 303.559 585.963 285.776 599.348 278.144C612.733 270.512 631.557 273.621 654.287 286.087C676.989 298.538 703.436 320.242 731.941 349.494C788.945 407.993 854.099 496.593 913.877 601.43Z"
            stroke="white"
            strokeOpacity="0.15"
          />
          <path
            d="M1334.92 361.352C1394.7 466.188 1437.76 567.382 1459.08 646.233C1469.73 685.662 1474.94 719.476 1474.1 745.353C1473.25 771.264 1466.34 789.047 1452.95 796.679C1439.57 804.311 1420.74 801.202 1398.01 788.736C1375.31 776.285 1348.87 754.581 1320.36 725.329C1263.36 666.83 1198.2 578.23 1138.42 473.393C1078.65 368.556 1035.58 267.363 1014.27 188.512C1003.61 149.083 998.399 115.269 999.246 89.3917C1000.09 63.4813 1007 45.6976 1020.39 38.0655C1033.77 30.4334 1052.6 33.5432 1075.33 46.0093C1098.03 58.4594 1124.48 80.1638 1152.98 109.416C1209.99 167.915 1275.14 256.515 1334.92 361.352Z"
            stroke="white"
            strokeOpacity="0.15"
          />
          <path
            d="M948.964 581.424C1008.74 686.261 1051.81 787.454 1073.12 866.305C1083.78 905.734 1088.99 939.548 1088.14 965.425C1087.29 991.336 1080.38 1009.12 1067 1016.75C1053.61 1024.38 1034.79 1021.27 1012.06 1008.81C989.358 996.358 962.911 974.653 934.406 945.401C877.401 886.902 812.248 798.302 752.47 693.465C692.691 588.629 649.624 487.435 628.312 408.584C617.655 369.155 612.445 335.341 613.292 309.464C614.14 283.553 621.05 265.77 634.435 258.138C647.82 250.506 666.644 253.615 689.374 266.081C712.075 278.532 738.523 300.236 767.028 329.488C824.032 387.987 889.186 476.587 948.964 581.424Z"
            stroke="white"
            strokeOpacity="0.15"
          />
          <path
            d="M1370.01 341.344C1429.78 446.181 1472.85 547.375 1494.16 626.225C1504.82 665.654 1510.03 699.468 1509.18 725.346C1508.34 751.256 1501.42 769.04 1488.04 776.672C1474.65 784.304 1455.83 781.194 1433.1 768.728C1410.4 756.278 1383.95 734.574 1355.45 705.321C1298.44 646.823 1233.29 558.223 1173.51 453.386C1113.73 348.549 1070.67 247.355 1049.35 168.505C1038.7 129.076 1033.49 95.2614 1034.33 69.3841C1035.18 43.4737 1042.09 25.6901 1055.48 18.058C1068.86 10.4258 1087.69 13.5356 1110.42 26.0017C1133.12 38.4519 1159.56 60.1562 1188.07 89.4084C1245.07 147.907 1310.23 236.507 1370.01 341.344Z"
            stroke="white"
            strokeOpacity="0.15"
          />
          <path
            d="M984.05 561.416C1043.83 666.253 1086.9 767.447 1108.21 846.297C1118.87 885.726 1124.07 919.54 1123.23 945.418C1122.38 971.328 1115.47 989.112 1102.08 996.744C1088.7 1004.38 1069.88 1001.27 1047.15 988.8C1024.44 976.35 997.997 954.646 969.492 925.394C912.487 866.895 847.334 778.295 787.556 673.458C727.777 568.621 684.71 467.427 663.398 388.577C652.74 349.148 647.531 315.334 648.378 289.456C649.226 263.546 656.136 245.762 669.521 238.13C682.906 230.498 701.73 233.608 724.46 246.074C747.161 258.524 773.609 280.228 802.114 309.481C859.118 367.979 924.272 456.579 984.05 561.416Z"
            stroke="white"
            strokeOpacity="0.15"
          />
          <path
            d="M1405.09 321.338C1464.87 426.175 1507.94 527.369 1529.25 606.219C1539.91 645.648 1545.12 679.462 1544.27 705.34C1543.42 731.25 1536.51 749.034 1523.13 756.666C1509.74 764.298 1490.92 761.188 1468.19 748.722C1445.49 736.272 1419.04 714.568 1390.53 685.315C1333.53 626.817 1268.38 538.217 1208.6 433.38C1148.82 328.543 1105.75 227.349 1084.44 148.499C1073.78 109.07 1068.57 75.2555 1069.42 49.3782C1070.27 23.4677 1077.18 5.68409 1090.56 -1.94803C1103.95 -9.58015 1122.77 -6.47035 1145.5 5.99574C1168.2 18.4459 1194.65 40.1502 1223.16 69.4024C1280.16 127.901 1345.31 216.501 1405.09 321.338Z"
            stroke="white"
            strokeOpacity="0.15"
          />
          <path
            d="M1019.14 541.41C1078.92 646.247 1121.98 747.441 1143.3 826.291C1153.95 865.72 1159.16 899.534 1158.32 925.412C1157.47 951.322 1150.56 969.106 1137.17 976.738C1123.79 984.37 1104.96 981.26 1082.23 968.794C1059.53 956.344 1033.08 934.64 1004.58 905.388C947.575 846.889 882.422 758.289 822.644 653.452C762.865 548.615 719.798 447.421 698.486 368.571C687.828 329.142 682.619 295.328 683.466 269.45C684.314 243.54 691.224 225.756 704.609 218.124C717.994 210.492 736.818 213.602 759.548 226.068C782.249 238.518 808.697 260.222 837.202 289.475C894.206 347.973 959.36 436.573 1019.14 541.41Z"
            stroke="white"
            strokeOpacity="0.15"
          />
          <path
            d="M1440.18 301.332C1499.96 406.169 1543.02 507.363 1564.34 586.213C1574.99 625.642 1580.2 659.456 1579.36 685.334C1578.51 711.244 1571.6 729.028 1558.21 736.66C1544.83 744.292 1526 741.182 1503.27 728.716C1480.57 716.266 1454.13 694.562 1425.62 665.309C1368.62 606.811 1303.46 518.211 1243.68 413.374C1183.91 308.537 1140.84 207.343 1119.53 128.493C1108.87 89.0636 1103.66 55.2495 1104.51 29.3722C1105.35 3.46176 1112.27 -14.3219 1125.65 -21.954C1139.03 -29.5861 1157.86 -26.4763 1180.59 -14.0102C1203.29 -1.56009 1229.74 20.1443 1258.24 49.3964C1315.25 107.895 1380.4 196.495 1440.18 301.332Z"
            stroke="white"
            strokeOpacity="0.15"
          />
        </svg>
        <div className="info !top-0 !rounded-b-[0]">
          <h3 className="!pb-[16px]">
            Book a demo<span style={{ color: '#1127E3' }}>.</span>
          </h3>
          <div>
            <InlineWidget
              url="https://calendly.com/kubarievilya/let-s-chat"
              styles={{
                height: '750px',
                width: '100%',
              }}
              prefill={{}}
            />
          </div>
        </div>
      </div>
    </main>
  );
};
