import { MtbillLogo, MbasisLogo } from 'components/svg';
import { useGetKpi } from 'hooks/data/useGetKpi';
import { memo } from 'react';

import { AssetCard } from '../components/AssetCard';
import { FeatureHighlights } from '../components/FeatureHighlights';
import { Partners } from '../components/Partners';
import { TvlCompanies } from '../components/TvlCompanies';

export function Home() {
  const { data: kpi } = useGetKpi();

  return (
    <main className="max-w-[1440px] flex-1 px-2">
      <section className="mt-10 flex flex-col gap-4 text-center md:mt-16">
        <h1 className="m-0 text-[40px] font-semibold leading-[48px] text-[#051C2C] sm:text-nowrap md:text-5xl lg:text-[56px] lg:leading-[64px]">
          Structured Products, Onchain.
        </h1>
        <div className="text-[20px] leading-[28px] text-shuttle-gray max-md:text-center">
          Institutional-grade and fully compliant yield-bearing instruments, onchain and DeFi ready.
        </div>
        {/*<div className="actions__user">*/}
        {/*  <button onClick={() => changeProp()}>Join Waiting-list</button>*/}
        {/*</div>*/}
        {/* </div> */}
      </section>
      <section className="mt-10 grid grid-cols-1 place-items-center justify-center gap-6 max-md:items-center sm:grid-cols-2 md:mt-20 md:gap-8 [&>*:first-child]:ml-auto [&>*:last-child]:mr-auto">
        <AssetCard
          name="mTBILL"
          description="Short Term US Treasuries"
          icon={<MtbillLogo className="max-md:h-[25px] max-md:w-[23px]" />}
          iconColor="#c8f2ab"
          apy={kpi?.mTbillAPY ?? '0.00'}
          tvl={kpi?.mTbillAUM ?? 0}
          link="mTBILL"
        />
        <AssetCard
          name="mBASIS"
          description="Risk-adjusted Crypto Delta Neutral Strategy"
          icon={<MbasisLogo className="max-md:h-[25px] max-md:w-[23px]" />}
          iconColor="#e8e5ff"
          apy={kpi?.mBasisAPY ?? '0.00'}
          tvl={kpi?.mBasisAUM ?? 0}
          link="mBASIS"
        />
      </section>
      <TvlCompanies />
      <FeatureHighlights />
      <Partners />
    </main>
  );
}

export default memo(Home);
