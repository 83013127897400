import { memo, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import { defaultTitle, titleConfig } from 'constants/title';

const description = 'Structured Products, Onchain.';

export const DefaultMeta = memo(() => {
  const location = useLocation();
  const canonical = useMemo(() => `https://midas.app${location.pathname}`, [location]);
  const title = useMemo(
    () => titleConfig[location.pathname.toLowerCase()] ?? defaultTitle,
    [location],
  );

  // @dev defaults should also be added to index.html with data-rh="true"
  return (
    <Helmet>
      <html lang="en" />
      <title>{title}</title>
      <link rel="canonical" href={canonical} />
      <meta name="description" content={description} />
      <meta property="og:title" content="Midas" />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={title} />
      <meta property="og:url" content={canonical} />
      <meta
        property="og:image"
        content="https://staging--magical-babka-081431.netlify.app/graph.jpg"
      />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content="Midas" />
      <meta property="twitter:description" content={description} />
      <meta
        property="twitter:image"
        content="https://staging--magical-babka-081431.netlify.app/graph.jpg"
      />
    </Helmet>
  );
});
DefaultMeta.displayName = 'DefaultMeta';
