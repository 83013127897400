import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { EIP6963Connector, walletConnectProvider } from '@web3modal/wagmi';
import { createWeb3Modal } from '@web3modal/wagmi/react';
import type { PropsWithChildren } from 'react';
import { WagmiConfig, configureChains, createConfig } from 'wagmi';
import { sepolia, mainnet } from 'wagmi/chains';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { publicProvider } from 'wagmi/providers/public';

const providerUrl = process.env.REACT_APP_PROVIDER_URL as string;
const mainnetProviderUrl = process.env.REACT_APP_MAINNET_PROVIDER_URL as string;

const SUPPORTED_CHAINS = [
  {
    ...mainnet,
    rpcUrls: {
      ...mainnet.rpcUrls,
      default: {
        http: [mainnetProviderUrl],
      },
      public: {
        http: [mainnetProviderUrl],
      },
    },
  } as const,
  {
    ...sepolia,
    rpcUrls: {
      ...sepolia.rpcUrls,
      default: {
        http: [providerUrl],
      },
      public: {
        http: [providerUrl],
      },
    },
  } as const,
];

const projectId = process.env.REACT_APP_PROJECT_ID as string;
const { publicClient, chains } = configureChains(SUPPORTED_CHAINS, [
  walletConnectProvider({ projectId }),
  publicProvider(),
]);

const queryClient = new QueryClient();

const metadata = {
  name: 'Midas',
  description: 'Structured Products, Onchain.',
  url: 'https://midas.app',
  icons: [],
};

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [
    new WalletConnectConnector({ chains, options: { projectId, showQrModal: false, metadata } }),
    new EIP6963Connector({ chains }),
    new InjectedConnector({ chains, options: { shimDisconnect: true } }),
    new CoinbaseWalletConnector({ chains, options: { appName: metadata.name } }),
  ],
  publicClient,
  queryClient,
});

createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  enableAnalytics: true,
});

export const ClientProviders = ({ children }: PropsWithChildren) => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <WagmiConfig config={wagmiConfig}>{children}</WagmiConfig>
      </QueryClientProvider>
    </>
  );
};
