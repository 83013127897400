import { toast as sonnerToast } from 'sonner';
import { cn } from 'utils/cn';
import { matchErrorMessage } from 'utils/regex/txErroMessage';

export type SonnerToastOptions = {
  type: 'info' | 'success' | 'danger';
  message: string;
  btnMessage?: string;
  onButtonClick?: () => void;
};

export const newTxToast = ({ message, btnMessage, onButtonClick, type }: SonnerToastOptions) => {
  const matchedError = matchErrorMessage(message);
  return sonnerToast.custom(t => (
    <div className="relative flex w-full items-center gap-6 rounded-[12px] border border-[#FBFBFF] bg-white px-6 py-8 shadow-[0px_4px_24px_0px_#131C2C14]">
      <button
        className="absolute right-[14px] top-[14px] p-1"
        onClick={() => sonnerToast.dismiss(t)}
      >
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.32166 5.99961L11.8083 1.51278C11.9317 1.38927 11.9998 1.22449 12 1.04878C12 0.872976 11.9319 0.708 11.8083 0.584683L11.4151 0.19161C11.2915 0.0678049 11.1267 0 10.9508 0C10.7752 0 10.6104 0.0678049 10.4868 0.19161L6.0002 4.67815L1.51337 0.19161C1.38995 0.0678049 1.22507 0 1.04927 0C0.873659 0 0.70878 0.0678049 0.585366 0.19161L0.192 0.584683C-0.064 0.840683 -0.064 1.25707 0.192 1.51278L4.67873 5.99961L0.192 10.4862C0.0684878 10.61 0.000487805 10.7747 0.000487805 10.9504C0.000487805 11.1261 0.0684878 11.2909 0.192 11.4145L0.585268 11.8076C0.708683 11.9313 0.873658 11.9992 1.04917 11.9992C1.22498 11.9992 1.38985 11.9313 1.51327 11.8076L6.0001 7.32098L10.4867 11.8076C10.6103 11.9313 10.7751 11.9992 10.9507 11.9992H10.9509C11.1266 11.9992 11.2914 11.9313 11.415 11.8076L11.8082 11.4145C11.9316 11.291 11.9997 11.1261 11.9997 10.9504C11.9997 10.7747 11.9316 10.61 11.8082 10.4863L7.32166 5.99961Z"
            fill="#051C2C"
          />
        </svg>
      </button>
      {getIcon(type)}

      <div className={cn('flex', btnMessage ? 'flex-col gap-3' : 'items-center')}>
        <p className="text-[20px] font-medium leading-[24px] text-[#14223D]">
          {type === 'danger' && matchedError
            ? `Error while sending the transaction: ${matchedError}`
            : message}
        </p>
        {btnMessage ? (
          <button className="flex items-center gap-[6px]" onClick={onButtonClick}>
            <span className="text-base font-medium text-[#FFAD53]">{btnMessage}</span>
            <svg
              width="20"
              height="10"
              viewBox="0 0 20 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.7709 4.44699L19.7702 4.44625L15.688 0.383747C15.3821 0.0794115 14.8875 0.0805441 14.5831 0.386403C14.2787 0.692224 14.2799 1.18687 14.5857 1.49125L17.3265 4.21875H0.78125C0.349766 4.21875 0 4.56851 0 5C0 5.43148 0.349766 5.78125 0.78125 5.78125H17.3264L14.5857 8.50875C14.2799 8.81312 14.2787 9.30777 14.5831 9.61359C14.8875 9.91949 15.3822 9.92054 15.688 9.61625L19.7702 5.55375L19.7709 5.55301C20.0769 5.24761 20.0759 4.75136 19.7709 4.44699Z"
                fill="#FFAD53"
              />
            </svg>
          </button>
        ) : null}
      </div>
    </div>
  ));
};

const getIcon = (type: 'info' | 'success' | 'danger') => {
  switch (type) {
    case 'info':
      return (
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 0C8.95414 0 0 8.95445 0 20C0 31.0462 8.95414 40 20 40C31.0459 40 40 31.0462 40 20C40 8.95445 31.0459 0 20 0ZM20 10C21.3808 10 22.5 11.1194 22.5 12.5C22.5 13.8813 21.3808 15 20 15C18.6192 15 17.5 13.8813 17.5 12.5C17.5 11.1194 18.6192 10 20 10ZM23.75 30H16.25C15.5597 30 15 29.4409 15 28.75C15 28.0597 15.5597 27.5 16.25 27.5H17.5V20H16.25C15.5597 20 15 19.4409 15 18.75C15 18.0597 15.5597 17.5 16.25 17.5H21.25C21.9403 17.5 22.5 18.0597 22.5 18.75V27.5H23.75C24.4403 27.5 25 28.0597 25 28.75C25 29.4409 24.4403 30 23.75 30Z"
            fill="#B9B9B9"
          />
        </svg>
      );
    case 'success':
      return (
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 0C8.97243 0 0 8.97243 0 20C0 31.0276 8.97243 40 20 40C31.0276 40 40 31.0276 40 20C40 8.97243 31.0276 0 20 0ZM31.1779 14.7368L18.396 27.4185C17.6441 28.1704 16.4411 28.2206 15.6391 27.4687L8.87218 21.3033C8.07018 20.5514 8.02005 19.2982 8.7218 18.4962C9.47368 17.6942 10.7268 17.6441 11.5288 18.396L16.8922 23.3083L28.3208 11.8797C29.1228 11.0777 30.3759 11.0777 31.1779 11.8797C31.9799 12.6817 31.9799 13.9348 31.1779 14.7368Z"
            fill="#26AE7A"
          />
        </svg>
      );
    case 'danger':
      return (
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20ZM22.5 30C22.5 31.3808 21.3808 32.5 20 32.5C18.6193 32.5 17.5 31.3808 17.5 30C17.5 28.6192 18.6193 27.5 20 27.5C21.3808 27.5 22.5 28.6192 22.5 30ZM20 7.5C18.6193 7.5 17.5 8.6193 17.5 10V20C17.5 21.3808 18.6193 22.5 20 22.5C21.3808 22.5 22.5 21.3808 22.5 20V10C22.5 8.6193 21.3808 7.5 20 7.5Z"
            fill="#FFAD53"
          />
        </svg>
      );
  }
};
