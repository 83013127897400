import type { DocumentNode, OperationVariables, TypedDocumentNode } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { useNetwork } from 'wagmi';

import { getClientByNetwork } from 'constants/subgraphs';

export const useDefaultSubgraphQuery = <T>(
  query: DocumentNode | TypedDocumentNode<T, OperationVariables>,
  options: Record<string, unknown> = {},
) => {
  const { chain } = useNetwork();

  const client = getClientByNetwork(chain?.network);

  const { called, data, loading, networkStatus, refetch, startPolling, stopPolling, error } =
    useQuery(query, {
      client,
      ...options,
    });

  console.log({ error });

  return {
    called,
    data,
    loading,
    networkStatus,
    refetch,
    startPolling,
    stopPolling,
    error,
  };
};
