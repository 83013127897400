import { sepolia, mainnet } from 'wagmi/chains';

type Addresses = {
  ac: `0x${string}`;
  dataFeed: `0x${string}`;
  mTBILL: `0x${string}`;
  mtbillDepositVault: `0x${string}`;
  mtbillRedemptionVault: `0x${string}`;
  mBASIS: `0x${string}`;
  mbasisDepositVault: `0x${string}`;
  mbasisRedemptionVault: `0x${string}`;
  usdcMtbillRecipient: `0x${string}`;
  usdcMbasisRecipient: `0x${string}`;
};

export const addresses: Record<string, Addresses> = {
  [sepolia.network]: {
    ac: '0xbf25b58cB8DfaD688F7BcB2b87D71C23A6600AaC',
    dataFeed: '0x4E677F7FE252DE44682a913f609EA3eb6F29DC3E',
    mTBILL: '0xefED40D1eb1577d1073e9C4F277463486D39b084',
    mtbillDepositVault: '0xE85f2B707Ec5Ae8e07238F99562264f304E30109',
    mtbillRedemptionVault: '0xf3482c80d1A2883611De939Af7b0a970d5fcdC06',
    mBASIS: '0x4089dC8b6637218f13465d28950A82a7E90cBE27',
    mbasisDepositVault: '0x8459f6e174deE33FC72BDAE74a3080751eC92c27',
    mbasisRedemptionVault: '0x141f0E9ed8bA2295254C9DF9476ccE7bC29172B1',
    usdcMtbillRecipient: '0xa0819ae43115420beb161193b8D8Ba64C9f9faCC',
    usdcMbasisRecipient: '0xa0819ae43115420beb161193b8D8Ba64C9f9faCC',
  },
  [mainnet.network]: {
    ac: '0x0312A9D1Ff2372DDEdCBB21e4B6389aFc919aC4B',
    dataFeed: '0x32d1463EB53b73C095625719Afa544D5426354cB',
    mTBILL: '0xDD629E5241CbC5919847783e6C96B2De4754e438',
    mtbillDepositVault: '0xcbCf1e67F1988e2572a2A620321Aef2ff73369f0',
    mtbillRedemptionVault: '0x8978e327FE7C72Fa4eaF4649C23147E279ae1470',
    mBASIS: '0x2a8c22E3b10036f3AEF5875d04f8441d4188b656',
    mbasisDepositVault: '0x27C0D44B02E1B732F37ba31C466a35053A7780B8',
    mbasisRedemptionVault: '0x73cB9a00cEB8FC9134a46eEE20D1fd00BEEe9D84',
    usdcMtbillRecipient: '0x875c06A295C41c27840b9C9dfDA7f3d819d8bC6A',
    usdcMbasisRecipient: '0xB8633297f9D9A8eaD48f1335ab04b14C189639f0',
  },
};

export const getAddressesByChain = (name?: string) => {
  if (!name || !addresses[name]) name = mainnet.network;
  return addresses[name ?? mainnet.network];
};
