import { NotificationModal } from 'components/NotificationModal';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/config';

interface SuccessfulTxModalProps {
  isModalOpened: boolean;
  setIsModalOpened: (e: boolean) => void;
}

export const SuccessfulTxModal = ({ isModalOpened, setIsModalOpened }: SuccessfulTxModalProps) => {
  const nav = useNavigate();
  return (
    <>
      <NotificationModal
        isModalOpened={isModalOpened}
        onBtnClick={() => {
          nav(ROUTES.INVESTMENTS);
        }}
        closeModal={() => setIsModalOpened(false)}
        content={{
          bodyContent: (
            <>
              Your transaction was successful.
              <br />
              Please view the transaction details in the Investment Portal.
            </>
          ),
          headerContent: <>Confirmation.</>,
          btnContent: <>Investment Portal</>,
        }}
        maxWidth="500px"
      />
    </>
  );
};

export const SuccessfulApproveModal = ({
  isModalOpened,
  setIsModalOpened,
}: SuccessfulTxModalProps) => {
  return (
    <NotificationModal
      isModalOpened={isModalOpened}
      closeModal={() => setIsModalOpened(false)}
      onBtnClick={() => setIsModalOpened(false)}
      content={{
        headerContent: 'Confirmation.',
        bodyContent: (
          <>
            Approved asset successfully.
            <br />
            Now you are able to proceed with investment.
          </>
        ),
        btnContent: 'Close',
      }}
      maxWidth="500px"
    />
  );
};
