import binance from '../assets/imgs/new/binance.svg';
import bybit from '../assets/imgs/new/bybit.svg';
import okx from '../assets/imgs/new/okx.svg';

export enum Exchanger {
  BINANCE = 'BINANCE',
  OKX = 'OKX',
  BYBIT = 'BYBIT',
  UNINVESTED = 'Uninvested',
}

export type ExchangerLogoResponse = { type: 'img'; img: string } | { type: 'text'; text: string };

const exchangerToSvg: Record<Exchanger, ExchangerLogoResponse> = {
  [Exchanger.BINANCE]: {
    type: 'img',
    img: binance,
  },
  [Exchanger.OKX]: {
    type: 'img',
    img: okx,
  },
  [Exchanger.BYBIT]: {
    type: 'img',
    img: bybit,
  },
  [Exchanger.UNINVESTED]: {
    type: 'text',
    text: 'Uninvested',
  },
};

export const getExchangerLogo = (name: Exchanger) => exchangerToSvg[name] ?? binance;
