export const NEXT_STEP = 'NEXT_STEP';
export const PREVIOUS_STEP = 'PREVIOUS_STEP';
export const SUBMIT_FORM = 'SUBMIT_FORM';

export const SET_STEP = 'SET_STEP';

export const SET_CUSTOM_ADDRESS = 'SET_CUSTOM_ADDRESS';
export const SET_METAMASK_INSTALLED = 'SET_METAMASK_INSTALLED';
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_ETHEREUM_ADDRESS = 'SET_ETHEREUM_ADDRESS';
export const SET_COINBASE_ADDRESS = 'SET_COINBASE_ADDRESS';
export const SET_COINBASE_INSTALLED = 'SET_COINBASE_INSTALLED';
export const UPDATE_FORM = 'UPDATE_FORM';
export const SET_NETWORK = 'SET_NETWORK';
