import Aside from 'layouts/adminTab';
import Header from 'layouts/headerAdminTab';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { parseUnits, zeroAddress, isAddress, getAddress } from 'viem';
import { useNetwork, usePublicClient } from 'wagmi';

import { NotificationModal } from '../../components/NotificationModal';
import { getExplorerByNetwork } from '../../constants/explorers';
import { useDepositVaultWithdrawalsAndDeposits } from '../../hooks/useDepositVaultWithdrawalsAndDeposits';
import { useManageDepositVault } from '../../hooks/useManageDepositVault';
import { successToast, txErrorToast } from '../../toasts';
import { comaFormat } from '../../utils/comaFormat';
import { downloadCsv } from '../../utils/csv';
import { dateToShorten } from '../../utils/date';
import { getHumanError, isCustomError } from '../../utils/errors';
import { addressToExplorerUri } from '../../utils/explorer';
import { formatBN } from '../../utils/formatBN';
import { shortenAddress } from '../../utils/shortenAddress';

export const ManageDepositVault = () => {
  const publicClient = usePublicClient();
  const [errorText, setErrorText] = useState('');
  const [isModalOpened, setIsModalOpened] = useState(false);
  const closeModal = () => setIsModalOpened(false);
  const { chain } = useNetwork();
  const explorerBaseUrl = getExplorerByNetwork(chain?.name);
  const {
    address,
    accessControl,
    // etfDataFeed,
    eurUsdDataFeed,
    stUSD,
    // fee,
    isOnPause,
    // lastRequestId,
    usdcBalance,
    usdc,
    withdrawTokenAsync,
    pauseAsync,
    // setFeeAsync,
  } = useManageDepositVault();
  const [isDownloading, setIsDownloading] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [withdrawAmount, setWithdrawAmount] = useState('0');
  const [withdrawAddress, setWithdrawAddress] = useState<string>(zeroAddress);

  const dataToShow: [string, string][] = [
    ['Address', addressToExplorerUri(explorerBaseUrl, address)],
    ['Access Control', addressToExplorerUri(explorerBaseUrl, accessControl)],
    // ['ETF Data Feed', addressToExplorerUri(explorerBaseUrl, etfDataFeed)],
    ['EUR/USD Data Feed', addressToExplorerUri(explorerBaseUrl, eurUsdDataFeed)],
    ['mTBILL', addressToExplorerUri(explorerBaseUrl, stUSD)],
    // ['USDC Fee', `${(+formatUnits(fee, 2)).toFixed(2)}%`],
    ['Is On Pause', isOnPause ? 'Yes' : 'No'],
    // ['Last Request Id', lastRequestId.toString()],
    ['USDC balance', comaFormat(formatBN(usdcBalance, 2, usdc.decimals))],
  ];

  const { data, refetch } = useDepositVaultWithdrawalsAndDeposits();

  const onWithdrawTokensClick = async () => {
    setIsLoading(true);

    if (!withdrawAddress || !isAddress(withdrawAddress)) {
      txErrorToast('Invalid address provided');
      setIsLoading(false);
      return;
    }

    if (!withdrawAmount || isNaN(parseFloat(withdrawAmount))) {
      txErrorToast('Invalid amount provided');
      setIsLoading(false);
      return;
    }
    try {
      await publicClient.waitForTransactionReceipt(
        await withdrawTokenAsync({
          args: [
            usdc.address,
            parseUnits(withdrawAmount, usdc.decimals),
            getAddress(withdrawAddress),
          ],
        }),
      );
      refetch();
      successToast('Tokens withdrawn');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      const error = getHumanError(e.shortMessage);
      setErrorText(
        `Error while sending transaction: ${isCustomError(error) ? e.shortMessage : error}`,
      );
      setIsModalOpened(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onPauseClick = async () => {
    setIsLoading(true);
    try {
      await publicClient.waitForTransactionReceipt(await pauseAsync({ args: [!isOnPause] }));
      successToast('Pause status updated');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      const error = getHumanError(e.shortMessage);
      setErrorText(
        `Error while sending transaction: ${isCustomError(error) ? e.shortMessage : error}`,
      );
      setIsModalOpened(true);
    } finally {
      setIsLoading(false);
    }
  };

  // const onSetFeeClick = async () => {
  //   setIsLoading(true);
  //   if (!feeAmount || isNaN(parseFloat(feeAmount))) {
  //     txErrorToast('Invalid amount provided');
  //     return;
  //   }
  //   try {
  //     await publicClient.waitForTransactionReceipt(
  //       await setFeeAsync({ args: [usdc.address, parseUnits(feeAmount, 2)] }),
  //     );
  //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //   } catch (e: any) {
  //     const error = getHumanError(e.shortMessage);
  //     setErrorText(
  //       `Error while sending transaction: ${isCustomError(error) ? e.shortMessage : error}`,
  //     );
  //     setIsModalOpened(true);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  return (
    <div className="admin">
      <NotificationModal
        isModalOpened={isModalOpened}
        closeModal={closeModal}
        onBtnClick={closeModal}
        content={{
          bodyContent: errorText,
          headerContent: <>Sorry.</>,
          btnContent: 'Close',
        }}
      />
      <Aside />
      <section>
        <Header />
        <div className="lists">
          <h5 className="!text-black">Manage Deposit Vault.</h5>
        </div>
        <div className="relative">
          <table className="w-full table-fixed border-collapse">
            <thead
              style={{
                borderTop: '1px solid #A5A5A5',
                borderBottom: '1px solid #A5A5A5',
              }}
            >
              <tr className="!text-[14px] text-black">
                <td className="p-[16px] font-semibold">Property</td>
                <td className="p-[16px] font-semibold">Value</td>
              </tr>
            </thead>
            <tbody>
              {dataToShow.map(([key, value]) => (
                <tr key={key} className="odd:bg-[#F0F0F0]">
                  <td className="p-[16px]">{key}</td>
                  <td className="p-[16px]">
                    {value.startsWith(explorerBaseUrl) ? (
                      <Link to={value} className="text-[16px] text-black" target="_blank">
                        Link
                      </Link>
                    ) : (
                      value
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex items-center gap-2 !py-0">
          <button
            onClick={async () => {
              if (!isDownloading) {
                setIsDownloading(true);
                try {
                  await downloadCsv(`deposit.csv`, dataToShow);
                } catch (error) {
                  console.error("Couldn't download CSV", error);
                } finally {
                  setIsDownloading(false);
                }
              }
            }}
            className="cursor-pointer rounded-[40px] border-[1px] border-black bg-black px-[16px] py-[8px] text-[16px] font-medium text-white transition-all hover:bg-white hover:text-black"
          >
            {isDownloading ? 'Processing...' : 'Export to CSV'}
          </button>
          <button
            disabled={isLoading}
            onClick={onPauseClick}
            className="cursor-pointer rounded-[40px] border-[1px] border-black bg-white px-[16px] py-[8px] text-[16px] font-medium text-black transition-all hover:bg-black hover:text-white"
          >
            {isLoading ? 'Processing...' : isOnPause ? 'Unpause' : 'Pause'}
          </button>
        </div>
        <div className="flex h-fit flex-wrap gap-4">
          <div className="flex min-w-[452px] flex-col gap-[16px] rounded-[24px] bg-[#F0F0F0] p-[40px]">
            <div className="text-[20px] font-semibold leading-[32px] text-black">Withdraw USDC</div>
            <div className="flex flex-col gap-[4px]">
              <div className="text-[14px] font-medium leading-[24px] text-black">Select Amount</div>
              <div className="relative w-full">
                <input
                  type="number"
                  step={0.1}
                  min={0}
                  className="flex h-[48px] w-available rounded-full border-none bg-white px-[24px] py-0 text-[18px] leading-[24px] text-black outline-none"
                  value={withdrawAmount}
                  onChange={e => setWithdrawAmount(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-col gap-[4px]">
              <div className="text-[14px] font-medium leading-[24px] text-black">Withdraw To</div>
              <div className="relative w-full">
                <input
                  className="flex h-[48px] w-available rounded-full border-none bg-white px-[24px] py-0 text-[18px] leading-[24px] text-black outline-none"
                  value={withdrawAddress}
                  onChange={e => setWithdrawAddress(e.target.value)}
                />
              </div>
            </div>
            <button
              onClick={onWithdrawTokensClick}
              disabled={isLoading}
              className="mt-[20px] cursor-pointer rounded-[40px] border-[1px] border-black bg-black px-[16px] py-[8px] text-[16px] font-medium text-white transition-all hover:bg-white hover:text-black"
            >
              {isLoading ? 'Processing...' : 'Withdraw'}
            </button>
          </div>
          {/* <div className="flex h-fit min-w-[452px] flex-col gap-[16px] rounded-[24px] bg-[#F3F4FE] p-[40px]">
            <div className="text-[20px] font-semibold leading-[32px] text-[#0C1C46]">
              Set USDC Deposit Fee
            </div>
            <div className="flex flex-col gap-[4px]">
              <div className="text-[14px] font-medium leading-[24px] text-[#6D7790]">
                Select Amount
              </div>
              <div className="relative w-full">
                <input
                  type="number"
                  step={0.01}
                  min={0}
                  className="flex h-[48px] w-available rounded-full border-none bg-white px-[24px] py-0 text-[18px] leading-[24px] text-[#0C1C46] outline-none"
                  value={feeAmount}
                  onChange={e => setFeeAmount(e.target.value)}
                />
              </div>
            </div>
            <button
              onClick={onSetFeeClick}
              disabled={isLoading}
              className="mt-[20px] cursor-pointer rounded-[40px] border-[1px] border-[#1127E3] bg-[#1127E3] px-[16px] py-[8px] text-[16px] font-medium text-white transition-all hover:bg-white hover:text-[#1127E3]"
            >
              {isLoading ? 'Processing...' : 'Set Fee'}
            </button>
          </div> */}
        </div>
        <div className="relative">
          <div className="mb-4 text-[24px] font-semibold text-[#0C1C46]">USDC Outflows</div>
          <table className="w-full table-fixed border-collapse">
            <thead
              style={{
                borderTop: '1px solid rgba(17, 39, 227, 0.20)',
                borderBottom: '1px solid rgba(17, 39, 227, 0.20)',
              }}
            >
              <tr className="!text-[14px] text-black">
                <td className="p-[16px] font-semibold">Date</td>
                <td className="p-[16px] font-semibold">Type</td>
                <td className="p-[16px] font-semibold">Caller</td>
                <td className="p-[16px] font-semibold">Recipient</td>
                <td className="p-[16px] font-semibold">Amount (USDC)</td>
                <td className="p-[16px] font-semibold">Etherscan</td>
              </tr>
            </thead>
            <tbody>
              {data.map(v => (
                <tr key={v.id} className="odd:bg-[#F3F4FE]">
                  <td className="p-[16px]">{dateToShorten(v.date)}</td>
                  <td className="p-[16px]">{v.type}</td>
                  <td className="p-[16px]">{shortenAddress(v.caller)}</td>
                  <td className="p-[16px]">{shortenAddress(v.recipient)}</td>
                  <td className="p-[16px]">{v.amount}</td>
                  <td className="p-[16px]">
                    <Link
                      to={v.explorerLink}
                      className="text-[16px] text-[#1127E3] no-underline hover:underline"
                      target="_blank"
                    >
                      Link
                    </Link>
                  </td>
                </tr>
              ))}
              {/*<tr*/}
              {/*  className="bg-[#F3F4FE]"*/}
              {/*  style={{*/}
              {/*    borderTop: '1px solid rgba(17, 39, 227, 0.20)',*/}
              {/*    borderBottom: '1px solid rgba(17, 39, 227, 0.20)',*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <td />*/}
              {/*  <td />*/}
              {/*  <td />*/}
              {/*  <td />*/}
              {/*  <td className="p-[16px] font-semibold">{total} USDC</td>*/}
              {/*  <td />*/}
              {/*</tr>*/}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};
