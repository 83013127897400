import { useContactUsModal } from 'providers/ContactUsModalProvider';
import Modal from 'react-modal';

import { ContactModal } from './ContactStep';
import { EligibleModal } from './EligibleStep';
import { ThankForInterestModal } from './ThankForInterestStep';

const ContactUsModal = () => {
  const { isModalOpened, modalStep, closeModal, setModalStep } = useContactUsModal();

  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      onRequestClose={closeModal}
      isOpen={isModalOpened}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-50%, -50%)',
          zIndex: 15,
          maxWidth: '500px',
          width: '90%',
          padding: '0',
          borderRadius: '12px',
          border: 'none',
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 15,
        },
      }}
    >
      {modalStep === 0 ? (
        <EligibleModal setModalStep={setModalStep} onClose={closeModal} />
      ) : modalStep === 1 ? (
        <ContactModal setModalStep={setModalStep} onClose={closeModal} />
      ) : (
        <ThankForInterestModal onClose={closeModal} />
      )}
    </Modal>
  );
};

export default ContactUsModal;
