import type { BigNumber } from 'ethers';
import { ethers } from 'ethers';
import { useMemo } from 'react';
import { convertToBase18 } from 'utils/decimalsConvertor';
import { useAccount, useContractRead, useNetwork } from 'wagmi';

import { getAddressesByChain } from 'constants/addresses';
import type { Token } from 'constants/tokens';
import { getMbasisToken, getTokenByAddress } from 'constants/tokens';

import { DepositVault } from '../abis/DepositVault';
import { ERC20 } from '../abis/ERC20';
import { RedemptionVault } from '../abis/RedemptionVault';

export const useMbasisTokenData = () => {
  const { address } = useAccount();
  const { chain } = useNetwork();

  const mbasisToken = useMemo(() => getMbasisToken(chain?.network), [chain]);
  const addresses = useMemo(() => getAddressesByChain(chain?.network), [chain]);

  const { data: depositTokensRaw } = useContractRead({
    abi: DepositVault.abi,
    address: addresses.mbasisDepositVault,
    functionName: 'getPaymentTokens',
    structuralSharing: (prev, next) => (prev?.toString() === next?.toString() ? prev : next),
  });

  const { data: redeemTokensRaw } = useContractRead({
    abi: RedemptionVault.abi,
    address: addresses.mbasisRedemptionVault,
    functionName: 'getPaymentTokens',
    structuralSharing: (prev, next) => (prev?.toString() === next?.toString() ? prev : next),
  });

  const depositTokens = useMemo(
    () =>
      depositTokensRaw
        ? (depositTokensRaw
            .map(token => getTokenByAddress(chain?.network, token))
            .filter(t => t !== undefined) as Token[])
        : [],
    [depositTokensRaw],
  );

  const redeemTokens = useMemo(
    () =>
      redeemTokensRaw
        ? (redeemTokensRaw
            .map(token => getTokenByAddress(chain?.network, token))
            .filter(t => t !== undefined) as Token[])
        : [],
    [redeemTokensRaw],
  );

  const {
    data: mbasisBalanceRaw,
    isLoading: isLoadingMbasisBalance,
    isError: isErrorMbasisBalance,
    isSuccess: isSuccessMbasisBalance,
  } = useContractRead({
    abi: ERC20.abi,
    address: mbasisToken.address,
    functionName: 'balanceOf',
    args: address ? [address] : undefined,
    watch: true,
    enabled: !!address,
  });

  const {
    data: usdcBalanceRaw,
    isLoading: isLoadingUsdcBalance,
    isError: isErrorUsdcBalance,
    isSuccess: isSuccessUsdcBalance,
  } = useContractRead({
    abi: ERC20.abi,
    address: depositTokens[0]?.address,
    functionName: 'balanceOf',
    args: address ? [address] : undefined,
    watch: true,
    enabled: !!address && !!depositTokens?.[0]?.address,
  });

  const {
    data: usdcMbasisAllowanceRaw,
    isLoading: isLoadingUsdcMbasisAllowance,
    isError: isErrorUsdcMbasisAllowance,
    isSuccess: isSuccessUsdcMbasisAllowance,
  } = useContractRead({
    abi: ERC20.abi,
    address: depositTokens[0]?.address,
    functionName: 'allowance',
    args: address ? [address, addresses.mbasisDepositVault] : undefined,
    watch: true,
    enabled: !!address && !!depositTokens?.[0]?.address,
  });

  const {
    data: mbasisAllowanceRaw,
    isLoading: isLoadingMbasisAllowance,
    isError: isErrorMbasisAllowance,
    isSuccess: isSuccessMbasisAllowance,
  } = useContractRead({
    abi: ERC20.abi,
    address: mbasisToken.address,
    functionName: 'allowance',
    args: address ? [address, addresses.mbasisRedemptionVault] : undefined,
    watch: true,
    enabled: !!address && !!mbasisToken?.address,
  });

  const allowances = useMemo(
    () =>
      depositTokens?.[0]
        ? {
            [depositTokens[0].name]: convertToBase18(
              ethers.BigNumber.from(usdcMbasisAllowanceRaw?.toString() ?? '0'),
              depositTokens[0].decimals,
            ),
          }
        : {},
    [depositTokens, usdcMbasisAllowanceRaw, address],
  );

  const redeemAllowance = useMemo(
    () =>
      convertToBase18(
        ethers.BigNumber.from(mbasisAllowanceRaw?.toString() ?? '0'),
        mbasisToken?.decimals ?? 18,
      ),
    [mbasisToken, mbasisAllowanceRaw, address],
  );

  const balances = useMemo<Record<string, BigNumber>>(
    () =>
      depositTokens?.[0]
        ? {
            [depositTokens[0].name]: convertToBase18(
              ethers.BigNumber.from(usdcBalanceRaw?.toString() ?? '0'),
              depositTokens[0].decimals,
            ),
            [mbasisToken.name]: convertToBase18(
              ethers.BigNumber.from(mbasisBalanceRaw?.toString() ?? '0'),
              mbasisToken.decimals,
            ),
          }
        : {},
    [depositTokens, usdcBalanceRaw, mbasisToken, mbasisBalanceRaw, address],
  );

  return {
    token: mbasisToken,
    depositAllowances: allowances,
    redeemAllowance,
    tokenBalances: balances,
    depositTokens,
    redeemTokens,
    isLoading:
      isLoadingUsdcMbasisAllowance ||
      isLoadingMbasisBalance ||
      isLoadingUsdcBalance ||
      isLoadingMbasisAllowance,
    isError:
      isErrorUsdcMbasisAllowance ||
      isErrorMbasisBalance ||
      isErrorUsdcBalance ||
      isErrorMbasisAllowance,
    isSuccess:
      isSuccessUsdcMbasisAllowance ||
      isSuccessMbasisBalance ||
      isSuccessUsdcBalance ||
      isSuccessMbasisAllowance,
  };
};
