export const MtbillLogoCircle = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="35"
      height="36"
      viewBox="0 0 35 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 35.5C27.165 35.5 35 27.665 35 18C35 8.33502 27.165 0.5 17.5 0.5C7.83502 0.5 0 8.33502 0 18C0 27.665 7.83502 35.5 17.5 35.5Z"
        fill="#C8F2AB"
      />
      <path
        d="M15.1839 23.384C14.6897 23.0467 13.9943 23.1115 13.6563 23.6052C13.3929 23.99 13.1355 24.3798 12.884 24.774C12.6208 25.1865 12.7036 25.7317 13.0954 26.0247C14.5024 27.077 16.1469 27.8395 17.8517 28.3496C18.0287 28.4026 18.2175 28.4025 18.3945 28.3496C23.3219 26.8743 27.4045 23.3434 27.3422 17.6774C27.3208 15.9906 27.3312 14.2944 27.338 12.6028C27.3411 11.8287 26.5023 11.3517 25.852 11.7717C22.1404 14.1684 18.82 17.1086 16.0229 20.4772C15.6823 20.8874 15.7346 21.4975 16.1608 21.8178C16.581 22.1335 16.9875 22.3916 17.3804 22.592C17.7538 22.7824 18.1976 22.6573 18.4728 22.3412C19.9581 20.6349 21.5836 19.049 23.3272 17.5926C23.5827 17.3792 23.9788 17.5626 23.9788 17.8955C24.018 21.454 21.4805 23.6873 18.3936 24.6418C18.2174 24.6963 18.0287 24.6963 17.8524 24.642C16.9064 24.3503 15.9914 23.935 15.1839 23.384Z"
        fill="#14223D"
      />
      <path
        d="M9.88602 22.2443C10.1982 22.8821 11.0639 22.8711 11.4695 22.2882C14.5758 17.8251 18.5259 13.9651 23.1094 10.9187C23.8106 10.4526 23.6986 9.37897 22.8909 9.14142C22.3508 8.98259 21.803 8.83665 21.2477 8.70467C20.978 8.64057 20.6939 8.69548 20.4667 8.85428C16.1269 11.8876 12.3928 15.6588 9.44589 19.9921C9.28757 20.2249 9.2356 20.5154 9.31621 20.7851C9.46868 21.2954 9.65947 21.7814 9.88602 22.2443Z"
        fill="#1AAB9B"
      />
      <path
        d="M8.89061 13.9991C8.89309 14.948 10.1215 15.3831 10.7571 14.6785C12.951 12.2462 15.4257 10.0549 18.1366 8.15814C18.1501 8.14871 18.1428 8.12703 18.1282 8.1195C18.1246 8.11764 18.1205 8.11665 18.1165 8.11721C14.9868 8.56071 12.0977 9.37831 9.45394 10.5836C9.10494 10.7427 8.88619 11.0945 8.88641 11.478C8.88697 12.4183 8.88868 13.259 8.89061 13.9991Z"
        fill="#14223D"
      />
    </svg>
  );
};
