export const MbasisLogoCircle = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="64" height="64" rx="32" fill="#E8E5FF" />
      <path
        d="M33.1008 35.1721L36.5996 32.9275C36.5996 32.9275 36.6336 21.5262 36.547 19.6691C36.4603 17.812 36.1227 15.2189 35.021 12.3021C34.0693 9.78238 32.8449 7.80813 31.8115 6.39868C30.8309 7.74133 29.7386 9.49125 28.8124 11.6444C27.6099 14.4398 27.0936 16.9692 26.8657 18.8797C26.8657 28.1433 26.8657 23.6063 26.8657 32.8698L30.3558 35.172L30.2857 19.3402C30.2884 17.4957 30.4398 15.3627 30.882 13.0257C31.1198 11.769 31.413 10.6048 31.7326 9.53962C32.1436 10.6289 32.5361 11.9189 32.8112 13.3875C33.2425 15.6899 33.2512 17.7183 33.127 19.3073C33.1183 29.1957 33.1095 25.2837 33.1008 35.1721Z"
        fill="#051C2C"
      />
      <path
        d="M34.8359 50.4854C34.8191 50.6687 34.7475 51.1672 34.3361 51.6365C33.9362 52.0924 33.4466 52.257 33.2574 52.3107C33.4169 52.3589 34.9589 52.8534 35.4936 54.3826C36.0799 56.0593 34.9067 57.4118 34.8359 57.4905C34.656 57.6779 33.8323 58.495 32.4769 58.5923C30.7982 58.7126 29.7078 57.6279 29.5744 57.4905C29.434 57.3435 28.6306 56.4747 28.7588 55.2212C28.8515 54.315 29.3786 53.7203 29.5744 53.5193C29.8741 53.2501 30.3492 52.8587 30.995 52.4709C31.6233 52.0935 32.0317 51.9486 32.797 51.5829C33.2916 51.3467 33.9995 50.9874 34.8359 50.4854Z"
        fill="#051C2C"
      />
      <path
        d="M34.8354 48.4896L34.8354 45.875L29.3766 49.0198L29.3766 51.5234L34.8354 48.4896Z"
        fill="#051C2C"
      />
      <path
        d="M32.0132 40.0695L38.5803 34.7695L40.2286 36.3154L40.1904 36.3513L40.2286 36.3513L40.2286 45.8008L37.4836 45.8008L37.4836 39.7467L31.9877 44.1991L26.5173 39.7706L26.5173 45.8008L23.7715 45.8008L23.7715 36.3513L25.4589 34.7695L32.0132 40.0695Z"
        fill="#2400FF"
      />
    </svg>
  );
};
