export const generateCsvContent = (objs: Record<string, unknown>[]) => {
  if (objs.length > 0) {
    const obj = objs[0];
    const headers = Object.keys(obj).join(',');
    const values = objs.map(obj => Object.values(obj).join(',')).join('\n');
    return `${headers}\n${values}`;
  }
  return '';
};

export const generateCsvContentFromArray = (obj: [string, string][]) => {
  return generateCsvContent([Object.fromEntries(obj)]);
};

const generateFile = async (name: string, content: string) => {
  const file = new File(['\ufeff' + content], name, { type: 'text/csv;charset=utf-8' });
  const url = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = file.name;
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

export const downloadCsv = async (name: string, arr: [string, string][]) =>
  generateFile(name, generateCsvContentFromArray(arr));

export const downloadCsvFromObj = async (name: string, obj: Record<string, unknown>[]) =>
  generateFile(name, generateCsvContent(obj));
