import type { FC, PropsWithChildren } from 'react';

import BG from '../assets/imgs/new/bg.avif';

export const BackgroundProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <img
        fetch-priority="high"
        src={BG}
        alt="bt-top"
        className="absolute left-0 top-0 z-0 h-full w-full rotate-180"
      />
      <div className="relative z-10 flex w-full flex-col">{children}</div>
    </>
  );
};
