import type { UserData } from './auth';
import type { BaseEntity } from './client';
import { api } from './client';

export const GET_KYC_URL = 'kyc';
export const PATCH_KYC_URL = 'kyc';
export const POST_KYC_SEAL_URL = 'kyc/seal';
export const PUT_KYC_INDIVIDUAL_DOC_URL = 'kyc/individual/document';
export const PUT_KYC_ENTITY_DOC_URL = 'kyc/entity/document';
export const GET_KYC_ADMIN_ALL = 'kyc/admin/all';
export const GET_KYC_ADMIN_ADDRESS = 'kyc/admin';
export const PATCH_KYC_ADMIN_ACCEPT = 'kyc/admin/accept';
export const PATCH_KYC_ADMIN_REJECT = 'kyc/admin/reject';
export const PATCH_KYC_ADMIN_REQUEST_CHANGES = 'kyc/admin/request-changes';
export const GET_KYC_ADMIN_FILE = 'kyc/admin/file';

export interface FileData extends BaseEntity {
  id: string;
  data: string;
  extension: string;
}
export interface KycStatus {
  data?: {
    id: number;
    ethAddress?: string;
    investorType?: number;
    phoneNumber?: string;
    isSealed: boolean;
    isAccepted: boolean;
    isChangesRequested: boolean;
    isRejected: boolean;
    revisionComment?: string;
    user?: UserData['userData']['user'];
    individualKycData?: {
      id: number;
      firstName?: string;
      lastName?: string;
      placeOfBirth?: string;
      dateOfBirth?: string;
      nationality?: string;
      residentialAddress?: string;
      countryOfResidence?: string;
      tin?: string;
      profession?: string;
      documentFile?: FileData;
      kycData?: unknown;
    } & BaseEntity;
    entityKycData?: {
      id: number;
      legalCompanyName?: string;
      registerNumber?: string;
      domicileCountry?: string;
      dateOfEstablishment?: string;
      dateOfRegistration?: string;
      personsAuthorizedToSign?: string;
      commercialRegister?: FileData;
      ultimateBeneficialOwnerForm?: FileData;
      passportOfUBO?: FileData;
      kycData?: unknown;
    } & BaseEntity;
  } & BaseEntity;
}

export const getKycStatus = async (jwt: string | null | undefined) => {
  if (!jwt) {
    return null;
  }
  const response = await api.get<KycStatus>(GET_KYC_URL, {
    headers: { Authorization: `Bearer ${jwt}` },
  });
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Unable to get kyc status');
};

export interface KycPatchRequest {
  shared?: {
    ethAddress?: string;
    investorType?: number;
    phoneNumber?: string;
  };
  individual?: {
    firstName?: string;
    lastName?: string;
    placeOfBirth?: string;
    dateOfBirth?: string;
    nationality?: string;
    residentialAddress?: string;
    countryOfResidence?: string;
    tin?: string;
    profession?: string;
  };
  entity?: {
    legalCompanyName?: string;
    registerNumber?: string;
    domicileCountry?: string;
    dateOfEstablishment?: string;
    dateOfRegistration?: string;
    personsAuthorizedToSign?: string;
  };
}
export const patchKycStatus = async (jwt: string | null | undefined, data: KycPatchRequest) => {
  if (!jwt) {
    return null;
  }
  const response = await api.patch<KycStatus>(PATCH_KYC_URL, data, {
    headers: { Authorization: `Bearer ${jwt}` },
  });
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Unable to patch kyc status');
};

export const putIndividualKycDocument = async (jwt: string | null | undefined, data: FormData) => {
  if (!jwt) {
    return null;
  }
  const response = await api.put(PUT_KYC_INDIVIDUAL_DOC_URL, data, {
    headers: { Authorization: `Bearer ${jwt}`, 'Content-Type': 'multipart/form-data' },
  });
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Unable to put individual kyc document');
};

export const putEntityKycDocument = async (
  jwt: string | null | undefined,
  key: 'ultimateBeneficialOwnerForm' | 'commercialRegister' | 'passportOfUBO',
  data: FormData,
) => {
  if (!jwt) {
    return null;
  }
  data.append('key', key);
  const response = await api.put(PUT_KYC_ENTITY_DOC_URL, data, {
    headers: { Authorization: `Bearer ${jwt}`, 'Content-Type': 'multipart/form-data' },
  });
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Unable to put entity kyc document');
};

export const postKycSeal = async (jwt: string | null | undefined) => {
  if (!jwt) {
    return null;
  }
  const response = await api.post<KycStatus>(POST_KYC_SEAL_URL, undefined, {
    headers: { Authorization: `Bearer ${jwt}` },
  });
  if (response.status === 201) {
    return response.data;
  }
  throw new Error('Unable to seal kyc status');
};

export interface KycAdminAllResponse {
  data: NonNullable<KycStatus['data']>[];
}
export const getKycAdminAll = async (jwt: string | null | undefined) => {
  if (!jwt) {
    return null;
  }
  const response = await api.get<KycAdminAllResponse>(GET_KYC_ADMIN_ALL, {
    headers: { Authorization: `Bearer ${jwt}` },
  });
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Unable to get kyc admin all');
};

export interface KycAdminAddressResponse {
  data: NonNullable<KycStatus['data']>;
}
export const getKycAdminAddress = async (jwt: string | null | undefined, address?: string) => {
  if (!jwt || !address) {
    return null;
  }
  const response = await api.get<KycAdminAddressResponse>(`${GET_KYC_ADMIN_ADDRESS}/${address}`, {
    headers: { Authorization: `Bearer ${jwt}` },
  });
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Unable to get kyc admin by address');
};

export const patchAcceptKyc = async (jwt: string | null | undefined, address?: string) => {
  if (!jwt || !address) {
    return null;
  }
  const response = await api.patch(
    `${PATCH_KYC_ADMIN_ACCEPT}`,
    {
      userAddress: address,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Unable to patch accept kyc');
};

export const patchRejectKyc = async (
  jwt: string | null | undefined,
  address?: string,
  revisionComment?: string,
) => {
  if (!jwt || !address) {
    return null;
  }
  const response = await api.patch(
    `${PATCH_KYC_ADMIN_REJECT}`,
    {
      userAddress: address,
      revisionComment: revisionComment ?? '',
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Unable to patch reject kyc');
};

export const patchRequestChangesKyc = async (
  jwt: string | null | undefined,
  address?: string,
  revisionComment?: string,
) => {
  if (!jwt || !address) {
    return null;
  }
  const response = await api.patch(
    `${PATCH_KYC_ADMIN_REQUEST_CHANGES}`,
    {
      userAddress: address,
      revisionComment: revisionComment ?? '',
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Unable to patch request changes kyc');
};
