import { useWeb3Modal } from '@web3modal/wagmi/react';
import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useAccount, useDisconnect } from 'wagmi';

import { useAuth } from '../../hooks/useAuth';
import { shortenAddress } from '../../utils/shortenAddress';

export const Auth = () => {
  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const { open } = useWeb3Modal();
  const { isLoading, signIn, isAdmin } = useAuth();

  const onSignInClick = useCallback(() => {
    if (address && !isLoading) {
      return signIn();
    }
  }, [address, isLoading, signIn]);

  useEffect(() => {
    if (isAdmin !== undefined && !isAdmin && address) {
      toast.error('You are not an admin');
    }
  }, [isAdmin]);

  return (
    <div className="relative flex h-full w-full items-center justify-center bg-[black]">
      <div className="absolute bottom-0 right-0 z-[1] overflow-hidden">
        <svg
          width="1440"
          height="1000"
          viewBox="0 0 1440 1000"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1424.25 923.381C1565.15 1045.43 1679.83 1170.74 1751.35 1274.68C1787.12 1326.65 1812.06 1373.24 1824.11 1411.37C1836.17 1449.55 1835.24 1479.01 1819.65 1497C1804.07 1514.99 1775.05 1520.12 1735.54 1513.62C1696.08 1507.14 1646.41 1489.09 1589.86 1461.11C1476.79 1405.15 1336.39 1309.51 1195.5 1187.47C1054.6 1065.42 939.913 940.103 868.392 836.17C832.627 784.199 807.681 737.611 795.632 699.478C783.568 661.295 784.507 631.84 800.09 613.851C815.672 595.862 844.691 590.73 884.204 597.224C923.667 603.71 973.337 621.754 1029.88 649.738C1142.95 705.702 1283.35 801.335 1424.25 923.381Z"
            stroke="white"
            strokeOpacity="0.15"
            strokeWidth="1.54462"
          />
          <path
            d="M974.938 1442.1C1115.84 1564.14 1230.52 1689.46 1302.04 1793.39C1337.81 1845.36 1362.75 1891.95 1374.8 1930.08C1386.87 1968.27 1385.93 1997.72 1370.34 2015.71C1354.76 2033.7 1325.74 2038.83 1286.23 2032.34C1246.77 2025.85 1197.1 2007.81 1140.56 1979.82C1027.48 1923.86 887.086 1828.23 746.188 1706.18C605.289 1584.13 490.605 1458.82 419.083 1354.89C383.319 1302.91 358.373 1256.33 346.324 1218.19C334.26 1180.01 335.199 1150.56 350.781 1132.57C366.363 1114.58 395.383 1109.45 434.896 1115.94C474.358 1122.42 524.028 1140.47 580.57 1168.45C693.644 1224.42 834.039 1320.05 974.938 1442.1Z"
            stroke="white"
            strokeOpacity="0.15"
            strokeWidth="1.54462"
          />
          <path
            d="M1465.09 876.222C1605.99 998.269 1720.68 1123.58 1792.2 1227.52C1827.96 1279.49 1852.91 1326.08 1864.96 1364.21C1877.02 1402.39 1876.08 1431.85 1860.5 1449.84C1844.92 1467.83 1815.9 1472.96 1776.39 1466.46C1736.92 1459.98 1687.25 1441.93 1630.71 1413.95C1517.64 1357.99 1377.24 1262.35 1236.34 1140.31C1095.45 1018.26 980.761 892.944 909.239 789.011C873.475 737.04 848.529 690.452 836.48 652.318C824.416 614.136 825.355 584.681 840.937 566.692C856.519 548.703 885.539 543.571 925.052 550.065C964.515 556.55 1014.18 574.595 1070.73 602.579C1183.8 658.542 1324.2 754.176 1465.09 876.222Z"
            stroke="white"
            strokeOpacity="0.15"
            strokeWidth="1.54462"
          />
          <path
            d="M1015.78 1394.94C1156.68 1516.99 1271.36 1642.3 1342.88 1746.23C1378.65 1798.21 1403.59 1844.79 1415.64 1882.93C1427.71 1921.11 1426.77 1950.56 1411.18 1968.55C1395.6 1986.54 1366.58 1991.67 1327.07 1985.18C1287.61 1978.7 1237.94 1960.65 1181.4 1932.67C1068.32 1876.7 927.926 1781.07 787.027 1659.02C646.129 1536.98 531.445 1411.66 459.923 1307.73C424.159 1255.76 399.213 1209.17 387.164 1171.04C375.099 1132.85 376.039 1103.4 391.621 1085.41C407.203 1067.42 436.223 1062.29 475.736 1068.78C515.198 1075.27 564.868 1093.31 621.41 1121.3C734.483 1177.26 874.879 1272.89 1015.78 1394.94Z"
            stroke="white"
            strokeOpacity="0.15"
            strokeWidth="1.54462"
          />
          <path
            d="M1505.94 829.068C1646.84 951.114 1761.52 1076.43 1833.05 1180.36C1868.81 1232.34 1893.76 1278.92 1905.81 1317.06C1917.87 1355.24 1916.93 1384.69 1901.35 1402.68C1885.77 1420.67 1856.75 1425.8 1817.23 1419.31C1777.77 1412.82 1728.1 1394.78 1671.56 1366.8C1558.49 1310.83 1418.09 1215.2 1277.19 1093.15C1136.29 971.106 1021.61 845.79 950.087 741.857C914.323 689.885 889.377 643.298 877.328 605.164C865.263 566.982 866.203 537.527 881.785 519.538C897.367 501.549 926.387 496.417 965.9 502.911C1005.36 509.396 1055.03 527.441 1111.57 555.425C1224.65 611.388 1365.04 707.021 1505.94 829.068Z"
            stroke="white"
            strokeOpacity="0.15"
            strokeWidth="1.54462"
          />
          <path
            d="M1056.63 1347.78C1197.53 1469.83 1312.21 1595.15 1383.73 1699.08C1419.5 1751.05 1444.44 1797.64 1456.49 1835.77C1468.56 1873.96 1467.62 1903.41 1452.04 1921.4C1436.45 1939.39 1407.43 1944.52 1367.92 1938.03C1328.46 1931.54 1278.79 1913.5 1222.25 1885.51C1109.17 1829.55 968.778 1733.92 827.879 1611.87C686.98 1489.82 572.296 1364.51 500.774 1260.57C465.01 1208.6 440.064 1162.01 428.015 1123.88C415.951 1085.7 416.89 1056.24 432.472 1038.25C448.055 1020.27 477.074 1015.13 516.587 1021.63C556.05 1028.11 605.72 1046.16 662.262 1074.14C775.335 1130.1 915.73 1225.74 1056.63 1347.78Z"
            stroke="white"
            strokeOpacity="0.15"
            strokeWidth="1.54462"
          />
          <path
            d="M1546.79 781.913C1687.69 903.96 1802.37 1029.28 1873.89 1133.21C1909.66 1185.18 1934.6 1231.77 1946.65 1269.9C1958.72 1308.08 1957.78 1337.54 1942.2 1355.53C1926.61 1373.52 1897.59 1378.65 1858.08 1372.16C1818.62 1365.67 1768.95 1347.63 1712.41 1319.64C1599.33 1263.68 1458.94 1168.04 1318.04 1046C1177.14 923.952 1062.46 798.636 990.935 694.702C955.17 642.731 930.224 596.143 918.175 558.01C906.111 519.827 907.05 490.372 922.633 472.383C938.215 454.394 967.234 449.263 1006.75 455.756C1046.21 462.242 1095.88 480.286 1152.42 508.271C1265.5 564.234 1405.89 659.867 1546.79 781.913Z"
            stroke="white"
            strokeOpacity="0.15"
            strokeWidth="1.54462"
          />
          <path
            d="M1097.48 1300.63C1238.38 1422.67 1353.06 1547.99 1424.58 1651.92C1460.35 1703.9 1485.29 1750.48 1497.34 1788.62C1509.4 1826.8 1508.47 1856.25 1492.88 1874.24C1477.3 1892.23 1448.28 1897.36 1408.77 1890.87C1369.31 1884.38 1319.64 1866.34 1263.09 1838.36C1150.02 1782.39 1009.63 1686.76 868.727 1564.71C727.828 1442.67 613.144 1317.35 541.622 1213.42C505.858 1161.45 480.912 1114.86 468.863 1076.72C456.799 1038.54 457.738 1009.09 473.32 991.098C488.902 973.109 517.922 967.977 557.435 974.471C596.897 980.957 646.567 999.001 703.109 1026.99C816.183 1082.95 956.578 1178.58 1097.48 1300.63Z"
            stroke="white"
            strokeOpacity="0.15"
            strokeWidth="1.54462"
          />
          <path
            d="M1587.63 734.755C1728.53 856.802 1843.22 982.118 1914.74 1086.05C1950.5 1138.02 1975.45 1184.61 1987.5 1222.74C1999.56 1260.93 1998.62 1290.38 1983.04 1308.37C1967.46 1326.36 1938.44 1331.49 1898.92 1325C1859.46 1318.51 1809.79 1300.47 1753.25 1272.48C1640.18 1216.52 1499.78 1120.89 1358.88 998.84C1217.98 876.794 1103.3 751.477 1031.78 647.544C996.014 595.573 971.068 548.985 959.019 510.852C946.955 472.669 947.894 443.214 963.476 425.225C979.058 407.236 1008.08 402.104 1047.59 408.598C1087.05 415.084 1136.72 433.128 1193.27 461.112C1306.34 517.076 1446.73 612.709 1587.63 734.755Z"
            stroke="white"
            strokeOpacity="0.15"
            strokeWidth="1.54462"
          />
          <path
            d="M1138.32 1253.47C1279.22 1375.52 1393.91 1500.83 1465.43 1604.77C1501.19 1656.74 1526.14 1703.33 1538.19 1741.46C1550.25 1779.64 1549.31 1809.1 1533.73 1827.09C1518.15 1845.08 1489.13 1850.21 1449.62 1843.71C1410.15 1837.23 1360.48 1819.18 1303.94 1791.2C1190.87 1735.24 1050.47 1639.6 909.574 1517.56C768.675 1395.51 653.992 1270.19 582.47 1166.26C546.705 1114.29 521.76 1067.7 509.711 1029.57C497.646 991.385 498.586 961.93 514.168 943.941C529.75 925.952 558.769 920.82 598.282 927.314C637.745 933.799 687.415 951.844 743.957 979.828C857.03 1035.79 997.426 1131.42 1138.32 1253.47Z"
            stroke="white"
            strokeOpacity="0.15"
            strokeWidth="1.54462"
          />
          <path
            d="M1628.48 687.6C1769.38 809.646 1884.06 934.963 1955.59 1038.9C1991.35 1090.87 2016.3 1137.46 2028.34 1175.59C2040.41 1213.77 2039.47 1243.23 2023.89 1261.22C2008.31 1279.2 1979.29 1284.34 1939.77 1277.84C1900.31 1271.36 1850.64 1253.31 1794.1 1225.33C1681.02 1169.36 1540.63 1073.73 1399.73 951.685C1258.83 829.639 1144.15 704.322 1072.63 600.389C1036.86 548.418 1011.92 501.83 999.867 463.696C987.803 425.514 988.742 396.059 1004.32 378.07C1019.91 360.081 1048.93 354.949 1088.44 361.443C1127.9 367.928 1177.57 385.973 1234.11 413.957C1347.19 469.92 1487.58 565.554 1628.48 687.6Z"
            stroke="white"
            strokeOpacity="0.15"
            strokeWidth="1.54462"
          />
          <path
            d="M1179.17 1206.32C1320.07 1328.36 1434.75 1453.68 1506.28 1557.61C1542.04 1609.58 1566.99 1656.17 1579.04 1694.3C1591.1 1732.49 1590.16 1761.94 1574.58 1779.93C1559 1797.92 1529.98 1803.05 1490.46 1796.56C1451 1790.07 1401.33 1772.03 1344.79 1744.04C1231.72 1688.08 1091.32 1592.45 950.422 1470.4C809.523 1348.35 694.839 1223.04 623.317 1119.1C587.553 1067.13 562.607 1020.55 550.558 982.412C538.494 944.23 539.433 914.775 555.015 896.786C570.597 878.797 599.617 873.665 639.13 880.159C678.593 886.644 728.263 904.689 784.805 932.673C897.878 988.636 1038.27 1084.27 1179.17 1206.32Z"
            stroke="white"
            strokeOpacity="0.15"
            strokeWidth="1.54462"
          />
          <path
            d="M1669.33 640.442C1810.23 762.488 1924.91 887.805 1996.43 991.738C2032.2 1043.71 2057.14 1090.3 2069.19 1128.43C2081.26 1166.61 2080.32 1196.07 2064.74 1214.06C2049.15 1232.05 2020.13 1237.18 1980.62 1230.68C1941.16 1224.2 1891.49 1206.15 1834.95 1178.17C1721.87 1122.21 1581.48 1026.57 1440.58 904.527C1299.68 782.48 1185 657.164 1113.47 553.231C1077.71 501.259 1052.76 454.672 1040.71 416.538C1028.65 378.356 1029.59 348.901 1045.17 330.912C1060.75 312.923 1089.77 307.791 1129.29 314.285C1168.75 320.77 1218.42 338.815 1274.96 366.799C1388.03 422.762 1528.43 518.395 1669.33 640.442Z"
            stroke="white"
            strokeOpacity="0.15"
            strokeWidth="1.54462"
          />
          <path
            d="M1220.01 1159.16C1360.91 1281.21 1475.59 1406.52 1547.12 1510.46C1582.88 1562.43 1607.83 1609.02 1619.88 1647.15C1631.94 1685.33 1631 1714.79 1615.42 1732.78C1599.84 1750.77 1570.82 1755.9 1531.3 1749.4C1491.84 1742.92 1442.17 1724.87 1385.63 1696.89C1272.56 1640.93 1132.16 1545.29 991.262 1423.25C850.363 1301.2 735.679 1175.88 664.157 1071.95C628.393 1019.98 603.447 973.391 591.398 935.258C579.334 897.075 580.273 867.62 595.855 849.631C611.437 831.642 640.457 826.511 679.97 833.004C719.432 839.49 769.102 857.534 825.644 885.519C938.718 941.482 1079.11 1037.12 1220.01 1159.16Z"
            stroke="white"
            strokeOpacity="0.15"
            strokeWidth="1.54462"
          />
          <path
            d="M1710.18 593.287C1851.07 715.334 1965.76 840.65 2037.28 944.583C2073.04 996.555 2097.99 1043.14 2110.04 1081.28C2122.1 1119.46 2121.16 1148.91 2105.58 1166.9C2090 1184.89 2060.98 1190.02 2021.47 1183.53C1982.01 1177.04 1932.34 1159 1875.79 1131.02C1762.72 1075.05 1622.32 979.419 1481.43 857.372C1340.53 735.326 1225.84 610.01 1154.32 506.077C1118.56 454.105 1093.61 407.517 1081.56 369.384C1069.5 331.201 1070.44 301.746 1086.02 283.757C1101.6 265.768 1130.62 260.637 1170.13 267.13C1209.6 273.616 1259.27 291.66 1315.81 319.645C1428.88 375.608 1569.28 471.241 1710.18 593.287Z"
            stroke="white"
            strokeOpacity="0.15"
            strokeWidth="1.54462"
          />
          <path
            d="M1260.86 1112C1401.76 1234.05 1516.44 1359.37 1587.96 1463.3C1623.73 1515.27 1648.67 1561.86 1660.72 1599.99C1672.79 1638.17 1671.85 1667.63 1656.27 1685.62C1640.68 1703.61 1611.66 1708.74 1572.15 1702.24C1532.69 1695.76 1483.02 1677.71 1426.48 1649.73C1313.4 1593.77 1173.01 1498.13 1032.11 1376.09C891.211 1254.04 776.527 1128.72 705.005 1024.79C669.241 972.82 644.295 926.232 632.246 888.099C620.181 849.916 621.121 820.461 636.703 802.472C652.285 784.483 681.305 779.351 720.818 785.845C760.28 792.331 809.95 810.375 866.492 838.359C979.565 894.323 1119.96 989.956 1260.86 1112Z"
            stroke="white"
            strokeOpacity="0.15"
            strokeWidth="1.54462"
          />
          <path
            d="M1751.02 546.133C1891.91 668.18 2006.6 793.496 2078.12 897.429C2113.88 949.401 2138.83 995.988 2150.88 1034.12C2162.94 1072.3 2162 1101.76 2146.42 1119.75C2130.84 1137.74 2101.82 1142.87 2062.31 1136.38C2022.85 1129.89 1973.18 1111.85 1916.63 1083.86C1803.56 1027.9 1663.16 932.265 1522.27 810.218C1381.37 688.172 1266.68 562.855 1195.16 458.922C1159.4 406.951 1134.45 360.363 1122.4 322.23C1110.34 284.047 1111.28 254.592 1126.86 236.603C1142.44 218.614 1171.46 213.482 1210.97 219.976C1250.44 226.462 1300.11 244.506 1356.65 272.49C1469.72 328.454 1610.12 424.087 1751.02 546.133Z"
            stroke="white"
            strokeOpacity="0.15"
            strokeWidth="1.54462"
          />
          <path
            d="M1301.71 1064.85C1442.61 1186.89 1557.29 1312.21 1628.81 1416.14C1664.58 1468.12 1689.52 1514.7 1701.57 1552.84C1713.64 1591.02 1712.7 1620.47 1697.11 1638.46C1681.53 1656.45 1652.51 1661.58 1613 1655.09C1573.54 1648.6 1523.87 1630.56 1467.32 1602.58C1354.25 1546.61 1213.86 1450.98 1072.96 1328.93C932.058 1206.89 817.374 1081.57 745.853 977.637C710.088 925.666 685.142 879.078 673.093 840.944C661.029 802.762 661.968 773.307 677.55 755.318C693.133 737.329 722.152 732.197 761.665 738.691C801.128 745.176 850.798 763.221 907.34 791.205C1020.41 847.168 1160.81 942.802 1301.71 1064.85Z"
            stroke="white"
            strokeOpacity="0.15"
            strokeWidth="1.54462"
          />
          <path
            d="M1791.86 498.976C1932.76 621.022 2047.45 746.339 2118.97 850.272C2154.73 902.243 2179.68 948.831 2191.73 986.965C2203.79 1025.15 2202.85 1054.6 2187.27 1072.59C2171.69 1090.58 2142.67 1095.71 2103.16 1089.22C2063.69 1082.73 2014.02 1064.69 1957.48 1036.7C1844.41 980.741 1704.01 885.107 1563.11 763.061C1422.21 641.014 1307.53 515.698 1236.01 411.765C1200.24 359.794 1175.3 313.206 1163.25 275.072C1151.19 236.89 1152.12 207.435 1167.71 189.446C1183.29 171.457 1212.31 166.325 1251.82 172.819C1291.28 179.304 1340.95 197.349 1397.5 225.333C1510.57 281.296 1650.96 376.93 1791.86 498.976Z"
            stroke="white"
            strokeOpacity="0.15"
            strokeWidth="1.54462"
          />
          <path
            d="M1342.56 1017.69C1483.45 1139.74 1598.14 1265.05 1669.66 1368.99C1705.42 1420.96 1730.37 1467.55 1742.42 1505.68C1754.48 1543.86 1753.54 1573.32 1737.96 1591.31C1722.38 1609.3 1693.36 1614.43 1653.85 1607.93C1614.38 1601.45 1564.71 1583.4 1508.17 1555.42C1395.1 1499.46 1254.7 1403.82 1113.8 1281.78C972.906 1159.73 858.222 1034.41 786.7 930.48C750.936 878.508 725.99 831.921 713.941 793.787C701.877 755.605 702.816 726.15 718.398 708.161C733.98 690.172 763 685.04 802.513 691.534C841.975 698.019 891.645 716.064 948.187 744.048C1061.26 800.011 1201.66 895.644 1342.56 1017.69Z"
            stroke="white"
            strokeOpacity="0.15"
            strokeWidth="1.54462"
          />
          <path
            d="M1832.71 451.82C1973.61 573.866 2088.29 699.183 2159.82 803.116C2195.58 855.087 2220.53 901.675 2232.57 939.808C2244.64 977.991 2243.7 1007.45 2228.12 1025.43C2212.54 1043.42 2183.52 1048.56 2144 1042.06C2104.54 1035.58 2054.87 1017.53 1998.33 989.548C1885.26 933.584 1744.86 837.951 1603.96 715.905C1463.06 593.858 1348.38 468.542 1276.86 364.609C1241.09 312.637 1216.15 266.05 1204.1 227.916C1192.03 189.734 1192.97 160.279 1208.55 142.29C1224.14 124.3 1253.16 119.169 1292.67 125.663C1332.13 132.148 1381.8 150.193 1438.34 178.177C1551.42 234.14 1691.81 329.773 1832.71 451.82Z"
            stroke="white"
            strokeOpacity="0.15"
            strokeWidth="1.54462"
          />
          <path
            d="M1383.4 970.536C1524.3 1092.58 1638.98 1217.9 1710.5 1321.83C1746.27 1373.8 1771.21 1420.39 1783.26 1458.52C1795.33 1496.71 1794.39 1526.16 1778.81 1544.15C1763.22 1562.14 1734.2 1567.27 1694.69 1560.78C1655.23 1554.29 1605.56 1536.25 1549.02 1508.26C1435.94 1452.3 1295.55 1356.67 1154.65 1234.62C1013.75 1112.57 899.066 987.258 827.544 883.325C791.78 831.353 766.834 784.766 754.785 746.632C742.72 708.449 743.66 678.994 759.242 661.005C774.824 643.016 803.844 637.885 843.357 644.378C882.819 650.864 932.489 668.908 989.031 696.893C1102.1 752.856 1242.5 848.489 1383.4 970.536Z"
            stroke="white"
            strokeOpacity="0.15"
            strokeWidth="1.54462"
          />
          <path
            d="M1873.56 404.665C2014.46 526.712 2129.14 652.028 2200.66 755.961C2236.43 807.933 2261.37 854.52 2273.42 892.654C2285.49 930.836 2284.55 960.291 2268.97 978.281C2253.38 996.27 2224.36 1001.4 2184.85 994.908C2145.39 988.422 2095.72 970.377 2039.18 942.393C1926.1 886.43 1785.71 790.797 1644.81 668.75C1503.91 546.704 1389.23 421.387 1317.7 317.454C1281.94 265.483 1256.99 218.895 1244.94 180.762C1232.88 142.579 1233.82 113.124 1249.4 95.1353C1264.98 77.1462 1294 72.0145 1333.52 78.5083C1372.98 84.9938 1422.65 103.038 1479.19 131.023C1592.26 186.986 1732.66 282.619 1873.56 404.665Z"
            stroke="white"
            strokeOpacity="0.15"
            strokeWidth="1.54462"
          />
        </svg>
      </div>
      <div className="absolute left-[60px] top-[44px] z-[2]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="68"
          height="15"
          viewBox="0 0 68 15"
          fill="none"
        >
          <path
            d="M16.964 0.208623V14.854H13.3151V6.07093L9.96499 14.854H7.0203L3.64885 6.05007V14.854H0V0.208623H4.31034L8.51398 10.3477L12.675 0.208623H16.964Z"
            fill="white"
          />
          <path d="M23.2367 0.208623V14.854H19.5879V0.208623H23.2367Z" fill="white" />
          <path
            d="M31.493 0.208623C33.072 0.208623 34.4519 0.514603 35.6326 1.12656C36.8134 1.73853 37.7238 2.60083 38.3639 3.71349C39.0183 4.81224 39.3455 6.08484 39.3455 7.53129C39.3455 8.96384 39.0183 10.2364 38.3639 11.3491C37.7238 12.4618 36.8062 13.3241 35.6113 13.936C34.4306 14.548 33.0578 14.854 31.493 14.854H25.881V0.208623H31.493ZM31.2583 11.7663C32.6382 11.7663 33.7122 11.3978 34.4804 10.6606C35.2485 9.92351 35.6326 8.88039 35.6326 7.53129C35.6326 6.1822 35.2485 5.13213 34.4804 4.38108C33.7122 3.63004 32.6382 3.25452 31.2583 3.25452H29.5299V11.7663H31.2583Z"
            fill="white"
          />
          <path
            d="M50.7095 12.267H45.1188L44.2226 14.854H40.4031L45.823 0.208623H50.048L55.4679 14.854H51.6057L50.7095 12.267ZM49.7706 9.51321L47.9142 4.1516L46.0791 9.51321H49.7706Z"
            fill="white"
          />
          <path
            d="M62.5374 15C61.442 15 60.4605 14.8261 59.5927 14.4784C58.725 14.1307 58.0279 13.6161 57.5016 12.9346C56.9894 12.2531 56.7192 11.4325 56.6907 10.4729H60.5743C60.6312 11.0153 60.8232 11.4325 61.1504 11.7246C61.4776 12.0028 61.9044 12.1419 62.4307 12.1419C62.9713 12.1419 63.398 12.0236 63.711 11.7872C64.024 11.5369 64.1805 11.1961 64.1805 10.765C64.1805 10.4033 64.0524 10.1043 63.7964 9.86787C63.5545 9.63143 63.2487 9.43672 62.8788 9.28373C62.5232 9.13074 62.0111 8.95688 61.3425 8.76217C60.3751 8.4701 59.5856 8.17803 58.9739 7.88595C58.3622 7.59388 57.8359 7.16273 57.3949 6.59249C56.9539 6.02225 56.7334 5.27816 56.7334 4.36022C56.7334 2.99722 57.2384 1.93324 58.2484 1.16829C59.2584 0.38943 60.5743 0 62.196 0C63.8461 0 65.1762 0.38943 66.1862 1.16829C67.1963 1.93324 67.7368 3.00417 67.808 4.38108H63.8604C63.8319 3.90821 63.6541 3.53964 63.3269 3.27538C62.9997 2.99722 62.5801 2.85814 62.068 2.85814C61.627 2.85814 61.2713 2.97636 61.001 3.2128C60.7308 3.43533 60.5956 3.76217 60.5956 4.19332C60.5956 4.6662 60.8232 5.03477 61.2784 5.29903C61.7337 5.56328 62.4449 5.8484 63.4123 6.15438C64.3796 6.47427 65.162 6.78025 65.7595 7.07232C66.3712 7.3644 66.8975 7.7886 67.3385 8.34492C67.7795 8.90125 68 9.61753 68 10.4937C68 11.3282 67.7795 12.0862 67.3385 12.7677C66.9117 13.4492 66.2858 13.9917 65.4607 14.395C64.6357 14.7983 63.6612 15 62.5374 15Z"
            fill="white"
          />
        </svg>
      </div>
      <div className="z-[2] flex w-full max-w-[546px] flex-col items-center gap-[24px] rounded-[24px] bg-white px-[12px] py-[48px] sm:px-[24px] lg:px-[80px]">
        <div className="text-[40px] font-bold text-[black]">Welcome.</div>
        {isConnected && address && (
          <>
            <div className="flex w-full items-center justify-between">
              <div className="flex items-center gap-[8px]">
                <img
                  src="https://cloud.walletconnect.com/images/default-profile-picture.svg"
                  alt=""
                  width="48"
                  height="48"
                />
                <div>
                  <b>{shortenAddress(address)}</b>
                </div>
              </div>
              <button
                type="button"
                className="cursor-pointer border-none bg-transparent text-[16px] font-medium text-black"
                onClick={() => disconnect()}
              >
                Disconnect
              </button>
            </div>
            <button
              type="button"
              className="flex w-full max-w-[426px] cursor-pointer items-center justify-center gap-[8px] rounded-[40px] border-none bg-black px-[32px] py-[12px] text-[16px] font-medium text-white"
              onClick={onSignInClick}
            >
              Sign In
            </button>
          </>
        )}
        {(!isConnected || !address) && (
          <button
            type="button"
            className="flex w-full max-w-[426px] cursor-pointer items-center justify-center gap-[8px] rounded-[40px] border-none bg-black px-[32px] py-[12px] text-[16px] font-medium text-white"
            onClick={() => open({ view: 'Connect' })}
          >
            <img
              src="https://cloud.walletconnect.com/images/walletconnect-icon.svg"
              srcSet="https://cloud.walletconnect.com/images/walletconnect-icon.svg 1x, https://cloud.walletconnect.com/images/walletconnect-icon.svg 2x"
              alt=""
              width="24"
              height="24"
            />
            Connect Wallet
          </button>
        )}
      </div>
    </div>
  );
};
