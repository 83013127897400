import { useContractWrite, useNetwork } from 'wagmi';

import { getAddressesByChain } from 'constants/addresses';

import { mTBILL } from '../abis/mTBILL';

export const usePauseStUsd = () => {
  const { chain } = useNetwork();

  const { writeAsync: pauseAsync } = useContractWrite({
    abi: mTBILL.abi,
    address: getAddressesByChain(chain?.network).mTBILL,
    functionName: 'pause',
  });

  const { writeAsync: unpauseAsync } = useContractWrite({
    abi: mTBILL.abi,
    address: getAddressesByChain(chain?.network).mTBILL,
    functionName: 'unpause',
  });

  return {
    pauseAsync,
    unpauseAsync,
  };
};
