import React from 'react';

export const PrivacyPolicy = () => {
  return (
    <main className="works mb-[200px] !bg-transparent !text-stone-blue">
      <div className="block !bg-transparent">
        <div className="info !top-0 !rounded-b-[0] !bg-transparent">
          <h3 className="!pb-[16px]">Privacy Policy</h3>
          <div className="w-full text-center text-[18px] text-stone-blue">
            Effective Date: 07 June 2023
          </div>
          <div className="mt-[64px] text-[28px] font-semibold text-stone-blue">I. General</div>
          <div className="mt-[18px] text-justify text-[18px]">
            Midas Software GmbH takes its responsibility for data protection and information security
            very seriously. The careful handling of all personal data is a core part of our business
            and the basis for our procedures and processes. With this data protection declaration, we
            inform you within the scope of the European General Data Protection Regulation (GDPR)
            which personal data we collect on which legal basis within the scope of the use of this
            website and the associated functions and for which purposes we process this personal
            data. The use of our site is possible without providing personal data.
          </div>
          <div className="mt-[18px] text-[18px] font-semibold">Person responsible and contact</div>
          <div className="mt-[6px] text-justify text-[18px]">
            Responsible for the web presence of Midas is Midas Software GmbH.
          </div>
          <div className="mt-[18px] text-[18px] font-semibold">Midas Software GmbH</div>
          <div className="mt-[6px] text-justify text-[18px]">Kurfürstendamm 15</div>
          <div className="mt-[6px] text-justify text-[18px]">10719 Berlin</div>
          {/*<div className="mt-[12px] text-justify text-[18px] text-stone-blue">Phone: NUMBER</div>*/}
          <div className="mt-[6px] text-justify text-[18px]">
            Email:{' '}
            <a href="mailto:team@midas.app" className="text-stone-blue">
              team@midas.app
            </a>
          </div>
          <div className="mt-[6px] text-justify text-[18px]">
            Internet:{' '}
            <a href="https://www.midas.app" target="_blank" className="text-stone-blue">
              www.midas.app
            </a>
          </div>
          <div className="mt-[18px] text-justify text-[18px]">
            If you have any questions or suggestions regarding data protection, or if you wish to
            exercise your rights, please do not hesitate to contact us using the contact details
            provided here.
          </div>
          <div className="mt-[40px] text-[28px] font-semibold text-stone-blue">
            II. Data protection officer
          </div>
          <div className="mt-[18px] text-justify text-[18px]">
            You can reach our data protection officer at{' '}
            <a href="mailto:team@midas.app" className="text-stone-blue">
              team@midas.app
            </a>
            .
          </div>
          <div className="mt-[40px] text-[28px] font-semibold text-stone-blue">
            III. Subject of data protection
          </div>
          <div className="mt-[18px] text-justify text-[18px]">
            The subject of data protection is personal data. According to Art. 4 No. 1 GDPR, this is
            all information relating to an identified or identifiable natural person. Personal data
            is also referred to as data in the following.
          </div>
          <div className="mt-[40px] text-[28px] font-semibold text-stone-blue">IV. Cookies</div>
          <div className="mt-[18px] text-justify text-[18px]">
            We use so-called cookies on our site to recognize multiple use of our offer, by the same
            user / Internet connection owner. Cookies are small text files that your Internet browser
            stores on your computer. They serve to optimize our Internet presence and our offers.
            Most of these are so-called “session cookies”, which are deleted after the end of your
            visit.
          </div>
          <div className="mt-[18px] text-justify text-[18px]">
            In some cases, however, these cookies provide information to automatically recognize you.
            This recognition is based on the IP address stored in the cookies. The information
            obtained in this way is used to optimize our offers and to provide you with easier access
            to our site.
          </div>
          <div className="mt-[18px] text-justify text-[18px]">
            You may refuse the use of cookies by selecting the appropriate settings on your browser,
            however please note that if you do this you may not be able to use the full functionality
            of this website.
          </div>
          <div className="mt-[32px] text-[24px] font-semibold text-stone-blue">
            Automated data collection on this website of server data.
          </div>
          <div className="mt-[18px] text-justify text-[18px]">
            For technical reasons, the following data, among others, which your Internet browser
            transmits to us or to our web space provider, is collected (so-called server log files):
          </div>
          <ul className="text-[18px] marker:text-stone-blue">
            <li>Browser type and version.</li>
            <li className="mt-[14px]">Operating system used.</li>
            <li className="mt-[14px]">Website from which you visit us (referrer URL).</li>
            <li className="mt-[14px]">Website that you visit.</li>
            <li className="mt-[14px]">Date and time of your access.</li>
            <li className="mt-[14px]">Your Internet Protocol (IP) address.</li>
          </ul>
          <div className="mt-[18px] text-justify text-[18px]">
            This anonymous data is stored separately from any personal data you may have provided and
            thus does not allow any conclusions to be drawn about a specific person. They are
            evaluated for statistical purposes in order to be able to optimize our Internet presence
            and our offers.
          </div>
          <div className="mt-[18px] text-justify text-[18px]">
            Since it is not possible for Midas Software GmbH to assign your IP address (only your
            Internet provider can do this) and a pattern recognition over a longer period of time is
            omitted by a short-cycle deletion, Midas’s interest in the proper operation of the
            website outweighs any possible interests of visitors that are worthy of protection. The
            legal basis for the processing of the aforementioned data to ensure proper website
            operation are our legitimate interests pursuant to Art. 6(1) lit. f) GDPR. We point out
            that the internet-based data transmission has security gaps, a complete protection
            against access by third parties is therefore impossible.
          </div>
          <div className="mt-[40px] text-[28px] font-semibold text-stone-blue">
            V. User onboarding
          </div>
          <div className="mt-[18px] text-justify text-[18px]">
            In order to use our service, users must onboard as stipulated in our Terms and
            Conditions.
          </div>
          <div className="mt-[18px] text-justify text-[18px]">
            When onboarding, users provide the following information (jointly “Customer Data”):
          </div>
          <ul className="text-[18px] marker:text-stone-blue">
            <li>First and last name.</li>
            <li className="mt-[14px]">A valid e-mail address.</li>
            <li className="mt-[14px]">Country of residence.</li>
            <li className="mt-[14px]">Wallet address.</li>
          </ul>
          <div className="mt-[18px] text-justify text-[18px]">
            Data processing is carried out based on legal provisions which authorise the data
            processing because it is necessary for the provision of this functionality to the user
            (Art. 6(1) lit. b) GDPR).
          </div>
          <div className="mt-[18px] text-justify text-[18px]">
            Customer Data is generally stored until the respective investor closes its account or
            otherwise unregisters for the use of the service, unless statutory retention obligations
            apply, or the Customer Data is necessary for other purposes described herein.
          </div>
          <div className="mt-[40px] text-[28px] font-semibold text-stone-blue">
            VI. Authentication
          </div>
          <div className="mt-[18px] text-justify text-[18px]">
            To buy tokens, investors must verify their identities. In this regard we receive certain
            information from Know Your Customer service provider(s) (“KYC-Provider”) we work with and
            the respective investor chooses to verify its identity with. In this regard, we receive
            the result of any such verification process (i.e. verified “yes” or “no”) and store that
            information.
          </div>
          <div className="mt-[18px] text-justify text-[18px]">
            Data is processed because it is contractually required for the provision of the service
            to the investors (Art. 6(1) lit. b) GDPR), and because compliance with KYC- and
            Anti-Money-Laundering regulations is required by law (Art. 6(1) lit. c) GDPR).
          </div>
          <div className="mt-[18px] text-justify text-[18px]">
            This information is generally stored until the respective investor closes its account or
            otherwise unregisters for the use of the service, unless statutory retention obligations
            apply, or the information is necessary for other purposes described herein.
          </div>
          <div className="mt-[18px] text-justify text-[18px]">
            The data processing is carried out on the basis of legal provisions which authorise the
            data processing because it is necessary for the provision of this functionality to the
            investor (Art. 6(1) lit. b) GDPR). Full name, date and place of birth, nationality,
            address, ZIP-Code, City and country of residence, type of ID document (e.g. identity
            card, passport), ID-document number, issuing authority, country and date of issuance,
            expiry date, signature, copies of front and back of ID document.
          </div>
          <div className="mt-[18px] text-justify text-[18px]">
            Tax information: Tax ID number(s), tax authority.
          </div>
          <div className="mt-[18px] text-justify text-[18px]">
            Bank information: IBAN, Bank name, Bank address.
          </div>
          <div className="mt-[40px] text-[28px] font-semibold text-stone-blue">
            VII. Contact option
          </div>
          <div className="mt-[18px] text-justify text-[18px]">
            On our website, we offer you the opportunity to contact us by e-mail or via a contact
            form. In this case, the information provided by the user will be stored for the purpose
            of processing his contact. The data will not be passed on to third parties. A comparison
            of the data collected in this way with data that may be collected by other components of
            our site also does not take place.
          </div>
          <div className="mt-[40px] text-[28px] font-semibold text-stone-blue">
            VIII. Disclosure of data to third parties in general
          </div>
          <div className="mt-[18px] text-justify text-[18px]">
            Based on Art. 28(1) GDPR, we share personal data with external service providers as our
            processors. The processors may only use the data for the purposes specified by us.
            Including the processors already explicitly mentioned in this Privacy Policy, we use the
            following categories of processors:
          </div>
          <ul className="text-[18px] marker:text-stone-blue">
            <li>IT service providers.</li>
            <li className="mt-[14px]">Cloud service providers.</li>
            <li className="mt-[14px]">Hosting service providers.</li>
            <li className="mt-[14px]">Marketing service providers.</li>
          </ul>
          <div className="mt-[18px] text-justify text-[18px]">
            If it is necessary for the clarification of an illegal use of our website or for legal
            prosecution, personal data will be forwarded to law enforcement authorities and, if
            necessary, to injured third parties. However, this only happens if there are concrete
            indications of unlawful or abusive behavior. A transfer may also take place if this
            serves the enforcement of claims. We are also legally obligated to provide information to
            certain public authorities upon request. These are law enforcement agencies, authorities
            that prosecute administrative offenses subject to fines and the tax authorities. The
            disclosure of this data is based on our overriding legitimate interest according to Art.
            6(1) lit. f) GDPR or based on a legal obligation according to Art. 6(1) lit. c) GDPR.
          </div>
          <div className="mt-[18px] text-justify text-[18px]">
            As part of the organization of our operations, financial accounting and compliance with
            legal obligations, such as archiving, we disclose or transmit here the same data that we
            have processed for the purpose of reimbursement of costs or, if applicable, in connection
            with the assertion, safeguarding or defense of claims from you, if applicable, to the tax
            authorities, consultants, such as tax advisors or auditors, as well as other fee offices
            and payment service providers. The transfer of this data is based on our legitimate
            interest in maintaining our business activities, the performance of our tasks as well as
            for the implementation of the reimbursement of costs, Art. 6(1) lit. f) GDPR or on the
            basis of a legal obligation according to Art. 6(1) lit. c) GDPR.
          </div>
          <div className="mt-[40px] text-[28px] font-semibold text-stone-blue">
            IX. Information / Revocation / Deletion
          </div>
          <div className="mt-[18px] text-justify text-[18px]">
            We delete your personal data as soon as they are no longer required for the purposes for
            which we processed them. We will continue to store your data if we are obliged to do so
            for legal reasons (Art. 6(1) lit. c) GDPR) or if the data is required for a longer period
            of time to secure, assert or enforce legal claims. Insofar as data must be retained for
            legal reasons, processing will be restricted. The data is then no longer available for
            further use. Further storage takes place on the basis of our aforementioned legitimate
            interests in accordance with Art. 6(1) lit. f) GDPR.
          </div>
          <div className="mt-[40px] text-[28px] font-semibold text-stone-blue">
            X. Your rights as a data subject
          </div>
          <div className="mt-[18px] text-justify text-[18px]">
            You have the following rights vis-à-vis us with regard to the personal data concerning
            you:
          </div>
          <div className="mt-[32px] text-[24px] font-semibold text-stone-blue">
            A. Right to restriction of processing
          </div>
          <div className="mt-[18px] text-justify text-[18px]">
            You have the right to demand that we restrict processing in accordance with Art. 18 GDPR.
            This right exists in particular if the accuracy of the personal data is disputed between
            you and us, for the duration that the verification of the accuracy requires, as well as
            in the event that you request restricted processing instead of erasure in the case of an
            existing right to erasure; furthermore, in the event that the data is no longer necessary
            for the purposes pursued by us, but you need it to assert, exercise or defend legal
            claims, as well as if the successful exercise of an objection is still disputed between
            you and us.
          </div>
          <div className="mt-[32px] text-[24px] font-semibold text-stone-blue">
            B. Right to data portability
          </div>
          <div className="mt-[18px] text-justify text-[18px]">
            You have the right to obtain from us the personal data concerning you that you have
            provided to us in a structured, commonly used, machine-readable format in accordance with
            Art. 20 GDPR.
          </div>
          <div className="mt-[32px] text-[24px] font-semibold text-stone-blue">
            C. Right to object to processing
          </div>
          <div className="mt-[18px] text-justify text-[18px]">
            You have the right to object at any time, on grounds relating to your particular
            situation, to the processing of personal data concerning you, which is carried out, inter
            alia, on the basis of Art. 6(1) lit. f) GDPR, in accordance with Art. 21 GDPR. We will
            stop processing your personal data unless we can demonstrate compelling legitimate
            grounds for the processing which override your interests, rights and freedoms, or the
            processing serves the purpose of asserting, exercising or defending legal claims.
          </div>
          <div className="mt-[32px] text-[24px] font-semibold text-stone-blue">
            D. Right to lodge a complaint with a data protection supervisory authority
          </div>
          <div className="mt-[18px] text-justify text-[18px]">
            You have the right to contact a supervisory authority of your choice in case of
            complaints. The competent supervisory authority is
          </div>
          <div className="mt-[18px] text-[18px] font-semibold">
            The Berlin Commissioner for Data Protection and Freedom of Information
          </div>
          <div className="mt-[6px] text-justify text-[18px]">Alt-Moabit 59-61</div>
          <div className="mt-[6px] text-justify text-[18px]">10555 Berlin</div>
          <div className="mt-[18px] text-justify text-[18px]">
            We will store the personal data provided by you when exercising your rights pursuant to
            Art. 15 to 22 GDPR for the purpose of implementing these rights and providing evidence
            thereof. These processing operations are based on Art. 6(1) lit. c) GDPR in conjunction
            with. Art. 15 to 22 GDPR and Section 34(2) BDSG.
          </div>
        </div>
      </div>
    </main>
  );
};
