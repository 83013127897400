import Step1 from 'components/KYS_Steps/Step1';

const Form = () => {
  return (
    <div className="form-block !bg-transparent">
      <div className="form-kys !bg-transparent px-[16px]">
        <div className="main-form box-border !rounded-[32px] !bg-secondary shadow-[0_4px_4px_rgba(0,0,0,0.25)]">
          <Step1 />
        </div>
      </div>
    </div>
  );
};

export default Form;
