import { useAccount, useContractRead, useNetwork } from 'wagmi';

import { getAddressesByChain } from 'constants/addresses';
import { acRoles } from 'constants/roles';

import { MidasAccessControl } from '../abis/MidasAccessControl';

export const useGreenListed = () => {
  const { address, isConnected } = useAccount();
  const { chain } = useNetwork();

  const { data, refetch, isLoading, isSuccess } = useContractRead({
    abi: MidasAccessControl.abi,
    address: getAddressesByChain(chain?.network).ac,
    args: [acRoles.greenListed as `0x${string}`, address as `0x${string}`],
    functionName: 'hasRole',
    enabled: isConnected,
  });

  return { isGreenListed: Boolean(data), isLoading, isSuccess, refetch };
};
