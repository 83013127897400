import close from '../../assets/imgs/mtbill/close.svg';
import dollar from '../../assets/imgs/mtbill/modal/dollar.svg';
import person from '../../assets/imgs/mtbill/modal/person.svg';
import world from '../../assets/imgs/mtbill/modal/world.svg';

export interface EligibleModalProps {
  setModalStep: (step: number) => void;
  onClose: () => void;
}

export const EligibleModal = ({ setModalStep, onClose }: EligibleModalProps) => {
  return (
    <div className="relative flex flex-col gap-8 p-4">
      <div className="absolute right-4 top-4 cursor-pointer" onClick={onClose}>
        <img src={close} alt={close} className="h-[24px] w-[24px]" />
      </div>
      <div className="text-[18px] font-medium leading-[26px] text-black">Eligibility Criteria</div>
      <div className="flex flex-col gap-y-4 text-base text-black sm:gap-y-[21px] sm:text-[18px] sm:leading-[26px]">
        <div className="flex items-center gap-4">
          <img src={world} alt="world" className="self-center" />
          <p>Available to non-US purchasers only.</p>
        </div>
        <div className="flex items-center gap-4">
          <img src={person} alt="person" />
          <p>
            Successfully complete our Know your customer (KYC) and Anti money laundering (AML)
            screening.
          </p>
        </div>
        <div className="flex items-center gap-4">
          <img src={dollar} alt="dollar" className="self-center" />
          <p>The minimum subscription size is 120,000 USDC.</p>
        </div>
      </div>
      <button
        onClick={() => setModalStep(1)}
        className="cursor-pointer rounded-[8px] border-none bg-black py-[10px] text-[18px] text-white"
      >
        Continue
      </button>
    </div>
  );
};
