import type { ChangeEvent } from 'react';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import browserImg from '../assets/imgs/connections/browser-wallets.svg';
import coinbaseWallet from '../assets/imgs/connections/coinbase-wallet.svg';
import {
  checkMetaMaskInstallation,
  checkCoinbaseInstallation,
  authenticateWithMetaMask,
  authenticateWithCoinbase,
  setCustomAddress,
} from '../store/actions/authActions';

interface ConnectionWalletProps {
  closeModal: () => void;
}

const ConnectionWallet = (props: ConnectionWalletProps) => {
  const dispatch = useDispatch();
  const isEmpty = (val: string) => val === null || !(Object.keys(val) || val).length;
  const [customAddress, ChangeCustomAddress] = useState('');

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(checkMetaMaskInstallation());
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(checkCoinbaseInstallation());
  }, [dispatch]);

  const isCryptoWallet = (value: string) => {
    if (!value.startsWith('0x')) {
      return false;
    }

    const validCharacters = /^[a-fA-F0-9]+$/;
    if (!validCharacters.test(value.substring(2))) {
      return false;
    }

    return true;
  };
  const handleCustomAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    ChangeCustomAddress(event.target.value);
  };

  const authenticateWithCustomAddress = () => {
    if (!isEmpty(customAddress) && isCryptoWallet(customAddress)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(setCustomAddress(customAddress));
      props.closeModal();
    }
  };

  return (
    <div className="modal-connect">
      <h2>Connect Wallet.</h2>
      <button
        className="browser-wallets"
        onClick={() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dispatch(authenticateWithMetaMask()).then(() => {
            props.closeModal();
          });
        }}
      >
        <span>Browser Wallet </span>
        <img src={browserImg} />
      </button>
      <button
        className="browser-wallets"
        onClick={() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dispatch(authenticateWithCoinbase()).then(() => {
            props.closeModal();
          });
        }}
      >
        <span>Coinbase wallet</span>
        <img src={coinbaseWallet} />
      </button>
      <p className="read-only-info">
        Track wallet balance in read-only mode
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
        >
          <g clipPath="url(#clip0_1515_6866)">
            <path
              d="M7 0C3.13395 0 0 3.13406 0 7C0 10.8662 3.13395 14 7 14C10.8661 14 14 10.8662 14 7C14 3.13406 10.8661 0 7 0ZM7 3.5C7.48327 3.5 7.875 3.89178 7.875 4.375C7.875 4.85844 7.48327 5.25 7 5.25C6.51673 5.25 6.125 4.85844 6.125 4.375C6.125 3.89178 6.51673 3.5 7 3.5ZM8.3125 10.5H5.6875C5.44589 10.5 5.25 10.3043 5.25 10.0625C5.25 9.82089 5.44589 9.625 5.6875 9.625H6.125V7H5.6875C5.44589 7 5.25 6.80433 5.25 6.5625C5.25 6.32089 5.44589 6.125 5.6875 6.125H7.4375C7.67911 6.125 7.875 6.32089 7.875 6.5625V9.625H8.3125C8.55411 9.625 8.75 9.82089 8.75 10.0625C8.75 10.3043 8.55411 10.5 8.3125 10.5Z"
              fill="#6D7790"
            />
          </g>
          <defs>
            <clipPath id="clip0_1515_6866">
              <rect width="14" height="14" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </p>
      <input
        type="text"
        value={customAddress}
        onChange={handleCustomAddressChange}
        placeholder="Enter ethereum address or surname"
        className="browser-wallets"
      />
      <button className="btn-accept" onClick={authenticateWithCustomAddress}>
        Track wallet
      </button>
      <div className="text-info">
        <p>
          Need help connecting a wallet?<b>Read our FAQ</b>
        </p>
        <div className="small-info">
          Wallets are provided by External Providers and by selecting you agree to Terms of those
          Providers. Your access to the wallet might be reliant on the External Provider being
          operational.
        </div>
      </div>
    </div>
  );
};

export default ConnectionWallet;
