import { useMemo } from 'react';
import { getAddress } from 'viem';
import { useNetwork } from 'wagmi';

import { useDefaultSubgraphQuery } from './useDefaultSubgraphQuery';

import { getExplorerByNetwork } from '../constants/explorers';
import { getTokenByName } from '../constants/tokens';
import { GET_REDEMPTION_VAULT_TOKEN_WITHDRAWALS } from '../graphql/queries.js/transactions';
import { comaFormat } from '../utils/comaFormat';
import { txToExplorerUri } from '../utils/explorer';
import { formatBN } from '../utils/formatBN';

type RedemptionVaultWithdrawal = {
  id: string;
  caller: `0x${string}`;
  token: `0x${string}`;
  withdrawTo: `0x${string}`;
  amount: `${number}`;
  timestamp: `${number}`;
  transactionHash: `0x${string}`;
};

type RedemptionVaultWithdrawalsQuery = {
  withdrawTokenEntityRedemptions: RedemptionVaultWithdrawal[];
};

export const useRedemptionVaultWithdrawals = () => {
  const { chain } = useNetwork();
  const usdc = useMemo(() => getTokenByName(chain?.network, 'USDC'), [chain]);
  const { data: rawData, refetch } = useDefaultSubgraphQuery<RedemptionVaultWithdrawalsQuery>(
    GET_REDEMPTION_VAULT_TOKEN_WITHDRAWALS,
  );

  const [data, total] = useMemo(
    () => [
      (
        rawData?.withdrawTokenEntityRedemptions.map(v => ({
          id: v.id,
          caller: getAddress(v.caller),
          recipient: getAddress(v.withdrawTo),
          amount: comaFormat(formatBN(v.amount, 0, usdc.decimals)),
          explorerLink: txToExplorerUri(getExplorerByNetwork(chain?.name), v.transactionHash),
          date: new Date(+v.timestamp * 1000),
        })) ?? []
      ).sort((a, b) => b.date.getTime() - a.date.getTime()),
      comaFormat(
        formatBN(
          rawData?.withdrawTokenEntityRedemptions.reduce(
            (acc, v) => acc + BigInt(v.amount),
            BigInt(0),
          ) ?? BigInt(0),
          0,
          usdc.decimals,
        ),
      ),
    ],
    [rawData],
  );

  return { data, total, refetch };
};
