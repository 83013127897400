import type { NavigateFunction } from 'react-router-dom';
import { ROUTES } from 'routes/config';

type CustomErrorTextWithBtn = {
  text: string;
  btnText: string;
  btnOnClick?: (navFunc: NavigateFunction) => void;
};

export const isCustomError = (
  err: string | CustomErrorTextWithBtn,
): err is CustomErrorTextWithBtn => {
  return (err as CustomErrorTextWithBtn).text !== undefined;
};

const errors: [string, string | CustomErrorTextWithBtn][] = [
  [
    'dv: invalid amount',
    'Please enter a valid amount in order to initiate this transaction. The USDC outlay cannot be zero for this transaction.',
  ],
  [
    'dv: invalid amount out',
    'Please enter a valid amount in order to initiate this transaction. The mTBILL amount cannot be zero for this transaction.',
  ],
  [
    'dv: usd amount < min',
    'The minimum investment is €100,000 for this transaction. Please enter a USDC outlay that exceeds the minimum amount.',
  ],
  [
    'mv: token not exists',
    'The requested token is not supported. Please input tokens that are supported by Midas, including mTBILL and USDC.',
  ],
  [
    'wmac: hasnt role',
    {
      btnText: 'Contact Us',
      text: 'Your wallet is not greenlisted, please contact us to get access.',
      btnOnClick: navFunc => {
        navFunc(ROUTES.CONTACT_US);
      },
    },
  ],
  [
    'wmac: has role',
    {
      btnText: 'Contact Us',
      text: 'Your account has been suspended. Please contact us in order to initiate a transaction.',
      btnOnClick: navFunc => {
        navFunc(ROUTES.CONTACT_US);
      },
    },
  ],
  ['erc20: insufficient allowance', 'Ensure that you have approved the assets in your wallet.'],
  [
    'rv: 0 amount',
    'Please enter a valid amount in order to initiate this transaction. The mTBILL amount cannot be zero for this transaction.',
  ],
  [
    'rv: amount < min',
    'The minimum redemption amount is €1 for this transaction. Please enter a USDC outlay that exceeds the minimum amount.',
  ],
  [
    'erc20: transfer amount exceeds balance',
    'Your wallet does not have the sufficient balance in order to initiate this transaction.',
  ],
];

export const getHumanError = (error: string): string | CustomErrorTextWithBtn => {
  const found = errors.find(e => error.toLowerCase().includes(e[0]));
  return found ? found[1] : error;
};
