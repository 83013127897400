import { BigExchanger } from 'components/BigExchanger';
import { NotUsBanner } from 'components/NotUsBanner';
import {
  ETFDetails,
  FeeStructure,
  // GenericKPIs,
  GetAssistance,
  MbasisFeatures,
  MtbillFeatures,
  OnchainAddresses,
  Overview,
  Performance,
  // Portfolio,
  // PriceEvolution,
} from 'components/PlatformToken';
import { PriceChangeArrow } from 'components/PriceChangeArrow';
import { MbasisLogoCircle, MtbillLogoCircle } from 'components/svg';
import { useGetKpi } from 'hooks/data/useGetKpi';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { usePriceUpdates } from 'hooks/usePriceUpdates';
import { cn } from 'utils/cn';

import { platformTokenPageConfig } from 'constants/platform-token-page';
import { PlatformToken } from 'constants/tokens';

// import btcIcon from '../assets/imgs/new/available-networks/btc.svg';
import ethIcon from '../assets/imgs/new/available-networks/eth.png';
// import usdtIcon from '../assets/imgs/new/available-networks/usdt.svg';

export const PlatformTokenPage = ({ token }: { token: PlatformToken }) => {
  const config = platformTokenPageConfig[token];
  const priceUpdates = usePriceUpdates(token);
  const { data: kpiData } = useGetKpi();
  const dayPriceGrown = parseFloat(priceUpdates?.dayUpdate || '0') > 0;
  const isMobile = useMediaQuery('only screen and (max-width: 640px)');

  return (
    <>
      <div className="mt-10 sm:mt-4">
        <NotUsBanner token={token} />
      </div>

      <div className="flex w-full max-w-[1440px] flex-col items-center justify-center px-4 pt-6 md:mx-auto md:pt-8">
        <div className="token-card-bg w-full rounded-[24px] border border-white px-3 py-8 pb-[56px] md:py-12 lg:max-w-[1200px]">
          <div className="flex w-full flex-col items-center justify-between">
            <div className="flex flex-col items-center">
              <div className="mb-6 flex flex-col items-center gap-3 text-center md:mb-8 md:gap-4 lg:mb-8">
                <div className="flex items-center gap-4">
                  {token === PlatformToken.MTBILL ? (
                    <MtbillLogoCircle className="h-8 w-8 md:h-16 md:w-16" />
                  ) : (
                    <MbasisLogoCircle className="h-8 w-8 md:h-16 md:w-16" />
                  )}
                  <div className="text-2xl font-semibold text-[#051C2C] md:text-[56px] md:leading-[64px]">
                    {config.name}
                  </div>
                </div>
                <div className="px-2 text-base text-[#636569] md:text-[20px] md:leading-[28px]">
                  {config.description}
                </div>
              </div>
              <div className="grid grid-cols-3 grid-rows-1 items-start divide-x divide-[#051C2C]/10">
                <div className=" flex h-full flex-col items-center pr-[15px] md:gap-2 md:px-10 xl:min-w-[240px]">
                  <div className="text-[20px] font-medium leading-8 text-[#051C2C] md:text-[40px] md:leading-[48px]">
                    ${priceUpdates?.lastPrice ?? '...'}
                  </div>
                  <div className="flex items-center gap-[8px] text-nowrap">
                    {parseFloat(priceUpdates?.dayUpdate || '0') !== 0 && dayPriceGrown ? (
                      <PriceChangeArrow color="#179386" />
                    ) : parseFloat(priceUpdates?.dayUpdate || '0') !== 0 ? (
                      <PriceChangeArrow className="rotate-180" color="#ef4444" />
                    ) : (
                      <div className="flex flex-col gap-1">
                        <PriceChangeArrow color="#051C2C" />
                        <PriceChangeArrow className="rotate-180" color="#051C2C" />
                      </div>
                    )}
                    <div
                      className={cn(
                        'text-sm md:text-base',
                        parseFloat(priceUpdates?.dayUpdate || '0') === 0
                          ? 'text-[#051C2C]'
                          : dayPriceGrown
                          ? 'text-[#179386]'
                          : 'text-red-500',
                      )}
                    >
                      ${Math.abs(parseFloat(priceUpdates?.dayUpdate || '0')).toFixed(2)} today
                    </div>
                  </div>
                </div>
                <div className=" flex h-full flex-col items-center px-[15px] md:gap-2 md:px-10 xl:min-w-[240px]">
                  <div className="text-[20px] font-medium leading-8 text-[#051C2C] md:text-[40px] md:leading-[48px]">
                    {token === PlatformToken.MBASIS ? kpiData?.mBasisAPY : kpiData?.mTbillAPY}%
                  </div>
                  <p className="text-sm text-[#636569] md:text-base">APY</p>
                  {/* <div className="flex items-center gap-[8px]">
                <img src={arrowUp} alt="arrow-up" />
                <div className="text-[16px] leading-6 text-[#179386]">$1.05 today</div>
              </div> */}
                </div>
                <div className=" flex h-full flex-col items-center pl-[15px] md:gap-2 md:px-10 xl:min-w-[240px]">
                  <div className="text-[20px] font-medium leading-8 text-[#051C2C] md:text-[40px] md:leading-[48px]">
                    $
                    {(token === PlatformToken.MBASIS ? kpiData?.mBasisAUM : kpiData?.mTbillAUM) ??
                      '0.00'}
                    m
                  </div>
                  <p className="text-center text-sm text-[#636569] md:text-base">
                    {isMobile ? 'AUM' : 'Assets Under Management'}
                  </p>
                  {/* <div className="flex items-center gap-[8px]">
                <img src={arrowUp} alt="arrow-up" />
                <div className="text-[16px] leading-6 text-[#179386]">$1.05 today</div>
              </div> */}
                </div>
              </div>
              <div className="flex items-center gap-2 py-[34px] text-xl text-[#051C2C] md:pb-[60px]">
                <p className="text-sm text-[#636569] md:text-xl">Available networks:</p>
                <p className="inline-flex items-center gap-2">
                  <img
                    className="max-h-8 max-w-8 rounded-full border-2 border-[#CCCCCC]"
                    src={ethIcon}
                    alt="eth"
                  />
                  Ethereum
                </p>
                {/* {token === PlatformToken.MBASIS && (
                  <div className="flex items-center justify-center rounded-[999px] bg-[#E8E5FF] px-[16px] py-[10px] text-[16px] font-semibold leading-[16px] text-[#2400FF]">
                    {kpiData?.mBasisCurrentCapacity.toLocaleString('en-US')}/
                    {kpiData?.mBasisMaxCapacity.toLocaleString('en-US')} USDT
                  </div>
                )} */}

                {/* <p className="inline-flex items-center gap-2">
                <img className="max-h-8 max-w-8" src={btcIcon} alt="btc" />
                Bitcoin
              </p>
              <p className="inline-flex items-center gap-2">
                <img className="max-h-8 max-w-8" src={usdtIcon} alt="usdt" />
                USDT
              </p> */}
              </div>
              {/* <SubDescription token={token} /> */}
            </div>
          </div>
        </div>

        <div className="rounded-6 -mt-[58px] flex w-full max-w-[600px] items-center justify-center max-sm:ml-[6px]">
          <BigExchanger platformToken={token} />
        </div>

        <GetAssistance />
        <div
          className={cn(
            'mt-[56px] grid grid-cols-1 gap-[56px] rounded-[24px] bg-[#FAFAFA] px-4 py-6 shadow-[0px_4px_24px_0px_#131C2C14] lg:gap-12',
            token === PlatformToken.MTBILL ? 'lg:grid-cols-2' : '',
          )}
        >
          <Overview token={token} />
          {token === PlatformToken.MTBILL ? <Performance token={token} /> : null}
        </div>
        {/* <GenericKPIs /> */}
        {token === PlatformToken.MTBILL ? <MtbillFeatures /> : <MbasisFeatures />}

        {/* Portfolio for mBASIS is temporarily hidden */}
        {/* {token === PlatformToken.MTBILL ? <ETFDetails /> : <Portfolio />} */}
        {token === PlatformToken.MTBILL ? <ETFDetails /> : null}

        <FeeStructure token={token} />
        <OnchainAddresses token={token} />
        {/* <PriceEvolution token={token} /> */}
      </div>
    </>
  );
};
