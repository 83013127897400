import Aside from 'layouts/adminTab';
import Header from 'layouts/headerAdminTab';
import React from 'react';

import { useAdminInfo } from '../../hooks/useAdminInfo';
import { dateToShorten } from '../../utils/date';

export const Subscribers = () => {
  const { subscribedOnUpdatesUsers } = useAdminInfo();

  return (
    <div className="admin">
      <Aside />
      <section>
        <Header />
        <div className="lists">
          <h5 className="!text-black">Subscribers.</h5>
        </div>
        <div className="relative">
          <table className="w-full table-auto border-collapse">
            <thead
              style={{
                borderTop: '1px solid #A5A5A5',
                borderBottom: '1px solid #A5A5A5',
              }}
            >
              <tr className="!text-[14px] text-black">
                <td className="p-[16px] font-semibold">Date, UTC</td>
                <td className="p-[16px] font-semibold">Email</td>
              </tr>
            </thead>
            <tbody>
              {subscribedOnUpdatesUsers?.subscribes?.map(user => (
                <tr key={user.id} className="odd:bg-[#F0F0F0]">
                  <td className="p-[16px]">{dateToShorten(new Date(user.createdAt), true)}</td>
                  <td className="p-[16px]">{user.email.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};
