import { useMediaQuery } from 'hooks/useMediaQuery';
import type { ReactNode } from 'react';
import { cn } from 'utils/cn';

import CaretRight from '../../assets/imgs/caret-right.svg';

export type FeatureCard = {
  bgColor: string;
  imageSrc: string;
  title: string;
  description: ReactNode;
  linkText: string;
  linkHref: string;
  isExternalLink: boolean;
};

const FeatureCard = ({
  bgColor,
  imageSrc,
  title,
  description,
  linkText,
  linkHref,
  isExternalLink,
}: FeatureCard) => {
  const isExtraSmall = useMediaQuery('(max-width: 375px)');
  return (
    <div className="flex flex-col rounded-[24px] shadow-[0px_2px_16px_0px_#14223D0D]">
      <div
        className={cn(
          'flex h-[81px] gap-4 rounded-t-[24px] p-6',
          bgColor,
          isExtraSmall ? '!p-4' : '',
        )}
      >
        <img src={imageSrc} alt={title} />
        <p className="text-xl font-medium text-[#14223D] sm:text-2xl">{title}</p>
      </div>
      <div
        className={cn(
          'flex h-full flex-col justify-between gap-4 rounded-b-[24px] p-4 md:gap-6',
          isExtraSmall ? '!p-4' : '',
        )}
      >
        <p className="text-base text-[#14223D]">{description}</p>
        <a
          href={linkHref}
          target={isExternalLink ? '_blank' : '_self'}
          className="group flex w-fit items-center gap-2 text-base text-[#14223D]"
        >
          {linkText}
          <img
            className="mt-[2px] opacity-0 transition-opacity group-hover:opacity-100"
            src={CaretRight}
            alt="chevron"
          />
        </a>
      </div>
    </div>
  );
};

export default FeatureCard;
