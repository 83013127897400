import type { FeatureCard } from 'components/PlatformToken/FeatureCard';

import defiSvg from '../../assets/imgs/feature-highlights/defi.svg';
import gradeSvg from '../../assets/imgs/feature-highlights/grade.svg';
import regulatorySvg from '../../assets/imgs/feature-highlights/regulatory.svg';

export const mbasisCardsData: FeatureCard[] = [
  {
    bgColor: 'bg-[#FDF7FE]',
    imageSrc: gradeSvg,
    title: 'Institutional-Grade',
    description:
      'A licensed asset manager executes the strategy with fiduciary care. Investments are protected within a Special Purpose Company.',
    linkText: 'Learn more',
    linkHref:
      'https://docs.midas.app/token-mechanics/mbasis/introduction-to-mbasis#institutional-grade',
    isExternalLink: true,
  },
  {
    bgColor: 'bg-[#def3ff]',
    imageSrc: regulatorySvg,
    title: 'Regulatory Compliant',
    description: (
      <>
        <span className="font-bold">mBASIS</span> is fully compliant with European Securities Laws.
      </>
    ),
    linkText: 'Learn more',
    linkHref:
      'https://docs.midas.app/token-mechanics/mbasis/introduction-to-mbasis#regulatory-compliant',
    isExternalLink: true,
  },
  {
    bgColor: 'bg-[#eefbf9]',
    imageSrc: defiSvg,
    title: 'DeFi Composability',
    description: (
      <>
        <span className="font-bold">mBASIS</span> is a non-custodial, permissionless token with daily
        auto-compounding interest. Use it as collateral without sacrificing yield.
      </>
    ),
    linkText: 'Learn more',
    linkHref:
      'https://docs.midas.app/token-mechanics/mbasis/introduction-to-mbasis#defi-composability',
    isExternalLink: true,
  },
  //   {
  //     bgColor: 'bg-[#fff4de]',
  //     imageSrc: launchSvg,
  //     title: 'Private Launch',
  //     description:
  //       'Stay ahead of the curve and join the Private Launch of mBASIS to get a share of the limited mBASIS TVL capacity.',
  //     linkText: 'Access the private launch',
  //     linkHref: '#',
  //     isExternalLink: false,
  //   },
];
