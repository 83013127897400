import { gql } from '@apollo/client';

export const GET_LAST_ST_USD_PAUSED_STATUS = gql`
  query GetUsersWithRole {
    stUsdPauses(orderBy: blockNumber, orderDirection: desc, first: 1) {
      paused
      id
      blockNumber
      timestamp
      transactionHash
    }
  }
`;
