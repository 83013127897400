import { useWeb3Modal } from '@web3modal/wagmi/react';
import clsx from 'clsx';
import { type ethers } from 'ethers/lib';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { useGreenListed } from 'hooks/useGreenListed';
import { usePriceUpdates } from 'hooks/usePriceUpdates';
import { useContactUsModal } from 'providers/ContactUsModalProvider';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { cn } from 'utils/cn';
import { comaFormat } from 'utils/comaFormat';
import { useAccount, useNetwork } from 'wagmi';

import type { PlatformToken, Token } from 'constants/tokens';

import { BigExchangerInput } from './BigExchangerInput';
import { SelectTokens } from './SelectTokens';

interface BigExchangerBodyProps {
  firstInput: {
    tokens: Token[];
    min: ethers.BigNumber;
    initValue: ethers.BigNumber;
    balance: number;
    value?: string;
    maxAvailable: boolean;
    usdValue: number;
    onValueChange: (value: string) => void;
  };
  secondInput?: {
    tokens: Token[];
    min: ethers.BigNumber;
    initValue: ethers.BigNumber;
    balance: number;
    value?: string;
    maxAvailable: boolean;
    onValueChange?: (value: ethers.BigNumber) => void;
  };
  minInvestment?: string;
  onSelectedTokenChange: (token: Token) => void;
  handleBtnClick: () => void;
  isBtnDisabled?: boolean;
  btnMessage?: string;
  platformToken: PlatformToken;
  simplifiedBtnsSection?: boolean;
  isRedeem?: boolean;
}

export const BigExchangerBody = ({
  firstInput,
  secondInput,
  minInvestment,
  onSelectedTokenChange,
  handleBtnClick,
  isBtnDisabled,
  btnMessage,
  platformToken,
  simplifiedBtnsSection = false,
  isRedeem = false,
}: BigExchangerBodyProps) => {
  const navigate = useNavigate();
  const priceUpdates = usePriceUpdates(platformToken);
  const { openModal } = useContactUsModal();
  const { chain } = useNetwork();

  const {
    tokens: firstInputTokens,
    balance: firstInputBalance,
    maxAvailable: firstInputMaxAvailable,
  } = firstInput;

  const {
    tokens: secondInputTokens,
    balance: secondInputBalance,
    // maxAvailable: secondInputMaxAvailable,
  } = secondInput ?? {};
  const { isConnected } = useAccount();
  const { isGreenListed } = useGreenListed();
  const { open } = useWeb3Modal();
  const [selectedOption, setSelectedOption] = useState<Token>();

  useEffect(() => {
    if (!selectedOption) return;
    onSelectedTokenChange?.(selectedOption);
  }, [selectedOption]);

  useEffect(() => {
    firstInput?.onValueChange?.('');
  }, [platformToken]);

  const handleSetMax = () => {
    firstInput?.onValueChange?.(String(firstInputBalance));
  };

  return (
    <div className="flex flex-col">
      <div className="calc">
        <div className={clsx('inputs w-full lg:h-[114px]')}>
          <div className="!flex !w-auto !px-[24px] lg:!h-[112px]">
            <div className="absolute top-[10px] z-[1] text-[14px] text-[#545558]">
              {secondInput ? 'You Invest' : 'Amount to redeem'}
            </div>
            <BigExchangerInput
              min={firstInput?.min ?? 0}
              initValue={firstInput?.initValue ?? 0}
              value={firstInput?.value}
              onValueChange={firstInput?.onValueChange}
            />
            <div className="absolute bottom-[10px] z-[1] text-[14px] text-[#545558]">
              {Number(firstInput?.value) !== 0
                ? isRedeem
                  ? `$${(Number(firstInput?.value) * Number(priceUpdates.lastPrice)).toLocaleString(
                      'en-US',
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )}`
                  : `$${Number(firstInput?.value).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`
                : ''}
            </div>

            <div
              style={{
                top: isConnected ? '24px' : '50%',
                transform: isConnected ? '' : 'translate(0,-50%)',
              }}
              className="select right-0"
            >
              <div className="pointer-events-none box-border flex w-full flex-col items-end pl-[18px] pr-[25px]">
                <SelectTokens onSelectedTokenChanged={setSelectedOption} tokens={firstInputTokens} />

                {isConnected ? (
                  <div
                    className="flex w-full justify-end text-[0.8rem] text-[grey]"
                    style={{
                      paddingTop: firstInputTokens?.length > 1 ? undefined : '0.5rem',
                      marginTop: firstInputTokens?.length > 1 ? '-0.3rem' : undefined,
                    }}
                  >
                    <span>
                      Balance: {comaFormat(formatUnits(parseUnits(firstInputBalance.toString())), 2)}
                      <span
                        style={{
                          paddingLeft: '0.3rem',
                          color: 'blue',
                          pointerEvents: 'all',
                          cursor: 'pointer',
                        }}
                      >
                        {firstInputMaxAvailable && <span onClick={handleSetMax}>max</span>}
                      </span>
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {!!secondInput && (
          <div className="my-[12px] flex w-available grid-cols-3 flex-col items-center justify-items-center gap-y-[10px] lg:my-[16px] lg:grid lg:gap-y-0 lg:px-[24px]">
            <div className="justify-self-start whitespace-pre-wrap text-[12px] text-[#545558] lg:whitespace-nowrap lg:text-[13.5px]">
              Minimum&nbsp;Amount: {comaFormat(Number(minInvestment || 0))}&nbsp;USDC
            </div>
            <div className="p-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="20"
                viewBox="0 0 17 20"
                fill="none"
                className="self-center"
              >
                <path
                  d="M7.49987 19.5814C8.05304 20.1395 8.95139 20.1395 9.50455 19.5814L16.5851 12.4382C17.1383 11.8801 17.1383 10.9738 16.5851 10.4158C16.032 9.85769 15.1336 9.85769 14.5804 10.4158L9.91611 15.1258V1.42865C9.91611 0.638428 9.28329 0 8.5 0C7.71671 0 7.08389 0.638428 7.08389 1.42865V15.1214L2.41956 10.4202C1.86639 9.86216 0.968046 9.86216 0.414877 10.4202C-0.138292 10.9783 -0.138292 11.8846 0.414877 12.4427L7.49544 19.5859L7.49987 19.5814Z"
                  fill="#B1B1B1"
                />
              </svg>
            </div>

            <div className="hidden justify-self-end lg:flex">
              <div className="w-[83px] whitespace-pre-wrap text-[12px] text-[#545558] lg:w-fit lg:whitespace-nowrap lg:text-[13.5px]">
                1&nbsp;{platformToken}&nbsp;=&nbsp;{priceUpdates.lastPrice}&nbsp;USDC
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        <div className="calc">
          {secondInput !== undefined &&
            secondInputTokens !== undefined &&
            secondInputBalance !== undefined && (
              <div className={clsx('inputs w-full lg:h-[114px]')}>
                <div className="!flex !w-auto !px-[24px] lg:!h-[112px]">
                  <div className="absolute top-[10px] z-[1] text-[14px] text-[#545558]">
                    You Receive
                  </div>
                  <BigExchangerInput
                    min={secondInput?.min ?? 0}
                    initValue={secondInput?.initValue ?? 0}
                    value={secondInput?.value?.toString()}
                    disabled={true}
                  />
                  <div className="absolute bottom-[10px] z-[1] text-[14px] text-[#545558]">
                    {Number(firstInput?.value) !== 0
                      ? `$${Number(firstInput?.value).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`
                      : ''}
                  </div>

                  <div
                    style={{
                      top: isConnected ? '24px' : '50%',
                      transform: isConnected ? '' : 'translate(0,-50%)',
                    }}
                    className="select right-0"
                  >
                    <div className="pointer-events-none box-border flex w-full flex-col items-end pl-[18px] pr-[25px]">
                      <SelectTokens
                        onSelectedTokenChanged={setSelectedOption}
                        tokens={secondInputTokens}
                      />

                      {isConnected ? (
                        <div
                          className="flex w-full justify-end text-[0.8rem] text-[grey]"
                          style={{
                            paddingTop: secondInputTokens?.length > 1 ? undefined : '0.5rem',
                            marginTop: secondInputTokens?.length > 1 ? '-0.3rem' : undefined,
                          }}
                        >
                          <span>
                            Balance:{' '}
                            {comaFormat(formatUnits(parseUnits(secondInputBalance.toString())), 2)}
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            )}
          {secondInput !== undefined ? (
            <div className="block w-full whitespace-nowrap pt-4 text-center text-[12px] text-[#545558] lg:hidden lg:w-fit lg:whitespace-nowrap lg:text-[13.5px]">
              1&nbsp;{platformToken}&nbsp;=&nbsp;{priceUpdates.lastPrice}&nbsp;USDC
            </div>
          ) : null}

          <div className="mt-6 flex flex-col gap-3 xl:items-center">
            {simplifiedBtnsSection && !isGreenListed && (
              <div
                className="btn-invest"
                onClick={() => {
                  navigate('/KYC');
                }}
              >
                <button className="white btn !w-full">Complete KYC Process to Get Access</button>
              </div>
            )}
            {(!isConnected || isGreenListed) && (
              <div className="flex w-available justify-center">
                <button
                  className="btn !w-full cursor-pointer !rounded-[12px] border-none !bg-[#FFAD53] py-[8px] text-[18px] font-semibold leading-[30px] text-[#14223D] !transition-colors hover:!bg-[#ffa74a] disabled:cursor-default disabled:!bg-[#ffae71] md:!w-[230px]"
                  onClick={isConnected ? handleBtnClick : () => open({ view: 'Connect' })}
                  disabled={isBtnDisabled}
                >
                  {isConnected ? btnMessage : 'Connect Wallet'}
                </button>
              </div>
            )}
            {!simplifiedBtnsSection && !isGreenListed && (
              <div
                className={cn(
                  'flex w-available justify-center xl:max-w-[425px]',
                  !isGreenListed ? '!max-w-full' : '',
                )}
              >
                <button
                  className="btn !w-full cursor-pointer !rounded-[12px] border-none !bg-transparent py-[8px] text-[18px] leading-[30px] text-[#14223D] hover:underline md:!w-[230px]"
                  onClick={() => openModal()}
                  style={{
                    border: '1px solid #DCDDE0',
                  }}
                >
                  {isGreenListed ? 'Contact Us' : 'Contact Us To Get Access'}
                </button>
              </div>
            )}
            {chain?.unsupported && (
              <div className="flex w-available justify-center">
                <button
                  className="btn !w-full cursor-pointer !rounded-[12px] border-none !bg-[#FFAD53] py-[8px] text-[18px] font-semibold leading-[30px] text-[#14223D] !transition-colors hover:!bg-[#ffa74a] disabled:cursor-default disabled:!bg-[#ffae71] md:!w-[230px]"
                  onClick={() => open({ view: 'Networks' })}
                >
                  Switch to Ethereum
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
