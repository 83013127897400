import { mainnet, sepolia } from 'wagmi';

const explorers: Record<string, string> = {
  [sepolia.name]: 'https://sepolia.etherscan.io/',
  [mainnet.name]: 'https://etherscan.io/',
};

export const getExplorerByNetwork = (network?: string) => {
  network = network ?? sepolia.network;
  if (!explorers[network]) network = sepolia.name;
  return explorers[network];
};
