import { useContractWrite, useNetwork } from 'wagmi';

import { getAddressesByChain } from 'constants/addresses';

import { mTBILL } from '../abis/mTBILL';

export const useBurnStUsd = () => {
  const { chain } = useNetwork();

  const { writeAsync: burnAsync } = useContractWrite({
    abi: mTBILL.abi,
    address: getAddressesByChain(chain?.network).mTBILL,
    functionName: 'burn',
  });

  return {
    burnAsync,
  };
};
