import { BigNumber, ethers } from 'ethers';
import { GET_USER_TRANSACTIONS_HISTORY } from 'graphql/queries.js/transactions';
import { useDefaultSubgraphQuery } from 'hooks/useDefaultSubgraphQuery';
import { useRedeem } from 'hooks/useRedeem';
import { useMemo, useState } from 'react';
import type { NavigateFunction } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { dateToShorten } from 'utils/date';
import { formatBN } from 'utils/formatBN';
import { transactionsToComponentData } from 'utils/parsers';
import { useAccount, useConfig, useNetwork } from 'wagmi';

import { getTokenByName } from 'constants/tokens';

import { CabinetMenu } from '../components/CabinetMenu';
import { NotificationModal } from '../components/NotificationModal';
import HeaderCabinet from '../layouts/headerCabinet';
import { comaFormat } from '../utils/comaFormat';
import { getHumanError, isCustomError } from '../utils/errors';

interface InvestmentRowProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataTx: any;
  setHeaderText: (str: string) => void;
  setModalText: (str: string) => void;
  setModalBtnText: (str: string | undefined) => void;
  setModalBtnCallback: (f: () => (() => void) | undefined) => void;
  setIsModalOpened: (str: boolean) => void;
}

export const InvestmentRow = ({
  dataTx,
  setModalText,
  setIsModalOpened,
  setHeaderText,
  setModalBtnCallback,
  setModalBtnText,
}: InvestmentRowProps) => {
  const navigate = useNavigate();

  const { chain } = useNetwork();
  const { publicClient } = useConfig();
  const { writeAsync, isLoading } = useRedeem(
    BigNumber.from(dataTx.rawValues.amount ?? 0),
    getTokenByName(chain?.name, 'USDC').address,
  );

  const onRedeemClick = async () => {
    if (isLoading || !writeAsync) return;

    try {
      await publicClient.waitForTransactionReceipt(await writeAsync());
      setHeaderText('Redemption Request Confirmation');
      setModalText(
        'The redemption request has been initiated and will usually be settled within 5 business days.',
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const hErr = getHumanError(err.shortMessage);

      let errText: string;
      let errBtnText: string | undefined;
      let errBtnOnClick: undefined | ((n: NavigateFunction) => void);

      if (isCustomError(hErr)) {
        errText = hErr.text;
        errBtnText = hErr.btnText;
        errBtnOnClick = hErr.btnOnClick;
      } else {
        errText = hErr;
      }

      setModalBtnText(errBtnText);
      setModalBtnCallback(() => () => errBtnOnClick?.bind(this, navigate));

      setHeaderText('Sorry');
      setModalText(`Error while sending the transaction: ${errText}`);
    } finally {
      setIsModalOpened(true);
    }
  };

  return (
    <div className="table-row">
      <div className="table-cell">
        <p>{dateToShorten(dataTx.date)}</p>
      </div>
      <div className="table-cell">
        <p>{dataTx.asset}</p>
      </div>
      <div className="table-cell">
        {dataTx.asset !== 'Pending' && (
          <button disabled={isLoading} onClick={onRedeemClick}>
            {isLoading ? 'Processing' : 'Redeem'}
          </button>
        )}
      </div>
    </div>
  );
};

export function Investments() {
  const { address } = useAccount();

  const { chain } = useNetwork();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { data: oldData } = useDefaultSubgraphQuery<any>(GET_USER_TRANSACTIONS_HISTORY, {
    variables: {
      userAddress: address,
    },
  });

  console.log('oldData ==>', oldData);
  const data = useMemo(() => {
    return {
      deposits: (oldData?.depositVaultDepositRequests ?? []).concat(
        oldData?.manualDepositEntities ?? [],
      ),
      mints: oldData?.mints ?? [],
      redemptionVaultRedeemRequests: (oldData?.redemptionVaultRedeemRequests ?? []).concat(
        oldData?.manualRedeemEntities ?? [],
      ),
    };
  }, [oldData]);

  const parsedTxData = transactionsToComponentData(chain?.name, data);
  console.log({ parsedTxData });

  const totalStUsdBought = parsedTxData.mints.reduce(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (prev: any, cur: any) => BigNumber.from(cur.rawValues.amount ?? '0').add(prev),
    ethers.constants.Zero,
  );

  const [headerText, setHeaderText] = useState('');
  const [modalText, setModalText] = useState('');
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [modalBtnCallback, setModalBtnCallback] = useState<() => (() => void) | undefined>(
    () => undefined,
  );
  const [modalBtnText, setModalBtnText] = useState<string | undefined>(undefined);

  const closeModal = () => {
    setIsModalOpened(false);
    setModalText('');
    setHeaderText('');
    setModalBtnText(undefined);
    setModalBtnCallback(() => () => undefined);
  };

  return (
    <div className="main-cabinet">
      <NotificationModal
        onBtnClick={() => {
          modalBtnCallback?.()?.();
          closeModal();
        }}
        isModalOpened={isModalOpened}
        closeModal={closeModal}
        content={{
          bodyContent: modalText,
          headerContent: (
            <>
              {headerText}
              <span className="text-[#1127E3]">.</span>
            </>
          ),
          btnContent: modalBtnText,
        }}
        maxWidth="500px"
      />
      <CabinetMenu />
      <div className="content-block">
        <HeaderCabinet />
        <div className="content-table">
          <p className="name-table">My Investments</p>
          <div className="table-info">
            <div className="head-table">
              <div className="name-cell">
                <p>Date</p>
              </div>
              <div className="name-cell">
                <p>Assets (mTBILL)</p>
              </div>
              <div className="name-cell">
                <p></p>
              </div>
            </div>
            {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              parsedTxData.mints.map((dataTx: any) => {
                return (
                  <InvestmentRow
                    key={dataTx.id}
                    dataTx={dataTx}
                    setModalText={setModalText}
                    setHeaderText={setHeaderText}
                    setIsModalOpened={setIsModalOpened}
                    setModalBtnCallback={setModalBtnCallback}
                    setModalBtnText={setModalBtnText}
                  />
                );
              })
            }

            {Boolean(parsedTxData?.investments?.length) && (
              <div className="last-row">
                <div className="table-cell-invest">
                  <p></p>
                </div>
                <div className="table-cell-invest"></div>
                <div className="table-cell-invest"></div>
                <div className="table-cell-invest">
                  <p>{comaFormat(formatBN(totalStUsdBought, 0))} mTBILL</p>
                </div>
                <div className="table-cell-invest">
                  <p></p>
                </div>
                <div className="table-cell-invest"></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Investments;
