import { gql } from '@apollo/client';

export const GET_PROTOCOL_TRANSACTIONS_HISTORY = gql`
  query GetUserTransactionsHistory {
    redemptionVaultRedeemRequests {
      id
      user
      amountStUsdIn
      amountUsdOut
      tokenOut
      isCancelled
      isFulfilled
      isManuallyRedeem
      creationTransactionHash
      closeTransactionHash
      fee
      createdAt
      closedAt
    }
    depositVaultDepositRequests {
      id
      user
      tokenIn
      amountUsdIn
      amountStUsdOut
      isCancelled
      isFulfilled
      createdAt
      closedAt
      fee
      creationTransactionHash
      closeTransactionHash
    }
    manualRedeemEntities {
      id
      user
      amountStUsdIn
      amountUsdOut
      tokenOut
      isCancelled
      isFulfilled
      creationTransactionHash
      createdAt
    }
    manualDepositEntities {
      id
      user
      tokenIn
      amountUsdIn
      amountStUsdOut
      isCancelled
      isFulfilled
      isManuallyDeposit
      createdAt
      creationTransactionHash
    }
  }
`;

export const GET_USER_TRANSACTIONS_HISTORY = gql`
  query GetUserTransactionsHistory($userAddress: Bytes) {
    redemptionVaultRedeemRequests(where: { user: $userAddress }) {
      id
      user
      amountStUsdIn
      tokenOut
      creationTransactionHash
      createdAt
    }
    mints: stUsdTransfers(
      where: { to: $userAddress, from: "0x0000000000000000000000000000000000000000" }
    ) {
      id
      amount
      txHash
      mToken
      to
      timestamp
    }
    depositVaultDepositRequests: deposits(where: { user: $userAddress }) {
      id
      user
      tokenIn
      mToken
      amountUsdIn
      createdAt
      creationTransactionHash
    }
  }
`;

export const GET_REDEMPTION_REQUESTS_BY_USER = gql`
  query GetUserTransactionsHistory($userAddress: Bytes) {
    redemptionVaultRedeemRequests(where: { user: $userAddress }) {
      id
      user
      amountStUsdIn
      amountUsdOut
      tokenOut
      isCancelled
      isFulfilled
      isManuallyRedeem
      creationTransactionHash
      closeTransactionHash
      createdAt
      closedAt
    }
  }
`;

export const GET_ALL_DEPOSIT_REQUESTS = gql`
  query GetUserTransactionsHistory {
    depositVaultDepositRequests {
      id
      user
      tokenIn
      amountUsdIn
      amountStUsdOut
      isCancelled
      isFulfilled
      isManuallyDeposit
      createdAt
      closedAt
      fee
      creationTransactionHash
      closeTransactionHash
    }
    manualDepositEntities {
      id
      user
      tokenIn
      amountUsdIn
      amountStUsdOut
      isCancelled
      isFulfilled
      isManuallyDeposit
      createdAt
      creationTransactionHash
    }
  }
`;

export const GET_ALL_REDEMPTION_REQUESTS = gql`
  query GetUserTransactionsHistory {
    redemptionVaultRedeemRequests {
      id
      user
      amountStUsdIn
      amountUsdOut
      tokenOut
      isCancelled
      isFulfilled
      isManuallyRedeem
      creationTransactionHash
      closeTransactionHash
      fee
      createdAt
      closedAt
    }
    manualRedeemEntities {
      id
      user
      amountStUsdIn
      amountUsdOut
      tokenOut
      isCancelled
      isFulfilled
      creationTransactionHash
      createdAt
    }
  }
`;

export const GET_PENDING_REDEMPTION_REQUESTS = gql`
  query GetUserTransactionsHistory {
    redemptionVaultRedeemRequests(where: { isFulfilled: false }) {
      id
      user
      amountStUsdIn
      amountUsdOut
      tokenOut
      isCancelled
      isFulfilled
      isManuallyRedeem
      creationTransactionHash
      closeTransactionHash
      createdAt
      closedAt
    }
  }
`;

export const GET_DEPOSIT_VAULT_TOKEN_WITHDRAWALS_AND_DEPOSIT = gql`
  query GetDepositVaultTokenWithdrawals {
    withdrawTokenEntityDeposits {
      id
      caller
      token
      withdrawTo
      amount
      timestamp
      transactionHash
    }
    usdctransfers {
      id
      from
      sender
      amount
      timestamp: createdAt
      transactionHash: creationTransactionHash
    }
  }
`;

export const GET_REDEMPTION_VAULT_TOKEN_WITHDRAWALS = gql`
  query GetDepositVaultTokenWithdrawals {
    withdrawTokenEntityRedemptions {
      id
      caller
      token
      withdrawTo
      amount
      timestamp
      transactionHash
    }
  }
`;
