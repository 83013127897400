import type { FC, PropsWithChildren } from 'react';
import { cn } from 'utils/cn';

import TwitterImg from '../../../assets/imgs/social/twitter-lg.svg';

export const BlogTitle: FC<PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => (
  <div
    className={cn(
      'box-border w-full px-5 text-[24px] font-[700] leading-[30px] text-[#14223D]',
      className,
    )}
  >
    {children}
  </div>
);

export const BlogSubtitle: FC<PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => (
  <div
    className={cn(
      'box-border w-full px-5 text-[20px] font-[600] leading-[28px] text-[#14223D]',
      className,
    )}
  >
    {children}
  </div>
);

export const BlogText: FC<PropsWithChildren<{ className?: string }>> = ({ children, className }) => (
  <div className={cn('box-border w-full px-5 text-[16px] leading-[30px] text-[#14223D]', className)}>
    {children}
  </div>
);

export const BlogTextLink: FC<PropsWithChildren<{ href: string }>> = ({ children, href }) => (
  <span className="cursor-pointer underline" onClick={() => window.open(href, '_blank')}>
    {children}
  </span>
);

export const ShareOnTwitter = () => {
  return (
    <a
      className="absolute right-[-355px] top-0 max-xl:hidden"
      href="https://twitter.com/intent/tweet?text=https://midas.app/blog"
      target="_blank"
      style={{ textDecoration: 'unset' }}
    >
      <div className="flex h-[145px] items-center gap-[25px] rounded-[16px] border-2 border-solid border-[#000] bg-waveGradient pl-5 pr-3 shadow-3xl">
        <img src={TwitterImg} alt="twitter-ico" />
        <p className="w-[138px] text-base font-medium text-stone-blue">
          Click to share this post on Twitter!
        </p>
      </div>
    </a>
  );
};
